import React, { useState } from 'react'
import createUseContext from 'constate'
import { eventTypes } from '../components/events/eventTypes'
import config from '../config'

const useEvents = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  const handleEvents = (event) => {
    const eventType = event.data.eventType || ''
    // eslint-disable-next-line no-console
    config.REACT_APP_DEBUG_MODE && console.info('%c handleEvents >', 'background: #222; color: #bada55', eventType)
    if (eventType === eventTypes.APPLICATION_LOADED) {
      setIsLoaded(true)
    }
  }

  const receiveMessage = (event) => {
    const isEvent = event.data.eventType || false
    if (isEvent) handleEvents(event)
  }

  React.useEffect(() => {
    window.addEventListener('message', receiveMessage)
    return () => {
      window.removeEventListener('message', receiveMessage)
    }
  })

  return {
    isLoaded,
    setIsLoaded,
  }
}

const [Provider, useEventsContext] = createUseContext(useEvents)
export const EventContextProvider = Provider
export default useEventsContext
