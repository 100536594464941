import { HTTPClientConfig, HttpMethodEnum, HTTPRequestCreate, HTTPResponsePagination } from '../types'
import {
  ParamsExportUsersWithToken,
  ParamsGetAllUsers,
  User, UserResponse,
  UserRoles,
} from '../../store/modules/users/types'
import { restClient, USER_API_BASE_URL, wrapperHeaderWithToken, dataToBody, FetchType } from '../configuration'
import { getByIdService, deleteByIdService, createService, updateService } from '../services.utils'

export const userCreate = (req: HTTPRequestCreate<UserRoles>): Promise<any> =>
  createService<User>(req, () => `${USER_API_BASE_URL}/accounts/v2`)

export const updateUser = async (req: any): Promise<any> =>
  updateService<User>(req, (req: any) => `${USER_API_BASE_URL}/accounts/v2/${req.id}`)

export const getAllUsers = async (request: ParamsGetAllUsers): Promise<any> => {
  const obj: any = {}
  if (request.page !== undefined) obj.page = request.page
  if (request.limit !== undefined) obj.limit = request.limit
  if (request.email !== undefined && request.email !== '') obj.email__like = request.email
  if (request.roles !== undefined && request.roles !== '') obj.roles = request.roles
  if (request.permissions !== undefined && request.permissions !== '') obj.permissions = request.permissions
  if (request.tenants !== undefined && request.tenants !== '') obj.tenants = request.tenants
  const params = new URLSearchParams(obj)

  const uri = `${USER_API_BASE_URL}/bff/rbac/accounts/v3?${params}`
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.GET,
    headers: wrapperHeaderWithToken(request.token),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)
      const data: HTTPResponsePagination<any> = await response.json()
      resolve(data)
    } catch (error) {
      reject(`${error}`)
    }
  })
}

export const getUserById = async (req: any): Promise<UserResponse> =>
  getByIdService(req, (req: any, params: any) => `${USER_API_BASE_URL}/accounts/v2/${req.id}?${params}`)
export const deleteUsers = async (req: any): Promise<any> =>
  deleteByIdService(req, (req: any) => `${USER_API_BASE_URL}/accounts/v2/${req.id}`)

export const exportUsers = async (request: ParamsExportUsersWithToken): Promise<Blob> => {
  const requestBody: any = {}

  if (request.email !== undefined && request.email !== '') requestBody.email__like = request.email
  if (request.tenants.length > 0) requestBody.tenant_id__in = request.tenants
  if (request.roles.length > 0) requestBody.role_id__in = request.roles
  if (request.permissions.length > 0) requestBody.permission_id__in = request.permissions

  const uri = `${USER_API_BASE_URL}/accounts/v2/export`

  const config: HTTPClientConfig = {
    method: HttpMethodEnum.POST,
    headers: wrapperHeaderWithToken(request.token, {}, FetchType.JSON),
    body: dataToBody(requestBody),
  }

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)

      if (response.status === 200) {
        const data: Blob = await response.blob()
        resolve(data)
      } else if (response.status === 400) {
        const data: any = await response.json()
        reject(data)
      } else if (response.status === 403) {
        reject('UNAUTHORIZED')
      } else {
        reject('INTERNAL_SERVER_ERROR')
      }
    } catch (error) {
      reject(`${error}`)
    }
  })
}
