import { IconProps } from '@jarvis-catalyst/jarvis-ui-kit'

export type MenuIcon = {
  name: IconProps['name']
}

export const DefaultIcon: MenuIcon = { name: 'Circle' }

export const AvailableMenuIcons: MenuIcon[] = [
  {
    name: 'Circle',
  },
  {
    name: 'Check',
  },
  {
    name: 'Bell',
  },
  {
    name: 'Calendar',
  },
  {
    name: 'Catalog',
  },
  {
    name: 'ClickAndCollect',
  },
  {
    name: 'Clock',
  },
  {
    name: 'DeliveryTruck',
  },
  {
    name: 'Settings',
  },
  {
    name: 'Site',
  },
  {
    name: 'SoldOut',
  },
  {
    name: 'User',
  },
  {
    name: 'UserManagement',
  },
  {
    name: 'File',
  },
  {
    name: 'Expensive',
  },
]

export const getMenuIconByName = (name: any): MenuIcon | undefined => AvailableMenuIcons.find((m) => m.name === name)
