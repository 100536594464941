import React from 'react'
import { Tooltip, Typography, Icon } from '@jarvis-catalyst/jarvis-ui-kit'
import { useTranslation } from 'react-i18next'

interface Props {
  title: string
  tooltipTitle: string
}

const generateTooltipTitle = (tooltipTitle: string, t: any) => (
  <Typography
    variant="H200"
    color="#8B97AD"
    gutterBottom
  >
    <div style={{ display: 'flex', lineHeight: 1.5, maxWidth: 240 }}>{t(tooltipTitle)}</div>
  </Typography>
)

const CustomTitleAndTooltip: React.FC<Props> = function ({ title, tooltipTitle }: Props) {
  const { t } = useTranslation()
  return (
    <section style={{ display: 'flex', alignContent: 'center', alignItems: 'center', marginBottom: 20, padding: 16 }}>
      <Typography
        variant="H900"
        classes="decoration"
      >
        <div style={{ textDecoration: 'underline', marginRight: 18 }}>{t(title)}</div>
      </Typography>
      <div
        className="conteiner-tooltip"
        style={{ margin: 0, display: 'flex', height: 22 }}
      >
        <Tooltip
          title={generateTooltipTitle(tooltipTitle, t)}
          placement="right-start"
        >
          <Icon
            name="Info"
            color="white"
            size="medium"
            bgcolor="#4a1eeb"
          />
        </Tooltip>
      </div>
    </section>
  )
}

export default CustomTitleAndTooltip
