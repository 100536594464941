import { Badge, IconCloseSmall } from '@jarvis-catalyst/ui-components'
import React from 'react'
import './FilterTag.css'

interface Props {
  testId: string;
  filter: string;
  searchText: string;
  className?: string;
  handleClose: (event: any) => void;
}

const FilterTag: React.FC<Props> = function ({ testId, filter, searchText, className, handleClose }: Props) {
  return (
    <Badge className={`filter__badge ${className}`}>
      <div data-testid={`${testId}-close-tag`}>
        <span>
          {`${filter}: `}
        </span>
        <span>{searchText}</span>
        <button
          type="button"
          aria-label="Close tag"
          className="close__badge"
          onClick={handleClose}
        >
          <IconCloseSmall />
        </button>
      </div>
    </Badge>
  )
}

export default FilterTag
