/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import createUseContext from 'constate'
import { useAuthentication } from './AuthenticationContext'
import { useAuthenticationOIDC } from './AuthenticationContextOIDC'
import config from '../config'

export const useAuthenticationContextSelected = (config) => {
  if (config && config.REACT_APP_LOGIN_PROVIDER === 'auth0') {
    return useAuthentication
  }
  return useAuthenticationOIDC
}

const [Provider, useAuthenticationContextWrapper] = createUseContext(useAuthenticationContextSelected(config))
export const AuthContextWrapperProvider = Provider
export default useAuthenticationContextWrapper
