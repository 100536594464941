import { fifteenMinuteHavePassed } from './localCacheStorage'
import getLocalStorage from '../getLocalStorage'

const storeSet = (key: string, data: any) => {
  getLocalStorage().setItem(key, JSON.stringify(data))
}

const storeGet = (key: string) => JSON.parse(getLocalStorage().getItem(key) || 'false')

const cacheStore = (key: string) => {
  const setData = (data: any) => {
    const d = {
      timestamp: new Date().getTime(),
      data,
    }
    storeSet(key, d)
  }

  const getData = () => {
    const s = storeGet(key)
    if (s) return s.data
    return {}
  }

  const getIsExpired = () => {
    const data = storeGet(key)
    if (data) {
      return fifteenMinuteHavePassed(new Date(data.timestamp), new Date())
    }
    return true
  }

  return {
    isExpired: getIsExpired(),
    setData,
    getData,
  }
}

export default cacheStore
