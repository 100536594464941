import React, { useEffect } from 'react'
import LogsListing from '../components/listing/LogsListing'
import { applicationUiSidebarAction, applicationUiTenantDependAction } from '../../../store/modules/application/actions'

export const LogsListingBySubscription = function () {
  useEffect(() => {
    applicationUiSidebarAction('hidden')
    applicationUiTenantDependAction(false)
  }, [])

  return (
    <div>
      <LogsListing tenant={false} />
    </div>
  )
}
