import React from 'react'
import { Title, Text, Spinner } from '@jarvis-catalyst/ui-components'
import './CustomNotification.css'

interface Props {
  style?: 'default';
  position?: 'relative' | 'fixed';
  icon?: any;
  title?: string;
  message?: string;
  visible?: boolean;
}
const CustomNotification: React.FC<Props> = function ({
  style = 'default',
  position = 'fixed',
  icon = <Spinner />,
  title,
  message,
  visible = false,
}:Props) {
  if (visible) {
    return (
      <div className={`custom-notification ${style}-style ${position}-position`}>
        <div className="icon">
          {icon}
        </div>
        <div className="message">
          <div>
            {title && <Title tag="h4">{title}</Title>}
            {message && <Text>{message}</Text>}
          </div>
        </div>
      </div>
    )
  }
  return null
}

export { CustomNotification }
