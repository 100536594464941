import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@jarvis-catalyst/jarvis-ui-kit'
import CustomBox from '../../../../components/custombox/custombox'
import { BoxAndtextProps } from '../../dashboard.types'

const CustomBoxTitle = function ({ boxBackgroundColor, link, imgUrl, imgSize }: BoxAndtextProps) {
  const { t } = useTranslation()
  return (
    <CustomBox
      sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
    >
      <div
        data-testid="box"
        style={{ width: '100%', height: '100%', marginBottom: '40px' }}
      >
        <Box
          sx={{
            backgroundColor: boxBackgroundColor,
            height: '100%',
            borderRadius: '4px',
            width: '100%',
            display: 'flex',
            marginBottom: '40px',
            position: 'top',
            backgroundImage: imgUrl,
            backgroundSize: imgSize,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      </div>
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', height: '70%' }}>
        <div
          data-testid="link"
          style={{ width: '100%', height: '70%' }}
        >
          <a
            style={{
              padding: 0,
              color: 'rgba(117, 105, 244, 1)',
              cursor: 'pointer',
              textDecoration: 'none',
              display: 'flex',
              justifyContent: 'center',
            }}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography
              color="primary"
              variant="H300"
              align="center"
            >
              {t(link.title)}
            </Typography>
          </a>
        </div>
      </Box>
    </CustomBox>
  )
}

export default CustomBoxTitle
