import { Dispatch } from 'redux'
import { getServicesStore, getDomainsStore } from '.'
import { enhancementAction } from '../../index'
import { DomainServicesItem } from './types'
import { getDomains, getServices } from '../../../services/clients/domains-services'
import { HTTPResponse } from '../../../services/types'
import getSubscriptionIdFromToken from '../../../utils/getSubscriptionIdFromToken'

export const domainsGetAction = (tenantDependent: boolean): void =>
  enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
    const { token, activeTenant } = getState().application
    const subscriptionId = getSubscriptionIdFromToken(token)
    const params = tenantDependent ? { token, tenant: activeTenant.id } : { token, subscription: subscriptionId }
    dispatch({ type: getDomainsStore.actions.START, payload: null })
    try {
      const data: HTTPResponse<DomainServicesItem> = await getDomains(params)
      dispatch({
        type: getDomainsStore.actions.RESULT,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: getDomainsStore.actions.ERROR,
        payload: error,
      })
    }
  })

export const servicesGetAction = (domain: string, tenantDependent: boolean): void =>
  enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
    const { token, activeTenant } = getState().application
    const subscriptionId = getSubscriptionIdFromToken(token)
    const params = tenantDependent
      ? { domain, token, tenant: activeTenant.id }
      : { domain, token, subscription: subscriptionId }
    dispatch({ type: getServicesStore.actions.START, payload: null })
    try {
      const data: HTTPResponse<DomainServicesItem> = await getServices(params)
      dispatch({
        type: getServicesStore.actions.RESULT,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: getServicesStore.actions.ERROR,
        payload: error,
      })
    }
  })
