/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Grid, GridItem } from '@jarvis-catalyst/ui-components'
import { applicationUiTenantDependAction } from '../../../store/modules/application/actions'

import Header from '../../layout/header/Header'

const ErrorPage = function ({ children }) {
  const { uiTenantDepend } = useSelector((s) => s.application)

  useEffect(() => {
    applicationUiTenantDependAction(false)
  }, [uiTenantDepend])

  return (
    <Grid
      repeatNumber={12}
      repeatTemplate="minmax(50px, 1fr)"
      gap={10}
      margin={30}
      className="app"
    >
      <GridItem
        columnStart={1}
        colSpan={12}
        className="app__header"
      >
        <Header />
      </GridItem>
      <GridItem
        columnStart={2}
        colSpan={10}
        className="app__body"
      >
        {children}
      </GridItem>
    </Grid>
  )
}

ErrorPage.propTypes = {
  children: PropTypes.element.isRequired,
}

export default ErrorPage
