import React, { useEffect, useState } from 'react'
import { Autocomplete, Icon, Typography } from '@jarvis-catalyst/jarvis-ui-kit'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Controller } from 'react-hook-form'
import { permissionsByRolesGetAllSelector } from '../../../../store/modules/custom-permissions/selectors'

type PermissionsSelectorProps = {
  permissions: string[]
  control: any
  name: string
}

export const PermissionsSelector = function ({ permissions, control, name }: PermissionsSelectorProps) {
  const { t } = useTranslation()
  const [permissionsOptions, setPermissionsOptions] = useState([])
  const permissionsStore = useSelector(permissionsByRolesGetAllSelector)

  useEffect(() => {
    const opt: any = permissionsStore.data.map((t: any) => ({ value: t.id, label: `${t.group}.${t.name}` }))
    setPermissionsOptions(opt)
  }, [permissionsStore])

  const parserSelectedPermissions = (permissions: string[]): any[] => {
    const filtered: any = permissionsStore.data.filter(
      (t: any) => permissions && permissions.includes(`${t.group}.${t.name}`),
    )
    const mapper: any = filtered.map((t: any) => ({ label: `${t.group}.${t.name}`, value: t.id, name: t?.name }))
    return mapper
  }

  return permissionsStore.error ? (
    <span data-testid="icon-error">
      <Icon
        name="Warning"
        size="medium"
        color="#e0350b"
      />
      {t('MANAGE_CUSTOM_APPLICATION.PERMISSION_ERROR')}
    </span>
  ) : (
    <Controller
      name={name}
      control={control}
      defaultValue={permissions}
      render={({ field: { onChange, value } }) => (
        <div
          className="application_mgmt--dropdown"
          style={{ marginTop: 15, marginBottom: 10 }}
        >
          <div style={{ marginBottom: 10 }}>
            <Typography variant="H100">{t('MANAGE_CUSTOM_APPLICATION.PERMISSION_TITLE')}</Typography>
          </div>
          <Autocomplete
            data-testid="autocomplete-permissions"
            placeholder={t('MANAGE_CUSTOM_APPLICATION.PERMISSION_LABEL')}
            value={parserSelectedPermissions(value)}
            options={permissionsOptions}
            multiple
            onChange={(e, option) => {
              onChange((option as any).map((p: any) => p.label))
            }}
          />
        </div>
      )}
    />
  )
}
