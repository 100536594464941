export default {
  COD_000_CRASH_INIT_LOGIN_PROVIDER: { code: 'COD_000_CRASH_INIT_LOGIN_PROVIDER', message: 'An error ocurred trying to connect to login provider' },
  COD_001_USER_HAS_NO_TENANT: { code: 'COD_001_USER_HAS_NO_TENANT', message: 'user has no assigned tenant' },
  COD_002_CRASH_DURING_INIT_TENANT_SERVICE: { code: 'COD_002_CRASH_DURING_INIT_TENANT_SERVICE', message: 'An error has ocurred trying to get user tenants' },
  COD_003_CRASH_DURING_INIT_RBAC_SERVICE: { code: 'COD_003_CRASH_DURING_INIT_RBAC_SERVICE', message: 'An error has ocurred trying to get user permissions' },
  COD_004_CRASH_DURING_INIT_SITES_SERVICE: { code: 'COD_004_CRASH_DURING_INIT_SITES_SERVICE', message: 'An error has ocurred trying to get sites' },
  COD_005_CRASH_DURING_INIT_APPLICATION_SERVICE: { code: 'COD_005_CRASH_DURING_INIT_APP_SERVICE', message: 'An error has ocurred trying to get applications' },
  COD_006_USER_HAS_NO_ACCESS_TO_SUBSCRIPTION: { code: 'COD_006_USER_HAS_NO_ACCESS_TO_SUBSCRIPTION', message: 'User has no access to the subscription' },
  COD_007_CRASH_DURING_INIT_AUTHENTICATION: { code: 'COD_007_CRASH_DURING_INIT_AUTHENTICATION', message: 'An error ocurred trying to get user authenticated' },
  COD_009_CRASH_DURING_INIT_GROUPS_APP_SERVICE: { code: 'COD_009_CRASH_DURING_INIT_GROUPS', message: 'An error has ocurred trying to get groups of applications' },
  COD_010_ACCESS_TOKEN_EXPIRED: { code: 'COD_010_ACCESS_TOKEN_EXPIRED', message: 'Access token expired..' },
}
