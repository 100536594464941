import { Icon, Spinner, Table, Button } from '@jarvis-catalyst/ui-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import '../tenantSelector/TenantSiteSelector.css'
import { Tenant } from '../../../../store/modules/tenants/types'
import { Site } from '../../../../store/modules/sites/types'
import './TenantSiteTable.css'

export type ListingData = { id: string, name: string }

type ListingProps = {
    selected?: string
    setSelected: (data: any) => void
    data: Tenant[] | Site[],
    loading: boolean,
}

const TenantSiteTable = function ({ selected, setSelected, data, loading }: ListingProps) {
  const { t } = useTranslation()

  const isActive = (id?: string) => selected && id === selected

  const iconComponent = ({ id }: any) => (
    <Icon
      name="Star"
      color={isActive(id) ? '#7569F4' : 'white'}
      size={16}
    />
  )

  const nameComponent = (selected: any) => (
    <Button
      color="table"
      onClick={() => setSelected(selected)}
      className={`select__${isActive(selected.id) ? 'button__active' : 'button'}`}
    >
      {selected.name}
    </Button>
  )

  const schema = [
    {
      title: '',
      getComponent: iconComponent,
    },
    {
      title: t('NAME'),
      getComponent: nameComponent,
    },
  ]

  return (
    <Table
      schema={schema}
      data={data}
      isLoading={loading}
      componentLoading={<Spinner position="absolute" />}
      className="tenant-site-selector list"
    />
  )
}

export default TenantSiteTable
