import { useEffect } from 'react'
import { getAllSitesStore } from '../store/modules/sites'
import { applicationSetConfigurationAction, applicationUserHasTenantAction } from '../store/modules/application/actions'
import { TENANT_SITE_KEY } from '../utils/cache-store/types'
import { getMyTenantsStore } from '../store/modules/tenants'
import { UseUpdateDataProps, SuccessProps, InitProps } from '../modules/shell/modules/mainRouter.types'
import cacheStore from '../utils/cache-store'
import useSetTenantSitesSeller from './useSetTenantSitesSeller'

export const init = async ({ token, successCallback, failed, userId }: InitProps) => {
  const { setTenantSitesSeller } = useSetTenantSitesSeller()
  if (token) {
    const query = new URLSearchParams(window.location.search)
    const tenant = query.get('tenant')
    let sites: string[] = []
    const qSites = query.get('sites')
    if (qSites != null) {
      sites = decodeURIComponent(qSites).split(',')
    }
    const seller = query.get('seller') || ''

    await setTenantSitesSeller({
      token,
      tenant,
      sitesQs: sites,
      sellerUrl: seller,
      successCallback,
      failed,
      userId,
    })
  }
}

export function useSetInitializationData({ token, history, dispatch, setErrorUi, userId }: UseUpdateDataProps) {
  useEffect(() => {
    const query = new URLSearchParams(window.location.search)

    const successCallback = ({ tenant, sitesQss, tenants, sites, sellers }: SuccessProps) => {
      if (tenant) {
        applicationUserHasTenantAction(true)
        const sitesQs = `${
          sitesQss ? sitesQss.reduce((prev: string, curr: any) => `${prev},${curr.key}`, '') : ''
        }`.replace(',', '')
        query.set('tenant', tenant.id)
        query.set('sites', encodeURIComponent(sitesQs))
        query.set('seller', sellers[0]?.key || '')
        history.push({ search: query.toString() })
        dispatch({
          type: getAllSitesStore.actions.RESULT,
          payload: sites,
        })
      }

      applicationSetConfigurationAction({
        key: 'activeTenant',
        value: { id: tenant.id, name: tenant.name, sites: sitesQss, sellers },
      })

      cacheStore(TENANT_SITE_KEY).setData({ id: tenant.id, name: tenant.name, sites: sitesQss, sellers })

      dispatch({
        type: getMyTenantsStore.actions.RESULT,
        payload: tenants,
      })
    }

    const failedCallback = (error: { code: string; message: string }) => {
      setErrorUi(error)
    }

    if (token && history) {
      init({ token, successCallback, failed: failedCallback, userId })
    }
  }, [token, history, dispatch])
}
