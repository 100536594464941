import { useEffect } from 'react'

const defaultTitle = 'PAT - Platform Admin Tool'
const defaultShotTitle = 'PAT'

const useDocumentTitle = (title: string): void => {
  useEffect(() => {
    document.title = `${defaultShotTitle} - ${title}`
  }, [title])

  useEffect(() => (): void => {
    document.title = defaultTitle
  }, [])
}

export default useDocumentTitle
