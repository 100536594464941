import React, { useState, useRef, useEffect } from 'react'
import { Box, Button } from '@jarvis-catalyst/jarvis-ui-kit'
import { useTranslation } from 'react-i18next'

interface LoadMoreBoxProps {
  height: number
  children: React.ReactNode
}

const LoadMoreBox = function ({ height, children }: LoadMoreBoxProps) {
  const [isExpanded, setIsExpanded] = useState(false)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (contentRef.current && contentRef.current.scrollHeight > height) {
      setIsOverflowing(true)
    }
  }, [height, children])

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div
      style={{
        maxHeight: isExpanded ? 'none' : `${height}px`,
        overflow: 'hidden',
        position: 'relative',
        padding: '16px',
        boxSizing: 'border-box',
      }}
      ref={contentRef}
    >
      {children}

      <Box sx={{ marginTop: '165px' }}>
        {isOverflowing && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              justifyContent: 'center',
              paddingBottom: '16px',
              background: 'linear-gradient(to bottom, transparent, #f8f8fb)',
            }}
          >
            <Box sx={{ marginTop: 20 }}>
              <Button
                onClick={toggleExpand}
                variant="contained"
                color="primary"
              >
                {isExpanded ? t('SHOW_LESS') : t('SHOW_MORE')}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </div>
  )
}

export default LoadMoreBox
