import React from 'react'
import { Classes, Icon, Tooltip } from '@jarvis-catalyst/ui-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import ExpandIcon from './ExpandIcon'
import menu, { Item } from './utils/menu'
import AppLink from './AppLink'

type MenuProps = {
  pathName: string
}

const Menu = function ({ pathName }: MenuProps) {
  const { t } = useTranslation()
  const { uiSidebar, language } = useSelector((s: any) => s.application)

  const translate = (text?: any) => ((text && typeof text !== 'string') ? text[language] : t(`${text}`))

  const makeLink = (i: Item, currentPage: string) => {
    const name = translate(i.name)
    const item = makeItemInternal(i, name, uiSidebar)
    return i.isActive
      ? (<AppLink
        item={i}
        name={name}
      >
        {item}
        {' '}
        {!isEmpty(i.submenu) && <ExpandIcon
          item={i}
          currentPage={currentPage}
        />}
      </AppLink>)
      : item
  }

  const iconTooltip = (icon: any, name: string) => <Tooltip
    type="customTooltip"
    position="right-top"
    offset={12}
    content={name}
    className="tooltipIcon"
  >
    <Icon
      name={icon}
      size={16}
    />
  </Tooltip>

  const longNameTooltip = (name: string) => {
    const content = <span title="">{name}</span>
    return name?.length >= 20
      ? <Tooltip
        type="customTooltip"
        position="right-top"
        offset={55}
        content={name}
        className="tooltipName"
      >
        {content}
      </Tooltip>
      : content
  }

  const makeItemInternal = (i: Item, name: string, uiSidebar: string) =>
    (i.type !== 'divider'
      ? uiSidebar === 'full'
        ? (<>
          {i.icon ? <Icon
            name={i.icon}
            size={16}
          /> : <br />}
          {longNameTooltip(name)}
        </>)
        : (<>
          {i.icon ? iconTooltip(i.icon, name) : <br />}
          {i.type !== 'item' && <span title="">{name}</span>}
        </>)
      : (<i />))

  const isCurrentPage = (i: Item) => i.route && pathName.startsWith(i.route)

  const makeItem = (i: Item, index: number) => {
    const currentPage = !!isCurrentPage(i)

    if (i.submenu?.some((sub) => isCurrentPage(sub))) {
      i.submenu.map((s) => s.hidden = false)
    }

    const className = new Classes('layout__sidebar__menu__item')
      .if(currentPage).add('layout__sidebar__menu__item--active')
      .if(!i.isActive && i.type === 'item')
      .add('layout__sidebar__menu__item--disabled')
      .if(i.type === 'divider')
      .add('layout__sidebar__menu__item--divider')
      .if(i.type === 'label')
      .add('layout__sidebar__menu__item--label')
      .if(i.type === 'labelMenu')
      .add('layout__sidebar__menu__item--labelMenu')
    return (currentPage || !i.hidden) && (<div
      className={className.build()}
      key={index}
    >
      {makeLink(i, pathName)}
    </div>)
  }

  return (
    <div>
      {menu.build().map((item: Item, i: number) => makeItem(item, i))}
    </div>
  )
}

export default Menu
