import { combineReducers } from 'redux'
import { createCommitReducer, createGetAllReducer, createGetReducer } from '../../utils'

export const getAllLogsStore = createGetAllReducer('LOGS')
export const exportLogsStore = createCommitReducer('LOGS_EXPORT', 'CREATE')
export const getLogsStore = createGetReducer('LOGS', {
  id: '',
  clientIp: '',
  countryCode: '',
  request: {
    method: 'GET',
    resource_path: '',
    absoluteUrl: '',
    userAgent: '',
  },
  identity: {
    id: '',
    email: '',
  },
  createdAt: '',
  state: {
    old: '',
    new: '',
  },
})

const rootReducer = combineReducers({
  getAll: getAllLogsStore.reducer,
  getById: getLogsStore.reducer,
  export: exportLogsStore.reducer,
})

export default rootReducer
