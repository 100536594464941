import React, { useState } from 'react'
import './CardBlock.css'
import { IconButton, Typography } from '@jarvis-catalyst/jarvis-ui-kit'

interface CardBlockProps {
  title: React.ReactNode
  description?: string
  children: React.ReactNode
  open?: boolean
}

const CardBlock: React.FC<CardBlockProps> = function ({ title, description, children, open = true }: CardBlockProps) {
  const [isOpen, setOpen] = useState(open)
  return (
    <div className="uci__cardblock">
      <div className="flex-row justify-content-between">
        <div style={{ marginBottom: 10 }}>
          <h3>{title}</h3>
          {description && <Typography variant="h4">{description}</Typography>}
        </div>
        {isOpen ? (
          <IconButton
            name="ChevronUp"
            size="medium"
            onClick={() => {
              setOpen(!isOpen)
            }}
          />
        ) : (
          <IconButton
            name="ChevronDown"
            onClick={() => {
              setOpen(!isOpen)
            }}
          />
        )}
      </div>
      <div>{isOpen && children}</div>
    </div>
  )
}

export default CardBlock
