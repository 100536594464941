import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from '@jarvis-catalyst/ui-components'
import { rolesByAccountIdAction, userPermissionsGetAction } from '../../store/modules/user-permissions/actions'
import { processError, RBAC_ERROR } from '../../modules/shell/contexts/handleError'

type UserPermissionsWrapperProps = {
  children: React.ReactNode
}

const UserPermissionsWrapper = function ({ children }: UserPermissionsWrapperProps) {
  const [lastTenant, setLastTenant] = useState('')
  const { token, activeTenant, userHasTenant, claims } = useSelector((s: any) => s.application)
  const { loading, success, error } = useSelector((s: any) => s.instrospect.getUserPermissions)

  useEffect(() => {
    const userLoaded = () => {
      if (userHasTenant === undefined) return false
      return userHasTenant ? activeTenant.id !== '' : true
    }
    if (token && userLoaded() && !success && !error && lastTenant === '') {
      rolesByAccountIdAction(claims.accountId)
      userPermissionsGetAction()
      setLastTenant(activeTenant.id)
    }
    if (error) {
      processError({ message: RBAC_ERROR })
    }
  }, [token, activeTenant, success, error, setLastTenant, lastTenant, userHasTenant])

  useEffect(() => {
    if (lastTenant !== activeTenant.id && lastTenant !== '') {
      rolesByAccountIdAction(claims.accountId)
      userPermissionsGetAction()
      setLastTenant(activeTenant.id)
    }
  }, [lastTenant, activeTenant, setLastTenant])

  return loading && lastTenant === '' ? <Spinner position="absolute" /> : children
}

export default UserPermissionsWrapper
