import React from 'react'
import { AuthProvider } from 'react-oidc-context'
import config from './config'
import MainRouter from './modules/MainRouter'
import { Auth0Provider } from '../../utils/third-party/react-auth0-wrapper'
import { AuthContextWrapperProvider } from './contexts/AuthenticationContextWrapper'
import { EventContextProvider } from './contexts/EventContext'

import './i18n'
import history from './history'
import { UserPermissionsWrapper } from '../../utils/user-permissions'

const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl ? appState.targetUrl : window.location.pathname,
  )
}

const {
  REACT_APP_AUDIENCE,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_CLIENT_ID,
  REACT_APP_LEEWAY,
  REACT_APP_LOGIN_PROVIDER,
  REACT_APP_OIDC_AUTHORITY,
  REACT_APP_OIDC_CLIENT_ID,
  REACT_APP_OIDC_REDIRECT_URI,
} = config

const REDIRECT_URI = REACT_APP_OIDC_REDIRECT_URI + window.location.pathname
const oidcConfig = {
  authority: REACT_APP_OIDC_AUTHORITY,
  client_id: REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: REDIRECT_URI,
  post_logout_redirect_uri: REDIRECT_URI,
}

const renderContext = () => (
  <AuthContextWrapperProvider history={history}>
    <EventContextProvider history={history}>
      <UserPermissionsWrapper>
        <MainRouter />
      </UserPermissionsWrapper>
    </EventContextProvider>
  </AuthContextWrapperProvider>
)

const Shell = function () {
  return REACT_APP_LOGIN_PROVIDER === 'auth0' ? (
    <Auth0Provider
      audience={REACT_APP_AUDIENCE}
      domain={REACT_APP_AUTH0_DOMAIN}
      client_id={REACT_APP_CLIENT_ID}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      leeway={REACT_APP_LEEWAY}
    >
      {renderContext()}
    </Auth0Provider>
  ) : (
    <AuthProvider {...oidcConfig}>{renderContext()}</AuthProvider>
  )
}

export default Shell
