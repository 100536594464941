import { Dispatch } from 'redux'
import { getAllSites } from '../../../services/clients/sites'
import { getAllSitesStore } from '../../../store/modules/sites'
import { applicationSetConfigurationAction } from '../../../store/modules/application/actions'

function getData(token: string, currentTenant: any, dispatch: Dispatch<any>, history: any, setLoading: any) {
  getAllSites({
    token,
    page: 1,
    limit: 100,
    tenant: currentTenant.value,
  }).then((sites: any) => {
    dispatch({
      type: getAllSitesStore.actions.RESULT,
      payload: sites,
    })
    const query = new URLSearchParams(window.location.search)
    if (sites.data.length === 0) {
      query.set('tenant', currentTenant.value)
      query.set('sites', '')
      query.set('seller', '')
      history.push({ search: query.toString() })

      applicationSetConfigurationAction({
        key: 'activeTenant',
        value: { id: currentTenant.value, name: currentTenant.label, sites: [], sellers: [] },
      })
    } else {
      query.set('tenant', currentTenant.value)
      query.set('sites', '')
      query.set('seller', '')
      history.push({ search: query.toString() })

      applicationSetConfigurationAction({
        key: 'activeTenant',
        value: { id: currentTenant.value, name: currentTenant.label, sites: [], sellers: [] },
      })
    }
    setLoading(false)
  })
}

export default getData
