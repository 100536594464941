import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import ReactGA from 'react-ga4'
import config from '../config'
import useAppendQS from '../../../hooks/useAppendQS'
import EventHandler from '../components/events/EventHandler'
import App from './App'
import HandlerEvents from '../../core/HandlerEvents'
import { DEFAULT_URI_START_APP, ROUTE_PREFIX, ROUTES } from '../../../const'
import GlobalApps from '../../globalApps/pages/globalApps/GlobalApps'
import TenantModule from '../../tenant'
import { LogsListingByTenant } from '../../log/pages/LogsListingByTenant'
import { LogsListingBySubscription } from '../../log/pages/LogsListingBySubscription'
import ApplicationsPage from '../../applications/components/applicationsPage/ApplicationsPage'
import ApplicationManagment from '../../applications/components/applicationManagment/ApplicationManagment'
import ManageWrapper from '../../user/pages/manage-role/ManageWrapper'
import UserModule from '../../user'
import ManageUser from '../../user/pages/manage-user/ManageUser'
import AddUser from '../../user/pages/manage-user/AddUser'
import Home from '../../dashboard/pages/home/Home'
import CustomAppRouter from './CustomAppRouter'
import useAuthenticationContextWrapper from '../contexts/AuthenticationContextWrapper'

export const Main = function ({ token }: any) {
  const { REACT_APP_CUSTOM_APPLICATIONS, REACT_APP_USER_MANAGEMENT } = config
  const appendQs = useAppendQS()
  const { userDetails }: any = useAuthenticationContextWrapper()

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', title: location.pathname })

    ReactGA.gtag('event', 'user_click_app', {
      app_name: location?.pathname,
      location: location?.pathname,
      user: userDetails?.email,
    })

    ReactGA.event({
      category: 'User',
      action: 'Click app',
      label: location.pathname,
      value: userDetails?.email,
    })
  }, [location])

  return (
    token && (
      <EventHandler>
        <App>
          <HandlerEvents>
            <Switch>
              <Route
                path={`${ROUTES.GLOBAL_APPS}/:groupName`}
                component={GlobalApps}
              />
              <Route
                path={ROUTES.GLOBAL_APPS}
                component={GlobalApps}
              />
              <Route
                path="/tenants"
                component={TenantModule}
              />
              <Route
                exact
                path={`${ROUTE_PREFIX}/logs`}
                component={LogsListingByTenant}
              />
              <Route
                exact
                path="/logs"
                component={LogsListingBySubscription}
              />
              <Route
                exact
                path={[ROUTES.APPLICATION_LIST, ROUTES.GROUP_LIST]}
                component={ApplicationsPage}
              />
              <Route
                exact
                path={`${ROUTES.APPLICATION_MANAGE}`}
                component={ApplicationManagment}
              />
              <Route
                exact
                path={`${ROUTES.APPLICATION_MANAGE}/:id`}
                component={ApplicationManagment}
              />
              {REACT_APP_USER_MANAGEMENT && (
                <Route
                  exact
                  path={`${ROUTES.ROLE_VIEW}/:id`}
                  component={ManageWrapper}
                />
              )}
              {REACT_APP_USER_MANAGEMENT && (
                <Route
                  exact
                  path={ROUTES.ROLE_CREATE}
                  component={ManageWrapper}
                />
              )}
              {REACT_APP_USER_MANAGEMENT && (
                <Route
                  exact
                  path={ROUTES.USER_LIST}
                  component={UserModule}
                />
              )}
              {REACT_APP_USER_MANAGEMENT && (
                <Route
                  exact
                  path={ROUTES.ROLE_LIST}
                  component={UserModule}
                />
              )}
              {REACT_APP_USER_MANAGEMENT && (
                <Route
                  exact
                  path={ROUTES.PERMISSION_LIST}
                  component={UserModule}
                />
              )}
              {REACT_APP_USER_MANAGEMENT && (
                <Route
                  exact
                  path={`${ROUTES.USER_VIEW}/:id`}
                  component={ManageUser}
                />
              )}
              {REACT_APP_USER_MANAGEMENT && (
                <Route
                  exact
                  path={ROUTES.USER_CREATE}
                  component={AddUser}
                />
              )}
              {REACT_APP_USER_MANAGEMENT && (
                <Route
                  exact
                  path={`${ROUTES.DEFAULT_URI_START_APP}`}
                  component={Home}
                />
              )}
              {REACT_APP_CUSTOM_APPLICATIONS && (
                <Route
                  path={ROUTE_PREFIX}
                  component={CustomAppRouter}
                />
              )}
              <Route render={() => <Redirect to={appendQs(DEFAULT_URI_START_APP)} />} />
            </Switch>
          </HandlerEvents>
        </App>
      </EventHandler>
    )
  )
}
