import React, { useEffect } from 'react'
import { Box, Grid, Container, Typography } from '@jarvis-catalyst/jarvis-ui-kit'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { applicationUiTenantDependAction } from '../../../../store/modules/application/actions'
import { LeftColumn } from './components/LeftColumn'
import { RightColumn } from './components/RightColumn'

const Home = function () {
  const { uiTenantDepend } = useSelector((s: any) => s.application)
  const { t } = useTranslation()

  useEffect(() => {
    applicationUiTenantDependAction(true)
  }, [uiTenantDepend])

  return (
    <Container maxWidth="xl">
      <Typography
        variant="H900"
        color="primary"
      >
        <Box sx={{ fontWeight: 'bolder', marginTop: 4.5, fontSize: '35px' }}>{t('HOME')}</Box>
      </Typography>
      <Box sx={{ marginTop: 2, marginBottom: 3 }}>
        <Typography
          variant="h3"
          color="text.secondary"
        >
          {t('WELCOME_MESSAGE')}
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
      >
        <LeftColumn />
        <RightColumn />
      </Grid>
    </Container>
  )
}

export default Home
