import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Dropdown, IconChevronDown, IconLoading, IconSite } from '@jarvis-catalyst/ui-components'
import { Box, Grid } from '@jarvis-catalyst/jarvis-ui-kit'
import { useTranslation } from 'react-i18next'
import { dataIsLoad } from '../../../store/utils'
import { applicationSetConfigurationAction } from '../../../store/modules/application/actions'
import SelectSiteModal from './SelectSiteModal'
import { Site } from '../../../store/modules/sites/types'
import getData from './getData'
import useTenants from './useTenants'
import cacheStore from '../../../utils/cache-store'
import { TENANT_SITE_KEY } from '../../../utils/cache-store/types'
import { userPermissionsGetAction } from '../../../store/modules/user-permissions/actions'
import './TenantSelector.css'
import { sellersGetAllAction } from '../../../store/modules/sellers/actions'
import SellerSellector from './SellerSellector'
import getUserType from '../../../hooks/getUserType'

export interface Tenant {
  label: string
  value: string
}

const TenantSelector = function () {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { myTenants, selectedTenant } = useTenants()
  const { sites: userSites, isRetailUser } = getUserType()

  const { token, userHasTenant, activeTenant } = useSelector((s: any) => s.application)
  const dataSites = useSelector((state: any) => state.sites.getAll)

  const [init, setInit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [tenant, setTenant] = useState<Tenant>({ label: '', value: '' })
  const [tenants, setTenants] = useState([])

  useEffect(() => {
    if (dataIsLoad(myTenants)) {
      const options = myTenants.data.map((t: any) => ({ label: t.name, value: t.id }))
      setTenants(options)
    }
  }, [myTenants])

  useEffect(() => {
    selectedTenant && sellersGetAllAction({ tenant: selectedTenant, page: 1, limit: 100 })
  }, [selectedTenant])

  useEffect(() => {
    if (userHasTenant && isRetailUser) {
      if (userSites.length === 1) {
        handleConfirmSite(userSites)
        return
      }
      const sitesSelected = activeTenant.sites?.length !== 0
            && userSites.find((us) => activeTenant.sites?.find((ss: any) => ss.key === us.key))
      if (!sitesSelected) setShowDialog(true)
    }
  }, [isRetailUser])

  const handleChangeTenant = (currentTenant: any) => {
    setTenant(currentTenant)
    if (!init) {
      setInit(true)
    } else {
      setLoading(true)
      if (!loading) {
        getData(token, currentTenant, dispatch, history, setLoading)
      }
      cacheStore(TENANT_SITE_KEY).setData({
        id: currentTenant.value,
        name: currentTenant.label,
        sites: [],
        sellers: [],
      })
    }
  }

  const getSelectedSitesByUrl = () => {
    const query = new URLSearchParams(window.location.search)
    let sites: string[] = []
    const qSites = query.get('sites')
    if (qSites != null) {
      sites = decodeURIComponent(qSites).split(',')
    }
    const validSites = dataSites.data
      .filter((e: Site) => userSites.find((s) => s.key === e.key) && sites.includes(e.key))
    return validSites.map((e: Site) => e.name)
  }

  const handleConfirmSite = (sites: Site[]) => {
    if (init) {
      const sitesQs = `${sites.reduce((prev: string, curr: Site) => `${prev},${curr.key}`, '')}`.replace(',', '')

      const query = new URLSearchParams(window.location.search)
      query.set('tenant', tenant.value)
      query.set('sites', sitesQs)
      query.set('seller', activeTenant.sellers[0]?.key || '')

      applicationSetConfigurationAction({
        key: 'activeTenant',
        value: { id: tenant.value, name: tenant.label, sites, sellers: activeTenant.sellers },
      })

      history.push({ search: query.toString() })
      cacheStore(TENANT_SITE_KEY).setData({
        id: tenant.value,
        name: tenant.label,
        sites,
        sellers: activeTenant.sellers,
      })
      userPermissionsGetAction()
      setShowDialog(false)
    }
  }

  const getTenantSelector = () => {
    if (!userHasTenant) {
      return (
        <Button
          block
          color="dark"
          disabled
        >
          {t('NO_TENANTS_ASSIGNED')}
        </Button>
      )
    }

    return selectedTenant && tenants.length > 0 ? (
      <Dropdown
        id="menu__dropdown-tenant"
        color="dark"
        selectedValue={selectedTenant}
        title={t('TENANT_SELECTION')}
        onSelected={handleChangeTenant}
        options={tenants}

      />
    ) : (
      <div id="menu__dropdown-tenant">
        <Button
          block
          color="dark"
          disabled
          iconLeft={<IconLoading />}
          iconLeftRotate
        >
          {t('TENANT_SELECTION')}
        </Button>
      </div>
    )
  }

  return (
    <>
      <Grid
        item
        className="header__option__menu"
      >
        <Box>
          {getTenantSelector()}
        </Box>
      </Grid>
      <Grid
        item
        className="header__option__menu"
      >
        {dataSites.data.length === 0 && (
          <Button
            id="menu__dropdown-site"
            className="screen-md-view-menu__dropdown"
            color="dark"
            iconLeftRotate={loading}
            iconLeft={loading ? <IconLoading /> : <IconSite />}
            disabled
          >
            {t('MANAGE_SITE.WITHOUT_SITE')}
          </Button>
        )}
        {dataSites.data.length > 0 && (
          <Button
            id="menu__dropdown-site"
            className="screen-md-view-menu__dropdown"
            title={
              getSelectedSitesByUrl().length > 0 ? getSelectedSitesByUrl().join(', ') : t('MANAGE_SITE.SITE_DEFAULT')
            }
            iconLeftRotate={loading}
            iconLeft={loading ? <IconLoading /> : <IconSite />}
            iconRight={getSelectedSitesByUrl().length === 0 && <IconChevronDown />}
            color="dark"
            disabled={loading}
            onClick={() => !loading && setShowDialog(true)}
          >
            {getSelectedSitesByUrl().length > 0 ? getSelectedSitesByUrl().join(', ') : t('MANAGE_SITE.SITE_DEFAULT')}
          </Button>
        )}
        {selectedTenant && dataSites.data.length > 0 && showDialog && (
          <SelectSiteModal
            dataSites={dataSites}
            tenant={tenant}
            onCancel={() => setShowDialog(false)}
            onConfirm={handleConfirmSite}
            isRetailUser={isRetailUser}
            userSites={userSites}

          />
        )}
      </Grid>
      <Grid
        item
        className="header__option__menu"
      >
        <SellerSellector
          tenant={tenant}
          init={init}
        />
      </Grid>
    </>
  )
}

export default TenantSelector
