import getLocalStorage from './getLocalStorage'

const memorizer = (id?: string) => {
  const ls = getLocalStorage()
  const storeName = `${id ? `${id}__` : ''}internalMemorizer`
  const store: any = ls.getItem(storeName)
  const get = (key: any) => {
    let current: any = {}
    try {
      current = JSON.parse(store) || {}
    } catch (_) { } // eslint-disable-line no-empty
    return current[key]
  }

  const set = (key: any, value: any) => {
    let current: any = {}
    try {
      current = JSON.parse(store) || {}
    } catch (_) { } // eslint-disable-line no-empty
    current[key] = value
    ls.setItem(storeName, JSON.stringify(current))
  }
  return { get, set }
}

export default memorizer
