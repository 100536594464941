type BooleanObj = {
    TRUE: boolean;
    FALSE: boolean;
}

export const ZERO = 0
export const VOID_ID = '0'
export const VOID_STRING = ''
export const VOID_ARRAY: any[] = []
export const BOOLEAN: BooleanObj = {
  FALSE: false,
  TRUE: true,
}

export const LANG = {
  ES: 'es',
  EN: 'en',
}

export const LANGS = [
  { label: 'En', value: 'en' },
  { label: 'Es', value: 'es' },
]

export const DEFAULT_LANG = LANGS[1]

export const ROUTE_PREFIX = '/application'
export const DEFAULT_URI_START_APP = '/home'
export const GLOBAL_APPS = '/global-apps'
export const CREATE_BY_SYSTEM = 'SYSTEM'

export const ROUTES = {
  DEFAULT_URI_START_APP,
  GLOBAL_APPS,
  ROLE_INDEX: '/users/roles',
  ROLE_VIEW: '/users/roles/view',
  ROLE_LIST: '/users/roles',
  ROLE_CREATE: '/users/roles/create',
  USER_LIST: '/users',
  USER_VIEW: '/users/user/view',
  USER_CREATE: '/users/user/create',
  PERMISSION_LIST: '/users/custom-permissions',
  APPLICATION_LIST: '/applications',
  GROUP_LIST: '/applications/groups',
  APPLICATION_MANAGE: '/applications/manage',
}

export const RETAIL_SITES = ['FA_RETAIL', 'LINIO', 'SODIMAC', 'TOTTUS']
