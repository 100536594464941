/* eslint-disable no-console */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from '@jarvis-catalyst/ui-components'
import {
  Modal,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  Box,
  Typography,
  Progress,
  Alert,
  Button,
  Icon,
  Table,
} from '@jarvis-catalyst/jarvis-ui-kit'
import './ManageSite.css'
import useSiteAdmin from './useSiteAdmin'
import SiteTable from './SiteTable'

interface ManageSiteProps {
  open: boolean
  tenant: any
  onClose: () => void
  hasWrite: boolean
}

const ManageSite: React.FunctionComponent<ManageSiteProps> = function ({
  open,
  tenant,
  onClose,
  hasWrite,
}: ManageSiteProps): JSX.Element {
  const { t } = useTranslation()
  const {
    sites,
    addSite,
    wrapHandleSiteRemove,
    handleSiteName,
    siteName,
    siteKey,
    handleSiteKey,
    siteDescription,
    handleSiteDescription,
    isValidSite,
    wrapHandleSiteDescription,
    wrapHandleSiteName,
    wrapHandleSiteKey,
    isKeyRepeat,
    isNameRepeat,
    isSomeItemVoid,
    handleSave,
    loading,
    error: errors,
    hasError,
  } = useSiteAdmin(open, onClose, tenant)

  return open ? (
    <Modal open={open}>
      {tenant && (
        <>
          <CardHeader
            title={t('MANAGE_SITE.TITLE')}
            titleTypographyProps={{ variant: 'H800' }}
          />
          <CardContent>
            <Grid
              container
              direction="row"
            >
              <Grid
                item
                xs={12}
                lg={5}
                xl={4}
                container
                direction="column"
              >
                <Typography variant="H100">
                  {t('NAME')} ({t('TENANT')})
                </Typography>
                <Typography variant="H500">{tenant.name}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={7}
                xl={8}
                container
                direction="column"
              >
                <Typography variant="H100">{t('DESCRIPTION')}</Typography>
                <Typography variant="H500">{tenant.description}</Typography>
              </Grid>
            </Grid>
            <Divider blank />
            <Grid
              container
              direction="row"
            >
              <Grid item>
                <Box sx={{ textAlign: 'left' }}>
                  <Alert color="info">{t('MANAGE_SITE.INFO')}</Alert>
                </Box>
              </Grid>
            </Grid>
            <Divider blank />
            <SiteTable
              sites={sites}
              hasWrite={hasWrite}
              wrapHandleSiteRemove={wrapHandleSiteRemove}
              wrapHandleSiteKey={wrapHandleSiteKey}
              wrapHandleSiteDescription={wrapHandleSiteDescription}
              wrapHandleSiteName={wrapHandleSiteName}
            />
            <Divider />
            {hasWrite && (
              <>
                <Box sx={{ pl: 1, pb: 1 }}>
                  <Typography variant="H100">{t('MANAGE_SITE.TITLE_NEW')}</Typography>
                </Box>
                <Box
                  sx={{ pl: 1 }}
                  className="input-container"
                >
                  <Input
                    placeholder={t('MANAGE_SITE.SITE_KEY_PLACEHOLDER')}
                    value={siteKey}
                    onChange={handleSiteKey}
                  />

                  <Input
                    placeholder={t('MANAGE_SITE.SITE_NAME_PLACEHOLDER')}
                    value={siteName}
                    onChange={handleSiteName}
                  />

                  <Input
                    placeholder={t('MANAGE_SITE.SITE_DESCRIPTION_PLACEHOLDER')}
                    value={siteDescription}
                    onChange={handleSiteDescription}
                  />

                  <Button
                    onClick={() => addSite(tenant)}
                    disabled={!isValidSite()}
                  >
                    <Icon name="Plus" />
                  </Button>
                </Box>
              </>
            )}

            {isKeyRepeat && <Alert color="warning">{t('MANAGE_SITE.DUPLICATE_ALERT')}</Alert>}
            {isNameRepeat && <Alert color="warning">{t('MANAGE_SITE.DUPLICATE_NAME_ALERT')}</Alert>}
            {hasError && (
              <Alert severity="error">
                <Box className="mt-1">
                  <Typography variant="H300">{t('ERRORS.SUMMARY')}</Typography>
                </Box>
                <Box sx={{ minHeight: '66px' }}>
                  <Table
                    columns={[
                      { key: 'key', content: t('MANAGE_SITE.SITE_KEY') },
                      { key: 'name', content: t('MANAGE_SITE.SITE_NAME') },
                      { key: 'description', content: t('MANAGE_SITE.SITE_DESCRIPTION') },
                    ]}
                    rows={errors.map((s: any, i: number) => ({
                      id: i.toString(),
                      key: <Typography variant="H200">{s.data.key}</Typography>,
                      name: <Typography variant="H200">{s.data.name}</Typography>,
                      description: <Typography variant="H200">{s.data.description}</Typography>,
                    }))}
                  />
                </Box>
              </Alert>
            )}
            {loading && <Progress circle />}
          </CardContent>
          <Box sx={{ textAlign: 'right' }}>
            {hasWrite ? (
              <>
                <Button
                  disabled={loading}
                  color="info"
                  onClick={() => onClose()}
                >
                  {t('ACTIONS.CANCEL')}
                </Button>
                <Button
                  disabled={isKeyRepeat || isNameRepeat || isSomeItemVoid() || loading}
                  color="primary"
                  variant="contained"
                  endIcon={loading ? <Icon name="Loading" /> : <Icon name="Plus" />}
                  onClick={handleSave}
                >
                  {t('ACTIONS.SAVE')}
                </Button>
              </>
            ) : (
              <Button
                disabled={loading}
                color="primary"
                onClick={() => onClose()}
              >
                {t('ACTIONS.CLOSE')}
              </Button>
            )}
          </Box>
        </>
      )}
    </Modal>
  ) : (
    <span />
  )
}

export default ManageSite
