import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const getCoreApplication = () => {
  const location = useLocation()
  const { availableApplications } = useSelector((s: any) => s.application)

  return availableApplications.find((e: { route: string }) => {
    const pName = location.pathname.split('/')
    const pathName = `/${pName[1]}/${pName[2]}`
    return pathName === e.route
  })
}

export default getCoreApplication
