/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import CrossCircle from '../../images/cross-circle.png'
import GenericError from '../genericError'
import Error from '../../../error/containers/error/Error'

const ApplicationError = function ({ errorMessage, errorMessageTenant }) {
  const { errors } = useSelector((s) => s.application)

  return errors.length > 0 ? (
    <Error errors={errors} />
  ) : (
    <GenericError
      errorMessage={errorMessage}
      errorMessageTenant={errorMessageTenant}
      imageSrc={CrossCircle}
    />
  )
}

ApplicationError.propTypes = {
  errorMessage: PropTypes.string,
  errorMessageTenant: PropTypes.string,
}

export default ApplicationError
