import { useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { Log } from 'oidc-client-ts'
import isEmpty from 'lodash.isempty'
import jwtDecode from 'jwt-decode'
import { useHistory, useLocation } from 'react-router-dom'
import parseJWT from '../../../utils/parseJWT'
import { applicationSetConfigurationAction } from '../../../store/modules/application/actions'
import config from '../config'
import {
  processError,
  LOGIN_PROVIDER_ERROR,
  FAILED_FETCH_ERROR,
  AUTH_ERROR,
  TOKEN_NOT_ACTIVE_ERROR,
} from './handleError'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAuthenticationOIDC = () => {
  const location = useLocation()
  const history = useHistory()
  const SESSION_NOT_ACTIVE = ['Session not active', 'Token is not active']

  const [token, setToken] = useState('')
  const { isLoading, isAuthenticated, signinRedirect, signoutRedirect, user, events, error } = useAuth()
  const [authorizationError, setAuthorizationError] = useState(false)
  const [initError, setInitError] = useState('')
  const [silentRenewError, setSilentRenewError] = useState('')
  const [tokenExpiredError, setTokenExpiredError] = useState(false)
  const [accessDenied, setAccessDenied] = useState(false)
  const [userDetails, setUserDetails] = useState({ name: '', email: '' })

  useEffect(() => {
    if (config.REACT_APP_DEBUG_MODE) {
      Log.setLogger(console)
      Log.setLevel(Log.INFO)
    }
  }, [])

  const deleteParams = () => {
    const queryParams = new URLSearchParams(location.search)
    queryParams.delete('state')
    queryParams.delete('code')
    history.replace({
      search: queryParams.toString(),
    })
  }

  const getToken = async () => {
    try {
      const newToken = user.access_token
      const claims = parseJWT(newToken)
      const accountIdClaim = 'https://auth.osmos.services/sub'
      const subscriptionIdClaim = 'https://auth.osmos.services/subscriptionId'

      if (!claims[accountIdClaim] || !claims[subscriptionIdClaim]) {
        setAccessDenied(true)
      } else {
        claims.accountId = claims['https://auth.osmos.services/sub']
        // eslint-disable-next-line
        claims['__raw'] = newToken
        applicationSetConfigurationAction({ key: 'claims', value: claims })
        applicationSetConfigurationAction({ key: 'token', value: newToken })
        setUserDetails({ ...claims })
        setToken(newToken)
      }
    } catch (err) {
      // eslint-disable-next-line
      console.log(`exec error: ${err}`)
      setAuthorizationError(true)
      processError({ message: AUTH_ERROR })
    }
  }

  useEffect(() => {
    async function login() {
      if (!isLoading && isEmpty(authorizationError) && !initError && !error) {
        try {
          await signinRedirect({})
        } catch (e) {
          setInitError(true)
        }
      }
    }
    if (error && isEmpty(silentRenewError) && !tokenExpiredError) {
      setAuthorizationError(true)
      processError({ message: FAILED_FETCH_ERROR })
    }
    if (initError) {
      setAuthorizationError(true)
      processError({ message: LOGIN_PROVIDER_ERROR })
    } else if (accessDenied) {
      setAuthorizationError(true)
      processError({ message: FAILED_FETCH_ERROR })
    } else if (isAuthenticated && user) {
      if (user.expired) {
        setTokenExpiredError(true)
      } else {
        getToken()
        deleteParams()
      }
    } else {
      login()
    }
  }, [isLoading, isAuthenticated, signinRedirect, user, initError, accessDenied, error])

  events.addSilentRenewError((error) => {
    if (silentRenewError === '') {
      setSilentRenewError(error.message)
    }
  })

  events.addAccessTokenExpired(() => {
    if (!tokenExpiredError) {
      setTokenExpiredError(true)
    }
  })

  useEffect(() => {
    if (silentRenewError !== '') {
      const value = SESSION_NOT_ACTIVE.includes(silentRenewError) ? TOKEN_NOT_ACTIVE_ERROR : LOGIN_PROVIDER_ERROR
      setAuthorizationError(true)
      processError({ message: value })
    }
  }, [silentRenewError])

  useEffect(() => {
    if (tokenExpiredError) {
      setAuthorizationError(true)
      processError({ message: TOKEN_NOT_ACTIVE_ERROR })
    }
  }, [tokenExpiredError])

  const isAdmin = (() => {
    if (!token) return false
    const decodedToken = jwtDecode(token)
    const identityKey = Object.keys(decodedToken).find((k) => k.indexOf('/isAdmin') !== -1)
    return decodedToken[identityKey]
  })()

  return {
    isAuthenticated,
    authorizationError,
    token,
    loading: isLoading,
    userDetails,
    logoutToLogin: signoutRedirect,
    isAdmin,
  }
}
