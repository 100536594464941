import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Typography, Chip, Progress } from '@jarvis-catalyst/jarvis-ui-kit'
import CustomBox from '../../../../components/custombox/custombox'
import RolesSection from '../roles-section/RolesSection'

const createRolesChips = (roles: []) =>
  roles?.slice(0, 3).map((role: { name: string }) => (
    <Chip
      sx={{ marginLeft: 0.5, marginTop: 0.5 }}
      label={role.name}
      key={role.name}
    />
  ))

const createChipWithThreePoints = () => (
  <Chip
    sx={{ marginLeft: 0.5, marginTop: 0.5 }}
    label="..."
    key={uuidv4()}
  />
)

const UserRolesDescription = function () {
  const { activeTenant, claims: userData, userHasTenant } = useSelector((s: any) => s.application)
  const { t } = useTranslation()
  const { loading, success, data } = useSelector((s: any) => s.instrospect.getRolesByAccountId)

  const getUserRolesBySubscription = () => {
    const rolesChips = createRolesChips(data.subscription)
    if (data.subscription.length > 3) {
      return (
        <>
          {rolesChips}
          {createChipWithThreePoints()}
        </>
      )
    }
    return rolesChips
  }

  const getUserRolesByTenant = () => {
    const activeTenantRoles = data.tenants.filter((tenant: { id: string }) => tenant.id === activeTenant.id)
    const tenantRoles = createRolesChips(activeTenantRoles[0]?.roles)
    if (activeTenantRoles[0]?.roles.length > 3) {
      return (
        <>
          {tenantRoles}
          {createChipWithThreePoints()}
        </>
      )
    }
    return tenantRoles
  }

  return userData ? (
    <CustomBox sx={{ height: '100%' }}>
      <Typography variant="H700">{userData.name}</Typography>
      <RolesSection
        title={t('MANAGE_USER.SUBSCRIPTION_ROLES')}
        textTooltip={t('SUBSCRIPTION_ROLE_DESC')}
        searchFunction={getUserRolesBySubscription}
        loading={loading}
        success={success}
      />
      {userHasTenant && (
        <RolesSection
          title={t('MANAGE_USER.ROLES_BY_TENANT')}
          textTooltip={t('TENANT_ROLE_DESC')}
          searchFunction={getUserRolesByTenant}
          loading={loading}
          success={success}
        />
      )}
    </CustomBox>
  ) : (
    <Progress
      circle
      height={30}
    />
  )
}

export default UserRolesDescription
