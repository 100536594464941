import React from 'react'
import { GridItem } from '@jarvis-catalyst/ui-components'
import { useSelector } from 'react-redux'

type SidebarProps = {
    children: React.ReactNode;
}

const Sidebar = function ({ children }: SidebarProps) {
  const { uiSidebar } = useSelector((s: any) => s.application)

  return uiSidebar === 'hidden' ? null
    : (
      <GridItem
        columnStart={1}
        colSpan={3}
        className={uiSidebar === 'full' ? 'layout__sidebar layout__sidebar__full' : 'layout__sidebar'}
      >
        <div id="layout__sidebar">
          {children}
          <span className="layout-modal-overlay" />
        </div>
      </GridItem>
    )
}

export default Sidebar
