import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Tabs,
  Typography,
  Box,
  Icon,
  Tooltip,
  CardContent,
  Card,
  Breadcrumbs,
} from '@jarvis-catalyst/jarvis-ui-kit'
import { useLocation, useHistory, Link } from 'react-router-dom'
import UsersListing from '../../components/usersListing/UsersListing'
import RolesListing from '../../components/rolesListing/RolesListing'
import CustomPermissions from '../custom-permissions'
import {
  applicationUiSidebarAction,
  applicationUiTenantDependAction,
} from '../../../../store/modules/application/actions'
import useDocumentTitle from '../../../../hooks/useDocumentTitle'

/**
 *
 * @returns JSX.Element
 */
const Users = function () {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()

  React.useEffect(() => {
    applicationUiSidebarAction('hidden')
    applicationUiTenantDependAction(false)
  }, [])

  const tabOptions = [
    {
      title: t('USERS'),
      content: <UsersListing />,
      key: '/users',
    },
    {
      title: t('ROLES'),
      content: <RolesListing />,
      key: '/users/roles',
    },
    {
      title: t('CUSTOM_PERMISSIONS'),
      content: <CustomPermissions />,
      key: '/users/custom-permissions',
    },
  ]

  const getIndexPage = () => {
    const i = tabOptions.findIndex((e) => location.pathname === e.key || location.pathname === `${e.key}/`)
    return i > -1 ? i : 0
  }

  const getTitlePage = () => tabOptions[getIndexPage()].title

  const getPath = () => tabOptions[getIndexPage()].key

  const handlerChange = (e, value) => {
    if (location.pathname !== tabOptions[value].key) history.push(`${tabOptions[value].key}${location.search}`)
  }

  const tooltipTitle = () => (
    <Typography
      variant="H200"
      color="#8B97AD"
      gutterBottom
    >
      <div style={{ display: 'flex', lineHeight: 1.5 }}>{t('USER_TOOLTIP')}</div>
    </Typography>
  )

  useDocumentTitle(t('MANAGE_USERS'))
  return (
    <section style={{ padding: 20 }}>
      <Card variant="elevation">
        <div style={{ marginLeft: 15, marginTop: 4 }}>
          <Breadcrumbs>
            <Link
              style={{ textDecoration: 'none', padding: 0 }}
              to={{ pathname: '/' }}
            >
              <Typography
                color="#a7a7a7"
                variant="h4"
              >
                {t('HOME')}
              </Typography>
            </Link>
            <Link
              to={{ pathname: getPath() }}
              style={{ textDecoration: 'none', padding: 0 }}
            >
              <Typography
                color="primary"
                variant="h4"
              >
                {getTitlePage()}
              </Typography>
            </Link>
          </Breadcrumbs>
        </div>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
            <Typography variant="H900">
              <div style={{ textDecoration: 'underline', marginRight: 16 }}>{t('USER_MANAGEMENT')}</div>
            </Typography>
            <Tooltip
              title={tooltipTitle()}
              placement="right-start"
            >
              <Icon
                name="Info"
                color="white"
                size="medium"
                bgcolor="#4a1eeb"
              />
            </Tooltip>
          </Box>
        </CardContent>
        <CardContent>
          <Tabs
            value={getIndexPage()}
            tabs={tabOptions}
            onChange={handlerChange}
            sx={{ paddingBottom: 3 }}
            sxBox={{ p: 0, paddingTop: 0, paddingBottom: 0 }}
          />
        </CardContent>
      </Card>
    </section>
  )
}

export default Users
