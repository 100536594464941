import groupBy from './groupBy'
import orderBykey from './orderByKey'

const corePermissionsRemap = (data: any[]) => {
  const cp = groupBy(data.filter((e: any) => e.createdBy !== 'USER'), 'group')
  const cpArray = Object.keys(cp)
    .map((e: any) => ({ key: e, data: cp[e] }))
    .sort(orderBykey('key'))
  return cpArray.map((e: any) => {
    const data = e.data.map((l: any) => ({
      ...l,
      scopes: l.scopes.map((s: string) => ({ name: s, selected: false })),
    }))
    return { ...e, data }
  })
}

export default corePermissionsRemap
