import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { useSelector as originalUseSelector } from 'react-redux'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import config from '../modules/shell/config'

import applicationReducer from './modules/application'
import customPermissionsReducer from './modules/custom-permissions'
import logsReducers from './modules/logs'
import userPermissionsReducer from './modules/user-permissions'
import tenantsReducer from './modules/tenants'
import rolesReducer from './modules/roles'
import usersReducer from './modules/users'
import sitesReducer from './modules/sites'
import domainsServicesReducer from './modules/domains-services'
import applicationsReducer from './modules/applications'
import groupsReducer from './modules/groups'
import sellersReducer from './modules/sellers'

const rootReducer = combineReducers({
  application: applicationReducer,
  customPermissions: customPermissionsReducer,
  logs: logsReducers,
  instrospect: userPermissionsReducer,
  tenants: tenantsReducer,
  roles: rolesReducer,
  users: usersReducer,
  sites: sitesReducer,
  domainsServices: domainsServicesReducer,
  applications: applicationsReducer,
  groups: groupsReducer,
  sellers: sellersReducer,
})

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
    }
}
// eslint-disable-next-line import/no-mutable-exports
let store: any
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose || compose

if (config.REACT_APP_DEBUG_MODE) {
  store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger as any, thunk)))
} else {
  store = createStore(rootReducer, applyMiddleware(thunk))
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line @typescript-eslint/ban-types
export const enhancementAction = (action: Function) => action(store.dispatch, store.getState)

export const useSelector = <T>(state: any): T => originalUseSelector(state)

export default store
