import { eventTypes, commandTypes, eventTypesList } from '../'
import { ContextApplication, SendNotification } from '../index'
import { setApplicationContextAction } from '../store/actions'

const uuid = () => `${Math.random()}${new Date().getTime()}`

const styleLog = 'background: #222; color: #bada55'

const devUtils = (env?: any) => ({
	isDev: env.REACT_APP_MODE === 'dev',
	log: console.log,
})

const Channel = (dispatch: Function, dev = false) => {
	const handler = (event: any) => {
		if (event.data.eventType) {
			const type = event.data.eventType
			const data = event.data.data
			if (eventTypesList.find((e: string) => e === type)) {
				if (dev) console.info('%c handleEvents >', styleLog, type)
				setApplicationContextAction(dispatch)({ type, data })
			}
		}
	}
	const sendEvent = (eventType: string, data: any = {}) => {
		if (dev) console.info('%c sendCmd >', styleLog, eventType)
		window.parent.postMessage(
			{
				eventId: uuid(),
				eventType,
				data,
			},
			'*',
		)
	}
	const init = () => {
		window.addEventListener('message', handler)
		sendEvent(eventTypes.APPLICATION_LOADED)
	}

	const isSuccess = (ctx: ContextApplication) => {
		return (
			ctx.localeChange && ctx.authTokenChange && ctx.tenantChange && ctx.userDetailsChange && ctx.userPermissionsChanged
		)
	}

	const pushNavigation = (pathname: string, search: string, state: any) => {
		if (dev) console.info('%c sendCmd >', styleLog, commandTypes.PUSH_NAVIGATION_HISTORY)
		window.parent.postMessage(
			{
				commandId: uuid(),
				commandType: commandTypes.PUSH_NAVIGATION_HISTORY,
				data: {
					pathname,
					search,
					state,
				},
			},
			'*',
		)
	}

	const setSidebar = (mode: 'compact' | 'full') => {
		if (dev) console.info('%c sendCmd >', styleLog, commandTypes.DISPLAY_SIDEBAR)
		window.parent.postMessage(
			{
				commandId: uuid(),
				commandType: commandTypes.DISPLAY_SIDEBAR,
				data: { mode },
			},
			'*',
		)
	}

	return {
		isSuccess,
		init,
		pushNavigation,
		setSidebar,
	}
}

export const sendNotification = (notification: SendNotification, env?: any) => {
	if (typeof env === 'boolean' && env) console.log('sendNotification', notification)
	else if (env?.REACT_APP_MODE && devUtils(env).isDev) return devUtils(env).log('sendNotification', notification)
	window.parent.postMessage(
		{
			commandId: uuid(),
			commandType: commandTypes.DISPLAY_NOTIFICATION,
			data: {
				level: notification.level,
				timeout: notification.timeout,
				message: {
					title: notification.message.title,
					text: notification.message.text,
				},
			},
		},
		'*',
	)
}

export const setSidebar = (mode: 'full' | 'compact' | 'hidden', env?: any) => {
	if (typeof env === 'boolean' && env) console.log('setSidebar', mode)
	else if (env?.REACT_APP_MODE && devUtils(env).isDev) return devUtils(env).log('setSidebar', mode)
	window.parent.postMessage(
		{
			commandId: uuid(),
			commandType: commandTypes.DISPLAY_SIDEBAR,
			data: { mode },
		},
		'*',
	)
}

// typeof a === 'boolean'
export const setModalOverlay = (mode: 'show' | 'hidden', env?: any) => {
	if (typeof env === 'boolean' && env) console.log('setModalOverlay', mode)
	else if (env?.REACT_APP_MODE && devUtils(env).isDev) return devUtils(env).log('setModalOverlay', mode)
	window.parent.postMessage(
		{
			commandId: uuid(),
			commandType: commandTypes.DISPLAY_MODAL_OVERLAY,
			data: { mode },
		},
		'*',
	)
}

export default Channel
