import { combineReducers } from 'redux'
import { createGetReducer } from '../../utils'

export const getServicesStore = createGetReducer('SERVICES')
export const getDomainsStore = createGetReducer('DOMAINS')

const rootReducer = combineReducers({
  getServices: getServicesStore.reducer,
  getDomains: getDomainsStore.reducer,
})

export default rootReducer
