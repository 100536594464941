import { useState, useEffect } from 'react'

const useInitialUriCustomApp = () => {
  const [initialUri, setInitialUri] = useState('')

  useEffect(() => {
    if (initialUri === '') {
      setInitialUri(window.location.href.split(/application(.+)/)[1])
    }
  }, [initialUri, setInitialUri])

  return initialUri
}

export default useInitialUriCustomApp
