import { ParamsGetAllAction } from '../../types'

export type Application = {
    id: string;
    name: string;
    description: {
        en: string,
        es: string
    };
    navigationType: NAVIGATION;
    url: string;
    route: string;
    isActive: boolean;
    initAppWithRoute: boolean;
    metadata: {
        contactInfo: Contact[]
    }
    permissions: string[];
    menu: Menu;
    groups: Group[]
    createdAt: string,
    updatedAt: string
}

export type ApplicationPayload = {
    id?: string;
    name: string;
    description: {
        en: string,
        es: string
    };
    navigationType: NAVIGATION;
    url: string;
    route: string;
    isActive: boolean;
    initAppWithRoute: boolean;
    metadata: {
        contactInfo: Contact[]
    }
    permissions: string[];
    menu: Menu;
    groups: Group[]
}

export enum NAVIGATION {
    Iframe = 'IFRAME',
    Internal = 'INTERNAL',
    External = 'EXTERNAL',
  }

export interface Contact {
    region: string
    name: string
    email: string
    channel: string
  }

interface Menu {
    name: {
        en: string,
        es: string
    };
    route: string;
    permissions: string[];
    icon: string;
    submenu: Submenu
}

export type MenuItem = {
    name: {
        en: string,
        es: string
    };
    route: string;
    permissions: string[];
    icon?: string;
    index?: number;
}

type Submenu = MenuItem[]

export interface Group {
    id: string,
    name: string
}

export type Tenant = {
    id: string;
    name: string;
}

export type ParamsGetAllApps = {
    groupId?: string,
    globalApps?: boolean
} & ParamsGetAllAction
