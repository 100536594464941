import { combineReducers } from 'redux'
import { ActionReducer } from '../../types'
import { createGetReducer } from '../../utils'

export const getRolesByAccountIdStore = createGetReducer('ROLES_BY_ACCOUNT_ID')

export const USER_PERMISSIONS_GET_START = 'USER_PERMISSIONS_GET_START'
export const USER_PERMISSIONS_GET_RESULT = 'USER_PERMISSIONS_GET_RESULT'
export const USER_PERMISSIONS_GET_ERROR = 'USER_PERMISSIONS_GET_ERROR'

export const getAllInitialState: any = {
  data: {
    subscription: {},
    tenant: {},
  },
  success: null,
  error: null,
  loading: false,
}

const getUserPermissionsReducer = (
  prevState = getAllInitialState,
  action: ActionReducer<any>,
): any => {
  switch (action.type) {
  case USER_PERMISSIONS_GET_START:
    return {
      ...prevState,
      success: null,
      error: null,
      loading: true,
    }
  case USER_PERMISSIONS_GET_RESULT:
    return {
      ...prevState,
      data: action.payload,
      success: true,
      error: false,
      loading: false,
    }
  case USER_PERMISSIONS_GET_ERROR:
    return {
      ...prevState,
      success: false,
      error: true,
      loading: false,
    }
  default:
    return prevState
  }
}

const rootReducer = combineReducers({
  getRolesByAccountId: getRolesByAccountIdStore.reducer,
  getUserPermissions: getUserPermissionsReducer,
})

export default rootReducer
