import createUseContext from 'constate'
import { useEffect, useState, useReducer } from 'react'
import { get, post, patch, remove } from '../utils/apiService'
import ApiLocations from '../utils/apiLocations'
import reducer from './TenantContextReducer'
import useAuthenticationContextWrapper from '../../shell/contexts/AuthenticationContextWrapper'

const regexForUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i

const LIMIT = 10
const initialState = {
  initialLoad: true,
  currentPage: 1,
  tenantStatus: 'ALL',
  searchValue: '',
  tenants: [],
  totalPages: 1,
  status: true,
}
const useTenants = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    tenants,
    currentPage,
    searchValue,
    tenantStatus,
    totalPages,
    status,
  } = state
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState({})
  const { token } = useAuthenticationContextWrapper()

  const updateTenants = (data, pages) => {
    dispatch({
      type: 'UPDATE_TENANTS',
      payload: data,
    })
    dispatch({
      type: 'UPDATE_TOTAL_PAGES',
      payload: pages,
    })
  }

  const handleGetError = (message = '') => {
    updateTenants([], 1)
    if (message === '') {
      setError({ message: 'GET_TENANTS_ERROR_MESSAGE' })
    } else {
      setError({ message })
    }
  }

  const getFilter = () => {
    const filters = []
    if (tenantStatus !== 'ALL') {
      filters.push(`status="${tenantStatus}"`)
    }
    if (searchValue) {
      filters.push(`name*="${encodeURIComponent(searchValue)}"`)
    }

    return filters.join('AND')
  }

  const getAllTenants = async () => {
    const {
      data,
      meta: { pagination },
    } = await get(
      ApiLocations.TENANT_GET_ALL(LIMIT, currentPage, getFilter()),
      token,
    )
    setError({})
    return { data, pagination }
  }

  const getTenantById = async (tenantId) => {
    const data = []

    try {
      const tenant = await get(
        ApiLocations.TENANT_GET_BY_ID(tenantId),
        token,
      )
      data.push(tenant)
      setError({})
    } catch (error) {
      handleGetError('GET_TENANT_ERROR_MESSAGE')
    }

    return data
  }

  const createTenant = async (tenant) => {
    await post(ApiLocations.TENANT_CREATE(), tenant, token)
    dispatch({
      type: 'RESET',
    })
  }

  const updateTenant = async (tenant) => {
    await patch(ApiLocations.TENANT_UPDATE(tenant.id), tenant, token)
    dispatch({
      type: 'RESET_SEARCH_VALUE',
    })
  }

  const deleteTenant = async (tenant) => {
    await remove(ApiLocations.TENANT_UPDATE(tenant.id), token)
    dispatch({
      type: 'RESET',
    })
  }

  const fetchTenants = async () => {
    setIsLoading(true)
    try {
      const { data, pagination } = await getAllTenants()
      updateTenants(data, pagination.totalPages)
    } catch {
      handleGetError()
    }
    setIsLoading(false)
  }

  const fetchTenantById = async (tenantId) => {
    setIsLoading(true)
    const data = await getTenantById(tenantId)
    updateTenants(data, 1)
    setIsLoading(false)
  }

  useEffect(() => {
    if (regexForUUID.test(searchValue)) {
      fetchTenantById(searchValue)
    } else {
      fetchTenants()
    }
  }, [status, currentPage, searchValue, tenantStatus])

  useEffect(() => {
    dispatch({
      type: 'RESET',
    })
  }, [token])

  return {
    tenants,
    isLoading,
    totalPages,
    currentPage,
    dispatch,
    createTenant,
    error,
    updateTenant,
    deleteTenant,
    searchValue,
    tenantStatus,
  }
}

const [Provider, useTenantsContext] = createUseContext(useTenants)
export const TenantsContextProvider = Provider
export default useTenantsContext
