import React from 'react'
// eslint-disable-next-line
const LogoFalabella = function () {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1865 404"
      xmlSpace="preserve"
    >
      <style type="text/css">
        {`.st0{fill:#FFFFFF;}
        .st1{fill:#C60E4C;}
        .st2{fill:#2649B6;}
        .st3{fill:#8CBA37;}
        .st4{fill:#959697;} `}
      </style>
      <g id="Capa_2">
        <path
          className="st0"
          d="M911.5,142c1.2,0,2.1,0,2.9,0c6,0,12-0.1,18,0c3.9,0.1,7.9-0.1,11.6-1.1c6.5-1.6,11.4-5.4,13.4-12.2
		c1.3-4.5,1.4-9,0-13.5c-1.6-5.1-5.2-8.5-10.1-10.3c-3.9-1.4-8-1.9-12.2-1.8c-6.9,0-13.8,0-20.6,0c-0.9,0-1.9-0.1-2.9-0.1
		c-0.1-0.9-0.2-1.6-0.2-2.3c0-6.6,0-13.1,0-19.7c0-2.6,0.1-2.7,2.7-2.7c8.1,0,16.2-0.1,24.2,0c4,0.1,8,0.2,11.9,0.9
		c6.3,1.1,12.6,2.4,18.3,5.6c8,4.5,13.7,10.8,16.8,19.5c1.8,5.2,1.9,10.4,2,15.8c0.1,5.6-1.4,10.7-4,15.5c-2.5,4.6-5.7,8.8-10,11.9
		c-1,0.7-1.9,1.4-2.9,2.1c-0.6,0.4-1.4,0.8-1.1,1.6c0.2,0.7,0.9,1.3,1.5,1.6c1.9,1.1,4,1.9,5.8,3.1c6.1,4.1,10.6,9.5,13.7,16.3
		c4,8.8,4.6,18,3.6,27.4c-0.6,6.1-2.9,11.7-6.1,16.9c-3.7,6.2-8.6,11.3-14.7,15.3c-4.1,2.6-8.4,4.6-13.1,6.2c-6.2,2-12.5,2.9-19,2.9
		c-9-0.1-17.9,0-26.9,0c-0.8,0-1.6,0-2.3,0c-0.6-1.8-0.7-21.1-0.2-24.8c0.8,0,1.6-0.1,2.5-0.1c7,0,13.9-0.1,20.9,0
		c3.9,0.1,7.9-0.1,11.7-1c5.9-1.5,11-4.5,14.6-9.6c3.3-4.7,4.3-9.9,4.3-15.6c0-4.5-0.9-8.9-3.2-12.8c-2.9-4.9-7-8.4-12.6-10.3
		c-4.2-1.4-8.5-1.9-12.9-1.9c-7.5,0.1-15,0-22.6,0c-0.9,0-1.7,0-2.8,0C911.5,157.3,911.5,149.8,911.5,142z"
        />
        <path
          className="st0"
          d="M899,240.8c-1.2,0.1-1.9,0.2-2.6,0.2c-4.7,0-9.4-0.1-14.2,0c-2.1,0-4.1-0.4-6-1.1c-0.8-0.3-1.5-0.8-2.1-1.4
		c-1.8-1.8-2.7-4.1-2.9-6.6c-0.1-1.8-0.1-3.5-0.1-5.3c0-44.4,0-88.8,0-133.1c0-1.8-0.1-3.7,0.2-5.5c0.4-2.8,1.4-5.4,3.9-7.1
		c1.4-0.9,2.8-1.7,4.6-1.7c5.5-0.1,11-0.3,16.5-0.4c2.4,0,2.7,0.3,2.8,2.8c0,0.5,0,1,0,1.4c0,51.3,0,102.7,0,154
		C899,238.1,899,239.3,899,240.8z"
        />
        <path
          className="st0"
          d="M455.5,182c9.3,0,18.4-0.1,27.7,0.1c0,1.2,0,2,0,2.9c0,5.8,0,11.7,0,17.5c0,2.8,0.8,5.4,2.3,7.6
		c2.5,3.7,6.2,5.4,10.4,5.9c2.3,0.3,4.6,0.2,6.9,0.2c18.9,0,37.7,0,56.6,0c1,0,2.1,0,3.1,0.1c2.2,0.1,3.7,1.6,3.6,3.9
		c-0.1,5.7,0,11.4,0,17c0,2.9-0.9,3.7-3.7,3.7c-11.3,0-22.5,0-33.8,0c-12.5,0-24.9,0.1-37.4-0.1c-8.2-0.1-15.8-2.4-22.5-7.3
		c-4.8-3.5-8.2-8.2-10.3-13.7c-2.2-5.7-3.2-11.6-3.1-17.7c0-5.8,0-11.7,0-17.5C455.3,183.9,455.4,183.2,455.5,182z"
        />
        <path
          className="st0"
          d="M483.1,168.7c-2.3,0.9-25.4,0.9-27.8,0c0-0.8,0-1.6,0-2.5c0-27.1,0-54.2,0-81.3c0-0.9,0-1.8,0-2.6
		c0.1-2.5,0.7-3.2,3.1-3.2c7.2-0.1,14.4-0.1,21.6,0c1.8,0,2.7,0.9,3,2.7c0.1,0.7,0.1,1.4,0.1,2.2c0,27.5,0,55,0,82.5
		C483.1,167.3,483.1,168,483.1,168.7z"
        />
        <path
          className="st1"
          d="M1449.3,325.5c0-16,12.6-28.2,28.2-28.1c15.2,0,28,11.8,28,28.3c0,16.4-13.1,28.4-28.3,28.3
		C1461.8,353.8,1449.1,341.6,1449.3,325.5z"
        />
        <path
          className="st2"
          d="M1601.2,325.6c-0.4-15.2,12.1-28,27.7-28c15.9,0,28,12.5,28,27.8c0.1,16.2-12.5,28.3-28.1,28.3
		C1613.5,353.7,1600.8,341.1,1601.2,325.6z"
        />
        <path
          className="st3"
          d="M1753.1,325.6c-0.4-15.2,12.1-28,27.7-28c15.9,0,28,12.5,28,27.8c0.1,16.2-12.5,28.3-28.1,28.3
		C1765.4,353.7,1752.7,341.1,1753.1,325.6z"
        />
        <path
          className="st4"
          d="M1297.4,325.5c0-16,12.6-28.2,28.2-28.1c15.2,0,28,11.8,28,28.3c0,16.4-13.1,28.4-28.3,28.3
		C1310,353.8,1297.2,341.6,1297.4,325.5z"
        />
        <path
          className="st0"
          d="M143.9,169.1c0.4,0,0.8,0,1.1,0c1,0,1.9,0,2.9-0.1c1.5-0.2,2.2-0.8,2.2-2.3c0.1-6.2,0-12.3,0-18.5
		c0-1.2-0.7-1.9-1.9-2c-1.4-0.1-2.7-0.2-4.1-0.2c-21.2,0-42.4,0-63.6,0c-4.4,0-4.4,0-4.4-4.3c0-6.6,0-13.3,0-19.9
		c0-3.3-0.1-6.5,1-9.7c1.1-3.2,2.8-5.8,6.1-7.2c3-1.3,6.2-1.9,9.5-1.9c19.7,0,39.3,0,59,0c1.4,0,2.7,0,4.1,0
		c2.4-0.1,3.3-0.9,3.3-3.4c0.1-4.1,0.1-8.2,0.1-12.2c0-1.9,0-3.8-0.1-5.8c0-1.3-0.6-2.3-2-2.6c-1.2-0.3-2.5-0.6-3.7-0.6
		c-21.5,0-43-0.1-64.5,0c-4.2,0-8.4,0.6-12.5,1.5c-3.2,0.8-6.2,1.8-9.1,3.3c-5.1,2.6-9.3,6.4-12.5,11.1c-2.9,4.2-4.3,9-5.3,13.9
		c-0.7,3.6-1,7.3-1,10.9c-0.1,15.6,0,34.3,0,49.9H143.9z"
        />
        <path
          className="st0"
          d="M48.5,182.1c0,0.1,0,0.1,0,0.2c0,11.5,0,26,0,37.5c0,5.6,0,11.2,0,16.8c0,2.5,1.2,4,3.7,4.1
		c7.1,0.3,14.2,0.1,21.3,0c1.8,0,2.7-1.2,2.8-3c0-0.7,0-1.4,0-2.2c0-16.8,0-36.7,0-53.5H48.5z"
        />
        <path
          className="st0"
          d="M1297.6,169.1h27.8c0-27.1,0-58.7,0-85.6c0-0.7,0-1.5-0.1-2.2c-0.3-1.4-1-2.2-2.4-2.2c-7.5,0-15,0-22.5,0
		c-1.4,0-2.4,1.1-2.7,2.6c-0.1,0.6-0.1,1.3-0.1,1.9C1297.6,110.8,1297.6,141.8,1297.6,169.1z"
        />
        <path
          className="st0"
          d="M1408.2,219c-0.4-1.9-0.9-2.4-2.8-2.6c-0.9-0.1-1.8-0.1-2.6-0.1c-6.6,0-13.3,0-19.9,0
		c-14.2,0-28.5-0.1-42.7-0.1c-3.4,0-6.4-1-9.2-2.9c-3-2-4.6-4.9-5-8.3c-0.4-3.7-0.4-7.5-0.5-11.2c-0.1-2.9,0-8.3,0-11.6h-28.1
		c0.1,7.1-0.2,17.3,0.6,24c0.6,4.8,1.3,9.6,3.1,14.1c1.8,4.5,4.5,8.4,8.1,11.6c6.6,6,14.7,8.7,23.5,9c11.8,0.3,23.7,0.1,35.5,0.1
		c0,0,0,0,0,0.1c11.7,0,23.4,0,35,0c0.9,0,1.8,0,2.6,0c1.2,0,2-0.5,2.2-1.7c0.3-1.6,0.7-3.3,0.7-4.9c0.1-3.4,0.1-6.9,0-10.3
		C1408.8,222.3,1408.5,220.6,1408.2,219z"
        />
        <path
          className="st0"
          d="M1199.2,219.9c-0.1-2.8-1-3.8-3.7-3.8c-1.4,0-2.7,0-4.1,0c-19.6,0-39.2,0-58.8,0c-2.8,0-5.6-0.2-8.3-1
		c-3.9-1.2-6.7-3.8-8.1-7.7c-0.8-2.4-1.1-5-1-7.6c0.1-4.5,0-12.6,0-17.6h-28c0.3,8.5-0.7,19,0.9,27.5c0.8,3.8,1.4,7.6,3,11.2
		c1.8,4.1,4.3,7.8,7.6,10.8c6.9,6.1,15.1,9,24.1,9.2c8.9,0.3,17.9,0.1,26.9,0.1c15,0,30.1,0,45.1,0c3,0,4.2-1.3,4.2-4.2
		c0-4.6,0-9.1,0-13.7C1199.2,222,1199.2,221,1199.2,219.9z"
        />
        <path
          className="st0"
          d="M1183.4,169.1c1,0,2.1,0,3.1-0.2c2.6-0.4,3.4-1.1,3.6-3.7c0.1-1.2,0-2.4,0-3.6c0-3.7,0-7.2,0-10.9
		c-0.1-3.1-1.3-4.4-4.4-4.4c-1.1,0-2.2,0-3.4,0c-21.1,0-42.2,0-63.3,0c-1.1,0-2.2-0.1-3.7-0.2c0-1.3,0-2.2,0-3.2
		c0-7.4,0-14.9,0-22.3c0-2.8,0.1-5.6,1-8.3c1.1-3.4,3-6.1,6.5-7.3c3.5-1.2,7.1-1.8,10.8-1.8c19.5,0,39,0,58.5,0c5,0,5.3-0.2,5.5-5.2
		c0.2-4.5,0.1-9,0-13.4c0-1.2-0.3-2.4-0.5-3.6c-0.2-1.3-1-2-2.3-2c-1.7-0.1-3.4-0.2-5-0.2c-19.3,0-38.6-0.1-57.8,0.1
		c-5.1,0-10.3-0.3-15.5,1.1c-3.1,0.8-6.1,1.7-8.9,3.1c-6.2,3.1-11.2,7.4-14.6,13.5c-1.9,3.3-3.3,6.8-4,10.6c-0.7,4-1.8,7.9-1.8,12
		c0,15.3,0,34.4,0,49.8H1183.4z"
        />
        <path
          className="st0"
          d="M1602.7,216.6c-1.2-0.3-2.4-0.3-3.6-0.3c-19,0-38.1,0-57.1,0c-2.2,0-4.5,0-6.7-0.2c-3.6-0.3-6.8-1.6-9.3-4.2
		c-2.9-2.9-3.8-6.6-3.9-10.5c-0.2-5.4-0.1-13.8-0.1-19.3h-27.8c0,5.4,0,13.3,0,18.5c-0.1,6.4,1,12.7,3.2,18.7
		c2,5.5,5.2,10.2,9.9,13.7c6.8,5.2,14.6,7.7,23,7.7c23.7,0.1,47.3,0.1,71,0.1c0.5,0,1,0,1.4-0.1c1.3-0.1,2-0.8,2.2-2.1
		c0.1-0.8,0.1-1.6,0.1-2.4c0-4.6,0-9.1,0-13.7c0-1.1,0-2.2,0-3.4C1604.9,217.6,1604.5,217,1602.7,216.6z"
        />
        <path
          className="st0"
          d="M1493.9,169.1h27.8c0-27.6,0-58.1,0-85.6c0-0.7,0-1.5-0.1-2.2c-0.3-1.4-1-2.2-2.4-2.2c-7.5,0-15,0-22.5,0
		c-1.4,0-2.4,1.1-2.7,2.6c-0.1,0.6-0.1,1.3-0.1,1.9C1493.9,111.1,1493.9,141.6,1493.9,169.1z"
        />
        <path
          className="st0"
          d="M1804.8,228.1c-1.2-3.6-2.5-7.1-3.8-10.7c-0.8-2.2-1.4-4.5-2.2-6.8c-1.2-3.4-2.6-6.8-3.7-10.3
		c-1.7-5-4.2-13-5.9-18.3h-103.4c-1.4,3.8-3.3,9.1-4.4,12.5c-1.5,4.4-2.9,8.9-4.4,13.3c-1.5,4.4-3.1,8.7-4.6,13
		c-1.3,3.6-2.6,7.3-3.7,11c-0.6,2.1-1.7,4.1-1.5,6.5c0.2,1.8,0.7,2.5,2.5,2.5c7.8,0,15.7,0,23.5,0c2,0,3.3-1.2,3.9-2.9
		c0.9-2.3,1.4-4.7,2.2-7c1.2-3.7,2.6-7.3,3.8-11c1.4-4.3,2.7-8.6,4.1-12.9c0.2-0.5,0.8-1,1.3-1.2c0.6-0.2,1.3-0.1,1.9-0.1
		c17.9,0,35.8,0,53.7,0c0.9,0,1.9,0.2,2.8,0.3c0.8,2.7,1.6,5.2,2.4,7.6c0.7,2.3,1.5,4.7,2.3,7c1.3,3.8,2.5,7.7,3.8,11.5
		c0.7,2,1.2,4.1,2,6.1c0.6,1.4,1.8,2.6,3.7,2.6c8.1,0,16.2,0,24.2,0c2.1,0,3.2-1.6,2.6-3.6C1806.9,234.3,1805.8,231.2,1804.8,228.1z
		"
        />
        <path
          className="st0"
          d="M1718.9,166.4c0.8-2.7,1.5-5.5,2.3-8.2c0.9-3,1.9-6,2.8-9.1c1.2-3.9,2.4-7.7,3.6-11.6c0.8-2.8,1.6-5.6,2.5-8.4
		c0.7-2.5,1.5-5,2.2-7.5c0.8-2.8,1.7-5.6,2.6-8.4c0.4-1.2,0.9-2.5,1.4-3.7c0.2,0,0.4,0,0.6,0c0.4,0.7,0.9,1.4,1.2,2.1
		c0.6,1.6,1,3.3,1.5,5c0.8,2.6,1.7,5.2,2.5,7.9c0.8,2.6,1.4,5.2,2.2,7.8c1.2,4,2.6,8,3.9,12c1.1,3.7,2.1,7.5,3.3,11.2
		c1.1,3.7,3.2,9.8,4.3,13.6h28.5c-1.2-3.5-3-8.5-4.1-11.7c-2-5.9-4-11.8-6-17.7c-2-5.9-4-11.8-6-17.7c-2-5.9-4-11.8-6-17.7
		c-2-6-4.1-12-6.1-18c-1.6-4.8-6.1-7.8-10.8-8c-5.3-0.2-10.6-0.2-15.8,0c-4.3,0.2-7.8,2.1-10,5.9c-1,1.7-1.6,3.6-2.2,5.5
		c-0.8,2.2-1.4,4.5-2.2,6.8c-1.6,4.5-3.3,8.9-4.8,13.4c-1.5,4.3-2.8,8.6-4.2,12.9c-1.3,3.7-2.6,7.4-3.9,11.1
		c-1.2,3.4-2.5,6.9-3.6,10.3c-1.1,3.2-2.2,6.4-3.3,9.7c-1.2,3.5-2.4,7-3.6,10.5c-0.5,1.4-0.9,2.9-1.4,4.6c9.4,0,18.5,0,27.6,0
		C1718.3,168,1718.7,167.3,1718.9,166.4z"
        />
        <path
          className="st0"
          d="M778.4,228.3c-1.2-3.6-2.5-7.1-3.8-10.7c-0.8-2.2-1.4-4.5-2.2-6.8c-1.2-3.4-2.6-6.8-3.7-10.3
		c-1.7-5-4.2-13-5.9-18.3H659.3c-1.4,3.8-3.3,9.1-4.4,12.5c-1.5,4.4-2.9,8.9-4.4,13.3c-1.5,4.4-3.1,8.7-4.6,13
		c-1.3,3.6-2.6,7.3-3.7,11c-0.6,2.1-1.7,4.1-1.5,6.5c0.2,1.8,0.7,2.5,2.5,2.5c7.8,0,15.7,0,23.5,0c2,0,3.3-1.2,3.9-2.9
		c0.9-2.3,1.4-4.7,2.2-7c1.2-3.7,2.6-7.3,3.8-11c1.4-4.3,2.7-8.6,4.1-12.9c0.2-0.5,0.8-1,1.3-1.2c0.6-0.2,1.3-0.1,1.9-0.1
		c17.9,0,35.8,0,53.7,0c0.9,0,1.9,0.2,2.8,0.3c0.8,2.7,1.6,5.2,2.4,7.6c0.7,2.3,1.5,4.7,2.3,7c1.3,3.8,2.5,7.7,3.8,11.5
		c0.7,2,1.2,4.1,2,6.1c0.6,1.4,1.8,2.6,3.7,2.6c8.1,0,16.2,0,24.2,0c2.1,0,3.2-1.6,2.6-3.6C780.5,234.4,779.5,231.3,778.4,228.3z"
        />
        <path
          className="st0"
          d="M692.6,166.6c0.8-2.7,1.5-5.5,2.3-8.2c0.9-3,1.9-6,2.8-9.1c1.2-3.9,2.4-7.7,3.6-11.6c0.8-2.8,1.6-5.6,2.5-8.4
		c0.7-2.5,1.5-5,2.2-7.5c0.8-2.8,1.7-5.6,2.6-8.4c0.4-1.2,0.9-2.5,1.4-3.7c0.2,0,0.4,0,0.6,0c0.4,0.7,0.9,1.4,1.2,2.1
		c0.6,1.6,1,3.3,1.5,5c0.8,2.6,1.7,5.2,2.5,7.9c0.8,2.6,1.4,5.2,2.2,7.8c1.2,4,2.6,8,3.9,12c1.1,3.7,2.1,7.5,3.3,11.2
		c1.1,3.7,3.2,9.8,4.3,13.6h28.5c-1.2-3.5-3-8.5-4.1-11.7c-2-5.9-4-11.8-6-17.7c-2-5.9-4-11.8-6-17.7c-2-5.9-4-11.8-6-17.7
		c-2-6-4.1-12-6.1-18c-1.6-4.8-6.1-7.8-10.8-8c-5.3-0.2-10.6-0.2-15.8,0c-4.3,0.2-7.8,2.1-10,5.9c-1,1.7-1.6,3.6-2.2,5.5
		c-0.8,2.2-1.4,4.5-2.2,6.8c-1.6,4.5-3.3,8.9-4.8,13.4c-1.5,4.3-2.8,8.6-4.2,12.9c-1.3,3.7-2.6,7.4-3.9,11.1
		c-1.2,3.4-2.5,6.9-3.6,10.3c-1.1,3.2-2.2,6.4-3.3,9.7c-1.2,3.5-2.4,7-3.6,10.5c-0.5,1.4-0.9,2.9-1.4,4.6c9.4,0,18.5,0,27.6,0
		C691.9,168.2,692.3,167.4,692.6,166.6z"
        />
        <path
          className="st0"
          d="M364.3,228.2c-1.2-3.6-2.5-7.1-3.8-10.7c-0.8-2.2-1.4-4.5-2.2-6.8c-1.2-3.4-2.6-6.8-3.7-10.3
		c-1.7-5-4.2-13-5.9-18.3H245.2c-1.4,3.8-3.3,9.1-4.4,12.5c-1.5,4.4-2.9,8.9-4.4,13.3c-1.5,4.4-3.1,8.7-4.6,13
		c-1.3,3.6-2.6,7.3-3.7,11c-0.6,2.1-1.7,4.1-1.5,6.5c0.2,1.8,0.7,2.5,2.5,2.5c7.8,0,15.7,0,23.5,0c2,0,3.3-1.2,3.9-2.9
		c0.9-2.3,1.4-4.7,2.2-7c1.2-3.7,2.6-7.3,3.8-11c1.4-4.3,2.7-8.6,4.1-12.9c0.2-0.5,0.8-1,1.3-1.2c0.6-0.2,1.3-0.1,1.9-0.1
		c17.9,0,35.8,0,53.7,0c0.9,0,1.9,0.2,2.8,0.3c0.8,2.7,1.6,5.2,2.4,7.6c0.7,2.3,1.5,4.7,2.3,7c1.3,3.8,2.5,7.7,3.8,11.5
		c0.7,2,1.2,4.1,2,6.1c0.6,1.4,1.8,2.6,3.7,2.6c8.1,0,16.2,0,24.2,0c2.1,0,3.2-1.6,2.6-3.6C366.4,234.4,365.3,231.3,364.3,228.2z"
        />
        <path
          className="st0"
          d="M278.4,166.5c0.8-2.7,1.5-5.5,2.3-8.2c0.9-3,1.9-6,2.8-9.1c1.2-3.9,2.4-7.7,3.6-11.6c0.8-2.8,1.6-5.6,2.5-8.4
		c0.7-2.5,1.5-5,2.2-7.5c0.8-2.8,1.7-5.6,2.6-8.4c0.4-1.2,0.9-2.5,1.4-3.7c0.2,0,0.4,0,0.6,0c0.4,0.7,0.9,1.4,1.2,2.1
		c0.6,1.6,1,3.3,1.5,5c0.8,2.6,1.7,5.2,2.5,7.9c0.8,2.6,1.4,5.2,2.2,7.8c1.2,4,2.6,8,3.9,12c1.1,3.7,2.1,7.5,3.3,11.2
		c1.1,3.7,3.2,9.8,4.3,13.6h28.5c-1.2-3.5-3-8.5-4.1-11.7c-2-5.9-4-11.8-6-17.7c-2-5.9-4-11.8-6-17.7c-2-5.9-4-11.8-6-17.7
		c-2-6-4.1-12-6.1-18c-1.6-4.8-6.1-7.8-10.8-8c-5.3-0.2-10.6-0.2-15.8,0c-4.3,0.2-7.8,2.1-10,5.9c-1,1.7-1.6,3.6-2.2,5.5
		c-0.8,2.2-1.4,4.5-2.2,6.8c-1.6,4.5-3.3,8.9-4.8,13.4c-1.5,4.3-2.8,8.6-4.2,12.9c-1.3,3.7-2.6,7.4-3.9,11.1
		c-1.2,3.4-2.5,6.9-3.6,10.3c-1.1,3.2-2.2,6.4-3.3,9.7c-1.2,3.5-2.4,7-3.6,10.5c-0.5,1.4-0.9,2.9-1.4,4.6c9.4,0,18.5,0,27.6,0
		C277.8,168.1,278.2,167.4,278.4,166.5z"
        />
      </g>
    </svg>
  )
}

export default LogoFalabella
