import { combineReducers } from 'redux'
import batchCreateReducer from './reducers/batch-create'
import { createCommitReducer, createGetAllReducer, createGetReducer } from '../../utils'

export const USERS_BATCH_CREATE_START = 'USERS_BATCH_CREATE_START'
export const USERS_BATCH_CREATE_APPEND_RESULT = 'USERS_BATCH_CREATE_APPEND_RESULT'
export const USERS_BATCH_CREATE_APPEND_ERROR = 'USERS_BATCH_CREATE_APPEND_ERROR'
export const USERS_BATCH_CREATE_END = 'USERS_BATCH_CREATE_END'
export const USERS_BATCH_CREATE_RESET = 'USERS_BATCH_CREATE_RESET'

export const getAllUsersStore = createGetAllReducer('USERS')
export const getUsersStore = createGetReducer('USERS')
// export const createUsersStore = createCommitReducer('USERS', 'CREATE')
export const updateUsersStore = createCommitReducer('USERS', 'UPDATE')
export const deleteUsersStore = createCommitReducer('USERS', 'DELETE')
export const exportUsersStore = createCommitReducer('USERS', 'EXPORT')

const rootReducer = combineReducers({
  getById: getUsersStore.reducer,
  getAll: getAllUsersStore.reducer,
  bacthCreate: batchCreateReducer,
  delete: deleteUsersStore.reducer,
  update: updateUsersStore.reducer,
  export: exportUsersStore.reducer,
})

export default rootReducer
