import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Notification, sendUciNotification } from '@jarvis-catalyst/ui-components'

import { applicationUiSidebarAction, applicationUiModalOverlay } from '../../store/modules/application/actions'
import { ROUTE_PREFIX } from '../../const'

type HandlerEventsProps = {
  children: React.ReactNode;
}

export const receiveMessage = (event: any, history: any) => {
  if (!event.data.commandType || !event.data.data) return 'badcode'
  const type = event.data.commandType
  const { data } = event.data

  switch (type) {
  case 'pushNavigationHistory': {
    const params = new URLSearchParams(window.location.search)
    const qs = new URLSearchParams(data.search)
    qs.set('locale', params.get('locale') || '')
    qs.set('tenant', params.get('tenant') || '')
    qs.set('sites', params.get('sites') || '')
    history.replace(`${ROUTE_PREFIX}${data.pathname}?${qs}`, data.state)
    return 'pushNavigationHistory-called'
  }
  case 'displayNotification':
    if (!data.message) return 'badcode-displayNotification-called'
    sendUciNotification({
      type: data.level || 'info',
      body: (
        <>
          {data.message.title && <strong>{data.message.title}</strong>}
          {data.message.text && <p>{data.message.text}</p>}
        </>
      ),
      closeButton: true,
      timeout: data.timeout || 2000,
    })
    return 'displayNotification-called'
  case 'displaySidebar':
    if (!data.mode) return 'badcode-displaySidebar-called'
    if (!['full', 'hidden', 'compact'].includes(data.mode)) return 'badcode-displaySidebar-called'
    applicationUiSidebarAction(data.mode, true)
    return 'displaySidebar-called'
  case 'displayModalOverlay':
    if (!data.mode) return 'badcode-displayModalOverlay-called'
    if (!['show', 'hidden'].includes(data.mode)) return 'badcode-displayModalOverlay-called'
    applicationUiModalOverlay(data.mode)
    return 'displayModalOverlay-called'
  default:
    return 'nomatch-called'
  }
}

const HandlerEvents = function ({ children }: HandlerEventsProps) {
  const history = useHistory()
  useEffect(() => {
    const handler = (event: any) => receiveMessage(event, history)
    window.addEventListener('message', handler)
    return () => window.removeEventListener('message', handler)
  }, [history])

  return (<>
    {children}
    <Notification />
  </>)
}

export default HandlerEvents
