import { LOGS_API_BASE_URL } from '../configuration'
import { DomainServicesItem } from '../../store/modules/domains-services/types'
import { HTTPResponse } from '../types'
import { getService } from '../http'

type Params = {
  token: string
  tenant?: string
  subscription?: string
}

type ServiceParams = {
  domain: string
} & Params

export const getDomains = async (params: Params): Promise<HTTPResponse<DomainServicesItem>> => {
  const filter = params.tenant ? `?tenant=${params.tenant}` : `?subscription=${params.subscription}`
  const uri = `${LOGS_API_BASE_URL}/audit-log/v2/domains${filter}`
  return getService(params.token, uri)
}

export const getServices = async (params: ServiceParams): Promise<HTTPResponse<DomainServicesItem>> => {
  const filter = `${params.tenant ? `?tenant=${params.tenant}` : `?subscription=${params.subscription}`}&domain=${
    params.domain
  }`
  const uri = `${LOGS_API_BASE_URL}/audit-log/v2/services${filter}`
  return getService(params.token, uri)
}
