import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@jarvis-catalyst/jarvis-ui-kit'
import AppBoxInfo from '../../../components/appboxinfo/AppBoxInfo'
import UserRolesDescription from '../../../components/user-roles-description/UserRolesDescription'
import CustomBoxTitle from '../../../components/customboxtitle/CustomBoxTitle'
import { JARVIS_DOCUMENTATION, LEAVE_FEEDBACK } from '../../../dashboard.constants'
import { apps } from '../../../utils/appsBox'
import QuestionSection from '../../../components/questions-section/QuestionSection'
import ChatOurTeam from '../../../components/chatourteam/ChatOurTeam'

export const LeftColumn: React.FC = function () {
  const { t } = useTranslation()
  return (
    <Grid
      item
      xs={12}
      md={10}
      sx={{
        order: { xs: 2, sm: 1 },
      }}
    >
      <Box sx={{ marginBottom: '16px' }}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={6}
          >
            <UserRolesDescription />
          </Grid>
          <Grid
            item
            xs={3}
            sm={6}
            md={4}
            lg={3}
          >
            <CustomBoxTitle
              link={{
                title: t('JARVIS_API_DOCUMENTATION'),
                url: JARVIS_DOCUMENTATION,
              }}
              boxBackgroundColor="rgba(234, 236, 254, 1)"
              imgUrl="url(/jarvis-Icon.png)"
              imgSize="45%"
            />
          </Grid>
          <Grid
            item
            xs={3}
            sm={6}
            md={12}
            lg={3}
          >
            <CustomBoxTitle
              link={{ title: t('LEAVE_FEEDBACK'), url: LEAVE_FEEDBACK }}
              boxBackgroundColor="rgba(117, 105, 244, 1)"
              imgUrl="url(/jarvis-icon-leave-feedback.png)"
              imgSize="60%"
            />
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        spacing={2}
      >
        {apps.map((app, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={4}
            key={index}
          >
            <AppBoxInfo
              title={app.title}
              icon={app.icon}
              description={app.description}
              links={app.links}
            />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ marginTop: '40px' }}>
        <Grid container>
          <Grid item>
            <QuestionSection />
          </Grid>
        </Grid>
      </Box>

      <ChatOurTeam />
    </Grid>
  )
}
