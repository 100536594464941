import { Dispatch } from 'redux'
import { enhancementAction } from '../../index'
import {
  getAllCustomPermissionsStore,
  getAllCorePermissionsStore,
  createCustomPermissionsStore,
  updateCustomPermissionsStore,
  getByIdCustomPermissionStore,
  deleteCustomPermissionsStore,
  getAllRolePermissionsStore,
} from '.'

import {
  createCustomPermissions,
  getAllCustomPermissionsV2,
  getAllCustomPermissions,
  getCustomPermissionById,
  updateCustomPermissions,
  deleteCustomPermission,
  getAllPermissionsByRoles,
} from '../../../services/clients/custom-permissions'
import { CustomPermission } from './types'
import { HTTPRequestCreate, HTTPResponsePagination } from '../../../services/types'
import { ParamsGetAllAction } from '../../types'
import { deleteAction, getAction, getAllAction, resetAction } from '../../utils'

type Params = {
  name: string
  description: string
  page?: number
  limit?: number
}

type PermissionByRoleParams = {
  page?: number
  limit?: number
  roles?:string[]
}

export const customPermissionsGetAllAction = (params: Params): void =>
  enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
    const { token } = getState().application
    const { actions } = getAllCustomPermissionsStore
    dispatch({ type: actions.START, payload: null })
    try {
      const data: HTTPResponsePagination<CustomPermission[]> = await getAllCustomPermissionsV2(token, params)
      dispatch({
        type: actions.RESULT,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: actions.ERROR,
        payload: error,
      })
    }
  })

export const customPermissionGetAllWithScopesAction = (): void =>
  enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
    const { token } = getState().application
    const { actions } = getAllCustomPermissionsStore
    dispatch({ type: actions.START, payload: null })
    const params = {
      filter: 'name*=""+AND+createdBy="USER"',
      page: 1,
      limit: 100,
      token,
    }
    try {
      // eslint-disable-next-line max-len
      const data: HTTPResponsePagination<CustomPermission[]> = await getAllCustomPermissions(params)
      dispatch({
        type: actions.RESULT,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: actions.ERROR,
        payload: error,
      })
    }
  })

export const permissionsByRolesGetAllAction = (params: PermissionByRoleParams): void =>
  enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
    const { token } = getState().application
    const { actions } = getAllRolePermissionsStore
    dispatch({ type: actions.START, payload: null })
    try {
      const data: HTTPResponsePagination<CustomPermission[]> = await getAllPermissionsByRoles(token, params)
      dispatch({
        type: actions.RESULT,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: actions.ERROR,
        payload: error,
      })
    }
  })

export const permissionsByRolesResetAction = (): void => resetAction(getAllRolePermissionsStore)()

export const customPermissionGetByIdAction = (id: string): void =>
  getAction(getByIdCustomPermissionStore, getCustomPermissionById)(id)

export const customPermissionResetGetByIdAction = (): void =>
  enhancementAction((dispatch: Dispatch) => {
    dispatch({ type: getByIdCustomPermissionStore.actions.RESET, payload: null })
  })

export const customPermissionCreateAction = (data: any): void =>
  enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
    const { token } = getState().application

    dispatch({ type: createCustomPermissionsStore.actions.START, payload: null })
    try {
      const request: HTTPRequestCreate<any> = { token, data }
      const result: any = await createCustomPermissions(request)
      if (result.errors && result.errors.length > 0) {
        dispatch({
          type: createCustomPermissionsStore.actions.ERROR,
          payload: result.errors[0].title,
        })
      } else {
        dispatch({
          type: createCustomPermissionsStore.actions.RESULT,
          payload: result,
        })
      }
    } catch (error) {
      dispatch({
        type: createCustomPermissionsStore.actions.ERROR,
        payload: error,
      })
    }
  })

export const customPermissionUpdateAction = (id: string, data: any): void =>
  enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
    const { token } = getState().application

    dispatch({ type: updateCustomPermissionsStore.actions.START, payload: null })
    try {
      const request: HTTPRequestCreate<any> = { token, data }
      const result: any = await updateCustomPermissions(id, request)
      if (result.errors && result.errors.length > 0) {
        dispatch({
          type: updateCustomPermissionsStore.actions.ERROR,
          payload: result.errors[0].title,
        })
      } else {
        dispatch({
          type: updateCustomPermissionsStore.actions.RESULT,
          payload: result,
        })
      }
    } catch (error) {
      dispatch({
        type: updateCustomPermissionsStore.actions.ERROR,
        payload: error,
      })
    }
  })

export const customPermissionResetUpdateAction = (): void =>
  enhancementAction((dispatch: Dispatch) => {
    dispatch({ type: updateCustomPermissionsStore.actions.RESET, payload: null })
  })

export const customPermissionResetCreateAction = (): void =>
  enhancementAction((dispatch: Dispatch) => {
    dispatch({ type: createCustomPermissionsStore.actions.RESET, payload: null })
  })

export const corePermissionsGetAllAction = (params?: ParamsGetAllAction): void =>
  getAllAction(getAllCorePermissionsStore, getAllCustomPermissions)(params)

export const customPermissionDeleteAction = (id: string): void =>
  deleteAction(deleteCustomPermissionsStore, deleteCustomPermission)(id)

export const customPermissionResetDeleteAction = (): void => resetAction(deleteCustomPermissionsStore)()
