import { Icon } from '@jarvis-catalyst/jarvis-ui-kit'
import {
  URL_ACTIVITY,
  URL_ACTIVITY_GUIDE_1,
  URL_ACTIVITY_GUIDE_2,
  URL_MERCHANT_CENTER,
  URL_SEARCH_DOCUMENTATION,
  URL_SEARCH,
  URL_PROMOTIONS,
  URL_CUSTOMERS,
  URL_CONTENT,
  URL_CONTENT_API,
} from '../dashboard.constants'
import { AppInfoProps } from '../dashboard.types'

export const apps: AppInfoProps[] = [
  {
    title: 'ACTIVITY_LOG',
    icon: (
      <Icon
        name="Audit"
        color="rgba(117, 105, 244, 1)"
      />
    ),
    description: 'ACTIVITY_DESCRIPTION',
    links: [
      { url: URL_ACTIVITY, title: 'DOCUMENTATION' },
      { url: URL_ACTIVITY_GUIDE_1, title: 'APPS_BOX.ACTIVITY_LOG.ACTIVITY_GUIDE_1' },
      { url: URL_ACTIVITY_GUIDE_2, title: 'APPS_BOX.ACTIVITY_LOG.ACTIVITY_GUIDE_2' },
    ],
  },
  {
    title: 'CATALOG',
    icon: (
      <Icon
        name="Catalog"
        color="rgba(117, 105, 244, 1)"
      />
    ),
    description: 'CATALOG_DESCRIPTION',
    links: [{ url: URL_MERCHANT_CENTER, title: 'DOCUMENTATION' }],
  },
  {
    title: 'SEARCH',
    icon: (
      <Icon
        name="Search"
        color="rgba(117, 105, 244, 1)"
      />
    ),
    description: 'SEARCH_DESCRIPTION',
    links: [
      { url: URL_SEARCH_DOCUMENTATION, title: 'DOCUMENTATION' },
      { url: URL_SEARCH, title: 'APPS_BOX.SEARCH.PUBLISHING_RULES' },
    ],
  },
  {
    title: 'PROMOTIONS',
    icon: (
      <Icon
        name="Promotion"
        color="rgba(117, 105, 244, 1)"
      />
    ),
    description: 'PROMOTIONS_DESCRIPTION',
    links: [{ url: URL_PROMOTIONS, title: 'DOCUMENTATION' }],
  },
  {
    title: 'CUSTOMERS',
    icon: (
      <Icon
        name="User"
        color="rgba(117, 105, 244, 1)"
      />
    ),
    description: 'CUSTOMERS_DESCRIPTION',
    links: [{ url: URL_CUSTOMERS, title: 'DOCUMENTATION' }],
  },
  {
    title: 'CONTENT',
    icon: (
      <Icon
        name="Content"
        color="rgba(117, 105, 244, 1)"
      />
    ),
    description: 'CONTENT_DESCRIPTION',
    links: [
      { url: URL_CONTENT, title: 'DOCUMENTATION' },
      { url: URL_CONTENT_API, title: 'API docs' },
    ],
  },
]
