import { combineReducers } from 'redux'

import { createGetAllReducer, createGetReducer, createCommitReducer } from '../../utils'

export const getAllRolesStore = createGetAllReducer('ROLES')
export const getAllRolesSecondStore = createGetAllReducer('ROLES_SECOND')
export const getAllRolesSitesStore = createGetAllReducer('ROLES_SITE')
export const getAllRolesSellersStore = createGetAllReducer('ROLES_SELLER')
export const getRolesStore = createGetReducer('ROLES')
export const createRolesStore = createCommitReducer('ROLES', 'CREATE')
export const updateRolesStore = createCommitReducer('ROLES', 'UPDATE')
export const deleteRolesStore = createCommitReducer('ROLES', 'DELETE')

const rootReducer = combineReducers({
  getById: getRolesStore.reducer,
  getAll: getAllRolesStore.reducer,
  getAllSecond: getAllRolesSecondStore.reducer,
  getAllSites: getAllRolesSitesStore.reducer,
  getAllSellers: getAllRolesSellersStore.reducer,
  create: createRolesStore.reducer,
  update: updateRolesStore.reducer,
  delete: deleteRolesStore.reducer,
})

export default rootReducer
