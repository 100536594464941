import { Button, Icon } from '@jarvis-catalyst/jarvis-ui-kit'

const ButtonToggleCardPermissions = function ({ open, setOpen }: any) {
  return (
    <Button
      size="small"
      onClick={() => setOpen(!open)}
    >
      {open ? <Icon name="ChevronUp" /> : <Icon name="ChevronDown" />}
    </Button>
  )
}

export default ButtonToggleCardPermissions
