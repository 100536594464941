// eslint-disable-next-line no-useless-escape
export const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const isValidEmail = (email: string): boolean => regexEmail.test(email)

export const validateScopeName = (name: string): boolean => name.length > 0 && name.length < 61

// eslint-disable-next-line no-useless-escape
export const regexUrl = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

export const regexRoute = /^((\/[\w\-/]+([\w-]+))+([\w-]+)+)$/

// eslint-disable-next-line no-useless-escape
export const regexAppUrl = /^https:\/\/[\w-]+\.[\w-]+\.[\w-]+([\/][\w-+!*'();:@&=\+$,\/?%#\[\]]*)*$/

export const regexValidIdentity = /^[^\s]{4,}$/
export const isValidIdentity = (identity: string): boolean => regexValidIdentity.test(identity)
