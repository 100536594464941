import { ActionReducer } from '../../../store/types'

const reducer = (state: any, action: ActionReducer<any>) => {
  switch (action.type) {
  case 'UPDATE_TENANTS':
    return {
      ...state,
      tenants: action.payload,
    }
  case 'UPDATE_TOTAL_PAGES':
    return {
      ...state,
      totalPages: action.payload,
    }
  case 'UPDATE_CURRENT_PAGE':
    return {
      ...state,
      currentPage: action.payload,
    }
  case 'UPDATE_TENANT_STATUS':
    return {
      ...state,
      tenantStatus: action.payload,
      currentPage: 1,
    }
  case 'UPDATE_SEARCH_VALUE':
    return {
      ...state,
      searchValue: action.payload,
      currentPage: 1,
    }
  case 'RESET_SEARCH_VALUE':
    return {
      ...state,
      searchValue: '',
      currentPage: 1,
      status: !state.status,
    }
  case 'RESET':
    return {
      ...state,
      currentPage: 1,
      tenantStatus: 'ALL',
      searchValue: '',
      status: state.initialLoad ? state.status : !state.status,
      initialLoad: false,
    }
  default:
    return state
  }
}

export default reducer
