import { Typography, Grid, Box } from '@jarvis-catalyst/jarvis-ui-kit'
import { useTranslation } from 'react-i18next'
import CardBlock from '../../../../components/CardBlock/CardBlock'
import { CustomQuestionProps } from '../../dashboard.types'

const QuestionAndAnswer = function ({
  title,
  description,
  points,
  footer,
  links = [],
  open = false,
}: CustomQuestionProps) {
  const { t } = useTranslation()

  return (
    <CardBlock
      title={t(title)}
      open={open}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
        <Typography
          variant="H400"
          color="text"
        >
          {t(description)}
        </Typography>
        {points?.length > 0 && (
          <ul>
            {points.map(({ title: pointTitle, description }, index) => (
              <li key={index}>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                    >
                      <Typography
                        variant="H500"
                        color="text"
                      >
                        {t(pointTitle)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                    >
                      <Typography
                        variant="H400"
                        color="text"
                        align="justify"
                      >
                        {t(description)}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </li>
            ))}
          </ul>
        )}
        {footer && (
          <Typography
            variant="H400"
            color="text"
          >
            {t(footer)}
          </Typography>
        )}

        {links?.length > 0 && (
          <Box sx={{ display: 'flex', marginTop: '50px' }}>
            {links.map((link, index) => (
              <Box sx={{ display: 'flex' }}>
                <a
                  style={{
                    padding: 0,
                    color: 'rgba(117, 105, 244, 1)',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    marginLeft: '5px',
                  }}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t(link.title)}
                </a>
                {index >= 0 && links && index < links.length - 1 && (
                  <Box sx={{ marginLeft: '5px', display: 'flex' }}> - </Box>
                )}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </CardBlock>
  )
}

export default QuestionAndAnswer
