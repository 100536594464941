import { ActionReducer, StoreAllData } from '../../types'
import { ApplicationReducer, ActionReducerPayload } from './types'

export const APPLICATION_CORE_START = 'APPLICATION_CORE_START'
export const APPLICATION_ACTION_SET_CONFIGURATION = 'APPLICATION_CORE_SET_CONFIGURATION'

export const initialState: ApplicationReducer = {
  start: false,
  activeTenant: {
    id: '',
    name: '',
    sites: [],
    sellers: [],
  },
  activeGroup: {
    id: '',
    name: '',
    description: '',
    isProtected: false,
  },
  userHasTenant: undefined,
  token: undefined,
  language: 'es',
  claims: undefined,
  uiSidebar: 'full',
  uiModalOverlay: 'hidden',
  uiTenantDepend: false,
  availableApplications: [],
  resetCustomAppUrl: 0,
  errors: [],
}

const reducer = (prevState = initialState, action: ActionReducer<ActionReducerPayload>): ApplicationReducer => {
  switch (action.type) {
  case APPLICATION_CORE_START:
    return {
      ...prevState,
      start: action.payload,
    }
  case APPLICATION_ACTION_SET_CONFIGURATION:
    return {
      ...prevState,
      [action.payload.key]: action.payload.value,
    }
  default:
    return prevState
  }
}

// Applications
export const getAllInitialState: StoreAllData<any> = {
  data: [],
  pagination: {
    total: 0,
    pageSize: 0,
    page: 0,
    totalPages: 0,
  },
  error: null,
  success: null,
  errorMessage: '',
  loading: false,
}

export default reducer
