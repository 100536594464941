/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { REACT_APP_TENANT_API_BASE_URL } from '../../../services/configuration'

const baseUrl = REACT_APP_TENANT_API_BASE_URL

const getUrl = (location, params) => {
  const url = new URL(location)
  url.search = new URLSearchParams(params)
  return url
}

const locations = {
  TENANT_GET_ALL: (limit, page, filter) => {
    const params = {
      limit,
      page,
    }

    if (filter) {
      params.filter = filter
    }
    return getUrl(`${baseUrl}/tenants/v1`, params)
  },
  TENANT_CREATE: () => getUrl(`${baseUrl}/tenants/v1`),
  TENANT_UPDATE: (id) => getUrl(`${baseUrl}/tenants/v1/${id}`),
  TENANT_GET_BY_ID: (id) => getUrl(`${baseUrl}/tenants/v1/${id}`),
}

export default locations
