import React from 'react'
import { Route } from 'react-router-dom'
import Tenants from './pages/tenants'
import { TenantsContextProvider } from './contexts/TenantsContext'

const TenantsModule = function () {
  return (
    <TenantsContextProvider>
      <Route
        exact
        path="/tenants"
        component={Tenants}
      />
    </TenantsContextProvider>
  )
}

export default TenantsModule
