import { dataToBody, FetchType, LOGS_API_BASE_URL, restClient, wrapperHeaderWithToken } from '../configuration'
import { HTTPClientConfig, HttpMethodEnum, HTTPResponsePagination } from '../types'
import { makeUriParams } from '../services.utils'
import { LogDetail, LogListItem, ParamsGetAllLogsAction } from '../../store/modules/logs/types'

const makeLogFilters = (request: { token: string; date?: string; identity?: string; action?: string;
  module?: string; service?: string; state?: string } & ParamsGetAllLogsAction, obj: any) => {
  if (request.identity !== undefined && request.identity !== '') obj.identity = request.identity
  if (request.action !== undefined && request.action !== '') obj.action = request.action
  if (request.module !== undefined && request.module !== '') obj.module = request.module
  if (request.service !== undefined && request.service !== '') obj.service = request.service
  if (request.date !== undefined && request.date !== '') {
    const dateRange = request.date.split('-')
    obj.startDate = new Date(dateRange[0]).toISOString()
    obj.endDate = new Date(dateRange[1]).toISOString()
  }
  if (request.state !== undefined && request.state !== '') obj.state__like = request.state
}

export const getAllLogs = async (request: ParamsGetAllLogsAction)
    : Promise<HTTPResponsePagination<LogListItem[]>> => {
  const obj: any = {}
  if (request.page !== undefined) obj.page = request.page
  if (request.limit !== undefined) obj.limit = request.limit
  if (request.tenant !== undefined && request.tenant !== '') obj.tenant = request.tenant
  makeLogFilters(request, obj)

  const objUrl = new URLSearchParams(obj)

  const uri = `${LOGS_API_BASE_URL}/audit-log/v2/logs?${objUrl}`
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.GET,
    headers: wrapperHeaderWithToken(request.token),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)
      const data: HTTPResponsePagination<LogListItem[]> = await response.json()
      resolve(data)
    } catch (error) {
      reject(`${error}`)
    }
  })
}

export const getLogById = async (request: any)
    : Promise<LogDetail> => {
  const objUrl = makeUriParams(request)
  const uri = `${LOGS_API_BASE_URL}/audit-log/v2/logs/${request.id}?${objUrl}`
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.GET,
    headers: wrapperHeaderWithToken(request.token),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)
      if (response.status === 200) {
        const data: LogDetail = await response.json()
        resolve(data)
      } else if (response.status === 404) {
        reject('NOT_FOUND')
      } else {
        reject('NOT_FOUND')
      }
    } catch (error) {
      reject(`${error}`)
    }
  })
}

export const exportLogs = async (request: ParamsGetAllLogsAction)
    : Promise<Blob> => {
  const obj: any = {}
  if (request.tenant !== undefined && request.tenant !== '') obj.tenant = request.tenant
  const objUrl = new URLSearchParams(obj)

  const requestBody: any = {}
  if (request.ids !== undefined) {
    requestBody.ids = request.ids
  } else {
    makeLogFilters(request, requestBody)
  }

  const uri = `${LOGS_API_BASE_URL}/audit-log/v2/logs/export?${objUrl}`
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.POST,
    headers: wrapperHeaderWithToken(request.token, {}, FetchType.CSV),
    body: dataToBody(requestBody),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)
      if (response.status === 200) {
        const data: Blob = await response.blob()
        resolve(data)
      } else if (response.status === 400) {
        const data: any = await response.json()
        reject(data)
      } else if (response.status === 403) {
        reject('UNAUTHORIZED')
      } else {
        reject('NOT_FOUND')
      }
    } catch (error) {
      reject(`${error}`)
    }
  })
}
