import { Pagination } from '../store/types'

export enum HttpMethodEnum {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

export type HTTPClientConfig = {
    method: HttpMethodEnum
    headers: Headers
    body?: string
}

export type HTTPRequestWithTenant = {
    tenant?: string
    token: string,
    userId?: string,
}

export type HTTPRequestFilterPagination = {
    page?: number
    limit?: number | 'all'
    filter?: string | null
} & HTTPRequestWithTenant

export type HTTPRequestCreate<T> = {
    token: string
    data: T
}

export type HTTPResponsePagination<T> = {
    data: T
    meta: {
        pagination: Pagination
    }
}

export type HTTPResponse<T> = {
    data: T
}

export type HTTPResponseCreate<T> = T

export type CustomRequest = {
    id?: string
    token: string
    data?: any
    page?: number
    limit?: number
    filter?: string
    tenant?: string
}

export type CustomCreateRequest = {
    token: string
    data: any
}

export type CustomUpdateRequest = {
    id: string
    token: string
    data: any
}

export type HTTPRequestGetAllApps = {
    token: string,
    page?: number
    limit?: number | string
    filter?: string | null
    tenant?: string
    groupId?: string,
    globalApps?: boolean
}

export type HTTPRequestGetTenants = {
    withSites?: boolean
} & HTTPRequestFilterPagination
