/*
diferenceTime(new Date('Wed Jul 08 2020 11:00:00 GMT-0400 (hora estándar de Chile)'),
new Date('Wed Jul 08 2020 11:00:01 GMT-0400 (hora estándar de Chile)'))
15 minutes = 900
*/
export function diferenceTime(a: Date, b: Date) {
  const tsA = a.getTime()
  const tsB = b.getTime()
  return (tsB - tsA) / 1000
}

// xMinutesHavePassed(1, new Date('Wed Jul 08 2020 11:00:00 GMT-0400 (hora estándar de Chile)'),
// new Date('Wed Jul 08 2020 11:01:01 GMT-0400 (hora estándar de Chile)'))
export function xMinutesHavePassed(minutes: number, oldDate: Date, currentDate: Date) {
  const second = minutes * 60
  return diferenceTime(oldDate, currentDate) > second
}

export function fifteenMinuteHavePassed(oldDate: Date, currentDate: Date) {
  return xMinutesHavePassed(15, oldDate, currentDate)
}
