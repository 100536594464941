import { sendUciNotification } from '@jarvis-catalyst/ui-components'
import { BOOLEAN } from '../const'

export const sendSuccessNotification = (message: string) => sendUciNotification({
  type: 'success',
  body: message,
  closeButton: BOOLEAN.TRUE,
  timeout: 3000,
})

export const sendDangerNotification = (message: string) => sendUciNotification({
  type: 'danger',
  body: message,
  closeButton: BOOLEAN.TRUE,
  timeout: 3000,
})
