/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import UUID from 'uuid'
import config from '../../config'

export const sendEvent = (iframe, eventType, data, receiver) => {
  // eslint-disable-next-line no-console
  config.REACT_APP_DEBUG_MODE && console.info('%c sendEvent >', 'background: #222; color: #bada55', eventType, data, receiver)
  if (iframe && iframe.contentWindow) {
    iframe.contentWindow.postMessage(
      {
        eventId: UUID.v4(),
        eventType,
        data,
      },
      receiver,
    )
  }
}
