import { Spinner } from '@jarvis-catalyst/ui-components'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { userByIdAction, userByIdResetAction, userUpdateAction, userUpdateResetAction } from '../../../../store/modules/users/actions'
import { usersGetByIdSelector, userUpdateSelector } from '../../../../store/modules/users/selectors'
import useAppendQS from '../../../../hooks/useAppendQS'
import { ROUTES } from '../../../../const'
import { tenantsGetAllAction } from '../../../../store/modules/tenants/actions'
import { rolesGetAllActionV2 } from '../../../../store/modules/roles/actions'
import { getAllTenantsFilter } from '../../../../utils/filters'
import {
  rolesGetAllSecondSelector,
  rolesGetAllSelector, rolesGetAllSellersSelector,
  rolesGetAllSitesSelector,
} from '../../../../store/modules/roles/selectors'
import { tenantsGetAllSelector } from '../../../../store/modules/tenants/selectors'
import { dataIsLoad } from '../../../../store/utils'
import { sendDangerNotification, sendSuccessNotification } from '../../../../utils/sendNotification'
import useHasPermissionAndHasSomeScopes from '../../../../hooks/useHasPermissionAndHasSomeScopes'
import ManageUserForm from './components/ManageUserForm'
import { Account, TenantRole } from './components/manage-user.type'
import { applicationUiSidebarAction } from '../../../../store/modules/application/actions'
import useAuthenticationContextWrapper from '../../../shell/contexts/AuthenticationContextWrapper'
import { sellersGetAllAction } from '../../../../store/modules/sellers/actions'

const useSetInit = (id: string, loadRoles: boolean) => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    applicationUiSidebarAction('hidden')
    if (id) {
      userByIdAction(id)
      tenantsGetAllAction(getAllTenantsFilter({ page: 1, limit: 100, status: 'active', withSites: true }))
      sellersGetAllAction({ page: 1, limit: 100 })
      if (loadRoles) {
        rolesGetAllActionV2({ limit: 100, page: 1, scope: 'SUBSCRIPTION' })
        rolesGetAllActionV2({ limit: 100, page: 1, scope: 'TENANT' }, 'second')
        rolesGetAllActionV2({ limit: 100, page: 1, scope: 'SITE' }, 'sites')
        rolesGetAllActionV2({ limit: 100, page: 1, scope: 'SELLER' }, 'sellers')
      }
      return () => {
        userByIdResetAction()
      }
    }
  }, [id, loadRoles])
}

export const useAfterCommit = (userUpdateStore: any) => {
  const { t } = useTranslation()
  const history = useHistory()
  const appendQs = useAppendQS()

  useEffect(() => {
    if (userUpdateStore.success && !userUpdateStore.loading) {
      history.push(appendQs(ROUTES.USER_LIST))
      userUpdateResetAction()
      sendSuccessNotification(t('ACTIONS.SAVED'))
    }

    if ((userUpdateStore.error && !userUpdateStore.loading)) {
      sendDangerNotification(t(`ACTIONS.${userUpdateStore.errorMessage}`))
    }
  }, [userUpdateStore])
}

const ManageUser = function () {
  const { t } = useTranslation()
  const params: any = useParams()
  const userStore = useSelector(usersGetByIdSelector)
  const userUpdateStore = useSelector(userUpdateSelector)

  const rolesStore = useSelector(rolesGetAllSelector)
  const rolesByTenantStore = useSelector(rolesGetAllSecondSelector)
  const rolesBySiteStore = useSelector(rolesGetAllSitesSelector)
  const rolesBySellerStore = useSelector(rolesGetAllSellersSelector)
  const tenantsStore = useSelector(tenantsGetAllSelector)
  const sellerStore = useSelector((s: any) => s.sellers.getAll)

  const [init, setInit] = useState(false)

  const { isAdmin } = useAuthenticationContextWrapper()

  const hasWrite = useHasPermissionAndHasSomeScopes({
    scope: 'subscription',
    permission: 'subscription.jarvisusers',
    values: ['write'],
  }) || isAdmin

  const pageTitle = t(hasWrite ? 'MANAGE_USER.TITLE_EDIT' : 'MANAGE_USER.TITLE_VIEW')

  useSetInit(params.id, hasWrite)

  const rolesLoaded = hasWrite ? dataIsLoad(rolesStore)
      && dataIsLoad(rolesByTenantStore)
      && dataIsLoad(rolesBySiteStore)
      && dataIsLoad(rolesBySellerStore) : true

  useEffect(() => {
    if (dataIsLoad(userStore)
        && dataIsLoad(tenantsStore)
        && dataIsLoad(sellerStore)
        && rolesLoaded) {
      setInit(true)
    }
  }, [tenantsStore, userStore, sellerStore, rolesLoaded, setInit])

  const handlerSave = (account: Account) => {
    const site = account.tenantRoles.flatMap((t:TenantRole) => t.siteRoles.id.map((site) => ({
      id: site.value,
      roles: t.siteRoles.roles.map((r) => r.value),
    })))

    const seller = account.tenantRoles.flatMap((t) => t.sellerRoles.id.map((seller) => ({
      id: seller.value,
      roles: t.sellerRoles.roles.map((r) => r.value),
    })))

    const roles = {
      subscription: account.roles.map((r) => r.value),
      tenant: account.tenantRoles.map((t) => ({
        id: t.id.value,
        roles: t.roles.map((r) => r.value),
      })),
      site,
      seller,
    }
    userUpdateAction(userStore.data.id, { roles })
  }

  useAfterCommit(userUpdateStore)

  return params.id ? (
    <ManageUserForm
      isLoading={userUpdateStore.loading}
      callSave={handlerSave}
      hasWrite={hasWrite}
      user={userStore}
      pageTitle={pageTitle}
      init={init}
    />
  ) : (<Spinner />)
}

export default ManageUser
