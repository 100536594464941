import { HTTPClientConfig, HttpMethodEnum, HTTPResponsePagination, HTTPResponse } from './types'
import { restClient, wrapperHeaderWithToken } from './configuration'

export function get(token: string, uri: string): any {
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.GET,
    headers: wrapperHeaderWithToken(token),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)
      if (response.status === 200) {
        const data: HTTPResponsePagination<any[]> = await response.json()
        resolve(data)
      } else if (response.status === 404) {
        reject('NOT_FOUND')
      } else {
        reject('NOT_FOUND')
      }
    } catch (error: any) {
      if (error.message) reject(error.message)
      else reject(`${error}`)
    }
  })
}

export function getService(token: string, uri: string): any {
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.GET,
    headers: wrapperHeaderWithToken(token),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)
      if (response.status === 200) {
        const data: HTTPResponse<any[]> = await response.json()
        resolve(data)
      } else if (response.status === 404) {
        reject('NOT_FOUND')
      } else {
        reject('NOT_FOUND')
      }
    } catch (error: any) {
      if (error.message) reject(error.message)
      else reject(`${error}`)
    }
  })
}
