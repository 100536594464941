import { Icon, Title, Text, Divider, Container, Row, Col, Card, CardBody } from '@jarvis-catalyst/ui-components'
import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import styles from '../../containers/error/Error.module.css'
import '../cod-002-to-005-crash-during-init/CrashDuringInit.css'

const Cod008FetchAppError = function () {
  const { t } = useTranslation()
  return (
    <Container className={`mt-4 ${styles.error}`}>
      <Divider
        size="lg"
        type="blank"
      />
      <Divider
        size="lg"
        type="blank"
      />
      <Divider
        size="lg"
        type="blank"
      />
      <Row>
        <Col
          offsetMd={2}
          md={8}
        >
          <Card paddingSize="lg">
            <CardBody>
              <Suspense fallback={<div>Loading...</div>}>
                <Icon
                  name="Warning"
                  size={48}
                  color="#e0350b"
                />
                <Title>{t('ERRORS.COD_008_FETCH_APPLICATION.TITLE')}</Title>
                <Card className="crash-init-error-card">
                  <Title tag="h3">{t('ERRORS.COD_008_FETCH_APPLICATION.ERROR_NAME')}</Title>
                  <Text className="crash-init-error-description">{t('ERRORS.COD_008_FETCH_APPLICATION.DESCRIPTION')}</Text>
                </Card>
              </Suspense>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Cod008FetchAppError
