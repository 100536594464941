import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Table,
  Pagination,
  Tooltip,
  Icon,
  ButtonGroup,
  IconButton,
  Menu,
  Button,
  Typography,
  Box,
} from '@jarvis-catalyst/jarvis-ui-kit'
import { useTranslation } from 'react-i18next'
import { Group } from '../../../../store/modules/application/types'
import { groupsGetAllAction, groupDeleteAction, groupDeleteResetAction } from '../../../../store/modules/groups/actions'
import { sendDangerNotification, sendSuccessNotification } from '../../../../utils/sendNotification'

type GroupsListingTableProps = {
  hasWrite: boolean
  handleEdit: any
  setCurrentPage: any;
  currentPage: number;
}

const GroupsListingTable = function ({ hasWrite, handleEdit, setCurrentPage, currentPage }: GroupsListingTableProps) {
  const { t } = useTranslation()
  const { loading, success, errorMessage, error, data, pagination } = useSelector((s: any) => s.groups.getAll)
  const deleteGroupStore = useSelector((s: any) => s.groups.delete)

  const handleDelete = (id: string) => groupDeleteAction(id)

  const options = (group: Group) => {
    const contents = [
      { content: t('DELETE_WARNING'), icon: 'Warning', key: '0', disabled: true },
      {
        content: (
          <ButtonGroup>
            <Button
              variant="text"
            >
              {t('CANCEL')}
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                handleDelete(group.id)
                setCurrentPage(1)
              }}
            >
              {t('CONFIRM')}
            </Button>
          </ButtonGroup>
        ),
        key: '1',
      },
    ]

    return (
      group.isProtected
        ? (
          <div style={{ display: 'flex', justifyContent: 'end', paddingRight: 8 }}>
            <Tooltip
              title={t('MANAGE_CUSTOM_APPLICATION.PROTECTED_HINT')}
              placement="left-start"
            >
              <Icon
                name="Lock"
                color="rgba(0, 0, 0, 0.54)"
              />
            </Tooltip>
          </div>
        )
        : <ButtonGroup size="small">
          <IconButton
            name="Edit"
            label={t('EDIT')}
            disabled={!hasWrite}
            onClick={() => {
              handleEdit(group)
            }}
          />
          <Menu
            AnchorComponent={IconButton}
            AnchorComponentProps={{ label: t('DELETE'), name: 'Delete', disabled: !hasWrite }}
            contents={contents as any}
          />
        </ButtonGroup>
    )
  }

  const handleCopy = (id: string) => navigator.clipboard.writeText(id)

  const getId = (id: string) => (
    <IconButton
      name="Copy"
      onClick={() => handleCopy(id)}
    />
  )

  const headers = [
    { key: 'groupId', content: <Typography variant="H500">{t('ID')}</Typography> },
    { key: 'name', content: <Typography variant="H500">{t('NAME')}</Typography> },
    { key: 'description', content: <Typography variant="H500">{t('DESCRIPTION')}</Typography> },
    { key: 'actions', content: '', align: 'right' as any },
  ]

  const rows = error ? [] : data.map((group: Group) => ({
    id: group.id,
    groupId: getId(group.id),
    name: <Typography variant="H400">{group.name}</Typography>,
    description: <Typography variant="H400">{group.description}</Typography>,
    actions: options(group),
  }))

  useEffect(() => {
    groupsGetAllAction({ page: currentPage, limit: 10 })
  }, [currentPage])

  useEffect(() => {
    if (!deleteGroupStore.loading && deleteGroupStore.success) {
      groupDeleteResetAction()
      sendSuccessNotification(t('ACTIONS.DELETED'))
      groupsGetAllAction({ page: 1, limit: 10 })
    }
    if (deleteGroupStore.error && !deleteGroupStore.loading) {
      groupDeleteResetAction()
      sendDangerNotification(t(`ACTIONS.${deleteGroupStore.errorMessage}`))
    }
  }, [deleteGroupStore])

  return (
    <>
      <Table
        columns={headers}
        rows={rows}
        loading={loading}
        emptyMessage={error ? errorMessage : t('NO_RESULTS_FOUND')}
      />
      {(success && data.length > 0) && (
        <Box
          sx={{ display: 'flex',
            justifyContent: 'end',
            marginTop: 2,
          }}
        >
          <Pagination
            color="primary"
            currentpage={currentPage}
            totalpages={pagination?.totalPages}
            onChange={(event, page) => setCurrentPage(page)}
          />
        </Box>
      )}
    </>
  )
}

export default GroupsListingTable
