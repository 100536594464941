import React, { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form/dist/types/form'
import { useFieldArray } from 'react-hook-form'
import { Typography, Button, Icon, Progress } from '@jarvis-catalyst/jarvis-ui-kit'
import { useTranslation } from 'react-i18next'
import { ApplicationPayload } from '../../../../store/modules/applications/types'
import ApplicationMenu from './ApplicationMenu'
import './ApplicationManagment.css'
import CardBlock from '../../../../components/CardBlock/CardBlock'

type ApplicationMenuProps = {
  form: UseFormReturn<ApplicationPayload>
  app?: ApplicationPayload
  init: boolean
}

const CardMenu = function ({ form, app, init }: ApplicationMenuProps) {
  const { t } = useTranslation()
  const { control, watch, register, setValue } = form

  useEffect(() => {
    setValue('menu.submenu', app ? app.menu?.submenu : [])
  }, [app])

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'menu.submenu',
  })
  const submenu = watch('menu.submenu')
  const handleRemove = (index: any) => remove(index)

  return (
    <CardBlock
      title={t('MANAGE_CUSTOM_APPLICATION.MENU')}
      description={t('MANAGE_CUSTOM_APPLICATION.MENU_DESCRIPTION')}
    >
      {init ? (
        <>
          <ApplicationMenu
            form={form}
            fieldName="menu"
            defaultName={app?.menu?.name}
            permissions={app?.menu?.permissions ? app?.menu?.permissions : []}
            defaultIcon={app?.menu?.icon}
          />
          <div style={{ marginTop: 15, display: 'block', height: 60 }}>
            <Button
              startIcon={<Icon name="CirclePlus" />}
              onClick={() => append({ name: { en: '', es: '' }, route: '', permissions: [] })}
              disabled={submenu?.length >= 5}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  marginTop: 27,
                }}
              >
                <Typography variant="H400">{t('MANAGE_CUSTOM_APPLICATION.ADD_SUBMENU')}</Typography>
                <Typography variant="H100">{t('MANAGE_CUSTOM_APPLICATION.ADD_SUBMENU_DESCRIPTION')}</Typography>
              </div>
            </Button>
          </div>
          {fields.map((field, index) => (
            <div
              id={`${field.id}SubmenuId`}
              key={`${field.id}SubmenuKey`}
              style={{
                border: '3px solid #f3f3f3',
                borderRadius: 1.5,
                padding: '25px 25px 25px 25px',
                marginBottom: 2.5,
                marginTop: 2.5,
              }}
            >
              <div
                style={{
                  marginBottom: 15,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="H500">{`${t('MANAGE_CUSTOM_APPLICATION.SUBMENU')} ${index + 1}`}</Typography>
                {true && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      paddingTop: 2,
                      paddingBottom: 2,
                    }}
                  >
                    <Button
                      id={`id-button-${index}`}
                      onClick={() => handleRemove(field.id)}
                    >
                      <Icon
                        name="Close"
                        color="var(--c-danger)"
                        size="small"
                      />
                    </Button>
                  </div>
                )}
              </div>
              <ApplicationMenu
                form={form}
                fieldName={`menu.submenu.${index}`}
                defaultName={submenu[index]?.name}
                defaultRoute={submenu[index].route}
                defaultIcon={submenu[index].icon}
                permissions={submenu[index].permissions}
                handleRemove={() => handleRemove(index)}
                index={index}
              />
              <input
                hidden
                defaultValue={index + 1}
                {...register(`menu.submenu.${index}.index`)}
              />
            </div>
          ))}
        </>
      ) : (
        <Progress
          circle
          color="secondary"
        />
      )}
    </CardBlock>
  )
}
export default CardMenu
