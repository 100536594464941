import { useSelector } from 'react-redux'
import useQuery from '../../../hooks/useQuery'

const useTenants = () => {
  const query = useQuery()
  const { token } = useSelector((s: any) => s.application)
  const { getMyTenants: myTenants } = useSelector((s: any) => s.tenants)

  return {
    selectedTenant: query.get('tenant'),
    token,
    myTenants,
  }
}

export default useTenants
