/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { eventTypes } from './eventTypes'
import { sendEvent } from './events'
import useEventsContext from '../../contexts/EventContext'
import { getParams } from '../../utils/router'

import { sendUserPermissions } from './eventSender'
import useAuthenticationContextWrapper from '../../contexts/AuthenticationContextWrapper'

const EventHandler = ({ children }) => {
  const history = useHistory()
  const location = useLocation()
  const { activeTenant, availableApplications } = useSelector((s) => s.application)

  const { isLoaded } = useEventsContext()
  const { locale } = getParams(history)
  const { token, userDetails } = useAuthenticationContextWrapper()

  const getCoreApplication = () => availableApplications.find((e) => {
    const pName = location.pathname.split('/')
    const pathName = `/${pName[1]}/${pName[2]}`
    return pathName === e.route
  })

  const application = getCoreApplication()
  const isAppLoaded = application && application.navigationType === 'IFRAME' && isLoaded

  const dispatchEvent = (event) => {
    const iframe = document.getElementById('extension-iframe')
    if (!iframe) {
      return
    }
    iframe.contentWindow && sendEvent(iframe, event.type, event.data, event.target)
  }

  useEffect(() => {
    if (isAppLoaded) {
      dispatchEvent({
        type: eventTypes.TENANT_CHANGE,
        data: {
          ...activeTenant,
        },
        target: application.url,
      })
    }
    // eslint-disable-next-line
  }, [activeTenant, isLoaded])

  useEffect(() => {
    if (isAppLoaded) {
      dispatchEvent({
        type: eventTypes.LOCALE_CHANGE,
        data: { locale },
        target: application.url,
      })
    }
    // eslint-disable-next-line
  }, [locale, isLoaded])

  useEffect(() => {
    if (isAppLoaded) {
      dispatchEvent({
        type: eventTypes.AUTH_TOKEN_CHANGE,
        data: { token },
        target: application.url,
      })
      dispatchEvent({
        type: eventTypes.USER_DETAILS_CHANGE,
        data: { ...userDetails },
        target: application.url,
      })
      sendUserPermissions()
    }
  }, [isLoaded])

  return children
}

export default EventHandler
