import { BOOLEAN, CREATE_BY_SYSTEM, VOID_ARRAY, VOID_STRING } from '../../../../const'

export const assignPrevPermissions = (d: any, corePermissionBase: any) => {
  let arrPermissionsCore: any[] = []
  const arrPermissionsCustom: any[] = []
  Object.keys(d.permissions).forEach((p: any) => {
    const key = p
    const permission = d.permissions[key]
    if (permission.createdBy === CREATE_BY_SYSTEM) {
      const newPermissionBase = corePermissionBase.map((e: any) => {
        const newPermission = { ...e }
        if (e.key === permission.group) {
          newPermission.data = e.data.map((permissionBase: any) => {
            if (permissionBase.name === permission.name) {
              permissionBase.scopes = permission.scopes
            }
            return permissionBase
          })
        }
        return newPermission
      })
      arrPermissionsCore = newPermissionBase
    } else {
      arrPermissionsCustom.push({
        name: permission.name,
        label: permission.name,
        ...permission,
        scopes: permission.scopes,
      })
    }
  })
  return { arrPermissionsCore, arrPermissionsCustom }
}

export const objectIsVoid = (o: any) => Object.entries(o).length === 0

export const getInitialRoleData = () => ({
  title: VOID_STRING,
  description: VOID_STRING,
  subscriptionScope: BOOLEAN.FALSE,
  tenantScope: BOOLEAN.FALSE,
  siteScope: BOOLEAN.FALSE,
  sellerScope: BOOLEAN.FALSE,
  createdBy: VOID_STRING,
})

export const getInitialCustomPermissionData = () => ({ name: VOID_STRING, label: VOID_STRING, scopes: VOID_ARRAY })

export const makeScopes = (role: any) => {
  const scopes = []
  if (role.subscriptionScope) scopes.push('SUBSCRIPTION')
  if (role.tenantScope) scopes.push('TENANT')
  if (role.siteScope) scopes.push('SITE')
  if (role.sellerScope) scopes.push('SELLER')
  return scopes
}

export const makeOutData = (role: any, coreP: any, customP: any) => ({
  name: role.title.trim(),
  description: role.description.trim(),
  scopes: makeScopes(role),
  permissions: { ...coreP, ...customP },
})
