import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, Chip, Modal, TextField, Typography } from '@jarvis-catalyst/jarvis-ui-kit'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { BOOLEAN, VOID_ARRAY, VOID_ID, VOID_STRING } from '../../../../const'
import { validateScopeName } from '../../../../utils/validations'
import {
  customPermissionCreateAction,
  customPermissionResetCreateAction,
  customPermissionResetUpdateAction,
  customPermissionUpdateAction,
} from '../../../../store/modules/custom-permissions/actions'
import { sendDangerNotification, sendSuccessNotification } from '../../../../utils/sendNotification'
import { standardizeCustomPermissionName } from '../../../../utils/string'

type ManageCustomPermissionProps = {
  id: string
  setId: (id: string) => void
  getAllPermissions: () => void
  hasWrite: boolean
}

export const useSetInit = (
  setOpen: any,
  id: string,
  setName: any,
  setDescription: any,
  setScopes: any,
  setInitScope: any,
): void => {
  useEffect(() => {
    setOpen(id !== VOID_STRING)
    if (id === VOID_ID) {
      setName(VOID_STRING)
      setDescription(VOID_STRING)
      setScopes(VOID_ARRAY)
      setInitScope(BOOLEAN.FALSE)
    }
  }, [id, setOpen, setName, setDescription, setScopes, setInitScope])
}

export const useSetInitData = (
  getByIdStore: any,
  setId: any,
  setName: any,
  setDescription: any,
  setScopes: any,
): void => {
  useEffect(() => {
    if (getByIdStore.data !== VOID_STRING && getByIdStore.success && !getByIdStore.loading) {
      const { data } = getByIdStore
      setId(data.id)
      setName(data.name)
      setDescription(data.description)
      setScopes(data.scopes ? data.scopes : [])
    }
  }, [getByIdStore, setId, setName, setDescription, setScopes])
}

export const useAfterCommit = (
  createStore: any,
  updateStore: any,
  setId: any,
  getAllPermissions: any,
  error: any = () => ({}),
): void => {
  const { t } = useTranslation()
  useEffect(() => {
    const isCreateComplete = createStore.success && !createStore.loading
    const isUpdateComplete = updateStore.success && !updateStore.loading

    const isCreateError = createStore.error && !createStore.loading
    const isUpdateError = updateStore.error && !updateStore.loading

    if (isCreateComplete || isUpdateComplete) {
      setId(VOID_STRING)
      isCreateComplete ? customPermissionResetCreateAction() : customPermissionResetUpdateAction()
      sendSuccessNotification(t(isCreateComplete ? 'ACTIONS.CREATED' : 'ACTIONS.SAVED'))
      getAllPermissions()
    }

    if (isCreateError || isUpdateError) {
      sendDangerNotification(
        t(
          createStore.errorMessage === 'CONFLICT' || updateStore.errorMessage === 'CONFLICT'
            ? 'MANAGER_CUSTOM_PERMISSIONS.CONFLICT'
            : 'MANAGER_CUSTOM_PERMISSIONS.ERROR',
        ),
      )
      customPermissionResetCreateAction()
      customPermissionResetUpdateAction()
      error()
    }
  }, [createStore, updateStore, setId, t, getAllPermissions, error])
}

const ManageCustomPermission = function ({ id, setId, getAllPermissions, hasWrite }: ManageCustomPermissionProps) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(BOOLEAN.FALSE)
  const [errorScopes, setErrorScopes] = useState(BOOLEAN.FALSE)
  const [name, setName] = useState(VOID_STRING)
  const [description, setDescription] = useState(VOID_STRING)

  const [scopeLength, setScopeLength] = useState(0)
  const [initScope, setInitScope] = useState(BOOLEAN.FALSE)

  const [scopes, setScopes] = useState(VOID_ARRAY)

  const getByIdStore: any = useSelector((s: any) => s.customPermissions.getById)
  const updateStore: any = useSelector((s: any) => s.customPermissions.update)
  const createStore: any = useSelector((s: any) => s.customPermissions.create)

  useSetInit(setOpen, id, setName, setDescription, setScopes, setInitScope)
  useSetInitData(getByIdStore, setId, setName, setDescription, setScopes)
  useAfterCommit(createStore, updateStore, setId, getAllPermissions, () => ({}))

  const handlerBlurName = () => setName(name.replace(/(^\.+|\.+$)/gm, VOID_STRING))

  const isDisabled = () => name === VOID_STRING || description === VOID_STRING

  const handlerSave = () => {
    if (id === VOID_ID) {
      customPermissionCreateAction({ group: 'up', name, description, scopes, createdBy: 'USER' })
    } else {
      customPermissionUpdateAction(id, { group: 'up', name, description, scopes, createdBy: 'USER' })
    }
  }

  const handlerValidateScope = (v: string) => validateScopeName(v)

  return (
    <Modal
      id="modal__manager__custom-permission"
      open={open}
      disableEscapeKeyDown
    >
      <Typography variant="H400">
        {t(`MANAGER_CUSTOM_PERMISSIONS.${id === VOID_ID ? 'TITLE_CREATE' : hasWrite ? 'TITLE_EDIT' : 'TITLE_VIEW'}`)}
      </Typography>
      <Box sx={{ marginBottom: 1, marginTop: 1, minWidth: 500, display: 'grid', rowGap: 3 }}>
        <TextField
          id="modal__manager__custom-permission__name-input"
          autoFocus={hasWrite}
          disabled={!hasWrite}
          value={name}
          onBlur={handlerBlurName}
          placeholder="my.permission"
          label={t('MANAGER_CUSTOM_PERMISSIONS.NAME')}
          fullWidth
          onChange={({ target }: any) => {
            setName(standardizeCustomPermissionName(target.value))
          }}
          InputProps={{
            startAdornment: <>up.</>,
          }}
          helperText={t('MANAGER_CUSTOM_PERMISSIONS.NAME_HINT')}
        />
        <TextField
          disabled={!hasWrite}
          id="modal__manager__custom-permission__description-input"
          label={t('MANAGER_CUSTOM_PERMISSIONS.DESCRIPTION')}
          placeholder={t('MANAGER_CUSTOM_PERMISSIONS.DESCRIPTION_PLACEHOLDER')}
          value={description}
          onChange={({ target }: any) => setDescription(target.value)}
          multiline
        />
        {hasWrite ? (
          <Autocomplete
            multiple
            id="modal__manager__custom-permission__scopes-chips"
            options={[]}
            freeSolo
            value={scopes}
            error={errorScopes}
            onChange={(event, newValue) => {
              setErrorScopes(false)
              setScopeLength(0)
              const currentScopes = (newValue as any).map((item: any) => {
                if (typeof item === 'string' && handlerValidateScope(item)) {
                  return item
                }
                setErrorScopes(true)
                return false
              })
              setScopes(currentScopes.filter((item: any) => typeof item === 'string'))
            }}
            onInputChange={(e, value: string) => {
              setScopeLength(value.length)
              setInitScope(BOOLEAN.TRUE)
            }}
            renderTags={
              (value, getTagProps) =>
                value.map((option, index) => {
                  if (typeof option === 'string') {
                    return (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                        color="primary"
                      />
                    )
                  }
                  return false
                })
              // eslint-disable-next-line
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Scopes"
                placeholder={t('MANAGER_CUSTOM_PERMISSIONS.SCOPES_PLACEHOLDER')}
                helperText={
                  <>
                    {t('MANAGER_CUSTOM_PERMISSIONS.SCOPES_HINT_PRE')}
                    {scopeLength}
                    {t('MANAGER_CUSTOM_PERMISSIONS.SCOPES_HINT')}
                    {initScope && t('MANAGER_CUSTOM_PERMISSIONS.SCOPES_HINT_MESSAGE')}
                  </>
                }
              />
            )}
          />
        ) : (
          <>
            <Typography variant="H200">Scopes</Typography>
            <div className="uci__badge__container">
              {scopes.map((scope: string, i: number) => (
                <Chip
                  key={i}
                  variant="outlined"
                  label={scope}
                  color="primary"
                />
              ))}
            </div>
          </>
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          id="modal__manager__custom-permission__cancel-button"
          onClick={() => setId(VOID_STRING)}
          color="secondary"
        >
          {t('CANCEL')}
        </Button>
        <Box sx={{ marginLeft: 1 }}>
          <Button
            id="modal__manager__custom-permission__save-button"
            disabled={isDisabled() || !hasWrite}
            onClick={handlerSave}
            variant="contained"
            color="primary"
          >
            {t('SAVE')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ManageCustomPermission
