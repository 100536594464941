import { isAfter, isBefore, isEqual, differenceInDays } from 'date-fns'

export const isInvalidRange = (fromDateTime: string | number, toDateTime: string | number) => {
  if (fromDateTime && toDateTime) {
    return (
      isEqual(new Date(fromDateTime), new Date(toDateTime)) || isAfter(new Date(fromDateTime), new Date(toDateTime))
    )
  }
  return false
}

export const isInvalidFromDateTime = (
  fromDateTime: string | number,
  toDateTime: string | number,
  minFromDateTime: Date,
) => {
  if (minFromDateTime && fromDateTime) {
    return isBefore(new Date(fromDateTime), minFromDateTime)
  }

  if (toDateTime && !fromDateTime) {
    return true
  }
  return false
}

export const isInvalidToDateTime = (
  fromDateTime: string | number,
  toDateTime: string | number,
  maxtoDateTime: Date,
) => {
  if (maxtoDateTime && toDateTime) {
    return isAfter(new Date(toDateTime), maxtoDateTime)
  }
  if (fromDateTime && !toDateTime) {
    return true
  }
  return false
}

export const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
export const monthNamesEn = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const today = new Date()
export const lessOneHour = () => new Date(new Date().setHours(today.getHours() - 1))
export const lessOneWeek = () => new Date(new Date().setDate(today.getDate() - 7))
export const lessOneMonth = () => new Date(new Date().setDate(today.getDate() - 30))

export const daysInDateRange = (dateFrom: Date, dateTo: Date) => differenceInDays(dateTo, dateFrom)

export const rangeDateText = (language:'en' | 'es', text:string | undefined) => {
  const daysEn = {
    'Last Hour': 'LAST_HOUR',
    'Last 7 Days': 'LAST_WEEK',
    'Last 30 Days': 'LAST_MONTH',
  }
  const daysEs = {
    'Última Hora': 'LAST_HOUR',
    'Últimos 7 Días': 'LAST_WEEK',
    'Últimos 30 Días': 'LAST_MONTH',
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return language === 'en' ? `CALENDAR.${daysEn[text]}` : `CALENDAR.${daysEs[text]}`
}
