import { ContextApplication } from '../index'

export const APPLICATION_CONTEXT = 'APPLICATION_CONTEXT'

const initialState: ContextApplication = {
	localeChange: null,
	authTokenChange: null,
	tenantChange: null,
	userDetailsChange: null,
	userPermissionsChanged: null,
}

const reducer = (state: any = initialState, action: any) => {
	switch (action.type) {
		case 'APPLICATION_CONTEXT':
			return {
				...state,
				[action.payload.type]: action.payload.data,
			}
		default:
			return state
	}
}

export default reducer
