import { Dispatch } from 'redux'
import {
  getRolesStore,
  getAllRolesStore,
  getAllRolesSecondStore,
  createRolesStore,
  updateRolesStore,
  deleteRolesStore,
  getAllRolesSitesStore, getAllRolesSellersStore,
} from '.'
import { getRoleById, createRole, updateRole, getAllRolesV2, deleteRole } from '../../../services/clients/roles'
import { getAction, resetAction, createAction, updateAction, deleteAction } from '../../utils'
import { enhancementAction } from '../../index'
import { HTTPResponsePagination } from '../../../services/types'

type Params = {
    name?: string;
    description?: string;
    page?: number;
    limit?: number;
    scope?: 'TENANT' | 'SUBSCRIPTION' | 'SITE' | 'SELLER';
    expand?: boolean;
}

export const rolesGetAllActionV2 = (params: Params, type: 'default' | 'second' | 'sites' | 'sellers' = 'default'): void =>
  enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
    const { token } = getState().application
    let { actions } = getAllRolesStore
    if (type === 'second') actions = getAllRolesSecondStore.actions
    if (type === 'sites') actions = getAllRolesSitesStore.actions
    if (type === 'sellers') actions = getAllRolesSellersStore.actions
    dispatch({ type: actions.START, payload: null })
    try {
      const data: HTTPResponsePagination<any[]> = await getAllRolesV2(token, params)
      dispatch({ type: actions.RESULT, payload: data })
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: error })
    }
  })

export const roleByIdAction = (id: string): void => getAction(getRolesStore, getRoleById)(id)
export const roleByIdResetAction = (): void => resetAction(getRolesStore)()
export const roleCreateAction = (data: any): void => createAction(createRolesStore, createRole)(data)
export const roleUpdateAction = (id: string, data: any): void => updateAction(updateRolesStore, updateRole)(id, data)
export const roleCreateResetAction = (): void => resetAction(createRolesStore)()
export const roleUpdateResetAction = (): void => resetAction(updateRolesStore)()
export const roleDeleteAction = (id: string): void => deleteAction(deleteRolesStore, deleteRole)(id)
export const roleDeleteResetAction = (): void => resetAction(deleteRolesStore)()
