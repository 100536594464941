export const standardizeCustomPermissionName = (s: string) => s.toUpperCase()
  .replace(/(\.)\1+/g, '$1')
  .replace(/[^0-9A-Z.]/g, '').toLowerCase()

export const removeUpIfIsInit = (s: string) => {
  if (!s) return s
  const match = 'up.'
  if (s.startsWith(match)) return s.slice(3)
  return s
}
