import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useDebounce, ErrorResult, NoResultFound } from '@jarvis-catalyst/ui-components'
import {
  Table,
  Column,
  Grid,
  TextField,
  Icon,
  Typography,
  Chip,
  IconButton,
  Menu,
  ButtonGroup,
  Button,
  Box,
  Pagination,
} from '@jarvis-catalyst/jarvis-ui-kit'

import {
  customPermissionsGetAllAction,
  customPermissionGetByIdAction,
  customPermissionResetGetByIdAction,
  customPermissionDeleteAction,
  customPermissionResetDeleteAction,
  customPermissionGetAllWithScopesAction,
} from '../../../../store/modules/custom-permissions/actions'

import { customPermissionsGetAllSelector } from '../../../../store/modules/custom-permissions/selectors'
import { CustomPermission } from '../../../../store/modules/custom-permissions/types'
import ManageCustomPermission from '../manage-custom-permission/ManageCustomPermission'
import { VOID_STRING } from '../../../../const'
import useHasPermissionAndHasSomeScopes from '../../../../hooks/useHasPermissionAndHasSomeScopes'
import { sendDangerNotification, sendSuccessNotification } from '../../../../utils/sendNotification'

export const useAfterCommit = (permissionsDeleteStore: any, getAllPermissions: () => void) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (permissionsDeleteStore.success && !permissionsDeleteStore.loading) {
      customPermissionResetDeleteAction()
      sendSuccessNotification(t('ACTIONS.DELETED'))
      getAllPermissions()
    }
    if (permissionsDeleteStore.error && !permissionsDeleteStore.loading) {
      customPermissionResetDeleteAction()
      sendDangerNotification(t(`ACTIONS.${permissionsDeleteStore.errorMessage}`))
    }
  }, [permissionsDeleteStore])
}

const CustomPermissions = function () {
  const [selectedId, setSelectedId] = useState('')
  const [searchPermissions, setSearchPermissions] = useState('')
  const { t } = useTranslation()
  const data = useSelector(customPermissionsGetAllSelector)
  const deleteCustomPermissionStore = useSelector((s: any) => s.customPermissions.delete)

  const debouncedSearchTerm = useDebounce(searchPermissions, 500)

  const hasWrite = useHasPermissionAndHasSomeScopes({
    scope: 'subscription',
    permission: 'rbac.permissions',
    values: ['write'],
  })

  useEffect(() => {
    customPermissionsGetAllAction({ name: debouncedSearchTerm, description: debouncedSearchTerm })
    return () => {
      customPermissionGetAllWithScopesAction()
    }
  }, [debouncedSearchTerm])

  const handlerChangeInput = ({ target }: any) => {
    setSearchPermissions(target.value)
  }

  const handlerChangePage = (_: any, page: number) => {
    customPermissionsGetAllAction({ name: debouncedSearchTerm, description: debouncedSearchTerm, page })
  }

  const handlerEdit = (customPermission: CustomPermission) => {
    customPermissionGetByIdAction(customPermission.id)
  }

  const handlerDelete = (customPermission: CustomPermission) => {
    customPermissionDeleteAction(customPermission.id)
  }

  const handlerSetId = (id: string) => {
    setSelectedId(id)
    if (id === VOID_STRING) customPermissionResetGetByIdAction()
  }

  // eslint-disable-next-line
  const getNotResult = () =>
    data.error ? (
      <ErrorResult
        title={t(`ERRORS.${data.errorMessage.toString()}`)}
        text={t('PLEASE_TRY_AGAIN')}
      />
    ) : (
      <NoResultFound
        title={t('EMPTY_VIEW_MESSAGE_PERMISSIONS')}
        text={t('EXTRA_DESCRIPTION_PERMISSIONS')}
      />
    )

  const getAllPermissions = () => {
    if (searchPermissions !== '') setSearchPermissions('')
    else customPermissionsGetAllAction({ name: debouncedSearchTerm, description: debouncedSearchTerm })
  }

  useAfterCommit(deleteCustomPermissionStore, getAllPermissions)

  const column: Column[] = [
    { key: 'group', content: <Typography variant="H500">{t('GROUP')}</Typography> },
    { key: 'name', content: <Typography variant="H500">{t('PERMISSION')}</Typography> },
    { key: 'description', content: <Typography variant="H500">{t('DESCRIPTION')}</Typography> },
    { key: 'options', content: '', align: 'right' },
  ]

  const options = (permission: any) => {
    const contents = [
      { content: t('DELETE_WARNING'), icon: 'Warning', key: '0', disabled: true },
      {
        content: (
          <Grid spacing={1}>
            <Button variant="text">{t('CANCEL')}</Button>
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                handlerDelete(permission)
              }}
            >
              {t('CONFIRM')}
            </Button>
          </Grid>
        ),
        key: '1',
      },
    ]
    return (
      <div className="text-right">
        <ButtonGroup size="small">
          <IconButton
            name="Edit"
            label={t('EDIT')}
            disabled={!hasWrite}
            onClick={() => handlerEdit(permission)}
          />
          <Menu
            AnchorComponent={IconButton}
            AnchorComponentProps={{ label: 'Delete', name: 'Delete' }}
            contents={contents as any}
          />
        </ButtonGroup>
      </div>
    )
  }

  return (
    <section>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={8}
          md={4}
          lg={5}
          xl={5}
        >
          <div style={{ width: '100%' }}>
            <TextField
              autoFocus
              onChange={handlerChangeInput}
              value={searchPermissions}
              placeholder={t('SEARCH_PERMISSIONS_INPUT_PLACEHOLDER')}
              icon="Search"
              key="search-permissions"
              fullWidth
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={8}
          lg={7}
          xl={7}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            {process.env.REACT_APP_RBAC_VERSION === 'v3' && hasWrite && (
              <Button
                onClick={() => setSelectedId('0')}
                variant="contained"
                size="large"
                startIcon={<Icon name="Plus" />}
              >
                {t('ADD_PERMISSIONS')}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
      <div style={{ marginTop: 30 }}>
        <Grid container>
          <Grid
            item
            xs={12}
          >
            <Table
              columns={column}
              rows={data.data.map((row) => ({
                ...row,
                name: <Chip label={row.name} />,
                options: options(row),
              }))}
              loading={data.loading}
              emptyMessage={getNotResult()}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: 2 }}>
              {data.success && data.data.length > 0 && (
                <Pagination
                  currentpage={data.pagination.page}
                  totalpages={data.pagination.totalPages}
                  onChange={handlerChangePage}
                  color="primary"
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </div>

      <br />
      <ManageCustomPermission
        hasWrite={hasWrite}
        id={selectedId}
        setId={handlerSetId}
        getAllPermissions={getAllPermissions}
      />
    </section>
  )
}

export default CustomPermissions
