import { Dispatch } from 'redux'
import { enhancementAction } from '../../index'
import {
  USERS_BATCH_CREATE_START,
  USERS_BATCH_CREATE_APPEND_RESULT,
  USERS_BATCH_CREATE_APPEND_ERROR,
  USERS_BATCH_CREATE_END,
  USERS_BATCH_CREATE_RESET,
  getUsersStore,
  getAllUsersStore,
  deleteUsersStore,
  updateUsersStore,
  exportUsersStore,
} from '.'

import {
  UserCreate,
  User,
  BatchCreateError,
  UserRoles,
  ParamsGetAllUsers, ParamsExportUsers,
} from './types'
import { userCreate, getAllUsers, deleteUsers, getUserById, updateUser, exportUsers } from '../../../services/clients/users'
import {
  HTTPRequestCreate,
  HTTPResponseCreate,
  HTTPResponsePagination,
} from '../../../services/types'
import { deleteAction, getAction, resetAction, updateAction } from '../../utils'

export const usersDeleteAction = (id: string): void => deleteAction(deleteUsersStore, deleteUsers)(id)
export const userByIdAction = (email: string): void => getAction(getUsersStore, getUserById)(email)
export const userByIdResetAction = (): void => resetAction(getUsersStore)()
export const userUpdateAction = (id: string, data: any): void => updateAction(updateUsersStore, updateUser)(id, data)
export const userUpdateResetAction = (): void => resetAction(updateUsersStore)()

export const usersGetAllAction = (params?: ParamsGetAllUsers): void => {
  enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
    const { token } = getState().application
    dispatch({ type: getAllUsersStore.actions.START, payload: null })
    try {
      const request = {
        token,
        ...params,
        limit: (params && params.limit) ? params.limit : 10,
        page: (params && params.page) ? params.page : 1,
      }
      const data: HTTPResponsePagination<any[]> = await getAllUsers(request)
      dispatch({ type: getAllUsersStore.actions.RESULT, payload: data })
    } catch (error) {
      dispatch({ type: getAllUsersStore.actions.ERROR, payload: error })
    }
  })
}

export const batchCreateUsersResetAction = (): void =>
  enhancementAction((dispatch: Dispatch) => {
    dispatch({ type: USERS_BATCH_CREATE_RESET, payload: null })
  })

export const batchCreateUsersAction = (data: UserCreate[]): void =>
  enhancementAction((dispatch: Dispatch, getState: () => any) => {
    const { token } = getState().application
    dispatch({ type: USERS_BATCH_CREATE_START, payload: null })

    if (data.length === 0) dispatch({ type: USERS_BATCH_CREATE_END, payload: null })

    data.forEach(async (user: UserCreate, i: number) => {
      try {
        const request: HTTPRequestCreate<UserRoles> = {
          token,
          data: {
            email: user.email.toLowerCase(),
            isTestingAccount: user.isTestingAccount,
            roles: {
              subscription: user.roles,
              tenant: user.tenantRoles.map((t) => ({ id: t.id, roles: t.roles })),
              site: user.tenantRoles.flatMap((t) => t.siteRoles),
              seller: user.tenantRoles.flatMap((t) => t.sellerRoles),
            },
          },
        }

        const response: HTTPResponseCreate<User> = await userCreate(request)

        dispatch({ type: USERS_BATCH_CREATE_APPEND_RESULT, payload: response })
      } catch (error: any) {
        const payload: BatchCreateError = { email: user.email, errorMessage: error }
        dispatch({ type: USERS_BATCH_CREATE_APPEND_ERROR, payload })
      }

      if (data.length === i + 1) dispatch({ type: USERS_BATCH_CREATE_END, payload: null })
    })
  })

export const usersExportAction = (params: ParamsExportUsers): void =>
  enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
    const { token } = getState().application

    dispatch({ type: exportUsersStore.actions.START, payload: null })

    try {
      const request = { token, ...params }

      const data: Blob = await exportUsers(request)

      dispatch({ type: exportUsersStore.actions.RESULT, payload: data })

      dispatch({ type: exportUsersStore.actions.RESET, payload: null })
    } catch (error) {
      dispatch({
        type: exportUsersStore.actions.ERROR,
        payload: error,
      })

      dispatch({ type: exportUsersStore.actions.RESET, payload: null })
    }
  })
