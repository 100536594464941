import { Dispatch } from 'redux'
import { enhancementAction } from '../../store'

type UserPermission = {
  data: any
}

const getPermissions = (): UserPermission =>
  enhancementAction((_: Dispatch, getState: () => any) => getState().instrospect.getUserPermissions)

export default getPermissions
