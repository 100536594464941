import React, { useState } from 'react'
import isEmpty from 'lodash.isempty'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  AvatarNamed,
  Dropdown,
  Button,
  Tooltip,
  Icon,
  IconArrowLeft,
} from '@jarvis-catalyst/ui-components'

import { Box, Grid } from '@jarvis-catalyst/jarvis-ui-kit'
import LogoFalabella from '../components/FalabellaIcon'
import './Header.css'

import { getParams, navigateToUrl, replaceParams } from '../../shell/utils/router'
import {
  applicationSetConfigurationAction,
  applicationUiSidebarAction,
} from '../../../store/modules/application/actions'
import TenantSelector from '../manage-tenant-site/TenantSelector'
import { DEFAULT_LANG, DEFAULT_URI_START_APP, LANGS } from '../../../const'
import ButtonSidebarController from './ButtonSidebarController'
import useAppendQS from '../../../hooks/useAppendQS'
import useAuthenticationContextWrapper from '../../shell/contexts/AuthenticationContextWrapper'
import cacheStore from '../../../utils/cache-store'
import { LANGUAGE_KEY } from '../../../utils/cache-store/types'
import EnvironmentIdentifier from './EnvironmentIdentifier'

const getLocale = (history: any) => {
  const state = getParams(history)
  if (isEmpty(LANGS.find((t) => t.value === state.locale))) {
    const storedLanguage = cacheStore(LANGUAGE_KEY).getData()
    state.locale = isEmpty(storedLanguage) ? DEFAULT_LANG.value : storedLanguage
    replaceParams(history, state)
  }
  return state.locale
}

const Header = function () {
  const { errors } = useSelector((s: any) => s.application)
  const { uiTenantDepend, uiModalOverlay } = useSelector((s: { application: any }) => s.application)
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const appendQS = useAppendQS()
  const [locale, setLocale] = useState<any>(() => getLocale(history))
  const [isOpen, setOpen] = useState(false)
  const [isToggleMenu, setIsToggleMenu] = useState(false)

  const [selectedLocale, setSelectedLocale] = useState({ value: '' })

  const { logoutToLogin, userDetails, authorizationError }: any = useAuthenticationContextWrapper()

  React.useEffect(() => {
    const lang: any = LANGS.find((t) => t.value === locale)
    setSelectedLocale(lang)
  }, [locale])

  const hasError = !isEmpty(authorizationError)

  React.useEffect(() => {
    if (history.action === 'POP') setLocale(getLocale(history))
  }, [history, history.location.search])

  React.useEffect(() => {
    i18n.changeLanguage(locale)
  }, [i18n, locale])

  const handleChange = (value: { locale: any }) => {
    replaceParams(history, value)
    applicationSetConfigurationAction({ key: 'language', value: value.locale })
    i18n.changeLanguage(value.locale)
    cacheStore(LANGUAGE_KEY).setData(value.locale)
  }

  const handleToggle = () => {
    setIsToggleMenu(!isToggleMenu)
  }

  React.useEffect(() => {
    if (uiModalOverlay === 'show') {
      setIsToggleMenu(false)
      setOpen(false)
    }
  }, [uiModalOverlay])

  return (
    <Grid
      container
      direction="row"
      className="header-container"
    >
      <Grid item>
        <Box
          className="header__logo"
        >
          {uiTenantDepend && <ButtonSidebarController />}
          <a
            href="/"
            id="menu__link-logo"
            className="header__menu__link_logo"
            style={{ width: 146, height: 56, display: 'flex' }}
          >
            <LogoFalabella />
          </a>
          <EnvironmentIdentifier />
        </Box>
      </Grid>
      <Grid item>
        <Box className="header__options">
          {!uiTenantDepend ? (
            <Box
              className="header__menu"
            >
              <Button
                disabled={errors.length > 0}
                id="menu__back-button"
                iconLeft={<IconArrowLeft />}
                color="link-light"
                onClick={() => {
                  setIsToggleMenu(false)
                  applicationUiSidebarAction('full', true)
                  history.push(appendQS(DEFAULT_URI_START_APP))
                }}
              >
                {t('BACK')}
              </Button>
            </Box>
          ) : (
            !hasError && <TenantSelector />
          )}
          <Box className="header__notification header__option__menu">
            <Dropdown
              color="very-dark"
              id="menu__dropdown-locale"
              selectedValue={selectedLocale.value}
              title="Select language"
              onSelected={(e) => handleChange({ locale: e.value })}
              options={LANGS}
            />
            <div>
              <Tooltip
                id="menu__user-context-menu"
                isOpen={isOpen}
                width={300}
                type="dialog"
                position="bottom-end"
                onOut={() => setOpen(false)}
                content={
                  <div className="user-section">
                    <div className="text-left">
                      <strong>{userDetails.name}</strong>
                      <span>{userDetails.email}</span>
                    </div>
                    <div>
                      <Button
                        disabled={errors.length > 0}
                        id="menu__user-context-menu__tenant-button"
                        block
                        color="ghost"
                        onClick={() => {
                          setOpen(false)
                          setIsToggleMenu(false)
                          navigateToUrl(history, '/tenants')
                        }}
                      >
                        {t('TENANT_MANAGEMENT_LABEL')}
                      </Button>
                      <Button
                        disabled={errors.length > 0}
                        id="menu__user-context-menu__users-button"
                        block
                        color="ghost"
                        onClick={() => {
                          setOpen(false)
                          setIsToggleMenu(false)
                          navigateToUrl(history, '/users')
                        }}
                      >
                        {t('USER_AND_PERMISSIONS')}
                      </Button>
                      <Button
                        disabled={errors.length > 0}
                        id="menu__user-context-menu__app-button"
                        block
                        color="ghost"
                        onClick={() => {
                          setOpen(false)
                          setIsToggleMenu(false)
                          navigateToUrl(history, '/applications')
                        }}
                      >
                        {t('APPLICATIONS')}
                      </Button>
                      <Button
                        disabled={errors.length > 0}
                        id="menu__user-context-menu__auditlog-button"
                        block
                        color="ghost"
                        onClick={() => {
                          setOpen(false)
                          setIsToggleMenu(false)
                          navigateToUrl(history, '/logs')
                        }}
                      >
                        {t('ACTIVITY_LOG')}
                      </Button>
                      <Button
                        id="menu__user-context-menu__logout-button"
                        block
                        color="ghost"
                        onClick={async () => {
                          setIsToggleMenu(false)
                          setOpen(false)
                          await logoutToLogin()
                        }}
                      >
                        {t('LOGOUT')}
                      </Button>
                    </div>
                  </div>
                }
              >
                <div
                  id="menu__avatar-button__wrapper"
                  className="avatar"
                  onClick={() => setOpen(!isOpen)}
                  role="presentation"
                >
                  <AvatarNamed
                    fullName={userDetails.name}
                    id="menu__avatar-button"
                  />
                </div>
              </Tooltip>
            </div>
          </Box>
          <div
            onClick={handleToggle}
            data-testid="header__menu-controller"
            className={`header__menu-controller header__menu-controller-${isToggleMenu ? 'open' : 'close'}`}
          >
            <Icon
              name="MenuBurger"
              color="#ffffff"
            />
          </div>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Header
