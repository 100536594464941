/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useCallback, useState } from 'react'
import {
  Dot,
} from '@jarvis-catalyst/ui-components'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash.isempty'
import debounce from 'lodash.debounce'
import {
  Grid,
  Pagination,
  Table,
  TextField,
  Typography,
  Icon,
  Button,
  ButtonGroup, Box, Autocomplete,
} from '@jarvis-catalyst/jarvis-ui-kit'
import useTenantsContext from '../../contexts/TenantsContext'
import { chopOff } from '../../utils/stringHelpers'
import { stateMap, tenantStates } from './stateMapping'

import './TenantsListing.css'

import useLanguageAndTenantId from '../../../../hooks/useLanguageAndTenantId'
import ManageSite from '../manage-site/ManageSite'
import { sitesGetAllResetAction, sitesBatchResetAction } from '../../../../store/modules/sites/actions'

const WAIT_TIME = 500

const useManageSite = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [tenant, setTenant] = useState()
  return {
    isOpen,
    tenant,
    setOpen: (newTenant) => {
      setIsOpen(true)
      setTenant(newTenant)
    },
    setClose: () => {
      setIsOpen(false)
      setTenant()
      sitesGetAllResetAction()
      sitesBatchResetAction()
    },
  }
}

export const getOptions = (t) => [
  { label: t('ALL'), value: 'ALL' },
  ...Object.entries(tenantStates).map(([key, value]) => ({
    label: t(key),
    value,
  })),
]

// eslint-disable-next-line react/prop-types
const TenantsListing = function ({ handleEdit, handleDelete, setIsAddModalOpen, hasWrite }) {
  const {
    isOpen: manageSiteOpen,
    tenant: siteManageTenant,
    setOpen: setManageSiteOpen,
    setClose: setManageSiteClose,
  } = useManageSite()

  const { t } = useTranslation()

  const {
    tenants,
    isLoading,
    totalPages,
    currentPage,
    searchValue,
    dispatch } = useTenantsContext()
  const { language } = useLanguageAndTenantId()
  const [searchInput, setSearchInput] = useState('')
  const [optionSelected, setOptionSelected] = useState({ value: 'ALL', label: 'All' })

  React.useEffect(() => {
    setSearchInput(searchValue)
  }, [searchValue])

  const handleStatusChange = (option) => {
    dispatch({ type: 'UPDATE_TENANT_STATUS', payload: option != null ? option.value : option.value })
  }

  const handleSearchValueChange = (value) => dispatch({ type: 'UPDATE_SEARCH_VALUE', payload: value })

  const debounced = useCallback(debounce(handleSearchValueChange, WAIT_TIME), [])

  const getId = ({ id }) => (
    <Button
      variant="outline"
      size="small"
      startIcon={<Icon name="Copy" />}
      onClick={() => navigator.clipboard.writeText(id)}
    />
  )
  const getState = ({ status, dependencies }) => {
    const tenantStateDetails = stateMap(t, status, dependencies)
    return (
      <span title={tenantStateDetails.message}>
        <Dot customColor={tenantStateDetails.color} />
      </span>
    )
  }
  const handlerChangePage = (_, page) => (
    dispatch({ type: 'UPDATE_CURRENT_PAGE', payload: page })
  )

  const getOptionsTable = (tenant) => (
    <div className="text-right">
      <ButtonGroup type="sm">
        <Button
          variant="outline"
          size="small"
          disabled={tenant.status === tenantStates.INACTIVE}
          onClick={() => setManageSiteOpen(tenant)}
          startIcon={<Icon name="Site" />}
        />
        <Button
          variant="outline"
          size="small"
          disabled={!hasWrite || tenant.status === tenantStates.INACTIVE}
          onClick={() => handleEdit(tenant)}
          startIcon={<Icon name="Edit" />}
        />
        <Button
          variant="outline"
          size="small"
          disabled={!hasWrite || tenant.status === tenantStates.INACTIVE}
          onClick={() => handleDelete(tenant)}
          startIcon={<Icon name="Delete" />}
        />
      </ButtonGroup>
    </div>
  )

  const getDescription = (description) => (description !== null
    ? chopOff(description, 50)
    : ' ')

  const columns = [
    { key: 'id', content: <Typography variant="H500">{t('ID')}</Typography> },
    { key: 'name', content: <Typography variant="H500">{t('NAME')}</Typography> },
    { key: 'description', content: <Typography variant="H500">{t('DESCRIPTION')}</Typography> },
    { key: 'created', content: <Typography variant="H500">{t('CREATED')}</Typography> },
    { key: 'state', content: <Typography variant="H500">{t('STATE')}</Typography> },
    { key: 'actions', content: '' },
  ]

  const rows = tenants.map((tenant) => ({
    id: getId(tenant),
    name: chopOff(tenant.name, 30),
    description: getDescription(tenant.description),
    created: new Date(tenant.createdAt).toLocaleDateString(language),
    state: getState(tenant),
    actions: getOptionsTable(tenant),
  }))
  return (
    <>
      <Grid
        container
        columnSpacing={2}
      >
        <Grid
          item
          lg={2}
        >
          <Autocomplete
            placeholder={t('TENANT_SELECTION')}
            onChange={(e, option) => {
              if (!option) {
                setOptionSelected({ value: 'ALL', label: 'All' })
                handleStatusChange({ value: 'ALL', label: 'All' })
                return
              }
              handleStatusChange(option)
              setOptionSelected({ ...option })
            }}
            options={getOptions(t)}
            value={optionSelected}
          />
        </Grid>
        <Grid
          item
          lg={4}
        >
          <TextField
            fullWidth
            autoFocus
            label={t('TENANTS')}
            icon="Search"
            onChange={(event) => {
              const {
                target: { value },
              } = event
              setSearchInput(value)
              debounced(value)
            }}
            value={searchInput}
            placeholder={t('SEARCH_TENANTS_PLACEHOLDER')}
          />
        </Grid>
        <Grid
          item
          lg={6}
        >
          <Box sx={{ textAlign: 'end' }}>
            <Button
              size="large"
              variant="contained"
              startIcon={<Icon name="Plus" />}
              disabled={!hasWrite}
              onClick={() => setIsAddModalOpen(true)}
            >
              {t('CREATE_TENANT')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Table
        columns={columns}
        rows={rows}
        loading={isLoading}
      />
      {!isEmpty(tenants) && (
        <Grid
          container
          direction="row-reverse"
        >
          <Grid item>
            <Pagination
              color="primary"
              currentpage={currentPage}
              totalpages={totalPages}
              onChange={handlerChangePage}
            />
          </Grid>
        </Grid>
      )}
      <ManageSite
        onClose={setManageSiteClose}
        open={manageSiteOpen}
        tenant={siteManageTenant}
        hasWrite={hasWrite}
      />
    </>
  )
}

export default TenantsListing
