import React, { useEffect } from 'react'
import {
  Row,
  Col,
  Spinner,
  Text,
  Grid,
  GridSection,
  NoResultFound,
} from '@jarvis-catalyst/ui-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { logByIdAction } from '../../../../store/modules/logs/actions'
import { GetLogReducer } from '../../../../store/modules/logs/types'
import { format } from '../../../../utils/date'
import './LogDetail.css'
import LogDiff from './LogDiff'
import { tenantsGetAllSelector } from '../../../../store/modules/tenants/selectors'

type LogDetailProps = {
    logId: string,
}

const LogDetail = function ({ logId }: LogDetailProps) {
  const { t } = useTranslation()
  const [isStarted, setIsStarted] = React.useState(false)
  const { activeTenant, language } = useSelector((s: any) => s.application)
  const logStore: GetLogReducer = useSelector((s: any) => s.logs.getById)
  const tenantStore = useSelector(tenantsGetAllSelector)
  const sellerStore = useSelector((s: any) => s.sellers.getAll)

  useEffect(() => {
    setIsStarted(true)
    logByIdAction(logId)
  }, [logId, activeTenant])

  const isLoading = logStore.loading || tenantStore.loading || sellerStore.loading

  return isStarted ? (
    <div className="log_detail">
      <hr />
      {isLoading ? (<Spinner position="relative" />) : (
        logStore.success ? (
          <div>
            <Grid
              repeatNumber={2}
              gap={40}
              repeatTemplate="minmax(50px,1fr)"
            >
              <GridSection>
                <Text>
                  <strong>
                    {t('ID')}
                    :
                  </strong>
                  {' '}
                  {logStore.data.id}
                </Text>
              </GridSection>
              <GridSection>
                <Text>
                  <strong>
                    {t('IP_ADDRESS')}
                    :
                  </strong>
                  {' '}
                  {logStore.data.clientIp}
                </Text>
              </GridSection>
              <GridSection>
                <Text>
                  <strong>
                    {t('DATE')}
                    :
                  </strong>
                  {' '}
                  {format(logStore.data.createdAt, language)}
                </Text>
              </GridSection>
              <GridSection>
                <Text>
                  <strong>
                    {t('IDENTITY')}
                    :
                  </strong>
                  {' '}
                  {logStore.data.identity.email}
                </Text>
              </GridSection>
              <GridSection>
                <Text>
                  <strong>
                    {t('RESOURCE')}
                    :
                  </strong>
                  {' '}
                  {logStore.data.request.method}
                  {' '}
                  {logStore.data.request.absoluteUrl}
                </Text>
              </GridSection>
              <GridSection>
                <Text>
                  <strong>
                    {t('SOURCE')}
                    :
                  </strong>
                  {' '}
                  {logStore.data.request.userAgent}
                </Text>
              </GridSection>
            </Grid>
            <LogDiff
              state={logStore.data.state}
              source={logStore.data.source}
              tenants={tenantStore.data}
              sellers={sellerStore.data}
            />
          </div>
        ) : (
          <Row>
            <Col sm={12}>
              <NoResultFound
                title={t('NOT_FOUND')}
                text=""
              />
            </Col>
          </Row>
        )
      )}
    </div>) : (<span />)
}

export default LogDetail
