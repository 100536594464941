import { HTTPRequestFilterPagination, HTTPClientConfig, HttpMethodEnum } from '../types'
import { dataToBody, REACT_APP_CUSTOM_APPLICATIONS, restClient, wrapperHeaderWithToken } from '../configuration'
import { getAllService, deleteByIdService } from '../services.utils'

export const getAllGroups = async (req: HTTPRequestFilterPagination): Promise<any> =>
  getAllService(req, (_: any, params: any) => `${REACT_APP_CUSTOM_APPLICATIONS}/pat/applications/v2/groups?${params}`)

export const deleteGroup = async (req: any): Promise<any> =>
  deleteByIdService(req, (req: any) => `${REACT_APP_CUSTOM_APPLICATIONS}/pat/applications/v2/groups/${req.id}`)

const conflictErrorMessage = (data: { errors: { [key: string]: { detail: string } } }) => {
  const constraint = Object.keys(data.errors)[0]
  return `CONFLICT_${constraint}`
}

export const createGroup = async (request: any): Promise<any> => {
  const uri = `${REACT_APP_CUSTOM_APPLICATIONS}/pat/applications/v2/groups`
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.POST,
    headers: wrapperHeaderWithToken(request.token),
    body: dataToBody(request.data),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)
      const data: any = await response.json()
      if (response.status === 201) {
        resolve(data)
      } else if (response.status === 404) {
        reject('NOT_FOUND')
      } else if (response.status === 400) {
        reject(data)
      } else if (response.status === 409) {
        data.errors ? reject(conflictErrorMessage(data)) : reject(data)
      } else {
        reject('NOT_FOUND')
      }
    } catch (error) {
      reject(`${error}`)
    }
  })
}

export const updateGroup = async (request: any): Promise<any> => {
  const uri = `${REACT_APP_CUSTOM_APPLICATIONS}/pat/applications/v2/groups/${request.id}`
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.PUT,
    headers: wrapperHeaderWithToken(request.token),
    body: dataToBody(request.data),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)
      const data: any = await response.json()
      if (response.status === 200) {
        resolve(data)
      } else if (response.status === 404) {
        reject('NOT_FOUND')
      } else if (response.status === 400) {
        reject(data)
      } else if (response.status === 409) {
        data.errors ? reject(conflictErrorMessage(data)) : reject(data)
      } else {
        reject('NOT_FOUND')
      }
    } catch (error) {
      reject(`${error}`)
    }
  })
}
