import { Dispatch } from 'redux'
import { enhancementAction } from '../../index'
import { HTTPResponsePagination } from '../../../services/types'
import { getLogsStore, getAllLogsStore, exportLogsStore } from '.'
import { LogListItem, LogDetail, ParamsGetAllLogsAction } from './types'
import { exportLogs, getAllLogs, getLogById } from '../../../services/clients/logs'
import { resetAction } from '../../utils'

export const logsGetAllAction = (tenant?: string, params?: ParamsGetAllLogsAction): void =>
  enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
    const { token } = getState().application

    dispatch({ type: getAllLogsStore.actions.START, payload: null })
    try {
      const request = {
        token,
        limit: params && params.limit ? params.limit : 10,
        page: params && params.page ? params.page : 1,
        date: params && params.date ? params.date : '',
        identity: params && params.identity ? params.identity : '',
        action: params && params.action ? params.action : '',
        module: params && params.module ? params.module : '',
        service: params && params.service ? params.service : '',
        tenant: '',
        state: params && params.state ? params.state : '',
      }

      if (tenant) request.tenant = tenant
      const data: HTTPResponsePagination<LogListItem[]> = await getAllLogs(request)
      dispatch({
        type: getAllLogsStore.actions.RESULT,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: getAllLogsStore.actions.ERROR,
        payload: error,
      })
    }
  })

export const logByIdAction = (id: string): void =>
  enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
    const { token } = getState().application

    dispatch({ type: getLogsStore.actions.START, payload: null })
    try {
      const request = {
        id,
        token,
      }
      const data: LogDetail = await getLogById(request)
      dispatch({
        type: getLogsStore.actions.RESULT,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: getLogsStore.actions.ERROR,
        payload: error,
      })
    }
  })

export const logsExportAction = (tenant?: string, params?: ParamsGetAllLogsAction): void =>
  enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
    const { token } = getState().application

    dispatch({ type: exportLogsStore.actions.START, payload: null })
    try {
      const request = {
        token,
        tenant: '',
        ...params,
      }

      if (tenant) request.tenant = tenant
      const data: Blob = await exportLogs(request)
      dispatch({
        type: exportLogsStore.actions.RESULT,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: exportLogsStore.actions.ERROR,
        payload: error,
      })
    }
  })

export const logsExportResetAction = resetAction(exportLogsStore)
