import { APPLICATIONS_BATCH_CREATE_START, APPLICATIONS_BATCH_CREATE_RESET, APPLICATIONS_BATCH_CREATE_APPEND_RESULT, APPLICATIONS_BATCH_CREATE_APPEND_ERROR, APPLICATIONS_BATCH_CREATE_END } from '..'
import { StoreBatchCreateData, ActionReducer } from '../../../types'
import { Application } from '../types'

export const batchCreateInitialState: StoreBatchCreateData<Application> = {
  data: [],
  errorData: [],
  error: null,
  success: null,
  loading: false,
}

const batchCreateReducer = (
  prevState = batchCreateInitialState,
  action: ActionReducer<any>,
): StoreBatchCreateData<Application> => {
  switch (action.type) {
  case APPLICATIONS_BATCH_CREATE_START:
    return {
      data: [],
      success: null,
      error: null,
      errorData: [],
      loading: true,
    }
  case APPLICATIONS_BATCH_CREATE_RESET:
    return {
      data: [],
      success: null,
      error: null,
      errorData: [],
      loading: false,
    }
  case APPLICATIONS_BATCH_CREATE_APPEND_RESULT:
    return {
      ...prevState,
      success: true,
      data: [...prevState.data, action.payload],
    }
  case APPLICATIONS_BATCH_CREATE_APPEND_ERROR:
    return {
      ...prevState,
      errorData: [...prevState.errorData, action.payload],
      success: false,
      error: true,
    }
  case APPLICATIONS_BATCH_CREATE_END:
    return {
      ...prevState,
      loading: false,
    }
  default:
    return prevState
  }
}

export default batchCreateReducer
