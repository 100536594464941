import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  Modal,
  Typography as Text,
  Button,
  Icon,
  Typography,
  Divider,
  Alert,
  Chip,
  Autocomplete,
} from '@jarvis-catalyst/jarvis-ui-kit'
import { useTranslation } from 'react-i18next'

import { getInitialCustomPermissionData } from '../utils'
import { customPermissionGetAllWithScopesAction } from '../../../../../store/modules/custom-permissions/actions'
import { customPermissionsGetAllSelector } from '../../../../../store/modules/custom-permissions/selectors'

type CustomPermissionAddProps = {
  onAdd: (customPermission: any) => void
  customPermissions: any[]
}

const CustomPermissionAdd = function ({ onAdd, customPermissions }: CustomPermissionAddProps) {
  const { t } = useTranslation()

  const [showForm, setShowForm] = React.useState(false)
  const [selected, setSelected] = React.useState(getInitialCustomPermissionData())
  const permissions = useSelector(customPermissionsGetAllSelector)

  const options = permissions.data.map((permission: any) => ({
    label: permission.name,
    value: permission.id,
    scopes: permission.scopes.map((scope: string) => ({ name: scope, selected: false })),
  }))

  const handlerReset = () => {
    setSelected(getInitialCustomPermissionData())
    setShowForm(false)
  }

  const handlerAdd = () => {
    onAdd(selected)
    handlerReset()
  }

  const itAlreadyExists = () => customPermissions.find((e: any) => e.name === selected.label)

  useEffect(() => {
    customPermissionGetAllWithScopesAction()
  }, [])

  return (
    <>
      <Box className="text-right">
        <Button
          color="primary"
          variant="contained"
          startIcon={<Icon name="Plus" />}
          onClick={() => setShowForm(true)}
        >
          {t('MANAGE_ROLE.ADD_CUSTOM_PERMISSIONS')}
        </Button>
      </Box>
      <Modal
        open={showForm}
        id="modal-add-custom-permission"
      >
        <Box
          sx={{
            height: 300,
            width: 300 * 1.618,
            bgcolor: 'background.paper',
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ pb: 2, paddingRight: 10 }}>
            <Typography variant="H900">{t('MANAGE_ROLE.ADD_CUSTOM_PERMISSIONS')}</Typography>
            <Divider blank />
          </Box>
          <Autocomplete
            options={options}
            value={selected}
            onChange={(e: any, newValue: any) => {
              setSelected(newValue || getInitialCustomPermissionData())
            }}
            placeholder={t('MANAGE_ROLE.SELECT_CUSTOM_PERMISSION_PLACEHOLDER')}
          />
          {selected.scopes.length > 0 && <Text variant="H200">{t('MANAGE_ROLE.SCOPES_AVAILABLE')}</Text>}
          <Box className="uci__badge__container">
            {selected.scopes.map((s: any, i: number) => (
              <Chip
                key={i}
                label={s.name}
                variant="outlined"
                color="primary"
                sx={{ mr: 1, mb: 1 }}
              />
            ))}
          </Box>
          {itAlreadyExists() && (
            <>
              <hr />
              <Alert color="warning">{t('MANAGE_ROLE.WARNING_MESSAGE')}</Alert>
            </>
          )}
          <Box sx={{ textAlign: 'right' }}>
            <Button onClick={handlerReset}>{t('ACTIONS.CANCEL')}</Button>
            <Button
              disabled={selected.name === '' || itAlreadyExists()}
              variant="contained"
              color="primary"
              startIcon={<Icon name="Plus" />}
              onClick={handlerAdd}
            >
              {t('ACTIONS.ADD')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default CustomPermissionAdd
