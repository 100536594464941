import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Spinner } from '@jarvis-catalyst/ui-components'
import {
  CardContent,
  Grid,
  Modal,
  Typography,
  TextField,
  Divider,
  Button,
  ButtonGroup,
  Alert,
  IconButton,
} from '@jarvis-catalyst/jarvis-ui-kit'

type DeleteTenantModalProps = {
  isModalOpen: boolean
  handleModalClose: () => any
  handleSaveClick: (e: any) => any
  tenant: {
    id: string,
    name: string,
  }
}

const DeleteTenantModal = function ({
  handleModalClose,
  handleSaveClick,
  isModalOpen,
  tenant,
}: DeleteTenantModalProps) {
  const { t } = useTranslation()

  const [name, setName] = useState('')
  const [isSaved, setIsSaved] = useState(true)
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)
  const [error, setError] = useState({ message: '' })

  useEffect(() => {
    setIsSaveDisabled(name.toLowerCase() !== tenant.name.toLowerCase())
  }, [name, tenant.name])

  const handleSave = async () => {
    setIsSaved(false)
    try {
      await handleSaveClick(tenant)
      setIsSaved(true)
      handleModalClose()
    } catch (err: any) {
      setIsSaved(true)
      setError(err)
    }
  }

  return (
    <Modal
      open={isModalOpen}
    >
      <IconButton
        name="Warning"
        label="button-delete"
        disabled
      />
      <Typography variant="H500">{t('DELETE_TENANT_HEADING')}</Typography>
      <CardContent>
        <Spinner isCompleting={isSaved} />
        <Alert color="warning">{t('DELETE_TENANT_WARNING')}</Alert>
        <TextField
          fullWidth
          value={name}
          label={`${t('TENANT_NAME_LABEL')}*`}
          onChange={(e: any) => {
            setError({ message: '' })
            setName(e.target.value)
          }}
        />
        <Divider
          blank
        />
        <Alert color="info">
          <Trans
            i18nKey="DELETE_TENANT_NAME_HINT"
            values={{ name: tenant.name }}
            components={[<span
              className="name"
              key="name"
            />]}
          />
        </Alert>
      </CardContent>
      <CardContent>
        <Grid>
          {error.message && (<Typography
            color="rgb(151, 36, 36)"
            variant="H600"
          >
            {t(`ERRORS.${error.message}`)}
          </Typography>)}
        </Grid>
        <Grid
          container
          spacing={2}
          direction="row-reverse"
        >
          <ButtonGroup>
            <Button
              onClick={handleModalClose}
              variant="text"
            >
              {t('CANCEL')}
            </Button>
            <Button
              onClick={handleSave}
              disabled={isSaveDisabled}
              variant="outlined"
            >
              {t('SAVE')}
            </Button>
          </ButtonGroup>
        </Grid>
      </CardContent>
    </Modal>
  )
}

export default DeleteTenantModal
