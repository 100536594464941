class ApiError extends Error {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor({ message, property }) {
    super()
    this.message = message
    this.property = property
  }
}

export default ApiError
