import React from 'react'
import { Box, Typography, Progress, Tooltip, Icon } from '@jarvis-catalyst/jarvis-ui-kit'
import { RolesSectionProps } from './rolesection.type'

const RolesSection = function ({ title, textTooltip, searchFunction, loading, success }: RolesSectionProps) {
  return (
    <Box>
      <Typography
        variant="h4"
        color="text.secondary"
      >
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
          {title}
          <Tooltip
            title={textTooltip}
            placement="right"
          >
            <Icon
              name="Info"
              size="medium"
              color="white"
              bgcolor="var(--c-primary-new)"
            />
          </Tooltip>
        </div>
        <Box sx={{ marginTop: 1 }}>
          {!loading && success ? (
            searchFunction()
          ) : (
            <Progress
              height={30}
              circle
            />
          )}
        </Box>
      </Typography>
    </Box>
  )
}

export default RolesSection
