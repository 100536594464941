import React from 'react'
import {
  Button,
  ButtonGroup,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  GridItem,
  Icon,
  Modal,
  Row, Spinner,
} from '@jarvis-catalyst/ui-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import './SelectSiteSellerModal.css'
import { Seller } from '../../../store/modules/sellers/types'
import ErrorNotification from '../../../components/errorNotification/ErrorNotification'
import { linkRequestAccessJarvis } from '../../dashboard/externalLinks'

interface SelectSellerModalProps {
  data: Seller[]
  error: boolean
  tenant: { label: string }
  onConfirm: (e: Seller[]) => void
  onCancel: () => void
  isOpen: boolean
  setSellerSelected: (e: Seller[]) => void
  sellerSelected: Seller[]
}

export const isSelected = (sellerSelected: Seller[], e: Seller) =>
  sellerSelected.find((seller: Seller) => seller.id === e.id)

const SelectSellerModal = function ({
  data: dataSellers,
  error: errorGetAllSellers,
  tenant,
  onConfirm,
  onCancel,
  isOpen,
  sellerSelected,
  setSellerSelected,
}: SelectSellerModalProps) {
  const { t } = useTranslation()
  const { activeTenant } = useSelector((s: any) => s.application)
  const {
    data: roles,
    error: isErrorRolesBySites,
    loading: loadingRoles,
  } = useSelector((s: any) => s.instrospect.getRolesByAccountId)

  const handleConfirm = () => {
    onConfirm(sellerSelected)
  }

  const handleCancel = () => {
    setSellerSelected(activeTenant?.sellers || [])
    onCancel()
  }

  const wrapperHandleAddSeller = (seller: Seller) => {
    setSellerSelected([seller])
  }

  const wrapperHandleRemoveSeller = (seller: Seller) => {
    setSellerSelected(sellerSelected.filter((s: Seller) => s.id !== seller.id))
  }

  const isDisabled = (seller: any) => !roles.sellers.map((role: any) => role.id).includes(seller.id)

  return (
    <Modal isOpen={isOpen}>
      <div className="site-selector-modal">
        <CardHeader>
          {t('MANAGE_SELLER.SELECT_A_SELLER')}
          {tenant.label}
        </CardHeader>
        {errorGetAllSellers || isErrorRolesBySites ? (
          <div className="error">
            <ErrorNotification
              color="#42526E"
              title={t('MANAGE_SELLER.ERROR_LOADING_SELLERS_TITLE')}
              errorMessage={t('MANAGE_SELLER.ERROR_LOADING_SELLERS_MSG')}
            />
            <Button
              color="bordered-light"
              onClick={handleCancel}
              size="md"
            >
              {t('ACTIONS.CLOSE')}
            </Button>
          </div>
        ) : (
          <CardBody>
            {loadingRoles ? (<Spinner />) : (
              dataSellers.map((seller: Seller) => (
                <React.Fragment key={seller.key}>
                  <span
                    onClick={function () {
                      return !isDisabled(seller) && (
                        isSelected(sellerSelected, seller)
                          ? wrapperHandleRemoveSeller(seller)
                          : wrapperHandleAddSeller(seller))
                    }}
                  >
                    <GridItem
                      columnStart={3}
                      colSpan={2}
                    >
                      <span data-testid={seller.id}>
                        <Row className={`site__${isDisabled(seller) ? 'inactive' : 'active'}`}>
                          <Icon
                            name={isSelected(sellerSelected, seller) ? 'Check' : 'ShoppingCart'}
                            color={isDisabled(seller) ? '#7569F4' : '#8B97AD'}
                            size={16}
                          />
                          <Col className={isSelected(sellerSelected, seller) ? 'checked' : ''}>
                            <Row className="name">{seller.name}</Row>
                            <Row className="locked__description">{isDisabled(seller) && t('MANAGE_SITE.LOCKED_DESCRIPTION')}</Row>
                          </Col>
                          {isDisabled(seller) && (
                            <a
                              href={linkRequestAccessJarvis}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Icon
                                name="Lock"
                                color="#7569F4"
                                size={16}
                                id="lock__icon"
                              />
                            </a>
                          )}
                        </Row>
                      </span>
                    </GridItem>
                  </span>
                </React.Fragment>
              ))
            )}
          </CardBody>
        )}
        <CardFooter textRight>
          <ButtonGroup>
            <Button
              color="bordered-light"
              onClick={handleCancel}
            >
              {t('ACTIONS.CANCEL')}
            </Button>
            <Button
              color="primary"
              onClick={handleConfirm}
              disabled={errorGetAllSellers}
            >
              {t('ACTIONS.CONFIRM')}
            </Button>
          </ButtonGroup>
        </CardFooter>
      </div>
    </Modal>
  )
}

export default SelectSellerModal
