import qs from 'query-string'

const tenantsPagePath = '/tenants'
const usersPagePath = '/users'

export function getParams(history: any) {
  return qs.parse(history.location.search)
}

export const updateParams = (history: any, newParams: any) => {
  const { pathname, search } = history.location
  const existingParams = qs.parse(search)

  history.push({
    pathname,
    search: `?${qs.stringify({ ...existingParams, ...newParams })}`,
  })
}

export const replaceParams = (history: any, newParams: any) => {
  const { pathname, search } = history.location
  const existingParams = qs.parse(search)

  history.replace({
    pathname,
    search: `?${qs.stringify({ ...existingParams, ...newParams })}`,
  })
}

export const replaceWithoutExistingParams = (history: any, newParams: any) => {
  const { pathname } = history.location

  history.replace({
    pathname,
    search: `?${qs.stringify({ ...newParams })}`,
  })
}

export const navigateToUrl = (history: any, url: any) => {
  const { tenant, locale, sites } = getParams(history)
  const query = new URLSearchParams(window.location.search)
  query.set('locale', `${locale}`)
  if (tenant) {
    query.set('tenant', `${tenant}`)
    query.set('sites', `${sites}`)
  }

  history.push({
    pathname: url,
    search: query.toString(),
  })
}

export const isTenantURL = (history: any) => {
  const { pathname } = history.location
  return pathname === tenantsPagePath
}

export const isUsersURL = (history: any) => {
  const { pathname } = history.location
  return pathname === usersPagePath
}

export const navigateToRoute = (
  history: any,
  pathname: any,
  newParams: any,
  replaceParams = false,
  defaultParams?: any,
) => {
  const existingParams = replaceParams ? {} : qs.parse(history.location.search)
  history.push({
    pathname,
    search: `?${qs.stringify({ ...existingParams, ...newParams, ...defaultParams })}`,
  })
}
