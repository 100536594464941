import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Grid, GridItem, Icon, Spinner, Tab, Text } from '@jarvis-catalyst/ui-components'
import { Page } from '@jarvis-catalyst/ui-components/lib/components/tab/Tab'
import './GlobalApps.css'
import { useTranslation } from 'react-i18next'
import { applicationUiSidebarAction } from '../../../../store/modules/application/actions'
import sortByKeyValue from '../../../../utils/sortByKeyValue'
import AppListing from '../../components/appListing/AppListing'
import { cleanString } from '../../../../utils/stringHelpers'
import { applicationsGetAllAction } from '../../../../store/modules/applications/actions'
import { Group } from '../../../../store/modules/application/types'

const getTabs = (groups: Group[]): [Page[], any[]] => {
  const { t } = useTranslation()
  const mainTab: Page = { key: 'all',
    title: t('GLOBAL_APPS.TABS_ALL'),
    body: <AppListing
      groupId=""
      groupName="all"
    /> }
  let tabs: Page[] = []

  if (Array.isArray(groups)) {
    groups.forEach((group) => {
      if (group.id && group.name) {
        const newTab = { key: cleanString(group.name),
          title: group.name,
          body: <AppListing
            groupId={group.id}
            groupName={cleanString(group.name)}
          /> }
        tabs.push(newTab)
      }
    })
  }
  tabs = sortByKeyValue(tabs, 'title')
  tabs.unshift(mainTab)
  const keysTabsGroups = tabs.map(({ key }) => key)
  return [tabs, keysTabsGroups]
}

const GlobalApps = function () {
  const { t } = useTranslation()
  const { claims, activeTenant } = useSelector((s: any) => s.application)
  const { data: groups, loading: isLoadingGroups } = useSelector((s: any) => s.groups.getAll)

  const [tabs, keysTabsGroups] = getTabs(groups)
  const { groupName }: { groupName: string } = useParams()

  const getActiveTab = (): number => {
    let indexTab = 0
    if (groupName) {
      indexTab = Math.max(0, keysTabsGroups.indexOf(groupName))
    }
    return indexTab
  }

  useEffect(() => {
    applicationUiSidebarAction('hidden')
    applicationsGetAllAction({ limit: 'all', globalApps: true })
  }, [activeTenant])

  return (
    <Grid
      repeatNumber={12}
      repeatTemplate="minmax(50px, 1fr)"
      gap={10}
      className="global-apps"
    >
      <GridItem
        columnStart={1}
        colSpan={10}
        className="global-apps__header"
      >
        <Icon
          name="Jarvis"
          size={48}
        />
        <Text className="title">
          {' '}
          {t('WELCOME')}
          ,
          {' '}
          {claims.name}
        </Text>
      </GridItem>

      <GridItem
        columnStart={1}
        colSpan={12}
      >
        <Tab
          pages={tabs}
          setIndexPage={getActiveTab()}
        />
        {isLoadingGroups && <Spinner isCompleting={!isLoadingGroups} />}
      </GridItem>

    </Grid>
  )
}

export default GlobalApps
