export const TENANT_SITE_KEY = 'activeTenant'

type Site = {
    id: string;
    key: string;
    name: string;
    description: string;
    updatedAt: string;
    createdAt: string;
}

export interface LocalStorageTenantSite {
    id: string;
    name: string;
    sites: Array<Site>
}

export const LANGUAGE_KEY = 'language'

export const ACCOUNT_ID = 'userId'
