import { TENTANTS_API_BASE_URL, PERMISSIONS_API_BASE_URL, wrapperHeaderWithToken, restClient } from '../configuration'
import { HTTPRequestGetTenants, HTTPClientConfig, HttpMethodEnum, HTTPResponsePagination } from '../types'
import { TenantList } from '../../store/modules/tenants/types'
import { getAllService } from '../services.utils'

export const getAllTenants = async (
  request: HTTPRequestGetTenants,
): Promise<HTTPResponsePagination<TenantList[]>> => {
  const obj: any = {}
  if (request.page !== undefined) obj.page = request.page
  if (request.limit !== undefined) obj.limit = request.limit
  if (request.filter !== undefined && request.filter !== '') obj.filter = request.filter
  if (request.tenant !== undefined && request.tenant !== '') obj.tenant = request.tenant
  if (request.withSites !== undefined) obj.withSites = request.withSites
  const objUrl = new URLSearchParams(obj)

  const uri = `${TENTANTS_API_BASE_URL}/tenants/v1?${objUrl}`
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.GET,
    headers: wrapperHeaderWithToken(request.token),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)
      if (response.status === 200) {
        const data: HTTPResponsePagination<TenantList[]> = await response.json()
        resolve(data)
      } else {
        reject('TENANT_NOT_FOUND')
      }
    } catch (error) {
      reject(`${error}`)
    }
  })
}

export const getMyTenants = async (req: any): Promise<any> =>
  getAllService(req, () => `${PERMISSIONS_API_BASE_URL}/permissions/v3/introspect/tenants`)
