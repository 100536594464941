import React from 'react'
import { Checkbox } from '@jarvis-catalyst/jarvis-ui-kit'

export const Scope = function ({ hasWrite, scope, onToggle }: any) {
  return (
    <Checkbox
      disabled={!hasWrite}
      size="medium"
      value={scope?.selected}
      checked={scope.selected}
      onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => onToggle(scope, checked)}
      label={scope.name}
    />
  )
}
