import { CustomPermission } from '../../store/modules/custom-permissions/types'
import { dataToBody, PERMISSIONS_API_BASE_URL, restClient, wrapperHeaderWithToken } from '../configuration'
import {
  HTTPClientConfig,
  HttpMethodEnum,
  HTTPRequestCreate,
  HTTPRequestFilterPagination,
  HTTPResponsePagination,
} from '../types'
import { deleteByIdService, makeUriParams } from '../services.utils'
import { get } from '../http'

type ParamsGetAllV2 = {
  name: string
  description: string
  page?: number
  limit?: number
}

type PermissionByRoleParams = {
  page?: number
  limit?: number
  roles?:string[]
}

export const getAllCustomPermissionsV2 = async (
  token: string,
  params: ParamsGetAllV2,
): Promise<HTTPResponsePagination<CustomPermission[]>> => {
  const name = params.name ? `%${params.name}%` : ''
  const description = params.description ? `%${params.description}%` : ''
  const group = name === '' || description === ''
    ? ''
    : `&name__like__startGrp=${name}&__op=or&description__like__endGrp=${description}`
  const limit = params.limit ? params.limit : 10
  const qs = encodeURI(`page=${params.page || 1}${group}&limit=${limit}&created_by__ne=SYSTEM`)
  const uri = `${PERMISSIONS_API_BASE_URL}/permissions/${process.env.REACT_APP_RBAC_VERSION}?${qs}`
  return get(token, uri)
}

export const getAllPermissionsByRoles = async (
  token: string,
  params: PermissionByRoleParams,
): Promise<HTTPResponsePagination<CustomPermission[]>> => {
  const roles = params.roles ? `&role_id__in=${params.roles.join(',')}` : ''
  const limit = params.limit ? params.limit : 100
  const qs = encodeURI(`page=${params.page || 1}${roles}&limit=${limit}`)
  const uri = `${PERMISSIONS_API_BASE_URL}/permissions/${process.env.REACT_APP_RBAC_VERSION}?${qs}`
  return get(token, uri)
}

export const getAllCustomPermissions = async (
  request: HTTPRequestFilterPagination,
): Promise<HTTPResponsePagination<CustomPermission[]>> => {
  const objUrl = makeUriParams(request)
  const uri = `${PERMISSIONS_API_BASE_URL}/permissions/${process.env.REACT_APP_RBAC_VERSION}?${objUrl}`

  const config: HTTPClientConfig = {
    method: HttpMethodEnum.GET,
    headers: wrapperHeaderWithToken(request.token),
  }

  try {
    const response = await restClient()(uri, config)
    const data: HTTPResponsePagination<CustomPermission[]> = await response.json()
    return data
  } catch (error) {
    throw new Error(error as string)
  }
}

export const getCustomPermissionById = async (request: any): Promise<any> => {
  const uri = `${PERMISSIONS_API_BASE_URL}/permissions/${process.env.REACT_APP_RBAC_VERSION}/${request.id}`
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.GET,
    headers: wrapperHeaderWithToken(request.token),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)
      if (response.status === 200) {
        const data: any = await response.json()
        resolve(data)
      } else if (response.status === 404) {
        reject('NOT_FOUND')
      } else {
        reject('NOT_FOUND')
      }
    } catch (error) {
      reject(`${error}`)
    }
  })
}

export const createCustomPermissions = async (request: HTTPRequestCreate<any>): Promise<any> => {
  const uri = `${PERMISSIONS_API_BASE_URL}/permissions/${process.env.REACT_APP_RBAC_VERSION}`
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.POST,
    headers: wrapperHeaderWithToken(request.token),
    body: dataToBody(request.data),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)
      if (response.status === 201) {
        const data: HTTPResponsePagination<CustomPermission[]> = await response.json()
        resolve(data)
      } else if (response.status === 409) {
        reject('CONFLICT')
      } else if (response.status === 404) {
        reject('NOT_FOUND')
      } else if (response.status === 400) {
        const data: any = await response.json()
        reject(data.message)
      } else {
        reject('NOT_FOUND')
      }
    } catch (error) {
      reject(`${error}`)
    }
  })
}

export const updateCustomPermissions = async (id: string, request: HTTPRequestCreate<any>): Promise<any> => {
  const uri = `${PERMISSIONS_API_BASE_URL}/permissions/${process.env.REACT_APP_RBAC_VERSION}/${id}`
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.PUT,
    headers: wrapperHeaderWithToken(request.token),
    body: dataToBody(request.data),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)
      if (response.status === 200) {
        const data: HTTPResponsePagination<CustomPermission[]> = await response.json()
        resolve(data)
      } else if (response.status === 404) {
        reject('NOT_FOUND')
      } else if (response.status === 400) {
        const data: any = await response.json()
        reject(data.message)
      } else {
        reject('NOT_FOUND')
      }
    } catch (error) {
      reject(`${error}`)
    }
  })
}

export const deleteCustomPermission = async (req: any): Promise<any> =>
  deleteByIdService(
    req,
    (req: any) => `${PERMISSIONS_API_BASE_URL}/permissions/${process.env.REACT_APP_RBAC_VERSION}/${req.id}`,
  )
