/* eslint-disable */
function getObjectProps(obj: any, desc: string) {
  const arr: any[] = desc.split('.')
  while (arr.length && (obj = obj[arr.shift()])) {
    //
  }
  return obj
}

export default getObjectProps
