import { Scope, UserPermissions } from '..'

// Private
const getPermission = (userPermissions: UserPermissions, scope: Scope, permission: string): string[] | undefined =>
	userPermissions[scope][permission]

// Public
const hasPermission = (userPermissions: UserPermissions, scope: Scope, permission: string): boolean =>
	!!getPermission(userPermissions, scope, permission)

const hasPermissionAndHasScopes = (
	userPermissions: UserPermissions,
	scope: Scope,
	permission: string,
	values: string[],
): boolean => {
	const permissionFinded = getPermission(userPermissions, scope, permission)
	return permissionFinded ? permissionFinded.every((item: string) => values.includes(item)) : false
}

const hasPermissionAndHasSomeScopes = (
	userPermissions: UserPermissions,
	scope: Scope,
	permission: string,
	values: string[],
): boolean => {
	const permissionFinded = getPermission(userPermissions, scope, permission)
	return permissionFinded ? permissionFinded.some((item: string) => values.includes(item)) : false
}

const utils = {
	hasPermission,
	hasPermissionAndHasScopes,
	hasPermissionAndHasSomeScopes,
}

export default utils
