import { combineReducers } from 'redux'
import { createGetAllReducer, createCommitReducer, createGetReducer } from '../../utils'
import batchCreateReducer from './reducers/batch-create'

export const getAllApplicationsStore = createGetAllReducer('APPLICATIONS_V2')
export const getAllTenantApplicationsStore = createGetAllReducer('TENANT_APPLICATIONS')
export const createApplicationStore = createCommitReducer('APPLICATIONS_V2', 'CREATE')
export const deleteApplicationStore = createCommitReducer('APPLICATIONS_V2', 'DELETE')
export const updateApplicationStore = createCommitReducer('APPLICATIONS_V2', 'UPDATE')
export const getByIdAplicationsStore = createGetReducer('APPLICATIONS_V2')
export const getAllAppsByGroupIdStore = createGetAllReducer('APPLICATIONS_BY_ACTIVE_GROUP')
export const APPLICATIONS_BATCH_CREATE_START = 'APPLICATIONS_BATCH_CREATE_START'
export const APPLICATIONS_BATCH_CREATE_APPEND_RESULT = 'APPLICATIONS_BATCH_CREATE_APPEND_RESULT'
export const APPLICATIONS_BATCH_CREATE_APPEND_ERROR = 'APPLICATIONS_BATCH_CREATE_APPEND_ERROR'
export const APPLICATIONS_BATCH_CREATE_END = 'APPLICATIONS_BATCH_CREATE_END'
export const APPLICATIONS_BATCH_CREATE_RESET = 'APPLICATIONS_BATCH_CREATE_RESET'

const rootReducer = combineReducers({
  getAll: getAllApplicationsStore.reducer,
  getAllByActiveGroup: getAllAppsByGroupIdStore.reducer,
  getAllTenant: getAllTenantApplicationsStore.reducer,
  create: createApplicationStore.reducer,
  update: updateApplicationStore.reducer,
  delete: deleteApplicationStore.reducer,
  getById: getByIdAplicationsStore.reducer,
  batchCreate: batchCreateReducer,
})

export default rootReducer
