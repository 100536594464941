export const eventTypes = {
  LOCALE_CHANGE: 'localeChange',
  TENANT_CHANGE: 'tenantChange',
  AUTH_TOKEN_CHANGE: 'authTokenChange',
  APPLICATION_LOADED: 'applicationLoaded',
  USER_DETAILS_CHANGE: 'userDetailsChange',
}

export const commandTypes = {
  PUSH_NAVIGATION_HISTORY: 'pushNavigationHistory',
  SEND_NOTIFICATION: 'sendNotification',
}
