import { Progress, CardHeader, CardContent, Box, Button, Icon } from '@jarvis-catalyst/jarvis-ui-kit'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFieldArray } from 'react-hook-form'
import { uniqBy } from 'lodash'
import TenantsWithRole from './TenantsWithRole'
import { tenantsGetAllSelector } from '../../../../../store/modules/tenants/selectors'
import { parseOption } from '../../../../../utils/parseOption'
import { Roles } from '../../../../../store/modules/users/types'
import { Seller } from '../../../../../store/modules/sellers/types'
import CustomBox from '../../../../../components/custombox/custombox'
import { TenantList } from '../../../../../store/modules/tenants/types'
import { CardRolesProps, Option } from './manage-user.type'

const CardRoles = function ({ init, defaultTenantRoles, form, user, hasWrite }: CardRolesProps) {
  const { t } = useTranslation()
  const { data: tenants } = useSelector(tenantsGetAllSelector)
  const { data: sellers } = useSelector((s: any) => s.sellers.getAll)
  const { control, watch } = form
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'tenantRoles',
  })
  const tenantRoles = watch('tenantRoles')
  const [partialDelete, setPartialDelete] = useState<TenantList[]>([{ name: '', id: '', sites: [{ id: '', name: '' }], createdAt: '', dependencies: [{ id: '', name: '', status: '' }], status: '', subscriptionId: '', updatedAt: '' }])

  const getRoles = (data: any[], roles?: Roles[]) => {
    const ids = data?.filter((s: any) => roles?.map((x: any) => x.id).includes(s.id))
    const userRoles = roles ? roles.filter((s: any) => ids?.map((x: any) => x.id).includes(s.id)) : []
    return {
      id: ids?.map(parseOption),
      roles: uniqBy(
        userRoles.flatMap((s: any) => s.roles.map(parseOption)),
        'value',
      ),
    }
  }

  const setSitesAndSellersForTenant = (tenant: any, tenantRoleOptions: Option[], index: number) => {
    const tenantSellers = sellers.filter((s: Seller) => s.tenantId === tenant.id)
    update(index, {
      id: parseOption(tenant),
      idTenant: tenant.id,
      roles: tenantRoleOptions,
      sites: tenant.sites?.map(parseOption),
      siteRoles: getRoles(tenant.sites, user?.data?.roles.sites),
      sellers: tenantSellers.map(parseOption),
      sellerRoles: getRoles(tenantSellers, user?.data?.roles.sellers),
    })
  }

  useEffect(() => {
    if (init && user?.data) {
      user.data.roles.tenants?.forEach((tenant:any, index: number) => {
        const tenantFound = tenants.find((tenantlist) => tenantlist.id === tenant.id)
        if (tenantFound) {
          setSitesAndSellersForTenant(tenantFound, tenant.roles.map(parseOption), index)
        }
      })
    }
  }, [init, user])

  const onTenantSelect = (tenant: Option, index: number) => {
    const tenantSelected = tenants.find((s) => s.id === tenant.value)
    setSitesAndSellersForTenant(
      tenantSelected,
      [],
      index,
    )

    setPartialDelete((old:any) => [...old, tenantSelected])

    const newtenant = tenants.map((s) => s.name)
    const indextenant = tenant ? newtenant.indexOf(tenant.label) : -1
    tenants.splice(indextenant, 1)
  }

  const returnTenantSelected = (tenantId:string, index:number) => {
    if (tenantId) {
      const tenantReturn = partialDelete.find((d:TenantList) => d.id === tenantId)
      tenantReturn && tenants.push(tenantReturn)
      partialDelete.splice(index, 1)
    }
  }

  const handlerOnRemove = (tenantId:string, index:number) => {
    remove(index)
    returnTenantSelected(tenantId, index)
  }
  const handlerOnChange = (tenant:Option, index:number) => {
    if (tenant.value) {
      returnTenantSelected(tenant.value, index)
    }
  }
  return (
    <CustomBox>
      <CardHeader
        title={t('MANAGE_USER.ROLES_BY_TENANT')}
        subheader={t('MANAGE_USER.ACCOUNT_DETAIL_HINT')}
      />
      <CardContent>
        {init ? (
          <TenantsWithRole
            tenants={tenants.map(parseOption)}
            form={form}
            hasWrite={hasWrite}
            tenantRoles={tenantRoles}
            fields={fields}
            onRemove={handlerOnRemove}
            onTenantSelect={onTenantSelect}
            onChangeTenant={handlerOnChange}
          />
        ) : (
          <Progress circle />
        )}
      </CardContent>
      <Box sx={{ textAlign: 'right', marginTop: 5 }}>
        <Button
          variant="contained"
          onClick={() => append(defaultTenantRoles)}
          disabled={!hasWrite}
          startIcon={<Icon name="Plus" />}
        >
          {t('ADD_TENANT')}
        </Button>
      </Box>
    </CustomBox>
  )
}

export default CardRoles
