const config = {
  REACT_APP_DEBUG_MODE: process.env.REACT_APP_DEBUG_MODE === 'true',
  REACT_APP_CORE_APP_SEARCH_URL: process.env.REACT_APP_CORE_APP_SEARCH_URL,
  REACT_APP_PROMOTIONS_URL: process.env.REACT_APP_PROMOTIONS_URL,
  REACT_APP_CUSTOM_APPLICATIONS_API_BASE_URL: process.env.REACT_APP_CUSTOM_APPLICATIONS_API_BASE_URL,
  REACT_APP_PERMISSIONS_API_BASE_URL: process.env.REACT_APP_PERMISSIONS_API_BASE_URL,
  REACT_APP_AUDIENCE: process.env.REACT_APP_AUDIENCE,
  REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
  REACT_APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  REACT_APP_LOGOUT_REDIRECT_URL: process.env.REACT_APP_LOGOUT_REDIRECT_URL || '',
  REACT_APP_TENANTS: process.env.REACT_APP_TENANTS,
  REACT_APP_USER_MANAGEMENT: process.env.REACT_APP_USER_MANAGEMENT === 'true',
  REACT_APP_CUSTOM_APPLICATIONS: process.env.REACT_APP_CUSTOM_APPLICATIONS === 'true',
  REACT_APP_LEEWAY: Number.parseInt(process.env.REACT_APP_LEEWAY || '300'),
  REACT_APP_ALLOW_TESTING_ACCOUNT: process.env.REACT_APP_ALLOW_TESTING_ACCOUNT === 'true',
  REACT_APP_LOGIN_PROVIDER: process.env.REACT_APP_LOGIN_PROVIDER || 'auth0',
  REACT_APP_OIDC_AUTHORITY: process.env.REACT_APP_OIDC_AUTHORITY,
  REACT_APP_OIDC_CLIENT_ID: process.env.REACT_APP_OIDC_CLIENT_ID,
  REACT_APP_OIDC_REDIRECT_URI: process.env.REACT_APP_OIDC_REDIRECT_URI,
  REACT_APP_TENANT_REQUIRED_WORKFLOW: process.env.REACT_APP_TENANT_REQUIRED_WORKFLOW === 'true',
  REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  REACT_APP_LOG_EXPORT_LIMIT: Number.parseInt(process.env.REACT_APP_LOG_EXPORT_LIMIT || '12000'),

}
export default config
