type CompareMode = '=' | '>' | '<' | '!='
type SearchMode = '*' | ''

export const MakeFilter = function (): any {
  let f = ''
  let sentenceResult = true

  const add = (attribute: string, value: string, compareMode: CompareMode, searchMode: SearchMode): void => {
    if (!value && !sentenceResult) return
    sentenceResult = true
    f = `${f}${attribute}${searchMode}${compareMode}"${value}"+`
  }

  const find = (attribute: string, value: string, searchMode: SearchMode = '*'): void => {
    add(attribute, value, '=', searchMode)
  }

  const equal = (attribute: string, value: string): void => {
    add(attribute, value, '=', '')
  }

  const notEqual = (attribute: string, value: string): void => {
    add(attribute, value, '!=', '')
  }

  const orIf = (sentence: boolean): void => {
    if (!sentence || f === '') {
      sentenceResult = false
      return
    }
    f = `${f}OR+`
  }

  const andIf = (sentence: boolean): void => {
    if (!sentence || f === '') {
      sentenceResult = false
      return
    }
    f = `${f}AND+`
  }

  return {
    orIf,
    andIf,
    find,
    equal,
    orIfFind: (sentence: boolean, attribute: string, value: string): void => {
      orIf(sentence)
      find(attribute, value)
    },
    orIfEqual: (sentence: boolean, attribute: string, value: string): void => {
      orIf(sentence)
      equal(attribute, value)
    },
    andIfEqual: (sentence: boolean, attribute: string, value: string): void => {
      andIf(sentence)
      equal(attribute, value)
    },
    andIfNotEqual: (sentence: boolean, attribute: string, value: string): void => {
      andIf(sentence)
      notEqual(attribute, value)
    },
    decode: (s: string): any => decodeURI(s),
    build: (): any => f.trim().replace(/\+\s*$/, ''),
  }
}

export const getAllCustomPermissionsFilter = (name: string, description: string, page?: number): any => {
  const filter: any = {}
  const mf = MakeFilter()
  mf.find('name', name)
  mf.orIfFind(!!description, 'description', description)
  filter.filter = mf.build()
  if (page) filter.page = page
  return filter
}

export const getAllCustomPermissionsSearchFilter = (name: string, description: string, page?: number): any => {
  const filter: any = {}
  const mf = MakeFilter()
  mf.find('name', name)
  mf.orIfFind(!!description, 'description', description)
  mf.andIfEqual(true, 'createdBy', 'USER')
  filter.filter = mf.build()
  if (page) filter.page = page
  return filter
}

export const getAllTenantsSearchFilter = (name: string, status: string, page?: number): any => {
  const filter: any = {}
  const mf = MakeFilter()
  mf.find('name', name)
  mf.orIfFind(!!status, 'status', status)
  filter.filter = mf.build()
  if (page) filter.page = page
  return filter
}

export const getAllCorePermissionsFilter = (page?: number, limit?: number): any => {
  const filter: any = {}
  const mf = MakeFilter()
  mf.andIfEqual(true, 'createdBy', 'SYSTEM')
  filter.filter = mf.build()
  if (page) filter.page = page
  if (limit) filter.limit = limit
  return filter
}

type TenantsFilter = {
    page?: number;
    limit?: number;
    status?: string;
    withSites?: boolean
}
export const getAllTenantsFilter = (filters: TenantsFilter): any => {
  const { page, status, limit, withSites } = filters
  const filter: any = {}
  const mf = MakeFilter()
  if (status) mf.equal('status', status)
  filter.filter = mf.build()
  if (page) filter.page = page
  if (limit) filter.limit = limit
  filter.withSites = withSites
  return filter
}

type RoleFilter = {
    page?: number;
    limit?: number;
    name?: string;
    scopes?: 'TENANT' | 'SUBSCRIPTION';
}
export const getAllRoleFilter = (filters: RoleFilter): any => {
  const { name, page, scopes, limit } = filters
  const filter: any = {}
  const mf = MakeFilter()
  if (name) mf.find('name', name)
  if (scopes) mf.equal('scope', scopes)

  filter.filter = mf.build()
  if (page) filter.page = page
  if (limit) filter.limit = limit
  return filter
}

type UserFilter = {
  page?: number;
  email?: string;
  roles?: string[];
  permissions?: { label:string, value:string }[];
  tenants?: string[]
}

export const getAllUserFilter = (filters: UserFilter): any => {
  const { page, email, roles, permissions, tenants } = filters
  const filter: any = {}
  if (page) filter.page = page
  if (email) filter.email = `%${email}%`
  if (roles && roles.length > 0) filter.roles = roles.join(',')
  if (permissions && permissions.length > 0) filter.permissions = permissions.map((p) => p.value).join(',')
  if (tenants && tenants.length > 0) filter.tenants = tenants.join(',')
  return filter
}
