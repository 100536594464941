import isEmpty from 'lodash.isempty'
import { getAllSellers } from '../services/clients/sellers'
import { getAllSites } from '../services/clients/sites'
import { getMyTenants } from '../services/clients/tenants'
import { SellersProps, SetInitTenantAndSiteProps, SetSiteProps } from '../modules/shell/modules/mainRouter.types'
import { Seller } from '../store/modules/sellers/types'
import dictionary from '../modules/error/dictionary'
import cacheStore from '../utils/cache-store'
import { TENANT_SITE_KEY, ACCOUNT_ID } from '../utils/cache-store/types'
import { applicationUserHasTenantAction } from '../store/modules/application/actions'
import config from '../modules/shell/config'

const useSetTenantSitesSeller = () => {
  const setSeller = async ({
    token,
    tenantExist,
    tenants,
    sitesQss,
    sites,
    successCallback,
    sellerUrl,
    isCacheData,
  }: SellersProps) => {
    try {
      const sellers = await getAllSellers({ token, page: 1, limit: 100, tenant: tenantExist.id })
      const sellerFilteredByUrl = sellers?.data.filter((seller: Seller) => seller.key === sellerUrl) || []
      const loadSeller = isCacheData ? cacheStore(TENANT_SITE_KEY).getData().sellers : sellerFilteredByUrl
      successCallback({ tenant: tenantExist, sitesQss, tenants, sites, sellers: loadSeller })
    } catch {
      successCallback({ tenant: tenantExist, sitesQss, tenants, sites, sellers: [] })
    }
  }

  const setSite = async ({
    token,
    tenantExist,
    sitesQs,
    tenants,
    successCallback,
    failed,
    sellerUrl,
    isCacheData,
  }: SetSiteProps) => {
    try {
      const sites = await getAllSites({ token, page: 1, limit: 100, tenant: tenantExist.id })

      if (sites.data.length === 0) {
        await setSeller({
          token,
          tenantExist,
          tenants,
          sitesQss: [],
          sites,
          successCallback,
          sellerUrl,
          isCacheData,
        })
      } else {
        const sitesExits = sites.data.filter((st: any) => sitesQs.find((sqs: any) => sqs === st.key))
        const sitesExistFiltered = sitesExits.length > 0 ? sitesExits : []

        await setSeller({
          token,
          tenantExist,
          tenants,
          sitesQss: sitesExistFiltered,
          sites,
          successCallback,
          sellerUrl,
          isCacheData,
        })
      }
    } catch {
      failed(dictionary.COD_004_CRASH_DURING_INIT_SITES_SERVICE)
    }
  }

  const setTenantSitesSeller = async ({
    token,
    tenant,
    sitesQs,
    sellerUrl,
    successCallback,
    failed,
    userId,
  }: SetInitTenantAndSiteProps) => {
    try {
      const tenants = await getMyTenants({ token, page: 1, limit: 100 })
      if (tenants.data.length === 0) {
        applicationUserHasTenantAction(false)
        if (config.REACT_APP_TENANT_REQUIRED_WORKFLOW) {
          failed(dictionary.COD_001_USER_HAS_NO_TENANT)
        }
      } else {
        const localCache = cacheStore(TENANT_SITE_KEY).getData()
        const storedUserId: string = cacheStore(ACCOUNT_ID).getData()
        const tenantExist = tenants.data.find((tn: { id: string }) => tn.id === tenant)
        if (tenantExist) {
          await setSite({ token, tenantExist, sitesQs, tenants, successCallback, failed, sellerUrl })
        } else if (!isEmpty(localCache) && userId === storedUserId) {
          const localTenantExist = tenants.data.find((tenant: { id: string; data: [] }) => tenant.id === localCache.id)
          if (localTenantExist) {
            const localStoreSiteQs = localCache.sites.map((site: any) => site.key)

            await setSite({
              token,
              tenantExist: localCache,
              sitesQs: localStoreSiteQs,
              tenants,
              successCallback,
              failed,
              sellerUrl,
              isCacheData: true,
            })
          }
        } else {
          await setSite({ token, tenantExist: tenants.data[0], sitesQs, tenants, successCallback, failed, sellerUrl })
        }
      }
      cacheStore(ACCOUNT_ID).setData(userId)
    } catch {
      failed(dictionary.COD_002_CRASH_DURING_INIT_TENANT_SERVICE)
    }
  }

  return { setTenantSitesSeller }
}

export default useSetTenantSitesSeller
