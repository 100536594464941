import { useSelector } from 'react-redux'
import { permissions } from '@jarvis-catalyst/custom-app-sdk'
import { userPermissionsGetSelector } from '../store/modules/user-permissions/selectors'

type Props = {
    scope: 'subscription' | 'tenant';
    permission: string;
    values: string[];
}

const useHasPermissionAndHasSomeScopes = (props: Props): boolean => {
  const userPermissions: any = useSelector(userPermissionsGetSelector)
  const result = (): boolean => permissions.utils.hasPermissionAndHasSomeScopes(
    userPermissions,
    props.scope,
    props.permission,
    props.values,
  )
  return result()
}

export default useHasPermissionAndHasSomeScopes
