import {
  CustomCreateRequest,
  CustomRequest,
  HTTPClientConfig,
  HttpMethodEnum,
  HTTPRequestFilterPagination,
  HTTPResponseCreate,
  HTTPResponsePagination,
} from './types'
import { ParamsGetAllAction } from '../store/types'
import { dataToBody, FetchType, restClient, wrapperHeaderWithToken } from './configuration'

export const makeRequestFilterPagination = (
  token: any,
  params: ParamsGetAllAction | undefined,
): HTTPRequestFilterPagination => ({
  token,
  limit: params && params.limit ? params.limit : 10,
  filter: params && typeof params.filter === 'string' ? params.filter : '',
  page: params && params.page ? params.page : 1,
})

export const makeUriParams = (request: HTTPRequestFilterPagination) => {
  const obj: any = {}
  if (request.page !== undefined) obj.page = request.page
  if (request.limit !== undefined) obj.limit = request.limit
  if (request.filter !== undefined && request.filter !== '') obj.filter = request.filter
  if (request.tenant !== undefined && request.tenant !== '') obj.tenant = request.tenant
  return new URLSearchParams(obj)
}

// Make services
export const updateService = async <T>(request: CustomRequest, makeUri: (r: any, o: any) => string) => {
  const objUrl = makeUriParams(request)
  const uri: string = makeUri(request, objUrl)
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.PUT,
    headers: wrapperHeaderWithToken(request.token),
    body: dataToBody(request.data),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)

      if (response.status >= 200 && response.status < 300) {
        const data: HTTPResponseCreate<T> = await response.json()
        resolve(data)
      } else {
        const { status } = response
        if (status === 500) {
          reject('INTERNAL_SERVER_ERROR')
        } else if (status === 404) {
          reject('NOT_FOUND')
        } else {
          reject('UNAUTHORIZED')
        }
      }
    } catch (error) {
      reject(`${error}`)
    }
  })
}

export const createService = async <T>(request: CustomCreateRequest, makeUri: (r: any, o: any) => string) => {
  const objUrl = makeUriParams(request)
  const uri: string = makeUri(request, objUrl)
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.POST,
    headers: wrapperHeaderWithToken(request.token),
    body: dataToBody(request.data),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)

      if (response.status >= 200 && response.status < 300) {
        const data: HTTPResponseCreate<T> = await response.json()
        resolve(data)
      } else {
        const { status } = response
        if (status === 409) {
          reject('CONFLICT')
        } else if (status === 404) {
          reject('NOT_FOUND')
        } else {
          reject('UNAUTHORIZED')
        }
      }
    } catch (error) {
      reject(`${error}`)
    }
  })
}

export const getService = async (request: any, makeUri: any) => {
  const objUrl = makeUriParams(request)
  const uri: string = makeUri(request, objUrl)
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.GET,
    headers: wrapperHeaderWithToken(request.token),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)
      const data: any = await response.json()
      resolve(data)
    } catch (error) {
      reject(`${error}`)
    }
  })
}

type Options = {
  errors?: {
    notFound?: string
    default?: string
  }
}
export const getAllService = async (request: any, makeUri: (r: any, o: any) => string, options?: Options) => {
  const opts = {
    errors: { default: 'NOT_FOUND', notFound: 'NOT_FOUND' },
  }
  if (options) {
    if (options.errors) {
      opts.errors = {
        ...opts.errors,
        ...options.errors,
      }
    }
  }

  const objUrl = makeUriParams(request)
  const uri: string = makeUri(request, objUrl)
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.GET,
    headers: wrapperHeaderWithToken(request.token),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)
      if (response.status === 200) {
        const data: HTTPResponsePagination<any> = await response.json()
        resolve(data)
      } else if (response.status === 404) {
        reject(opts.errors.notFound)
      } else {
        reject(opts.errors.default)
      }
    } catch (error) {
      reject(`${error}`)
    }
  })
}

export const getByIdService = async (request: any, makeUri: (r: any, o: any) => string): Promise<any> => {
  const objUrl = makeUriParams(request)
  const uri: string = makeUri(request, objUrl)
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.GET,
    headers: wrapperHeaderWithToken(request.token),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)
      if (response.status === 200) {
        const data: any = await response.json()
        resolve(data)
      } else if (response.status === 404) {
        reject('NOT_FOUND')
      } else {
        reject('NOT_FOUND')
      }
    } catch (error) {
      reject(`${error}`)
    }
  })
}

export const deleteByIdService = async (request: any, makeUri: (r: any, o: any) => string) => {
  const uri: string = makeUri(request, {})
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.DELETE,
    headers: wrapperHeaderWithToken(request.token, {}, FetchType.PLAIN),
  }
  try {
    const response = await restClient()(uri, config)
    if (response.status >= 200 && response.status < 300) {
      return response.statusText
    }
    const { status } = response
    if (status === 404) {
      throw new Error('NOT_FOUND')
    } else {
      const data = await response.json()
      throw new Error(JSON.stringify(data))
    }
  } catch (error) {
    throw new Error(`${error}`)
  }
}
