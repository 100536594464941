export const questions: {
  question: string
  answer: {
    description: string
    points: { title: string; description: string }[]
    footer?: string
    links?: { title: string; url: string }[]
  }
  open?: boolean
}[] = [
  { question: 'QUESTIONS.QUESTION1', answer: { description: 'QUESTIONS.ANSWER1', points: [] }, open: true },
  {
    question: 'QUESTIONS.QUESTION2',
    answer: {
      description: 'QUESTIONS.ANSWER2.DESCRIPTION',
      points: [
        { title: 'QUESTIONS.ANSWER2.POINT2.TITLE', description: 'QUESTIONS.ANSWER2.POINT2.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER2.POINT1.TITLE', description: 'QUESTIONS.ANSWER2.POINT1.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER2.POINT4.TITLE', description: 'QUESTIONS.ANSWER2.POINT4.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER2.POINT3.TITLE', description: 'QUESTIONS.ANSWER2.POINT3.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER2.POINT5.TITLE', description: 'QUESTIONS.ANSWER2.POINT5.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER2.POINT6.TITLE', description: 'QUESTIONS.ANSWER2.POINT6.DESCRIPTION' },
      ],
      footer: 'QUESTIONS.ANSWER2.FOOTER',
    },
  },
  {
    question: 'QUESTIONS.QUESTION3',
    answer: {
      description: 'QUESTIONS.ANSWER3.DESCRIPTION',
      points: [
        { title: 'QUESTIONS.ANSWER3.POINT1.TITLE', description: 'QUESTIONS.ANSWER3.POINT1.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER3.POINT2.TITLE', description: 'QUESTIONS.ANSWER3.POINT2.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER3.POINT3.TITLE', description: 'QUESTIONS.ANSWER3.POINT3.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER3.POINT4.TITLE', description: 'QUESTIONS.ANSWER3.POINT4.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER3.POINT5.TITLE', description: 'QUESTIONS.ANSWER3.POINT5.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER3.POINT6.TITLE', description: 'QUESTIONS.ANSWER3.POINT6.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER3.POINT7.TITLE', description: 'QUESTIONS.ANSWER3.POINT7.DESCRIPTION' },
      ],
      footer: 'QUESTIONS.ANSWER3.FOOTER',
    },
  },
  {
    question: 'QUESTIONS.QUESTION4',
    answer: {
      description: 'QUESTIONS.ANSWER4.DESCRIPTION',
      points: [
        { title: 'QUESTIONS.ANSWER4.POINT1.TITLE', description: 'QUESTIONS.ANSWER4.POINT1.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER4.POINT2.TITLE', description: 'QUESTIONS.ANSWER4.POINT2.DESCRIPTION' },
      ],
      footer: 'QUESTIONS.ANSWER4.FOOTER',
    },
  },
  {
    question: 'QUESTIONS.QUESTION5',
    answer: {
      description: 'QUESTIONS.ANSWER5.DESCRIPTION',
      points: [
        { title: 'QUESTIONS.ANSWER5.POINT1.TITLE', description: 'QUESTIONS.ANSWER5.POINT1.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER5.POINT2.TITLE', description: 'QUESTIONS.ANSWER5.POINT2.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER5.POINT3.TITLE', description: 'QUESTIONS.ANSWER5.POINT3.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER5.POINT4.TITLE', description: 'QUESTIONS.ANSWER5.POINT4.DESCRIPTION' },
      ],
      footer: '',
    },
  },
  {
    question: 'QUESTIONS.QUESTION6',
    answer: {
      description: 'QUESTIONS.ANSWER6.DESCRIPTION',
      points: [
        { title: 'QUESTIONS.ANSWER6.POINT1.TITLE', description: 'QUESTIONS.ANSWER6.POINT1.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER6.POINT2.TITLE', description: 'QUESTIONS.ANSWER6.POINT2.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER6.POINT3.TITLE', description: 'QUESTIONS.ANSWER6.POINT3.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER6.POINT4.TITLE', description: 'QUESTIONS.ANSWER6.POINT4.DESCRIPTION' },
      ],
      footer: 'QUESTIONS.ANSWER6.FOOTER',
      links: [
        { title: 'ACCESS_REQUEST_PORTAL', url: 'https://jira.falabella.tech/plugins/servlet/desk/portal/16' },
        {
          title: 'REQUEST_ACCESS_JARVIS',
          url: 'https://confluence.falabella.tech/display/PLSRE/Request+Access+to+Jarvis',
        },
      ],
    },
  },
  {
    question: 'QUESTIONS.QUESTION7',
    answer: {
      description: 'QUESTIONS.ANSWER7.DESCRIPTION',
      points: [
        { title: 'QUESTIONS.ANSWER7.POINT1.TITLE', description: 'QUESTIONS.ANSWER7.POINT1.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER7.POINT2.TITLE', description: 'QUESTIONS.ANSWER7.POINT2.DESCRIPTION' },
      ],
      footer: '',
      links: [{ title: '#jarvis_queries', url: 'https://falabella.slack.com/archives/CM19DDD0A' }],
    },
  },
  {
    question: 'QUESTIONS.QUESTION8',
    answer: {
      description: 'QUESTIONS.ANSWER8.DESCRIPTION',
      points: [],
      footer: 'QUESTIONS.ANSWER8.FOOTER',
      links: [
        { title: 'CUSTOM_APP.TITLE', url: 'https://confluence.falabella.tech/display/PLSRE/Custom+Applications' },
        {
          title: 'Develop a new application for Jarvis',
          url: 'https://confluence.falabella.tech/display/PLSRE/Develop+a+new+application+for+Jarvis',
        },
      ],
    },
  },
  {
    question: 'QUESTIONS.QUESTION9',
    answer: {
      description: 'QUESTIONS.ANSWER9.DESCRIPTION',
      points: [],
      footer: 'QUESTIONS.ANSWER9.FOOTER',
      links: [{ title: 'ACTIVITY', url: 'https://confluence.falabella.tech/display/PLSRE/Audit+Log' }],
    },
  },
  {
    question: 'QUESTIONS.QUESTION10',
    answer: {
      description: 'QUESTIONS.ANSWER10.DESCRIPTION',
      points: [
        { title: 'QUESTIONS.ANSWER10.POINT1.TITLE', description: 'QUESTIONS.ANSWER10.POINT1.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER10.POINT2.TITLE', description: 'QUESTIONS.ANSWER10.POINT2.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER10.POINT3.TITLE', description: 'QUESTIONS.ANSWER10.POINT3.DESCRIPTION' },
        { title: 'QUESTIONS.ANSWER10.POINT4.TITLE', description: 'QUESTIONS.ANSWER10.POINT4.DESCRIPTION' },
      ],
      footer: '',
    },
  },
]
