import dictionary from '../../error/dictionary'
import { applicationSetConfigurationAction } from '../../../store/modules/application/actions'

export const LOGIN_PROVIDER_ERROR = 'LOGIN_PROVIDER_ERROR'
export const RBAC_ERROR = 'RBAC_ERROR'
export const FAILED_FETCH_ERROR = 'FAILED_FETCH_ERROR'
export const AUTH_ERROR = 'AUTH_ERROR'
export const TOKEN_NOT_ACTIVE_ERROR = 'TOKEN_NOT_ACTIVE_ERROR'
export const UNKNOWN_ERROR = 'UNKNOWN_ERROR'

/**
 * Processes errors based on the received message and executes the corresponding action.
 *
 * @param {object} error with the message identifying the type of error.
 */
export const processError = (error) => {
  const errorActions = {
    LOGIN_PROVIDER_ERROR: () =>
      applicationSetConfigurationAction({ key: 'errors', value: [dictionary.COD_000_CRASH_INIT_LOGIN_PROVIDER] }),
    RBAC_ERROR: () =>
      applicationSetConfigurationAction({
        key: 'errors',
        value: [dictionary.COD_003_CRASH_DURING_INIT_RBAC_SERVICE],
      }),
    FAILED_FETCH_ERROR: () =>
      applicationSetConfigurationAction({
        key: 'errors',
        value: [dictionary.COD_006_USER_HAS_NO_ACCESS_TO_SUBSCRIPTION],
      }),
    AUTH_ERROR: () =>
      applicationSetConfigurationAction({
        key: 'errors',
        value: [dictionary.COD_007_CRASH_DURING_INIT_AUTHENTICATION],
      }),
    TOKEN_NOT_ACTIVE_ERROR: () =>
      applicationSetConfigurationAction({
        key: 'errors',
        value: [dictionary.COD_010_ACCESS_TOKEN_EXPIRED],
      }),
  }

  const action = errorActions[error.message]
  if (action) {
    action()
  }
}
