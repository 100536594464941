import { combineReducers } from 'redux'
import { createCommitReducer, createGetAllReducer, createGetReducer } from '../../utils'

export const getByIdCustomPermissionStore = createGetReducer('CUSTOM_PERMISSIONS')
export const getAllCustomPermissionsStore = createGetAllReducer('CUSTOM_PERMISSIONS')
export const getAllCorePermissionsStore = createGetAllReducer('CORE_PERMISSIONS')
export const getAllRolePermissionsStore = createGetAllReducer('ROLE_PERMISSIONS')
export const createCustomPermissionsStore = createCommitReducer('CUSTOM_PERMISSIONS', 'CREATE')
export const updateCustomPermissionsStore = createCommitReducer('CUSTOM_PERMISSIONS', 'UPDATE')
export const deleteCustomPermissionsStore = createCommitReducer('CUSTOM_PERMISSIONS', 'DELETE')

const rootReducer = combineReducers({
  getAll: getAllCustomPermissionsStore.reducer,
  getAllCore: getAllCorePermissionsStore.reducer,
  getAllByRole: getAllRolePermissionsStore.reducer,
  getById: getByIdCustomPermissionStore.reducer,
  create: createCustomPermissionsStore.reducer,
  update: updateCustomPermissionsStore.reducer,
  delete: deleteCustomPermissionsStore.reducer,
})

export default rootReducer
