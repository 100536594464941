import { Icon, Title, Text } from '@jarvis-catalyst/ui-components'
import React from 'react'
import './ErrorNotification.css'
import { useTranslation } from 'react-i18next'

interface Props {
  errorMessage: string;
  color?: string;
  title?: string;
}
const ErrorNotification: React.FC<Props> = function ({ errorMessage, title, color }:Props) {
  const { t } = useTranslation()

  return (
    <>
      <Icon
        name="Warning"
        size={48}
        color={color || '#8B97AD'}
      />
      {title && <Text className="error__info">{title}</Text>}
      <Text className="error__message">{errorMessage}</Text>
      {!title && <Title tag="h4">{t('PLEASE_TRY_AGAIN')}</Title>}
    </>
  )
}

export default ErrorNotification
