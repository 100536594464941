import React from 'react'
import { Grid, Typography, RadioButton, Switch, TextField, Progress } from '@jarvis-catalyst/jarvis-ui-kit'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { UseFormReturn } from 'react-hook-form/dist/types/form'
import './ApplicationManagment.css'
import { regexRoute, regexAppUrl } from '../../../../utils/validations'
import { ApplicationPayload, NAVIGATION } from '../../../../store/modules/applications/types'
import CardBlock from '../../../../components/CardBlock/CardBlock'

type CardNavigationProps = {
  form: UseFormReturn<ApplicationPayload>
  app?: ApplicationPayload
  init: boolean
}

const navigationTypeOptions = [
  {
    label: 'Iframe',
    value: 'IFRAME',
  },
  {
    label: 'External',
    value: 'EXTERNAL',
  },
  {
    label: 'Internal',
    value: 'INTERNAL',
  },
]

const CardNavigation = function ({ form, app, init }: CardNavigationProps) {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
  } = form

  return (
    <CardBlock title={t('MANAGE_CUSTOM_APPLICATION.TITLE_NAVIGATION')}>
      {init ? (
        <>
          <div style={{ marginBottom: 10 }}>
            <Typography variant="H500">{t('MANAGE_CUSTOM_APPLICATION.TITLE_NAVIGATION')}</Typography>
          </div>
          <div style={{ padding: 2 }}>
            <Grid
              container
              columnSpacing={3}
              rowSpacing={2}
            >
              <Grid
                item
                sm={6}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    sm={12}
                  >
                    <Controller
                      name="url"
                      defaultValue={app?.url || ''}
                      control={control}
                      rules={{ required: true, pattern: regexAppUrl }}
                      render={({ field: { onChange, value, ref } }) => (
                        <TextField
                          fullWidth
                          label={t('MANAGE_CUSTOM_APPLICATION.URL')}
                          error={!!errors?.url}
                          helperText={errors.url?.type === 'pattern' ? t('HINT_INVALID_URL') : '' || errors?.url ? t('HINT_REQUIRED_FIELD') : ''}
                          value={value}
                          inputRef={ref}
                          onChange={onChange}
                          placeholder={t('MANAGE_CUSTOM_APPLICATION.URL_PLACEHOLDER')}
                          color={errors?.url?.type === 'pattern' ? 'error' : 'primary'}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                  >
                    <Controller
                      name="route"
                      defaultValue={app?.route || ''}
                      control={control}
                      rules={{ required: true, pattern: regexRoute }}
                      render={({ field: { onChange, value, ref } }) => (
                        <TextField
                          fullWidth
                          label={t('MANAGE_CUSTOM_APPLICATION.ROUTE')}
                          error={!!errors?.route}
                          helperText={t(errors.route?.type === 'pattern' ? 'HINT_INVALID_ROUTE' : errors?.route ? t('HINT_REQUIRED_FIELD') : '')}
                          value={value}
                          inputRef={ref}
                          onChange={onChange}
                          placeholder={t('MANAGE_CUSTOM_APPLICATION.ROUTE_PLACEHOLDER')}
                          color={errors?.route?.type === 'pattern' ? 'error' : 'primary'}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Controller
                        name="initAppWithRoute"
                        defaultValue={app ? app?.initAppWithRoute : true}
                        control={control}
                        render={({ field: { onChange, value: initAppWithRoute } }) => (
                          <>
                            {/* eslint-disable-next-line */}
                            {`${t('MANAGE_CUSTOM_APPLICATION.INIT_WITH_ROUTE')}:`}{' '}
                            {initAppWithRoute ? t('MANAGE_CUSTOM_APPLICATION.WITH_ROUTE') : 'No'}
                            <div className="application_mgmt--right">
                              <Switch
                                value={initAppWithRoute}
                                onChange={onChange}
                                checked={initAppWithRoute}
                              />
                            </div>
                          </>
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={6}
              >
                <Grid
                  container
                  spacing={2}
                  direction="column"
                >
                  <div style={{ padding: 5, marginLeft: 15 }}>
                    <div style={{ marginBottom: 5 }}>
                      <Typography variant="H400">{t('MANAGE_CUSTOM_APPLICATION.TITLE_NAVIGATIONTYPE')}</Typography>
                    </div>
                    <Controller
                      name="navigationType"
                      defaultValue={app?.navigationType || NAVIGATION.Iframe}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value: navigationOptions } }) => (
                        <RadioButton
                          size="small"
                          selectedOption={navigationOptions}
                          onChange={onChange}
                          options={navigationTypeOptions.map(({ value }) => value)}
                        />
                      )}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <Progress
          circle
          color="secondary"
        />
      )}
    </CardBlock>
  )
}

export default CardNavigation
