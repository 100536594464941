import { useHistory } from 'react-router-dom'
import { Progress } from '@jarvis-catalyst/jarvis-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import useAuthenticationContextWrapper from '../contexts/AuthenticationContextWrapper'
import { applicationSetConfigurationAction } from '../../../store/modules/application/actions'
import { Main } from './Main'
import { useSetInitializationData } from '../../../hooks/useSetInitializationData'
import { ComponentWithRouterError } from '../components/componentWithRouterError/ComponentWithError'

const MainRouter = function () {
  const { token, isAuthenticated, loading: isAuthLoading, authorizationError } = useAuthenticationContextWrapper()
  const { getMyTenants: myTenants } = useSelector((s: any) => s.tenants)
  const applicationErrors: [] = useSelector((s: any) => s.application.errors)
  const userId = useSelector((store: any) => store.application.claims?.accountId)
  const dispatch = useDispatch()
  const history = useHistory()

  useSetInitializationData({
    token,
    history,
    dispatch,
    setErrorUi: (error) => {
      applicationSetConfigurationAction({ key: 'errors', value: [error] })
    },
    userId,
  })

  return (
    <>
      {!(!isAuthLoading || myTenants.data.loading) && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
          }}
        >
          <Progress
            circle
            variant="indeterminate"
            size={70}
          />
        </div>
      )}
      {(myTenants.error || authorizationError || applicationErrors.length > 0) && (
        <ComponentWithRouterError errorMessageTenant={myTenants.errorMessage} />
      )}
      {isAuthenticated && applicationErrors.length === 0 ? (
        <Main token={token} />
      ) : (
        <div>
          {applicationErrors.length > 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100vw',
                height: '100vh',
              }}
            >
              <Progress
                circle
                variant="indeterminate"
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default MainRouter
