import React from 'react'
import { Route } from 'react-router-dom'
import Users from './pages/users'

const UsersModule = function () {
  return <Route
    path="/users"
    component={Users}
  />
}

export default UsersModule
