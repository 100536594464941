import { combineReducers } from 'redux'
import { ActionReducer, StoreAllData } from '../../types'
import { TenantList } from './types'
import { createGetAllReducer } from '../../utils'

export const TENANTS_GET_ALL_START = 'TENANTS_GET_ALL_START'
export const TENANTS_GET_ALL_RESULT = 'TENANTS_GET_ALL_RESULT'
export const TENANTS_GET_ALL_ERROR = 'TENANTS_GET_ALL_ERROR'

export const getAllInitialState: StoreAllData<TenantList> = {
  data: [],
  pagination: {
    total: 0,
    pageSize: 0,
    page: 0,
    totalPages: 0,
  },
  error: null,
  success: null,
  errorMessage: '',
  loading: false,
}

const getAllReducer = (
  prevState = getAllInitialState,
  action: ActionReducer<any>,
): StoreAllData<TenantList> => {
  switch (action.type) {
  case TENANTS_GET_ALL_START:
    return {
      ...prevState,
      data: [],
      success: null,
      error: null,
      errorMessage: action.payload,
      loading: true,
    }
  case TENANTS_GET_ALL_RESULT:
    return {
      ...prevState,
      success: true,
      error: false,
      data: action.payload.data,
      pagination: action.payload.meta.pagination,
      loading: false,
    }
  case TENANTS_GET_ALL_ERROR:
    return {
      ...prevState,
      success: false,
      error: true,
      errorMessage: action.payload,
      loading: false,
    }
  default:
    return prevState
  }
}

export const getMyTenantsStore = createGetAllReducer('MY_TENANTS')

const rootReducer = combineReducers({
  getAll: getAllReducer,
  getMyTenants: getMyTenantsStore.reducer,
})

export default rootReducer
