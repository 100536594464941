import {
  HTTPResponsePagination,
  HTTPClientConfig,
  HttpMethodEnum,
  HTTPRequestCreate,
  HTTPResponseCreate,
} from '../types'
import { wrapperHeaderWithToken, ROLES_API_BASE_URL, restClient, dataToBody } from '../configuration'
import { makeUriParams, deleteByIdService } from '../services.utils'
import { CustomPermission } from '../../store/modules/custom-permissions/types'
import { get } from '../http'

type ParamsGetAllV2 = {
  name?: string
  description?: string
  page?: number
  limit?: number
  scope?: 'TENANT' | 'SUBSCRIPTION' | 'SITE' | 'SELLER'
  expand?: boolean
}

const getIf = (comparison: any, yes: any, not: any = '') => (comparison ? yes : not)

export const getAllRolesV2 = async (
  token: string,
  params: ParamsGetAllV2,
): Promise<HTTPResponsePagination<CustomPermission[]>> => {
  const page = getIf(params.page, `page=${params.page}`, 'page=1')
  const limit = getIf(params.limit, `&limit=${params.limit}`, '&limit=10')
  const name = getIf(params.name, `%${params.name}%`)
  const description = getIf(params.description, `%${params.description}%`)
  const scope = getIf(params.scope, `&role_scopes_name=${params.scope}`)
  const group = getIf(
    !(name === '' || description === ''),
    `&name__like__startGrp=${name}&__op=or&description__like__endGrp=${description}`,
  )
  const expand = getIf(params.expand, '&expand=true', '')

  const qs = encodeURI(`${page}${limit}${group}${scope}${expand}`)

  const uri = `${ROLES_API_BASE_URL}/roles/v3?${qs}`
  return get(token, uri)
}

export const getRoleById = async (request: any): Promise<any> => {
  const objUrl = makeUriParams(request)
  const uri = `${ROLES_API_BASE_URL}/bff/rbac/roles/v3/${request.id}?${objUrl}`
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.GET,
    headers: wrapperHeaderWithToken(request.token),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)
      if (response.status === 200) {
        const data: any = await response.json()
        resolve(data)
      } else if (response.status === 404) {
        reject('NOT_FOUND')
      } else {
        reject('NOT_FOUND')
      }
    } catch (error) {
      reject(`${error}`)
    }
  })
}

export const createRole = (request: HTTPRequestCreate<any>): Promise<HTTPResponseCreate<any>> => {
  const uri = `${ROLES_API_BASE_URL}/roles/v3`
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.POST,
    headers: wrapperHeaderWithToken(request.token),
    body: dataToBody(request.data),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)

      if (response.status >= 200 && response.status < 300) {
        const data: HTTPResponseCreate<any> = await response.json()
        resolve(data)
      } else {
        const { status } = response
        if (status === 409) {
          reject('CONFLICT')
        } else if (status === 404) {
          reject('NOT_FOUND')
        } else if (status === 400) {
          reject('BAD_REQUEST')
        } else if (status === 403) {
          reject('UNAUTHORIZED')
        } else {
          reject('NOT_FOUND')
        }
      }
    } catch (error) {
      reject(`${error}`)
    }
  })
}

export const updateRole = async (request: any): Promise<any> => {
  const uri = `${ROLES_API_BASE_URL}/roles/v3/${request.id}`
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.PUT,
    headers: wrapperHeaderWithToken(request.token),
    body: dataToBody(request.data),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)
      if (response.status === 200) {
        const data: HTTPResponsePagination<any[]> = await response.json()
        resolve(data)
      } else if (response.status === 404) {
        reject('NOT_FOUND')
      } else if (response.status === 400) {
        const data: any = await response.json()
        reject(data.message)
      } else if (response.status === 403) {
        reject('UNAUTHORIZED')
      } else {
        reject('NOT_FOUND')
      }
    } catch (error) {
      reject(`${error}`)
    }
  })
}

export const deleteRole = async (req: any): Promise<any> =>
  deleteByIdService(req, (req: any) => `${ROLES_API_BASE_URL}/roles/v3/${req.id}`)
