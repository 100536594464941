import { HTTPResponsePagination, HTTPClientConfig, HttpMethodEnum, HTTPRequestGetAllApps } from '../types'
import { wrapperHeaderWithToken, REACT_APP_CUSTOM_APPLICATIONS, restClient, dataToBody } from '../configuration'
import { deleteByIdService, getByIdService } from '../services.utils'

export const getAllApplications = async (request: HTTPRequestGetAllApps): Promise<HTTPResponsePagination<any[]>> => {
  const obj: any = {}
  if (request.page !== undefined) obj.page = request.page
  if (request.limit !== undefined) obj.limit = request.limit
  if (request.tenant !== undefined && request.tenant !== '') obj.tenant = request.tenant
  if (request.groupId !== undefined && request.groupId !== '') obj.groupId = request.groupId
  if (request.globalApps !== undefined) obj.globalApps = request.globalApps

  const objUrl = new URLSearchParams(obj)
  const uri = `${REACT_APP_CUSTOM_APPLICATIONS}/pat/applications/v2?${objUrl}`
  const config: HTTPClientConfig = {
    method: HttpMethodEnum.GET,
    headers: wrapperHeaderWithToken(request.token),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)
      if (response.status === 200) {
        const data: HTTPResponsePagination<any[]> = await response.json()
        resolve(data)
      } else {
        reject('APPLICATIONS_NOT_FOUND')
      }
    } catch (error) {
      reject(`${error}`)
    }
  })
}

const conflictErrorMessage = (data: { errors: { [key: string]: { detail: string } } }) => {
  const constraint = Object.keys(data.errors)[0]
  return `CONFLICT_${constraint}`
}

export const createApplication = async (request: any): Promise<any> => {
  const obj: any = {}
  if (request.tenant !== undefined && request.tenant !== '') obj.tenant = request.tenant
  const objUrl = new URLSearchParams(obj)
  const uri = `${REACT_APP_CUSTOM_APPLICATIONS}/pat/applications/v2?${objUrl}`

  const config: HTTPClientConfig = {
    method: HttpMethodEnum.POST,
    headers: wrapperHeaderWithToken(request.token),
    body: dataToBody(request.data),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)
      if (response.status === 201) {
        const data: any = await response.json()
        resolve(data)
      } else if (response.status === 409) {
        const data: any = await response.json()
        data.errors ? reject(conflictErrorMessage(data)) : reject(data)
      } else if (response.status === 404) {
        reject('NOT_FOUND')
      } else if (response.status === 400) {
        const data: any = await response.json()
        reject(data)
      } else {
        reject('NOT_FOUND')
      }
    } catch (error) {
      reject(`${error}`)
    }
  })
}

export const updateApplication = async (request: any): Promise<any> => {
  const obj: any = {}
  if (request.tenant !== undefined && request.tenant !== '') obj.tenant = request.tenant
  const objUrl = new URLSearchParams(obj)
  const uri = `${REACT_APP_CUSTOM_APPLICATIONS}/pat/applications/v2/${request.appId}?${objUrl}`

  const config: HTTPClientConfig = {
    method: HttpMethodEnum.PATCH,
    headers: wrapperHeaderWithToken(request.token),
    body: dataToBody(request.data),
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)
      if (response.status === 200) {
        const data: any = await response.json()
        resolve(data)
      } else if (response.status === 409) {
        const data: any = await response.json()
        data.errors ? reject(conflictErrorMessage(data)) : reject(data)
      } else if (response.status === 404) {
        reject('NOT_FOUND')
      } else if (response.status === 400) {
        const data: any = await response.json()
        reject(data)
      } else {
        reject('NOT_FOUND')
      }
    } catch (error) {
      reject(`${error}`)
    }
  })
}

export const deleteApplication = async (req: any): Promise<any> =>
  deleteByIdService(req, (req: any) => `${REACT_APP_CUSTOM_APPLICATIONS}/pat/applications/v2/${req.id}`)

export const getApplicationById = async (req: any): Promise<any> =>
  getByIdService(req, (req: any) => `${REACT_APP_CUSTOM_APPLICATIONS}/pat/applications/v2/${req.id}`)
