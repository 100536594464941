import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Grid,
  CardContent,
  IconButton,
  ButtonGroup,
  Pagination,
  TextField,
  Autocomplete,
  Button,
  Icon,
  Menu,
  Tooltip,
  Table,
  Typography,
  Box,
} from '@jarvis-catalyst/jarvis-ui-kit'
import { useDebounce } from '@jarvis-catalyst/ui-components'
import { getDateWithLocale } from '../../../../utils/date'
import { usersDeleteAction, usersGetAllAction, usersExportAction } from '../../../../store/modules/users/actions'
import { getAllTenantsFilter, getAllUserFilter } from '../../../../utils/filters'
import useAppendQS from '../../../../hooks/useAppendQS'
import { ROUTES } from '../../../../const'
import useHasPermissionAndHasSomeScopes from '../../../../hooks/useHasPermissionAndHasSomeScopes'
import { usersBatchCreateSeletor } from '../../../../store/modules/users/selectors'
import useAuthenticationContextWrapper from '../../../shell/contexts/AuthenticationContextWrapper'
import { CustomNotification } from '../../../../components/customNotification/CustomNotification'
import downloadFile from '../../../../utils/downloadFile'
import { sendDangerNotification, sendSuccessNotification } from '../../../../utils/sendNotification'
import buildBagdesWithTooltip from '../../../../utils/buildBagdesWithTooltip'
import './UsersListing.css'
import { rolesGetAllSelector } from '../../../../store/modules/roles/selectors'
import { parseOption } from '../../../../utils/parseOption'
import { rolesGetAllActionV2 } from '../../../../store/modules/roles/actions'
import {
  permissionsByRolesGetAllAction,
  permissionsByRolesResetAction,
} from '../../../../store/modules/custom-permissions/actions'
import { permissionsByRolesGetAllSelector } from '../../../../store/modules/custom-permissions/selectors'
import { tenantsGetAllAction } from '../../../../store/modules/tenants/actions'
import { dataIsLoad } from '../../../../store/utils'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UsersListing = function () {
  const { t } = useTranslation()

  const locale = useSelector((s) => s.application.language)
  const usersStore = useSelector((s) => s.users.getAll)
  const usersDeleteStore = useSelector((s) => s.users.delete)
  const rolesStore = useSelector(rolesGetAllSelector)
  const permissionsStore = useSelector(permissionsByRolesGetAllSelector)
  const { isAdmin, userDetails } = useAuthenticationContextWrapper()
  const userBatchCreateStore = useSelector(usersBatchCreateSeletor)
  const exportSelector = useSelector((s) => s.users.export)
  const tenantsStore = useSelector((s) => s.tenants.getAll)

  const [tenantOptions, setTenantOptions] = useState([])
  const [roleOptions, setRoleOptions] = useState([])
  const [permissionOptions, setPermissionOptions] = useState([])

  const [searchInput, setSearchInput] = useState('')
  const [selectedRoles, setSelectedRoles] = useState([])
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const [selectedTenants, setSelectedTenants] = useState([])

  const appendQS = useAppendQS()
  const history = useHistory()

  // eslint-disable-next-line
  const hasWrite =
    useHasPermissionAndHasSomeScopes({
      scope: 'subscription',
      permission: 'subscription.jarvisusers',
      values: ['write'],
    }) || isAdmin

  const hasRead = useHasPermissionAndHasSomeScopes({
    scope: 'subscription',
    permission: 'subscription.jarvisusers',
    values: ['read'],
  })

  useEffect(() => {
    rolesGetAllActionV2({ limit: 100, page: 1 })
    tenantsGetAllAction(getAllTenantsFilter({ page: 1, limit: 100, status: 'active' }))
  }, [])

  useEffect(() => {
    dataIsLoad(tenantsStore) && setTenantOptions(tenantsStore.data.map(parseOption))
  }, [tenantsStore])

  useEffect(() => {
    dataIsLoad(rolesStore) && setRoleOptions(rolesStore.data.map(parseOption))
  }, [rolesStore])

  useEffect(() => {
    if (!usersDeleteStore.loading && usersDeleteStore.success) usersGetAllAction()
  }, [usersDeleteStore])

  useEffect(() => {
    if (!userBatchCreateStore.loading && userBatchCreateStore.success) usersGetAllAction()
  }, [userBatchCreateStore])

  const debounceSearchInput = useDebounce(searchInput, 500)

  const limitOptions = 10

  useEffect(() => {
    if (selectedRoles.length > 0) {
      permissionsByRolesGetAllAction({
        roles: selectedRoles.map((role) => role.value),
        limit: 100,
        page: 1,
      })
    } else {
      permissionsByRolesResetAction()
    }
  }, [selectedRoles])

  const parsePermissionOptions = (p) => ({ label: `${p.group}.${p.name}`, value: p.id })

  useEffect(() => {
    dataIsLoad(permissionsStore) && setPermissionOptions(permissionsStore.data.map(parsePermissionOptions))
  }, [permissionsStore])

  const userFilters = {
    email: debounceSearchInput,
    tenants: selectedTenants.map((option) => option.value),
    roles: selectedRoles.map((option) => option.value),
    permissions: selectedPermissions,
  }

  const filterList = JSON.stringify(Object.values(userFilters))

  useEffect(() => {
    const filters = getAllUserFilter(userFilters)
    usersGetAllAction(filters)
  }, [filterList])

  useEffect(() => {
    if (exportSelector.success) {
      downloadFile(exportSelector.data, 'jarvis-users')
      sendSuccessNotification(t('SUCCESS_EXPORT'))
    }
    if (exportSelector.error) {
      sendDangerNotification(t('ERROR_EXPORT'))
    }
  }, [exportSelector])

  const handlerChangeInputSearch = ({ target }) => setSearchInput(target.value)
  const handlerChangePage = (_, page) =>
    usersGetAllAction(
      getAllUserFilter({
        email: searchInput,
        tenants: selectedTenants.map((option) => option.value),
        roles: selectedRoles.map((option) => option.value),
        permissions: selectedPermissions,
        page,
      }),
    )
  const handlerDelete = (id) => usersDeleteAction(id)

  const exportUsers = () => {
    usersExportAction({
      email: searchInput.trim(),
      roles: selectedRoles.map((p) => p.value),
      tenants: selectedTenants.map((p) => p.value),
      permissions: selectedPermissions.map((p) => p.value),
    })
  }

  const exportMessage = `${usersStore.pagination.total} ${t('EXPORT_MESSAGE')}`

  const exportButtonIsDisabled = exportSelector.loading || !usersStore.data.length || !hasRead

  useEffect(() => {
    const filteredPermissions = permissionsStore.data.filter((p) =>
      selectedPermissions.map((s) => s.value).includes(p.id),
    )
    setSelectedPermissions(filteredPermissions.map(parsePermissionOptions))
  }, [permissionsStore])

  const tenantNames = (data) =>
    tenantsStore.data.filter((t) => data.tenants?.includes(t.id)).map((filtered) => filtered.name)

  const getMail = (data) => <span>{data.email}</span>
  const getTenants = (data) => <span>{buildBagdesWithTooltip(tenantNames(data), 2, 'bagdes_listing')}</span>
  const getRoles = (data) => <span>{buildBagdesWithTooltip(data.roles, 2, 'bagdes_listing')}</span>
  const getPermissions = (data) => <span>{buildBagdesWithTooltip(data.permissions, 2, 'bagdes_listing')}</span>

  const buttonName = process.env.REACT_APP_RBAC_VERSION !== 'v2' && hasWrite ? 'Edit' : 'View'

  const getOptions = (user) => (
    <ButtonGroup type="small">
      <IconButton
        name={buttonName}
        label={`button-${buttonName.toLowerCase()}`}
        onClick={() => history.push(appendQS(`${ROUTES.USER_VIEW}/${user.email}`))}
      />
      {user.id !== userDetails.accountId && hasWrite && (
        <Menu
          AnchorComponent={IconButton}
          AnchorComponentProps={{ label: 'Delete', name: 'Delete' }}
          contents={[
            {
              content: t('DELETE_WARNING'),
              icon: 'Warning',
              disabled: true,
              key: 101,
            },
            {
              content: (
                <Grid spacing={1}>
                  <Button variant="text">{t('CANCEL')}</Button>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => handlerDelete(user.id)}
                  >
                    {t('CONFIRM')}
                  </Button>
                </Grid>
              ),
              key: 102,
            },
          ]}
        />
      )}
    </ButtonGroup>
  )

  const columns = [
    { key: 'email', content: <Typography variant="H500">{t('EMAIL')}</Typography> },
    { key: 'tenants', content: <Typography variant="H500">{t('TENANTS')}</Typography> },
    { key: 'roles', content: <Typography variant="H500">{t('ROLES')}</Typography> },
    { key: 'permissions', content: <Typography variant="H500">{t('PERMISSIONS')}</Typography> },
    { key: 'joined', content: <Typography variant="H500">{t('JOINED')}</Typography> },
    { key: 'actions', content: '' },
  ]

  const rows = usersStore.error
    ? []
    : usersStore.data.map((user) => ({
      id: user.id,
      email: getMail(user),
      tenants: getTenants(user),
      roles: getRoles(user),
      permissions: getPermissions(user),
      joined: getDateWithLocale(user.createdAt, locale),
      actions: getOptions(user),
    }))

  return (
    <Box>
      <CustomNotification
        title={t('EXPORT_PROGRESS_TITLE')}
        message={t('EXPORT_PROGRESS_MESSAGE')}
        visible={exportSelector.loading}
      />
      <CardContent sx={{ padding: 0 }}>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            lg={3}
          >
            <TextField
              autoFocus
              fullWidth
              label={t('EMAIL')}
              icon="Search"
              onChange={handlerChangeInputSearch}
              value={searchInput}
              placeholder={t('SEARCH_USERS_PLACEHOLDER')}
            />
          </Grid>
          <Grid
            item
            lg={3}
          >
            <Autocomplete
              multiple
              loading={tenantsStore.loading}
              placeholder={t('SEARCH_TENANT')}
              options={tenantOptions}
              value={selectedTenants}
              onChange={(e, option) => {
                setSelectedTenants(option)
              }}
              limitTags={3}
              getOptionDisabled={(option) =>
                selectedTenants.length === limitOptions && !selectedTenants.includes(option)}
              error={selectedTenants.length === limitOptions}
              helpertext={t('SEARCH_TENANT_HINT')}
            />
          </Grid>
          <Grid
            item
            lg={3}
          >
            <Autocomplete
              multiple
              loading={rolesStore.loading}
              placeholder={t('SEARCH_BY_ROLES_PLACEHOLDER')}
              options={roleOptions}
              value={selectedRoles}
              onChange={(e, option) => {
                setSelectedRoles(option)
              }}
              limitTags={3}
              getOptionDisabled={(option) =>
                selectedRoles.length === limitOptions && !selectedRoles.includes(option)}
              error={selectedRoles.length === limitOptions}
              helpertext={t('SEARCH_BY_ROLES_HINT')}
            />
          </Grid>
          <Grid
            item
            lg={3}
          >
            <Autocomplete
              multiple
              loading={permissionsStore.loading}
              placeholder={t('SEARCH_BY_PERMISSIONS_PLACEHOLDER')}
              options={permissionOptions}
              value={selectedPermissions}
              onChange={(e, option) => setSelectedPermissions(option)}
              disabled={selectedRoles.length === 0 || permissionsStore.loading}
              limitTags={3}
              getOptionDisabled={(option) =>
                selectedPermissions.length === limitOptions && !selectedPermissions.includes(option)}
              error={selectedPermissions.length === limitOptions}
              helpertext={t('SEARCH_BY_PERMISSIONS_HINT')}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardContent sx={{ padding: 0, marginTop: 2 }}>
        <Grid
          container
          direction="row-reverse"
          spacing={1}
        >
          <Grid item>
            <Tooltip title={exportMessage}>
              <Button
                variant="outlined"
                onClick={exportUsers}
                startIcon={<Icon name="Export" />}
                disabled={exportButtonIsDisabled}
              >
                {t('EXPORT')}
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<Icon name="Plus" />}
              disabled={!hasWrite}
              onClick={() => history.push(appendQS(ROUTES.USER_CREATE))}
            >
              {t('CREATE_USER')}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent sx={{ padding: 0, marginTop: 3 }}>
        <div style={{ overflowX: 'auto', '@media (max-width: 900px)': { width: '100%' } }}>
          <Table
            columns={columns}
            rows={rows}
            loading={usersStore.loading || tenantsStore.loading}
            emptyMessage={
              usersStore.error
                ? `${t('EXTRA_DESCRIPTION_USER')} ${t(usersStore.errorMessage)} ${t('PLEASE_TRY_AGAIN')}`
                : t('EMPTY_VIEW_MESSAGE_USER')
            }
          />
        </div>
      </CardContent>
      <CardContent>
        {usersStore.data.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <Pagination
              currentpage={usersStore.pagination.page}
              totalpages={usersStore.pagination.totalPages}
              onChange={handlerChangePage}
              color="primary"
            />
          </Box>
        )}
      </CardContent>
    </Box>
  )
}

export default UsersListing
