/* eslint-disable react/jsx-no-useless-fragment */
import { Button, Tooltip, Icon } from '@jarvis-catalyst/jarvis-ui-kit'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { sendDangerNotification, sendSuccessNotification } from '../../utils/sendNotification'
import { CustomNotification } from '../customNotification/CustomNotification'
import downloadFile from '../../utils/downloadFile'
import { RowsStore } from './Table'

type TableExportProps = {
  rowsToExport: RowsStore;
  asyncGetData: () => any;
  className?: string;
  totalRows: number
  exportSelector: any
  exportResetAction: any
}

const TableExport = function ({ rowsToExport, asyncGetData, className, totalRows, exportSelector, exportResetAction }:
                                  TableExportProps) {
  const { t } = useTranslation()

  const classes = ['app__table__export']
  if (className) classes.push(className)

  const exportMessage = `${rowsToExport.list.length > 0 ? rowsToExport.list.length : totalRows} ${t('EXPORT_MESSAGE')}`
  const haveSelectedRows = rowsToExport.list && rowsToExport.list.length > 0
  const exportButtonIsDisabled = exportSelector.loading || !totalRows

  useEffect(() => {
    if (exportSelector.success) {
      downloadFile(exportSelector.data)
      sendSuccessNotification(t('SUCCESS_EXPORT'))
      exportResetAction()
    }
    if (exportSelector.error) {
      sendDangerNotification(t('ERROR_EXPORT'))
      exportResetAction()
    }
  }, [exportSelector])

  return (
    <div
      data-testid="export-table"
      className={classes.join(' ')}
    >
      <CustomNotification
        title={t('EXPORT_PROGRESS_TITLE')}
        message={t('EXPORT_PROGRESS_MESSAGE')}
        visible={exportSelector.loading}
      />
      <div className="app__table__export--selected">
        {`${rowsToExport.list.length} ${t('EXPORT_SELECTED')}`}
      </div>
      <div className="app__table__export-button">
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => { rowsToExport.clear() }}
          disabled={!haveSelectedRows}
        >
          {t('EXPORT_CLEAR')}
        </Button>
        <Tooltip title={exportMessage}>
          <Button
            id="export-button-id"
            color="primary"
            variant="outlined"
            onClick={asyncGetData}
            startIcon={<Icon
              size="medium"
              name="Export"
            />}
            disabled={exportButtonIsDisabled}
          >
            {t('EXPORT')}
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}

export default TableExport
