export const contactUsUrl = 'https://forms.office.com/Pages/ResponsePage.aspx?id=a4ioxEDxi0esRySVVcMK_dpwelMww3FEo-qJToY9QPlURDlNUjFUSU5OODdVSUlVRDhTUkk2NFg0MCQlQCN0PWcu'
export const jarvisConfluenceUrl = 'https://confluence.falabella.com/pages/viewpage.action?pageId=53383542'
export const APIsUrl = 'https://falabella-gitlab.gitlab.io/catalyst/core/pat/development/ms-core/api-documentation/'
export const feedbackProgramUrl = ''
export const searchConfluenceUrl = 'https://confluence.falabella.com/display/Catalyst/Search'
export const searchAPIsUrl = 'https://falabella-gitlab.gitlab.io/catalyst/core/search/development/ms-core/api-documentation/'
export const catalogConfluenceUrl = 'https://confluence.falabella.tech/display/DR/1.+Merchant+Center+Features'
export const catalogAPIsUrl = 'https://falabella-gitlab.gitlab.io/catalyst/core/catalog/discovery/api-definition/redoc/catalog.html'
export const promotionsConfluenceUrl = 'https://confluence.falabella.com/display/Catalyst/Promotions+Definition'
export const promotionsAPIsUrl = 'https://falabella-gitlab.gitlab.io/catalyst/core/promotion/development/ms-core/api-documentation/'
export const linkRequestAccessJarvis = 'https://confluence.falabella.tech/display/PLSRE/Request+Access+to+Jarvis'
export const linkSlack = 'https://falabella.slack.com/archives/CM19DDD0A'
export const linkKnownErrors = 'https://confluence.falabella.com/display/Catalyst/Known+errors'
