import React, { useCallback, useEffect, useState } from 'react'
import { Card, Breadcrumbs, Typography, Grid, Button, Box } from '@jarvis-catalyst/jarvis-ui-kit'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, useParams, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { ROUTES } from '../../../../const'
import { ApplicationPayload } from '../../../../store/modules/applications/types'
import { permissionsByRolesGetAllAction } from '../../../../store/modules/custom-permissions/actions'
import { sendDangerNotification, sendSuccessNotification } from '../../../../utils/sendNotification'
import { ContactInfo } from './ContactInfo'
import './ApplicationManagment.css'
import {
  applicationGetByIdAction,
  applicationCreateAction,
  applicationCreateResetAction,
  applicationUpdateAction,
  applicationUpdateResetAction,
  batchApplicationsCreateResetAction,
  batchApplicationsCreateAction,
  applicationGetByIdResetAction,
} from '../../../../store/modules/applications/actions'
import { TenantsSelector } from './TenantsSelector'
import useAppendQS from '../../../../hooks/useAppendQS'
import { GroupsSelector } from './GroupsSelector'
import { tenantsGetAllAction } from '../../../../store/modules/tenants/actions'
import { getAllTenantsFilter } from '../../../../utils/filters'
import CardMenu from './CardMenu'
import CardGeneral from './CardGeneral'
import CardNavigation from './CardNavigation'
import Cod008FetchAppError from '../../../error/components/cod-008-fetch-app/Cod008FetchAppError'
import CustomTitleAndTooltip from '../../../../components/customTitleAndTooltip/CustomTitleAndTooltip'

export const useAfterCommit = (
  createAppStore: any,
  updateAppStore: any,
  batchCreateAppStore: any,
  selectedTenant: string,
  resetAndNavigateToListing: any,
): void => {
  const { t } = useTranslation()

  const getErrorMessage = (errorMessage?: any): string => {
    if (!errorMessage) return t('MANAGE_CUSTOM_APPLICATION.ERROR')
    if (typeof errorMessage === 'object') {
      if (errorMessage.errors && errorMessage.errors['.route']) return t('MANAGE_CUSTOM_APPLICATION.INVALID_ROUTE')
      if (errorMessage.message && errorMessage.status === 'CONFLICT') {
        return t('MANAGE_CUSTOM_APPLICATION.CONFLICT_ROUTE')
      }
    } else if (errorMessage.includes('CONFLICT')) {
      if (errorMessage.endsWith('name')) return t('MANAGE_CUSTOM_APPLICATION.CONFLICT_NAME')
      if (errorMessage.endsWith('route')) return t('MANAGE_CUSTOM_APPLICATION.CONFLICT_ROUTE')
    }
    return errorMessage
  }

  useEffect(() => {
    if (createAppStore.success || updateAppStore.success) {
      createAppStore.success ? applicationCreateResetAction() : applicationUpdateResetAction()
      createAppStore.success
        ? sendSuccessNotification(t('ACTIONS.CREATED'))
        : sendSuccessNotification(t('ACTIONS.SAVED'))
      resetAndNavigateToListing()
    }

    if (batchCreateAppStore.success && !batchCreateAppStore.loading) {
      batchApplicationsCreateResetAction()
      sendSuccessNotification(
        `${batchCreateAppStore.data?.length} - ${t('MANAGE_CUSTOM_APPLICATION.BATCH_CREATE.CREATED')}`,
      )
      resetAndNavigateToListing()
    }

    if (createAppStore.error || updateAppStore.error) {
      const errorMessage = getErrorMessage(createAppStore.errorMessage || updateAppStore.errorMessage)
      sendDangerNotification(errorMessage)
      applicationCreateResetAction()
      applicationUpdateResetAction()
    }

    if (batchCreateAppStore.error && !batchCreateAppStore.loading) {
      batchApplicationsCreateResetAction()
      batchCreateAppStore.errorData.forEach((d: any) => {
        const errorMessage = getErrorMessage(d)
        sendDangerNotification(errorMessage)
      })
    }
  }, [createAppStore, updateAppStore, batchCreateAppStore, resetAndNavigateToListing])
}

const ApplicationManagment = function () {
  const { t } = useTranslation()
  const appendQS = useAppendQS()
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const location = useLocation<{ selectedTenant: string }>()

  const [app, setApp] = useState<ApplicationPayload>()
  const [selectedTenant, setSelectedTenant] = useState('')
  const [selectedAppId, setSelectedAppId] = useState('')
  const [tenants, setTenants] = useState<any[]>([])
  const [init, setInit] = useState<boolean>(false)

  const createAppStore: any = useSelector((s: any) => s.applications.create)
  const updateAppStore: any = useSelector((s: any) => s.applications.update)
  const batchCreateAppStore: any = useSelector((s: any) => s.applications.batchCreate)

  const appStore: any = useSelector((s: any) => s.applications.getById)
  const tenantsStore: any = useSelector((s: any) => s.tenants.getAll)

  const form = useForm<ApplicationPayload>({
    defaultValues: {
      metadata: { contactInfo: [{ region: '', name: '', email: '', channel: '' }] },
    },
    mode: 'onChange',
  })
  const { handleSubmit, reset } = form
  const onSubmit = (data: ApplicationPayload) => handleSave(data)

  // eslint-disable-next-line
  const getTitle = () =>
    selectedAppId ? t('MANAGE_CUSTOM_APPLICATION.TITLE_EDIT') : t('MANAGE_CUSTOM_APPLICATION.TITLE_ADD')

  useEffect(() => {
    const tenantFromLocation = location.state?.selectedTenant
    if (tenantFromLocation && tenantsStore.success) {
      const validTenant: any = tenantsStore.data.find((tenant: any) => tenant.id === tenantFromLocation)
      if (validTenant) {
        setSelectedTenant(validTenant.id)
      } else {
        history.push(appendQS(ROUTES.APPLICATION_LIST))
      }
    }
  }, [tenantsStore, location])

  useEffect(() => {
    permissionsByRolesGetAllAction({ limit: 100, page: 1 })
    tenantsGetAllAction(getAllTenantsFilter({ page: 1, limit: 100, status: 'active' }))
    if (params?.id) {
      applicationGetByIdAction(params.id)
      setSelectedAppId(params.id)
    } else {
      setInit(true)
    }
  }, [params?.id])

  useEffect(() => {
    if (selectedAppId && appStore.success) {
      if (appStore.data) {
        setApp(appStore.data)
        if (appStore.data.tenantId) {
          const appTenant = tenantsStore.data.filter((tenant: any) => tenant.id === appStore.data.tenantId)
          setTenants(appTenant)
        }
        setInit(true)
      } else {
        history.push(appendQS(ROUTES.APPLICATION_LIST), { selectedTenant })
      }
    }
  }, [selectedAppId, appStore, tenantsStore])

  const resetAndNavigateToListing = () => {
    reset()
    applicationGetByIdResetAction()
    history.push(appendQS(ROUTES.APPLICATION_LIST), { selectedTenant })
  }

  const resetAndNavigateToListingCallback = useCallback(() => {
    resetAndNavigateToListing()
  }, [history, reset, applicationGetByIdResetAction])

  const handleSave = (data: ApplicationPayload) => {
    if (!selectedAppId) {
      tenants.length > 0 ? batchApplicationsCreateAction(data, tenants) : applicationCreateAction(data)
    } else {
      applicationUpdateAction(data, selectedTenant, selectedAppId)
    }
  }

  useAfterCommit(createAppStore, updateAppStore, batchCreateAppStore, selectedTenant, resetAndNavigateToListingCallback)

  const breadcrumbPaths = [
    { id: 'applications', label: t('APPLICATIONS'), url: '/applications' },
    { id: 'manage', label: getTitle(), active: true },
  ]

  return (
    <div style={{ padding: 20 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card variant="elevation">
          <div style={{ marginLeft: 15, marginTop: 4 }}>
            <Breadcrumbs>
              <Link
                style={{ textDecoration: 'none', padding: 0 }}
                to={{ pathname: breadcrumbPaths[0].url }}
              >
                <Typography
                  color="#a7a7a7"
                  variant="h4"
                >
                  {breadcrumbPaths[0].label}
                </Typography>
              </Link>
              <Link
                to={{ pathname: breadcrumbPaths[1].url }}
                style={{ textDecoration: 'none', padding: 0 }}
              >
                <Typography
                  color="primary"
                  variant="h4"
                >
                  {breadcrumbPaths[1].label}
                </Typography>
              </Link>
            </Breadcrumbs>
          </div>
          <CustomTitleAndTooltip
            title={getTitle()}
            tooltipTitle="APPLICATIONS_TOOLTIP"
          />
          {appStore.error ? (
            <Cod008FetchAppError />
          ) : (
            <Box sx={{ padding: '16px 16px 24px' }}>
              <CardGeneral
                form={form}
                app={app}
                init={init}
              />
              <CardNavigation
                form={form}
                app={app}
                init={init}
              />
              <div style={{ display: 'flex' }}>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    sm={6}
                  >
                    <GroupsSelector
                      form={form}
                      groups={app?.groups}
                      init={init}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={6}
                  >
                    <TenantsSelector
                      tenants={tenants}
                      setTenants={setTenants}
                      disabled={!!selectedAppId}
                      init={init}
                    />
                  </Grid>
                </Grid>
              </div>
              <CardMenu
                form={form}
                app={app}
                init={init}
              />
              <ContactInfo
                contacts={app?.metadata?.contactInfo}
                form={form}
                init={init}
              />
              <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 24 }}>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={resetAndNavigateToListing}
                  color="error"
                >
                  {t('CANCEL')}
                </Button>
                {!appStore.error && (
                  <div style={{ marginLeft: 8 }}>
                    <Button
                      size="large"
                      variant="outlined"
                      disabled={!init || appStore.error}
                      onClick={handleSubmit(onSubmit)}
                    >
                      {/* eslint-disable-next-line */}
                      {t('SAVE')}{' '}
                    </Button>
                  </div>
                )}
              </div>
            </Box>
          )}
        </Card>
      </form>
    </div>
  )
}

export default ApplicationManagment
