const objCorePermissionsBase = (permissions: any[]) => {
  const obj: any = {}
  permissions.forEach((cpb: any) => {
    const d = cpb.data
    d.forEach((cp: any) => {
      const k = `${cp.group}.${cp.name}`
      const scopes: string[] = []
      cp.scopes.forEach((s: any) => {
        if (s.selected) scopes.push(s.name)
      })
      if (scopes.length > 0) obj[k] = { scopes }
    })
  })
  return obj
}

export default objCorePermissionsBase
