import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Checkbox, Typography, Box, TextField } from '@jarvis-catalyst/jarvis-ui-kit'
import { RoleDescription } from '../../../../../store/modules/roles/types'

type RoleDefinitionProps = {
  role: RoleDescription
  setRole: any
  hasWrite: boolean
}

const RoleDefinition = function ({ role, setRole, hasWrite }: RoleDefinitionProps) {
  const { t } = useTranslation()

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={8}
      >
        <Grid
          item
          md={5}
        >
          <TextField
            fullWidth
            disabled={!hasWrite}
            label={t('MANAGE_ROLE.TITLE')}
            value={role.title}
            onChange={({ target }: any) => setRole((r: RoleDescription) => ({ ...r, title: target.value }))}
            placeholder={t('MANAGE_ROLE.TITLE_PLACEHOLDER')}
          />
        </Grid>
        <Grid
          item
          md={7}
        >
          <TextField
            fullWidth
            disabled={!hasWrite}
            label={t('MANAGE_ROLE.DESCRIPTION')}
            value={role.description}
            placeholder={t('MANAGE_ROLE.DESCRIPTION_PLACEHOLDER')}
            onChange={({ target }: any) =>
              setRole((r: RoleDescription) => ({
                ...r,
                description: target.value,
              }))}
            sx={{ width: '80%' }}
          />
        </Grid>
      </Grid>
      <hr style={{ border: 'none', height: 0 }} />
      <Box>
        <Typography variant="H300">{t('MANAGE_ROLE.SELECT_SCOPES')}</Typography>
      </Box>
      <Grid
        container
        direction="row"
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={2}
        >
          <Checkbox
            label={t('MANAGE_ROLE.SUBSCRIPTION')}
            value={role.subscriptionScope}
            checked={role.subscriptionScope}
            disabled={!hasWrite}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
              setRole((r: RoleDescription) => ({ ...r, subscriptionScope: checked }))
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={2}
        >
          <Checkbox
            label={t('MANAGE_ROLE.TENANT')}
            disabled={!hasWrite}
            value={role.tenantScope}
            checked={role.tenantScope}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
              setRole((r: RoleDescription) => ({ ...r, tenantScope: checked }))
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={2}
        >
          <Checkbox
            label={t('MANAGE_ROLE.SITE')}
            disabled={!hasWrite}
            value={role.siteScope}
            checked={role.siteScope}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
              setRole((r: RoleDescription) => ({ ...r, siteScope: checked }))
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={2}
        >
          <Checkbox
            label={t('MANAGE_ROLE.SELLER')}
            disabled={!hasWrite}
            value={role.sellerScope}
            checked={role.sellerScope}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
              setRole((r: RoleDescription) => ({ ...r, sellerScope: checked }))
            }}
          />
        </Grid>
      </Grid>

    </>
  )
}

export default RoleDefinition
