import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { sitesBatchAction, sitesBatchResetAction, sitesGetAllAction } from '../../../../store/modules/sites/actions'
import { Site } from '../../../../store/modules/sites/types'
import { sendSuccessNotification } from '../../../../utils/sendNotification'

const siteKeyExist = (sites: any[]) => {
  const arr = sites.map((s: Site) => s.key).filter((e: string) => e !== '')
  const setArr = new Set(arr)
  return arr.length !== setArr.size
}

const siteNameExists = (sites: any[]) => {
  const arr = sites.map((s: Site) => s.name.toUpperCase()).filter((e: string) => e !== '')
  const setArr = new Set(arr)
  return arr.length !== setArr.size
}

export const siteKeyCheck = (text: string) => {
  if (text === '') return false
  if (text.length > 10) return false
  if (text.search(/^[A-Z0-9_]+$/) === -1) return false
  return true
}

export const isSomeItemVoid = (sites: Site[]) =>
  sites.filter((s: Site) => s.key === '' || s.description === '' || s.name === '').length > 0

export const modObjectPropFromArray = (arr: any[], i: number, key: string, value: any) =>
  arr.map((currSite: Site, currI: number) => {
    if (i === currI) return { ...currSite, [key]: value }
    return currSite
  })

const useSiteAdmin = (open: boolean, onClose: () => void, tenant: any) => {
  const { t } = useTranslation()

  const [loadData, setLoadData] = React.useState(false)
  const [sitesDb, setSitesDb] = React.useState<Site[]>([])
  const [sites, setSites] = React.useState<Site[]>([])
  const [siteName, setSiteName] = React.useState<string>('')
  const [siteKey, setSiteKey] = React.useState<string>('')
  const [siteDescription, setSiteDescription] = React.useState<string>('')

  const { loading, error } = useSelector((state: any) => state.sites.batch)
  const { data, success: successGetAll } = useSelector((state: any) => state.sites.getAll)

  const addSite = (tenant: any) => {
    setSites((prev: Site[]) => [
      ...prev,
      {
        tenant: tenant.id,
        name: siteName,
        key: siteKey,
        description: siteDescription,
      },
    ])
    setSiteName('')
    setSiteKey('')
    setSiteDescription('')
  }

  const wrapHandleSiteDescription = (i: number) =>
    ({ target }: any) => {
      setSites((prev: Site[]) => modObjectPropFromArray(prev, i, 'description', target.value))
    }

  const wrapHandleSiteName = (i: number) =>
    ({ target }: any) => {
      setSites((prev: Site[]) => modObjectPropFromArray(prev, i, 'name', target.value))
    }

  const wrapHandleSiteKey = (i: number) =>
    ({ target }: any) => {
      if (!siteKeyCheck(target.value)) return
      setSites((prev: Site[]) => modObjectPropFromArray(prev, i, 'key', target.value))
    }

  const isKeyRepeat = siteKeyExist([...sites, { key: siteKey }])

  const isNameRepeat = siteNameExists([...sites, { name: siteName }])

  const wrapHandleSiteRemove = (i: number) => () => {
    setSites((prev: Site[]) => prev.filter((_: Site, currI: number) => i !== currI))
  }

  const getDeleteSitesIds = (): string[] => {
    const filteredData = sitesDb.filter((sDb: Site) => !sites.find((s: Site) => s.id === sDb.id)) || []
    return filteredData.map((s: Site) => `${s.id}`)
  }

  const getSaveSites = () => sites.filter((s: Site) => !s.id)

  const getUpdateSites = () => sites.filter((s: Site) => s.id)

  React.useEffect(() => {
    if (open && tenant) {
      sitesGetAllAction({ tenant: tenant.id, page: 1, limit: 100 })
      setLoadData(true)
    } else {
      setLoadData(false)
      setSites([])
      setSitesDb([])
    }
  }, [open, tenant])

  const handleSave = () => {
    sitesBatchAction({
      delete: getDeleteSitesIds(),
      create: getSaveSites(),
      update: getUpdateSites(),
    })
  }

  React.useEffect(() => {
    const prevData = data
    if (prevData && successGetAll) {
      setSites(prevData)
      setSitesDb(prevData)
      setLoadData(false)
    }
  }, [data, successGetAll])

  React.useEffect(() => {
    if (loading === false && error.length === 0 && open) {
      sendSuccessNotification(t('ACTIONS.SAVED'))
      sitesBatchResetAction()
      onClose()
    }
  }, [loading, error, onClose, t, open])

  return {
    sites,
    addSite,
    siteName,
    handleSiteName: ({ target }: any) => setSiteName(target.value),
    siteKey,
    handleSiteKey: ({ target }: any) => siteKeyCheck(target.value) && setSiteKey(target.value),
    siteDescription,
    handleSiteDescription: ({ target }: any) => setSiteDescription(target.value),
    isValidSite: () => siteKey !== '' && siteName !== '' && siteDescription !== '' && !isKeyRepeat && !isNameRepeat,
    wrapHandleSiteDescription,
    wrapHandleSiteName,
    wrapHandleSiteKey,
    isKeyRepeat,
    isNameRepeat,
    wrapHandleSiteRemove,
    isSomeItemVoid: () => isSomeItemVoid(sites),
    getDeleteSitesIds,
    handleSave,
    loading: loading || loadData,
    error,
    hasError: error.length > 0,
  }
}

export default useSiteAdmin
