import React from 'react'
import { GridItem } from '@jarvis-catalyst/ui-components'
import { useSelector } from 'react-redux'

type ContentProps = {
    children: React.ReactNode;
}

const Content = function ({ children }: ContentProps) {
  const { uiSidebar } = useSelector((s: any) => s.application)
  const sideBar = () => {
    if (uiSidebar === 'full') return { columnStart: 5, colSpan: 20, className: 'layout__content__withSidebar' }
    if (uiSidebar === 'compact') return { columnStart: 2, colSpan: 23, className: 'layout__content__compactSidebar' }
    return { columnStart: 1, colSpan: 24, className: 'layout__content' }
  }

  const { colSpan, columnStart, className } = sideBar()

  return (
    <GridItem
      columnStart={columnStart}
      colSpan={colSpan}
      className={className}
    >
      {children}
    </GridItem>
  )
}

export default Content
