import { sendEvent } from './events'
import getPermissions from '../../../../utils/user-permissions/getPermissions'

type JarvisEvent = {
    type: string;
    data: any;
}

const dispatchEvent = (event: JarvisEvent) => {
  const iframe = <HTMLIFrameElement>document.getElementById('extension-iframe')
  if (!iframe || !iframe.src) {
    return
  }
  if (iframe.src) sendEvent(iframe, event.type, event.data, iframe.src)
}

/**
 * @name userPermissionsChanged
 * @returns {undefined}
 * @example
 * // This event send a dictionary with permissions by subscription, tenant, site and seller
 * {
    "eventId": "SOME-UUID",
    "eventType": "userPermissionsChanged",
    "data": {
        "subscription": {},
        "tenant": {},
        "site": {},
        "seller": {},
        }
    }
 */
export const sendUserPermissions = () => {
  const userPermissions = getPermissions()
  dispatchEvent({
    type: 'userPermissionsChanged',
    data: userPermissions.data,
  })
}
