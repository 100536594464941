import React from 'react'
import { Box } from '@jarvis-catalyst/jarvis-ui-kit'

interface Props {
  id?: string
  component?: React.ElementType
  children: React.ReactNode
  className?: string
  sx?: Record<string, string | number>
}

const CustomBox: React.FC<Props> = function ({ id, component, className, children, sx }: Props) {
  return (
    <Box
      sx={{
        border: '3px solid #f3f3f3',
        borderRadius: 1.5,
        padding: '25px 25px 25px 25px',
        marginBottom: 2.5,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...sx,
      }}
      id={id}
      component={component}
      className={className}
    >
      {children}
    </Box>
  )
}

export default CustomBox
