import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RowsStore, Row } from './Table'
import ErrorNotification from '../errorNotification/ErrorNotification'

interface Props {
		rows: Row[];
		columnLength: number;
		emptyMessage?: string;
		errorMessage?: string;
		setSelected?: (ids: string[]) => void;
		selected?: string[];
		disabled: boolean;
		isLoading: boolean;
    selectedRowsStore?: RowsStore;
}

const TableBody: React.FC<Props> = function ({
  rows,
  columnLength,
  emptyMessage = '',
  errorMessage,
  setSelected,
  selected,
  disabled,
  isLoading,
  selectedRowsStore,
}: Props) {
  const { t } = useTranslation()
  const [isChecked, setChecked] = useState(Array(rows.length).fill(false))

  useEffect(() => {
    setChecked(rows.map((x) => selected?.includes(x.key)))
  }, [selected, rows])

  const handleCheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const temp = [...isChecked]
    temp[index] = e.target.checked
    setChecked(temp)
    setSelected
				&& setSelected(rows.filter((x, i) => temp[i]).map((row) => row.key))

    if (temp[index]) {
      selectedRowsStore && selectedRowsStore.add(rows[index].key)
    } else {
      selectedRowsStore && selectedRowsStore.remove(rows[index].key)
    }
  }

  const getErrorMessage = () => {
    if (errorMessage) {
      return errorMessage
    }
    if (emptyMessage) {
      return emptyMessage
    }
    return t('EMPTY_VIEW_MESSAGE')
  }

  return (
    <tbody>
      {rows.length > 0 ? (
        rows.map((row, index) => (
          <tr
            key={row.key}
            className={row.className}
          >
            {selected && row.hasCheckbox && (
              <td>
                <input
                  type="checkbox"
                  aria-label={row.key}
                  disabled={disabled}
                  checked={selected?.includes(row.key)}
                  onChange={(e) => handleCheck(e, index)}
                />
              </td>
            )}
            {row.cells.map((cell) => (
              <td
                key={cell.key}
                colSpan={(row.hasCheckbox || !cell.colSpan) ? (cell.colSpan) : (cell.colSpan + 1)}
              >
                {cell.content}
              </td>
            ))}
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={columnLength}>
            { !isLoading && <ErrorNotification errorMessage={getErrorMessage()} /> }
          </td>
        </tr>
      )}
    </tbody>
  )
}

export default TableBody
