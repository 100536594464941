import { combineReducers } from 'redux'
import { createGetAllReducer, createCommitReducer } from '../../utils'

export const getAllGroupsStore = createGetAllReducer('GROUPS')
export const deleteGroupStore = createCommitReducer('GROUPS', 'DELETE')
export const createGroupStore = createCommitReducer('GROUPS', 'CREATE')
export const updateGroupStore = createCommitReducer('GROUPS', 'UPDATE')

const rootReducer = combineReducers({
  getAll: getAllGroupsStore.reducer,
  delete: deleteGroupStore.reducer,
  create: createGroupStore.reducer,
  update: updateGroupStore.reducer,
})

export default rootReducer
