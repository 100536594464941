import { format as formatFns, formatDistance, parse } from 'date-fns'
import { es, enUS } from 'date-fns/locale'

const Locale = {
  es,
  en: enUS,
}

const DateFormat = {
  es: 'yyyy/MM/dd HH:mm:ss',
  en: 'MM/dd/yyyy pp',
}

export const getDateWithLocale = (date: string, locale: string): any => new Date(date).toLocaleDateString(locale, {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
})

export const format = (date: any, locale: 'es' | 'en'): any => formatFns(new Date(date), DateFormat[locale], { locale: Locale[locale] })

export const formatDate = (date: Date, locale: 'es' | 'en'): any => formatFns(date, DateFormat[locale].split(' ')[0], { locale: Locale[locale] })

export const formatDistanceFromNow = (date: any, locale: 'es' | 'en'): any => formatDistance(new Date(date), new Date(), { locale: Locale[locale] })

export const parseDate = (date: string, locale: 'en' | 'es'): any => parse(date, DateFormat[locale], new Date(), { locale: Locale[locale] })
