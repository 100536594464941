import React from 'react'

type SidebarFooterProps = {
    children?: React.ReactNode;
}

const SidebarFooter = function ({ children }: SidebarFooterProps) {
  return (
    <div className="layout__sidebar__footer">
      {children}
    </div>
  )
}

export default SidebarFooter
