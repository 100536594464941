import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Icon, Modal, Typography } from '@jarvis-catalyst/jarvis-ui-kit'

interface IconSelectorModalProps {
  onClose: () => void
  iconList: string[]
  setSelectedIcon: (s: any) => void
}

const renderIcon = (id: any, icon: any, click: (s: string) => void) => (
  <span
    data-testid={`icon-selector-${id}`}
    key={id}
    onClick={() => click(icon)}
    style={{ padding: 5, marginLeft: 3, marginRight: 3 }}
  >
    <Icon
      name={icon}
      size="large"
      color="var(--c-primary)"
    />
  </span>
)

const IconSelectorModal = function ({ onClose, iconList, setSelectedIcon }: IconSelectorModalProps) {
  const { t } = useTranslation()

  return (
    <Modal
      open
      onClose={onClose}
    >
      <div className="application_mgmt--icon-selector-modal">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="H500">{t('MANAGE_CUSTOM_APPLICATION.ICON_SELECTOR_TITLE')}</Typography>
          <a
            data-testid="close-modal"
            className="close"
            onClick={() => onClose()}
            style={{ marginLeft: 5 }}
          >
            <Icon
              name="Close"
              size="medium"
            />
          </a>
        </div>
        <Divider variant="middle" />
        <Box sx={{ marginTop: 3 }}>{iconList && iconList.map((name, i) => renderIcon(i, name, setSelectedIcon))}</Box>
      </div>
    </Modal>
  )
}

export default IconSelectorModal
