import { useRef } from 'react'
import { Autocomplete, Typography, Grid } from '@jarvis-catalyst/jarvis-ui-kit'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import isEmpty from 'lodash.isempty'
import { TenantWithRoleProps, Option } from './manage-user.type'

const TenantWithRole = function ({
  id,
  tenants,
  data,
  roles,
  existingTenants,
  onTenantSelect,
  hasWrite,
  type = 'TENANT',
  index,
  control,
  rolesError,
  onChangeTenant,
}: TenantWithRoleProps) {
  const { t } = useTranslation()
  const fieldName = type === 'SITES' ? 'siteRoles' : 'sellerRoles'
  const refRole = useRef<Option[]>([])

  const isIdSelected = () => {
    if (type === 'TENANT' && existingTenants) {
      if (existingTenants[index]?.roles) {
        if (existingTenants[index].roles.length === 0) {
          refRole && refRole.current.length > 0 && refRole.current.forEach((value) => {
            existingTenants[index].roles.push(value)
          })
        }
      }

      return !isEmpty(existingTenants[index]?.id?.value)
    }

    return !isEmpty(id[0]?.value)
  }

  const isRolesSelected = () => {
    if (type === 'TENANT' && existingTenants) {
      return existingTenants[index]?.roles && !isEmpty(existingTenants[index].roles[0]?.value)
    }

    return !isEmpty(roles[0]?.value)
  }

  const errorMessage = (error: any, field: 'id' | 'roles') => {
    const message = `${t('HINT_REQUIRED_FIELD')}: ${t(type)} ${field}`
    if (type === 'TENANT') {
      return error?.[field] ? message : ''
    }
    if (error?.[fieldName]?.[field]) {
      return message
    }
    return undefined
  }
  return (
    <Grid
      container
      spacing={2}
      columns={12}
      key={index}
    >
      <Grid
        item
        lg={3}
      >
        <Controller
          key={id}
          name={type === 'TENANT' ? `tenantRoles.${index}.id` : `tenantRoles.${index}.${fieldName}.id`}
          control={control}
          defaultValue={id}
          rules={{ required: isRolesSelected() }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <Autocomplete
              multiple={type !== 'TENANT'}
              filterSelectedOptions={type === 'TENANT'}
              value={value}
              placeholder={t(`${type}`)}
              onChange={(e, option) => {
                onChangeTenant && onChangeTenant(value as Option || { value: '', label: '' }, index)
                onChange(option)
                onTenantSelect && option && onTenantSelect(option as Option, index)
              }}
              options={type === 'TENANT' ? [...tenants, { label: '', value: '' }] : tenants}
              disabled={!hasWrite}
              getOptionDisabled={(option) => option.value === ''}
              error={errors.tenantRoles && !!errorMessage(errors.tenantRoles[index], 'id')}
              helpertext={errors.tenantRoles && errorMessage(errors.tenantRoles[index], 'id')}
            />
          )}
        />
      </Grid>
      <Grid
        item
        lg={8}
      >
        <Controller
          name={type === 'TENANT' ? `tenantRoles.${index}.roles` : `tenantRoles.${index}.${fieldName}.roles`}
          control={control}
          key={id}
          rules={{ required: isIdSelected() }}
          render={({ field: { onChange, value }, formState: { errors } }) => (
            <Autocomplete
              key={`tenantRoles.${index}.roles`}
              multiple
              placeholder={t(`MANAGE_USER.ROLES_BY_${type}`)}
              value={value}
              onChange={(e, option) => {
                refRole.current = option as Option[]
                onChange(option)
              }}
              options={data}
              disabled={!hasWrite}
              error={errors?.tenantRoles && !!errorMessage(errors.tenantRoles[index], 'roles')}
              helpertext={errors.tenantRoles && errorMessage(errors.tenantRoles[index], 'roles')}
            />
          )}
        />
        {rolesError && <Typography
          variant="h4"
          color="red"
        >
          {rolesError}
        </Typography>}
      </Grid>
    </Grid>
  )
}

export default TenantWithRole
