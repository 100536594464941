import lodash from 'lodash'

interface RbacLog {
  id: any;
  email: any;
  roles: { subscription: any[], tenants: any[], sites?: any[], sellers?: any[] }
}

type RbacEntity = {
  id: string,
  name:string,
}

const parseRoles = (roles: any) => roles.map((role: any) => role.name).sort()

const parseData = (data: any, obj: RbacEntity[]) => {
  if (!data) return []

  const result = data.map((e: any) => ({
    id: e.id,
    name: obj.find((o) => o.id === e.id)?.name,
    roles: parseRoles(e.roles),
  }))

  return lodash.sortBy(result, ['name'])
}

export const parseRbacLogs = (state: RbacLog, tenants: RbacEntity[], sites: RbacEntity[], sellers: RbacEntity[]) => ({
  id: state.id,
  email: state.email,
  subscriptionRoles: parseRoles(state.roles.subscription),
  tenants: parseData(state.roles.tenants, tenants),
  sites: parseData(state.roles.sites, sites),
  sellers: parseData(state.roles.sellers, sellers),
})
