import { combineReducers } from 'redux'

import { createGetAllReducer, createGetReducer, createCommitReducer } from '../../utils'

export const getAllSitesStore = createGetAllReducer('SITES')
export const getSitesStore = createGetReducer('SITES')
export const createSitesStore = createCommitReducer('SITES', 'CREATE')
export const updateSitesStore = createCommitReducer('SITES', 'UPDATE')
export const deleteSitesStore = createCommitReducer('SITES', 'DELETE')

export const SITE_BATCH = {
  SITE_BATCH_ADD: 'SITES_BATCH_ADD',
  SITES_BATCH_ERROR_ADD: 'SITES_BATCH_ERROR_ADD',
  SITES_BATCH_START: 'SITES_BATCH_START',
  SITES_BATCH_END: 'SITES_BATCH_END',
  SITES_BATCH_RESET: 'SITES_BATCH_RESET',
}

export const initialState = {
  status: null,
  data: [],
  error: [],
  loading: null,
}

export const batchReducer = (prevData = initialState, action: any) => {
  switch (action.type) {
  case SITE_BATCH.SITE_BATCH_ADD:
    return {
      ...prevData,
      data: [...prevData.data, action.payload],
      status: 'PROCESS',
    }
  case SITE_BATCH.SITES_BATCH_ERROR_ADD:
    return {
      ...prevData,
      error: [...prevData.error, action.payload],
      status: 'PROCESS',
    }
  case SITE_BATCH.SITES_BATCH_START:
    return {
      ...prevData,
      status: 'START',
      data: [],
      error: [],
      loading: true,
    }
  case SITE_BATCH.SITES_BATCH_END:
    return {
      ...prevData,
      status: 'END',
      data: prevData.data,
      error: prevData.error,
      loading: false,
    }
  case SITE_BATCH.SITES_BATCH_RESET:
    return {
      ...initialState,
    }
  default:
    return prevData
  }
}

const rootReducer = combineReducers({
  getById: getSitesStore.reducer,
  getAll: getAllSitesStore.reducer,
  create: createSitesStore.reducer,
  update: updateSitesStore.reducer,
  delete: deleteSitesStore.reducer,
  batch: batchReducer,
})

export default rootReducer
