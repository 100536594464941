import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, IconChevronDown, IconLoading, IconShoppingCart } from '@jarvis-catalyst/ui-components'
import { Seller } from '../../../store/modules/sellers/types'
import SelectSellerModal from './SelectSellerModal'
import { Tenant } from './TenantSelector'
import { applicationSetConfigurationAction } from '../../../store/modules/application/actions'
import { TENANT_SITE_KEY } from '../../../utils/cache-store/types'
import { userPermissionsGetAction } from '../../../store/modules/user-permissions/actions'
import cacheStore from '../../../utils/cache-store'

type SellerSellectorProps = {
  init: boolean
  tenant: Tenant
}

const SellerSellector = function ({ init, tenant }: SellerSellectorProps) {
  const { t } = useTranslation()
  const history = useHistory()
  const { activeTenant } = useSelector((s: any) => s.application)
  const { data: dataSellers, loading, error } = useSelector((state: any) => state.sellers.getAll)
  const [showDialog, setShowDialog] = useState(false)
  const [sellerSelected, setSellerSelected] = React.useState<Seller[]>(
    activeTenant?.sellers ? activeTenant.sellers : [],
  )

  const getSelectedSeller = () => (activeTenant.sellers?.length ? activeTenant.sellers.map((e: Seller) => e.name) : [])

  const handleConfirmSeller = (sellers: Seller[]) => {
    if (init) {
      const newActiveTenant = { ...activeTenant, sellers }
      const query = new URLSearchParams(window.location.search)
      query.set('seller', sellerSelected[0]?.key || '')
      applicationSetConfigurationAction({
        key: 'activeTenant',
        value: newActiveTenant,
      })
      history.push({ search: query.toString() })
      cacheStore(TENANT_SITE_KEY).setData(newActiveTenant)
      userPermissionsGetAction()
      setShowDialog(false)
    }
  }

  return (
    <>
      {dataSellers.length === 0 && (
        <Button
          id="menu__dropdown-seller"
          className="screen-md-view-menu__dropdown"
          color="dark"
          iconLeftRotate={loading}
          iconLeft={loading ? <IconLoading /> : <IconShoppingCart />}
          disabled
        >
          {t('MANAGE_SELLER.WITHOUT_SELLER')}
        </Button>
      )}
      {dataSellers.length > 0 && (
        <Button
          id="menu__dropdown-seller"
          className="screen-md-view-menu__dropdown"
          title={getSelectedSeller().length > 0 ? getSelectedSeller().join(', ') : t('MANAGE_SELLER.SELLER_DEFAULT')}
          iconLeftRotate={loading}
          iconLeft={loading ? <IconLoading /> : <IconShoppingCart />}
          iconRight={getSelectedSeller().length === 0 && <IconChevronDown />}
          color="dark"
          disabled={loading}
          onClick={() => !loading && setShowDialog(true)}
        >
          {getSelectedSeller().length > 0 ? getSelectedSeller().join(', ') : t('MANAGE_SELLER.SELLER_DEFAULT')}
        </Button>
      )}
      {dataSellers.length > 0 && showDialog && (
        <SelectSellerModal
          data={dataSellers}
          error={error}
          sellerSelected={sellerSelected}
          setSellerSelected={setSellerSelected}
          tenant={tenant}
          onCancel={() => setShowDialog(false)}
          onConfirm={handleConfirmSeller}
          isOpen={showDialog}
        />
      )}
    </>
  )
}

export default SellerSellector
