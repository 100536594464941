/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react'
import { Spinner } from '@jarvis-catalyst/ui-components'
import useEventsContext from '../../contexts/EventContext'
import './CustomApplication.css'
import useInitialUriCustomApp from '../../../../utils/useInitialUriCustomApp'
import Cod008FetchAppError from '../../../error/components/cod-008-fetch-app/Cod008FetchAppError'
import getCoreApplication from '../../../../utils/getCoreApplication'

const ERROR = 404
const SUCCESS = 200

const CustomApplication = function () {
  const ref = React.useRef()
  const initialUri = useInitialUriCustomApp()
  const application = getCoreApplication()
  const { isLoaded, setIsLoaded } = useEventsContext()
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const fetchApplication = async () => {
      try {
        const { status } = await fetch(application.url)
        return status
      } catch {
        return { status: ERROR }
      }
    }

    fetchApplication().then((status) => {
      setIsError(status !== SUCCESS)
    })
  }, [application.url])

  useEffect(() => {
    setIsLoaded(false)
  }, [application.route])

  const getIframeUrl = () => {
    if (!application.initAppWithRoute && application.submenu?.length) {
      const submenuRoute = initialUri.split('/')[2]
      return submenuRoute ? `${application.url}/${submenuRoute}` : application.url
    }
    if (application.initAppWithRoute) {
      return application.url + initialUri
    }
    return application.url
  }

  return (
    <div className="custom-app-wrapper">
      <Spinner isCompleting={isLoaded || isError} />
      {!isLoaded && isError ? (
        <Cod008FetchAppError />
      ) : application.navigationType === 'IFRAME' ? (
        <iframe
          ref={ref}
          title={application.name.en}
          sandbox="allow-forms allow-scripts allow-same-origin allow-downloads allow-modals allow-popups allow-popups-to-escape-sandbox"
          allow="clipboard-read; clipboard-write"
          src={getIframeUrl()}
          className="custom-app"
          id="extension-iframe"
        />
      ) : (
        application.url
      )}
    </div>
  )
}

export default CustomApplication
