import React from 'react'
import { ROUTES } from '../../const'
import HeaderLanding from './header/HeaderLanding'

type NavbarProps = {
    children: React.ReactNode;
    route?: string
}

const Navbar = function ({ children, route }: NavbarProps) {
  const isLandingPage = route?.startsWith(ROUTES.GLOBAL_APPS)

  return (
    <div className="layout__navbar grid-template__12-columns">

      {isLandingPage ? <HeaderLanding /> : children}
      <span className="layout-modal-overlay" />
    </div>

  )
}

export default Navbar
