import ApiError from '../../../utils/ApiError'

const getHeaders = (token: string) => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
})

export const get = async (url: string, token: string) => {
  try {
    const response = await fetch(url, { headers: getHeaders(token) })
    if (response.status === 200) {
      const data = await response.json()
      return Promise.resolve(data)
    }
    return Promise.reject(
      new ApiError({
        message: 'GENERIC_ERROR_MESSAGE',
        property: '',
      }),
    )
  } catch (err) {
    return Promise.reject(
      new ApiError({
        message: 'GENERIC_ERROR_MESSAGE',
        property: '',
      }),
    )
  }
}

export const post = async (url: string, body: any, token: string) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaders(token),
      body: JSON.stringify(body),
    })

    if (response.status === 201) {
      return Promise.resolve()
    }
    if (response.status === 500) {
      return Promise.reject(
        new ApiError({
          message: 'INTERNAL_SERVER_ERROR',
          property: '',
        }),
      )
    }
    const error = await response.json()
    return Promise.reject(
      new ApiError({
        message: error.status,
        property: Object.keys(error.errors)[0],
      }),
    )
  } catch (err) {
    return Promise.reject(
      new ApiError({
        message: 'GENERIC_ERROR_MESSAGE',
        property: '',
      }),
    )
  }
}

export const patch = async (url: string, body: any, token: string) => {
  try {
    const response = await fetch(url, {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: getHeaders(token),
    })

    if (response.status === 200) {
      return Promise.resolve()
    }
    if (response.status === 500) {
      return Promise.reject(
        new ApiError({
          message: 'INTERNAL_SERVER_ERROR',
          property: '',
        }),
      )
    }
    const error = await response.json()
    return Promise.reject(
      new ApiError({
        message: error.status,
        property: Object.keys(error.errors)[0],
      }),
    )
  } catch (err) {
    return Promise.reject(
      new ApiError({
        message: 'GENERIC_ERROR_MESSAGE',
        property: '',
      }),
    )
  }
}

export const remove = async (url: string, token: string) => {
  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${token}` },
    })
    if (response.status === 204) {
      return Promise.resolve({})
    }
    if (response.status === 500) {
      return Promise.reject(
        new ApiError({
          message: 'INTERNAL_SERVER_ERROR',
          property: '',
        }),
      )
    }
    const error = await response.json()
    return Promise.reject(new ApiError({ message: error.status, property: '' }))
  } catch (err) {
    return Promise.reject(
      new ApiError({
        message: 'GENERIC_ERROR_MESSAGE',
        property: '',
      }),
    )
  }
}
