import React from 'react'
import { IconChevronDown, IconChevronUp } from '@jarvis-catalyst/ui-components'
import { Item } from './utils/menu'

type Props = {
    item: Item
    currentPage: string
}

const ExpandIcon = function ({ item, currentPage }: Props) {
  const isCollapsed = item.submenu?.every((sub) => sub.hidden && sub.route !== currentPage)

  return (
    <button
      type="button"
      className="layout__submenu__button"
      onClick={() => item.submenu?.map((s) => s.hidden = !isCollapsed)}
    >
      {isCollapsed ? <IconChevronDown title="expand" /> : <IconChevronUp title="collapse" />}
    </button>
  )
}

export default ExpandIcon
