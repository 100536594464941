import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography, Box } from '@jarvis-catalyst/jarvis-ui-kit'
import CustomBox from '../../../../components/custombox/custombox'
import AppModalInfo from '../appmodalinfo/AppModalInfo'
import { AppInfoProps } from '../../dashboard.types'

const AppBoxInfo = function ({ title, description, links, icon }: AppInfoProps) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <CustomBox sx={{ height: '100%', display: 'flex', justifyContent: 'space-between', padding: 2 }}>
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          xs={3}
        >
          <Box
            sx={{
              display: 'flex',
              padding: '10px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
              backgroundColor: 'rgba(234, 236, 254, 1)',
              marginTop: '3px',
              height: '60%',
            }}
          >
            {icon}
          </Box>
        </Grid>
        <Grid
          item
          xs={9}
        >
          <div
            onClick={() => setOpen(!open)}
            style={{ cursor: 'pointer' }}
          >
            <Box sx={{ paddingLeft: 0.5 }}>
              <Typography variant="H700">{t(title)}</Typography>
            </Box>
            <Box sx={{ marginTop: '4px', height: '60px', paddingLeft: '5px' }}>
              <Typography
                variant="h4"
                paragraph
              >
                {`${t(description)?.substring(0, 40)}...`}
              </Typography>
            </Box>
            <AppModalInfo
              open={open}
              description={description}
              links={links}
              icon={icon}
              title={title}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            {links.length > 0 && (
              <a
                style={{ padding: 0, color: 'rgba(117, 105, 244, 1)', cursor: 'pointer', textDecoration: 'none' }}
                href={links[0].url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography
                  color="primary"
                  variant="H300"
                >
                  {t(`${links[0].title}`)}
                </Typography>
              </a>
            )}
          </Box>
        </Grid>
      </Grid>
    </CustomBox>
  )
}

export default AppBoxInfo
