import React, { useState } from 'react'
import { Col, Row } from '@jarvis-catalyst/ui-components'
import { useTranslation } from 'react-i18next'
import './TenantSiteViewer.css'
import { useSelector } from 'react-redux'
import { TenantSiteCard } from '../tenantSiteCard/TenantSiteCard'
import TenantSiteSelector from '../tenantSelector/TenantSiteSelector'
import { Site } from '../../../../store/modules/sites/types'

const TenantSiteViewer = function () {
  const { t } = useTranslation()
  const [selectedTenant, setSelectedTenant] = useState<any>()
  const [selectedSites, setSelectedSites] = useState([])
  const { activeTenant, userHasTenant } = useSelector((s: any) => s.application)
  const [isSelectorOpen, setSelectorOpen] = useState(false)

  React.useEffect(() => {
    if (activeTenant && userHasTenant) {
      setSelectedTenant({ id: activeTenant.id, name: activeTenant.name })
      setSelectedSites(activeTenant.sites.map((site: Site) => ({ id: site.id, name: site.name })))
    }
  }, [activeTenant])

  return (
    <Row className="tenant-site-viewer">
      {!selectedSites
        && <Col
          xs={12}
          lg={5}
          xl={2}
        >
          <TenantSiteCard>
            <div className="vertically-centered">
              <p>
                {t('GLOBAL_APPS.TENANT_SITE_SELECTOR.TITLE_EMPTY_1')}
                {' '}
                <strong>{t('GLOBAL_APPS.TENANT_SITE_SELECTOR.TENANT')}</strong>
                {' '}
                {t('GLOBAL_APPS.TENANT_SITE_SELECTOR.TITLE_EMPTY_2')}
                {' '}
                <strong>{t('GLOBAL_APPS.TENANT_SITE_SELECTOR.SITE')}</strong>
              </p>
            </div>
          </TenantSiteCard>
        </Col>}
      <Col
        xs={6}
        xl={4}
      >
        <TenantSiteCard
          tenant={selectedTenant}
          sites={selectedSites.map((s: Site) => s.name).join(', ')}
          onClick={() => setSelectorOpen(true)}
        />
      </Col>
      <TenantSiteSelector
        isOpen={isSelectorOpen}
        setOpen={setSelectorOpen}
        tenant={selectedTenant}
        site={selectedSites[0]}
      />
    </Row>
  )
}

export { TenantSiteViewer }
