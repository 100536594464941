import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Input, Grid, Icon, GridItem } from '@jarvis-catalyst/ui-components'
import { myTenantsGetAction } from '../../../../store/modules/tenants/actions'
import './TenantSiteSelector.css'
import { TenantSiteCard } from '../tenantSiteCard/TenantSiteCard'
import ErrorComponent from '../error/ErrorComponent'
import { Tenant } from '../../../../store/modules/tenants/types'
import TenantSiteTable from '../tenantSiteListing/TenantSiteTable'
import { linkRequestAccessJarvis } from '../../../dashboard/externalLinks'

type TenantsListingProps = {
    selectedTenant?: Tenant
    setSelectedTenant: (d: Tenant) => void
}

const TenantsListing = function ({ selectedTenant, setSelectedTenant }: TenantsListingProps) {
  const { t } = useTranslation()
  const { getMyTenants: { data, loading, error, errorMessage } } = useSelector((s: any) => s.tenants)
  const [tenants, setTenants] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (!loading && !error) {
      data.length === 0 ? myTenantsGetAction() : setTenants(data)
    }
  }, [loading, error, data])

  useEffect(() => {
    const filteredData = data.filter((t: any) => t.name.toLowerCase().startsWith(search.toLowerCase()))
    setTenants(filteredData)
  }, [search])

  const errorIcon = (
    <div className="tenant-site-selector error__icon__tenant">
      <Icon
        name="ProjectManagement"
        color="#8B97AD"
        size={32}
      />
      <Icon
        name="Warning"
        color="#E20342"
        size={16}
      />
    </div>
  )

  const accessRequestMessage = (
    <>
      {t('GLOBAL_APPS.TENANT_SITE_SELECTOR.TENANT_EMPTY_ERROR_MESSAGE')}
      <a
        href={linkRequestAccessJarvis}
        target="_blank"
        rel="noreferrer noopener"
      >
        {t('GLOBAL_APPS.TENANT_SITE_SELECTOR.TENANT_EMPTY_ERROR_LINK')}
      </a>
    </>
  )

  return (
    <Grid
      repeatTemplate="minmax(20px, 1fr)"
      repeatNumber={12}
      gap={10}
      className="tenant-site-selector grid"
    >
      <GridItem
        columnStart={1}
        colSpan={6}
      >
        <TenantSiteCard tenant={data.find((t: any) => t.id === selectedTenant?.id)} />
      </GridItem>
      <GridItem
        columnStart={7}
        colSpan={6}
      >
        <Input
          icon="search"
          placeholder={t('GLOBAL_APPS.TENANT_SITE_SELECTOR.SEARCH_TENANT_PLACEHOLDER')}
          value={search}
          onChange={(e: any) => setSearch(e.target.value)}
        />
      </GridItem>
      <GridItem
        columnStart={1}
        colSpan={12}
        className="tenant-site-selector grid_content"
      >
        {error ? (
          <ErrorComponent
            icon={<Icon
              name="Jarvis"
              size={48}
              color="#D41A47"
            />}
            title={errorMessage}
            text={t('GLOBAL_APPS.TENANT_SITE_SELECTOR.ERROR_MESSAGE')}
          />
        ) : tenants.length === 0 && !loading ? (
          <ErrorComponent
            icon={errorIcon}
            title={t('GLOBAL_APPS.TENANT_SITE_SELECTOR.TENANT_EMPTY_ERROR')}
            text={accessRequestMessage}
          />
        ) : (
          <TenantSiteTable
            selected={selectedTenant?.id}
            data={tenants}
            loading={loading}
            setSelected={setSelectedTenant}
          />
        )}
      </GridItem>
    </Grid>
  )
}

export default TenantsListing
