/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from 'react'
import isEmpty from 'lodash.isempty'
import jwtDecode from 'jwt-decode'
import { useAuth0 } from '../../../utils/third-party/react-auth0-wrapper'
import config from '../config'
import { applicationSetConfigurationAction } from '../../../store/modules/application/actions'
import parseJWT from '../../../utils/parseJWT'
import dictionary from '../../error/dictionary'

export const hasError = (search) => search.includes('error=unauthorized')

export const useAuthentication = ({ history }) => {
  const {
    isAuthenticated,
    loginWithRedirect,
    getTokenSilently,
    loading,
    logout,
    getIdTokenClaims,
    accessDenied,
  } = useAuth0()
  const [token, setToken] = useState('')
  const [userDetails, setUserDetails] = useState({ name: '', email: '' })
  const [authorizationError, setAuthorizationError] = useState('')

  const logoutToLogin = async () => {
    await logout({ returnTo: config.REACT_APP_LOGOUT_REDIRECT_URL })
  }

  useEffect(() => {
    async function login() {
      if (!loading && isEmpty(authorizationError)) {
        try {
          await loginWithRedirect({})
        } catch (_) { } // eslint-disable-line
      }
    }

    const getToken = async () => {
      const newToken = await getTokenSilently()
      const idClaims = await getIdTokenClaims()
      const jwtDecode = parseJWT(newToken)
      idClaims.accountId = jwtDecode['https://auth.osmos.services/sub']

      applicationSetConfigurationAction({ key: 'claims', value: idClaims })
      applicationSetConfigurationAction({ key: 'token', value: newToken })
      setToken(newToken)
      setUserDetails(idClaims)
    }

    if (hasError(history.location.search)) {
      setAuthorizationError('PERMISSION_ERROR_MESSAGE')
      applicationSetConfigurationAction({ key: 'errors', value: [dictionary.COD_006_USER_HAS_NO_ACCESS_TO_SUBSCRIPTION] })
    } else if (accessDenied) {
      setAuthorizationError('ACCESS_DENIED_ERROR_MESSAGE')
    } else if (isAuthenticated) {
      getToken()
    } else {
      login()
    }
  }, [
    loading,
    isAuthenticated,
    loginWithRedirect,
    getTokenSilently,
    getIdTokenClaims,
    authorizationError,
    history,
    accessDenied,
  ])

  const isAdmin = (() => {
    if (!token) return false
    const decodedToken = jwtDecode(token)
    const identityKey = Object.keys(decodedToken).find((k) => k.indexOf('/isAdmin') !== -1)
    return decodedToken[identityKey]
  })()

  return {
    authorizationError,
    token,
    isAuthenticated,
    loading,
    logoutToLogin,
    userDetails,
    isAdmin,
  }
}
