import React from 'react'

import './FieldsetBlock.css'
import { Hint } from '@jarvis-catalyst/ui-components'
import { useTranslation } from 'react-i18next'

interface Props {
    legend?: string,
    error?: boolean
    errorMessage?: string
    children: React.ReactNode,
}

const FieldsetBlock: React.FC<Props> = function ({ legend, error, errorMessage, children }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <fieldset className={error ? 'uci__fieldset error' : 'uci__fieldset'}>
        {legend ? <legend>{legend}</legend> : '' }
        {children}
      </fieldset>
      {error && <Hint color="danger">{errorMessage || t('HINT_REQUIRED_FIELD')}</Hint> }
    </>
  )
}

export default FieldsetBlock
