import React from 'react'
import { useSelector } from 'react-redux'
import { IconMenuBurger } from '@jarvis-catalyst/ui-components'
import { applicationUiSidebarAction } from '../../../store/modules/application/actions'

export const handlerClick = (uiSidebar: string) => () => {
  if (uiSidebar === 'full') applicationUiSidebarAction('compact', true)
  if (uiSidebar === 'compact' || uiSidebar === 'hidden') applicationUiSidebarAction('full', true)
}

const ButtonSidebarController = function () {
  const { uiSidebar } = useSelector((s: any) => s.application)
  return (
    <div className="layout-sidebar-controller">
      <button
        type="button"
        onClick={handlerClick(uiSidebar)}
      >
        <IconMenuBurger color="#ffffff" />
      </button>
    </div>
  )
}

export default ButtonSidebarController
