import React from 'react'
import { Progress, CardHeader, CardContent, Autocomplete, Typography } from '@jarvis-catalyst/jarvis-ui-kit'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash.isempty'
import { CardSubscriptionRolesProps } from './manage-user.type'
import { parseOption } from '../../../../../utils/parseOption'
import { rolesGetAllSelector } from '../../../../../store/modules/roles/selectors'
import CustomBox from '../../../../../components/custombox/custombox'

const CardSubscriptionRoles = function ({ user, hasWrite, form, init }: CardSubscriptionRolesProps) {
  const { t } = useTranslation()
  const rolesStore = useSelector(rolesGetAllSelector)
  const DEFAULT_ROLE = 'Jarvis User'

  const defaultRoles = () => {
    if (!hasWrite) {
      return user?.data?.roles.subscription.map(parseOption)
    }
    const roles = user?.data ? user.data.roles.subscription.map((r: any) => r.name) : [DEFAULT_ROLE]
    return rolesStore.data.map(parseOption).filter((e: any) => roles.includes(e.label))
  }

  return (
    <CustomBox>
      <CardHeader title={t('MANAGE_USER.SUBSCRIPTION_ROLES')} />
      <CardContent>
        {init ? (
          <Controller
            control={form.control}
            name="roles"
            rules={{ required: true }}
            defaultValue={defaultRoles()}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <Autocomplete
                multiple
                placeholder={t('ADD_USER_SUBSCRIPTION_ROLE_INPUT_LABEL')}
                value={value}
                onChange={(e, option) => onChange(option)}
                options={rolesStore.data.map(parseOption)}
                disabled={!hasWrite}
                error={!isEmpty(errors.roles)}
                helpertext={errors.roles && t('MANAGE_USER.REQUIRED_ROLE')}
              />
            )}
          />
        ) : (
          <Progress circle />
        )}
        {rolesStore?.error && (
          <Typography
            variant="h4"
            color="red"
          >
            {t(rolesStore?.errorMessage)}
          </Typography>
        )}
      </CardContent>
    </CustomBox>
  )
}

export default CardSubscriptionRoles
