type Scope = { name: string; selected: boolean }

type CustomPermissionArgs = {
  label: string
  scopes: Scope[]
}

const objCustomPermissionBase = (customPermissions: CustomPermissionArgs[]) => {
  const obj: any = {}
  customPermissions.forEach((customPermission: CustomPermissionArgs) => {
    const k = `up.${customPermission.label}`
    const scopes: string[] = []
    customPermission.scopes.forEach((s: Scope) => {
      if (s.selected) scopes.push(s.name)
    })
    if (scopes.length > 0) obj[k] = { scopes }
  })
  return obj
}

export default objCustomPermissionBase
