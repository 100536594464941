import React from 'react'
import { useSelector } from 'react-redux'
import { Item } from './utils/menu'
import LinkWrapper from './utils/LinkWrapper'
import useAppendQS from '../../hooks/useAppendQS'

type AppLinkProps = {
    item: Item;
    name: string;
    children: React.ReactNode
}

const AppLink = function ({ item, name, children }: AppLinkProps) {
  const { uiSidebar } = useSelector((s: any) => s.application)
  const appendQS = useAppendQS()

  return item.navigationType === 'EXTERNAL' ? (
    <a
      href={item.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ) : (
    <LinkWrapper
      to={appendQS(`${item.route}`)}
      title={uiSidebar === 'full' ? name : ''}
    >
      {children}
    </LinkWrapper>
  )
}

export default AppLink
