import { useSelector } from 'react-redux'
import { dataIsLoad } from '../store/utils'
import { RETAIL_SITES } from '../const'

const getUserType = () => {
  const siteStore = useSelector((state:any) => state.sites.getAll)
  const introspect = useSelector((state:any) => state.instrospect.getRolesByAccountId)
  const retailSites = new Set(RETAIL_SITES)
  let mySites: Array<any> = []
  let siteIds: Array<any> = []

  if (dataIsLoad(introspect)) {
    siteIds = introspect.data.sites ? introspect.data.sites.map((site: any) => site.id) : []
  }
  if (dataIsLoad(siteStore)) {
    mySites = siteStore.data
  }

  const sites = mySites.filter((site: any) => siteIds.includes(site.id))

  const isRetailUser = sites.length !== 0 && sites.every((site) => retailSites.has(site.key))

  return { isRetailUser, sites }
}

export default getUserType
