import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Autocomplete, Button, Grid, Box, Icon } from '@jarvis-catalyst/jarvis-ui-kit'
import { useHistory, useLocation } from 'react-router-dom'
import ApplicationListingTable from './ApplicationsListingTable'
import { tenantsGetAllSelector } from '../../../../store/modules/tenants/selectors'
import { ROUTES } from '../../../../const'
import useAppendQS from '../../../../hooks/useAppendQS'
import { tenantsGetAllAction } from '../../../../store/modules/tenants/actions'
import { getAllTenantsFilter } from '../../../../utils/filters'

type ApplicationsListingProps = {
  hasWrite: boolean
}

export type Option = {
  label: string
  value: string
  tenantDependent?: boolean
}

const appTypes: Option[] = [
  { label: 'Tenant Apps', value: 'tenant', tenantDependent: true },
  { label: 'Global Apps', value: 'global', tenantDependent: false },
]

const ApplicationListing = function ({ hasWrite }: ApplicationsListingProps) {
  const { t } = useTranslation()
  const appendQS = useAppendQS()
  const location = useLocation<{ selectedTenant: string }>()
  const { activeTenant } = useSelector((s: any) => s.application)
  const [tenantOptions, setTenantOptions] = useState([])
  const tenantStore = useSelector(tenantsGetAllSelector)
  const [selectedAppType, setSelectedAppType] = useState(appTypes[0])
  const [selectedTenant, setSelectedTenant] = useState<string>(activeTenant?.id)
  const history = useHistory()

  useEffect(() => {
    tenantsGetAllAction(getAllTenantsFilter({ page: 1, limit: 100, status: 'active' }))
  }, [])

  useEffect(() => {
    const opt: any = tenantStore.data.map((t) => ({ label: t.name, value: t.id }))
    setTenantOptions(opt)
  }, [tenantStore])

  useEffect(() => {
    const tenantFromLocation = location.state?.selectedTenant
    if (tenantFromLocation) {
      setSelectedTenant(tenantFromLocation)
      history.replace({ state: {} })
    }
  }, [location])

  const handleAppCreation = () => {
    history.push(appendQS(ROUTES.APPLICATION_MANAGE), {
      selectedTenant: selectedAppType?.tenantDependent && selectedTenant,
    })
  }

  const renderStatusSelector = () => (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        lg={2}
      >
        <Autocomplete
          placeholder={t('TYPE')}
          options={appTypes}
          value={selectedAppType}
          onChange={(e, option: any) => {
            setSelectedAppType(option)
          }}
        />
      </Grid>
      <Grid
        item
        lg={4}
      >
        {selectedAppType?.tenantDependent && (
          <Autocomplete
            placeholder={t('ADD_USER_TENANT_INPUT_LABEL')}
            options={tenantOptions}
            value={tenantOptions.find((t: any) => t.value === selectedTenant) || null}
            onChange={(e, option: any) => {
              setSelectedTenant(option?.value)
            }}
          />
        )}
      </Grid>

      <Grid
        item
        lg={6}
      >
        <Box sx={{ textAlign: 'end' }}>
          <Button
            size="large"
            variant="contained"
            disabled={!hasWrite}
            onClick={() => handleAppCreation()}
            color="primary"
            startIcon={<Icon name="Plus" />}
          >
            {t('CREATE_APP')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  )

  return (
    <>
      {renderStatusSelector()}
      <ApplicationListingTable
        hasWrite={hasWrite}
        selectedTenant={selectedTenant}
        selectedAppType={selectedAppType}
      />
    </>
  )
}

export default ApplicationListing
