import { restClient, USER_PERMISSIONS_API_BASE_URL, wrapperHeaderWithToken } from '../configuration'
import { getService } from '../services.utils'
import { HTTPClientConfig, HttpMethodEnum } from '../types'

export const getUserPermissions = async (req: any): Promise<any> => {
  const obj: any = {}
  if (req.tenant !== undefined && req.tenant !== '') obj.tenant = req.tenant
  if (req.site !== undefined && req.site !== '') obj.site = req.site
  if (req.seller !== undefined && req.seller !== '') obj.seller = req.seller
  const objUrl = new URLSearchParams(obj)
  const uri = process.env.REACT_APP_RBAC_VERSION === 'v2'
    ? `${USER_PERMISSIONS_API_BASE_URL}/permissions/v2/introspect?${objUrl}`
    : `${USER_PERMISSIONS_API_BASE_URL}/accounts/v1/${req.userId}/permissions?${objUrl}`

  const config: HTTPClientConfig = {
    method: HttpMethodEnum.GET,
    headers: wrapperHeaderWithToken(req.token),
  }

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient()(uri, config)
      if (response.status === 200) {
        const data: any = await response.json()
        resolve(data)
      } else if (response.status === 404) {
        reject('NOT_FOUND')
      } else if (response.status === 500) {
        const data: any = await response.json()
        reject(data.message)
      } else {
        reject('NOT_FOUND')
      }
    } catch (error) {
      reject(`${error}`)
    }
  })
}

export const getRolesByAccountId = async (req: any): Promise<any> =>
  getService(req, (req: any) => `${USER_PERMISSIONS_API_BASE_URL}/accounts/v1/${req.id}/roles`)
