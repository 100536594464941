import React from 'react'
import { Button, Col, Icon, Row, Text } from '@jarvis-catalyst/ui-components'
import './Help.css'
import { useTranslation, Trans } from 'react-i18next'
import { Application } from '../../../store/modules/applications/types'

type HelpContactProps = {
    name: string
    language: any
    application: Application
    setOpen: any;
}

const HelpContact = function ({ name, language, application, setOpen }: HelpContactProps) {
  const { t } = useTranslation()
  const { metadata } = application

  const emailLink = (email: string) => (
    <a
      href={`mailto:${email}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      {email}
    </a>
  )

  const linkTo = (link: string) => (
    <a
      href={link}
      target="_blank"
      rel="noreferrer noopener"
    >
      <strong>{t('HELP.CHAT_LINK')}</strong>
    </a>
  )

  return (
    <>
      <div className="help__header">
        <Button
          color="link"
          onClick={() => setOpen(false)}
        >
          <Icon
            name="Close"
            size={16}
            color="white"
          />
        </Button>
      </div>
      <div className="help__content">
        <Text>
          <Trans
            t={t}
            i18nKey="HELP.MESSSAGE"
            tOptions={{ name }}
          />
        </Text>

        <Text>
          <Trans
            t={t}
            i18nKey="HELP.SUPPORT"
            tOptions={{ application: application.name[language] }}
          />
        </Text>

        {metadata.contactInfo.map((contact, i) => (
          <div
            className="help__contact"
            key={i}
          >
            <Row>
              <Col><strong>{contact.region}</strong></Col>
            </Row>
            <Row>
              <Col>{contact.name}</Col>
            </Row>
            <Row>
              <Col>{emailLink(contact.email)}</Col>
            </Row>
            <Row>
              <Col>{linkTo(contact.channel)}</Col>
            </Row>
          </div>
        ))}
      </div>
    </>
  )
}

export default HelpContact
