import React, { useState } from 'react'
import { Alert, Spinner } from '@jarvis-catalyst/ui-components'
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import CustomApplication from '../components/custom-application/CustomApplication'
import { applicationUiTenantDependAction } from '../../../store/modules/application/actions'
import useAppendQS from '../../../hooks/useAppendQS'

export function useUpdateData(
  url: string,
  activeTenant: any,
  availableApplications: any[],
  setAppIsAllow: any,
  appendQs: (s: string, onlyQS?: boolean) => (string | string),
  data: any,
): void {
  React.useEffect(() => {
    if (url) {
      const currentApp = window.location.pathname.split('/')
      const startPathCurrentApp = `/${currentApp[1]}/${currentApp[2]}`
      const found = availableApplications.find((app: any) => app.route === startPathCurrentApp)
      found && found.isActive ? setAppIsAllow(true) : setAppIsAllow(false)
    }
  }, [appendQs, activeTenant, url, data, availableApplications, setAppIsAllow])
}

const CustomAppRouter = function (): JSX.Element {
  const appendQs = useAppendQS()
  const { t } = useTranslation()
  const [tenantSelected, setTenantSelected] = useState('')
  const [url, setUrl] = useState('')
  const [appIsAllow, setAppIsAllow] = useState(null)
  const { data } = useSelector((s: any) => s.applications)
  const { availableApplications,
    activeTenant,
    resetCustomAppUrl,
    userHasTenant,
  } = useSelector((s: any) => s.application)
  React.useEffect(() => {
    applicationUiTenantDependAction(true)
  }, [])

  React.useEffect(() => {
    if (window.location.href !== url) {
      setUrl(window.location.href)
    }
  }, [setUrl, url])

  useUpdateData(url, activeTenant, availableApplications, setAppIsAllow, appendQs, data)

  // Fix force update custom App
  // this line forces to re-render <CustomApplication>
  React.useEffect(() => {
    setTenantSelected('')
    setTimeout(() => {
      setTenantSelected(activeTenant.id)
    }, 0)
  }, [setTenantSelected, activeTenant, resetCustomAppUrl])

  return (!userHasTenant || tenantSelected) && appIsAllow !== null ? (
    <>
      <Switch>
        {availableApplications.map((application: any) => application.id && (appIsAllow ? (
          <Route
            key={application.id}
            path={`${application.route}`}
            component={CustomApplication}
          />
        ) : (<span key={application.id} />)))}
      </Switch>
      {appIsAllow === false && <Alert color="danger">
        {t('CUSTOM_APP.NOT_AVAILABLE_THIS_TENANT')}
      </Alert>}
    </>
  ) : <Spinner />
}

export default CustomAppRouter
