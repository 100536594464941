/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import './genericError.css'
import { Container, Row, Col } from '@jarvis-catalyst/ui-components'

const GenericError = function ({ errorMessage, errorMessageTenant, imageSrc }) {
  const { t } = useTranslation()

  return (
    <Container
      fluid
      className="permission-error"
    >
      <Row>
        <Col className="text-center">
          <div>
            <img
              className="error-icon"
              alt="Error logo"
              src={imageSrc}
            />
          </div>
          <span className="error-message">
            <Trans
              t={t}
              i18nKey={errorMessage || errorMessageTenant}
            />
          </span>
        </Col>
      </Row>
    </Container>
  )
}

GenericError.propTypes = {
  errorMessageTenant: PropTypes.string,
  errorMessage: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
}

export default GenericError
