import React from 'react'
import { Grid, Typography as Text, Box } from '@jarvis-catalyst/jarvis-ui-kit'
import { Scope } from './Scope'

const CorePermission = function ({
  hasWrite,
  groupPermission,
  groupPermissionIndex,
  corePermission,
  onToggleScope,
}: any) {
  const handlerToggleScope = (scope: any, value: boolean) => {
    const newGroupPermission = groupPermission.data.map((p: any) => ({
      ...p,
      scopes: p.scopes.map((s: any) => ({
        ...s,
        selected: s.name === scope.name && p.id === corePermission.id ? value : s.selected,
      })),
    }))
    onToggleScope({ key: groupPermission.key, data: newGroupPermission })
  }
  return (
    <Box className="uci__hover pl-2">
      <Grid container>
        <Grid
          item
          md={9}
        >
          <Text variant="h4">
            {corePermission.name}
            {' '}
            (
            {corePermission.description}
            )
          </Text>
        </Grid>
        <Grid
          item
          md={3}
        >
          <Box
            className="uci__badge__container"
            sx={{ textAlign: 'right', justifyContent: 'flex-end' }}
          >
            {corePermission.scopes.map((s: any, k: number) => (
              <Scope
                hasWrite={hasWrite}
                onToggle={handlerToggleScope}
                key={k}
                scope={s}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
      {groupPermissionIndex + 1 < groupPermission.data.length && <span />}
    </Box>
  )
}

export default CorePermission
