import { FunctionComponent, useEffect, useState } from 'react'
import 'react-calendar/dist/Calendar.css'
import { Icon, Tooltip } from '@jarvis-catalyst/ui-components'
import { useTranslation } from 'react-i18next'
import { DateRange, DatePicker as NewDatePicker, Typography } from '@jarvis-catalyst/jarvis-ui-kit'
import {
  isInvalidFromDateTime,
  isInvalidRange,
  isInvalidToDateTime,
  rangeDateText,
} from './dateUtils'
import './DatePicker.css'
import { format, formatDate, parseDate } from '../../utils/date'

interface DatePickerProps {
  value: Array<Date>
  onChange: any
  getMinDate?: () => Date
  getMaxDate?: () => Date
  language: 'en' | 'es'
}

const DEFAULT_DATE_LABEL = 'LAST_WEEK'

export const DatePicker: FunctionComponent<DatePickerProps> = function ({
  value,
  getMinDate,
  getMaxDate,
  language,
  onChange,
}: DatePickerProps) {
  const { t } = useTranslation()
  const [dateRange, setDateRange] = useState<DateRange>([value[0], value[1]])
  const [dateText, setDateText] = useState<string | undefined>(`CALENDAR.${DEFAULT_DATE_LABEL}`)
  const [fromTime, setFromTime] = useState<string>('')
  const [toTime, setToTime] = useState<string>('')
  const [fromDate, setFromDate] = useState<string>('')
  const [toDate, setToDate] = useState<string>('')
  const [isOpen, setIsOpen] = useState(false)
  const [selectedPredefinedRange, setSelectedPredefinedRange] = useState('')
  const [dateRangeLabel, setDateRangeLabel] = useState('')

  const getCurrentRange = (datePickerInternalState: DateRange, text?:string) => {
    if (text) {
      setDateText(rangeDateText(language, text))
      setDateRange(datePickerInternalState)
      return
    }
    setDateText(undefined)
    setDateButtonLabel(datePickerInternalState)
    setDateRange(datePickerInternalState)
  }
  const setDateButtonLabel = (range: any, label = '') => {
    if (label === '') {
      setDateRangeLabel(`${format(range[0], language)} - ${format(range[1], language)}`)
    }
  }

  const fromDateTimestamp = parseDate(`${fromDate} ${fromTime}`, language)
  const toDateTimestamp = parseDate(`${toDate} ${toTime}`, language)
  const minDate = getMinDate ? getMinDate() : undefined
  const maxDate = getMaxDate ? getMaxDate() : undefined

  const rangeSelected = dateRange && dateRange[0]?.toString() !== dateRange[1]?.toString()

  const isInvalidFrom = minDate ? isInvalidFromDateTime(fromDateTimestamp, toDateTimestamp, minDate) : false
  const isInvalidTo = maxDate ? isInvalidToDateTime(fromDateTimestamp, toDateTimestamp, maxDate) : false
  const isInvalidDateRange = isInvalidRange(fromDateTimestamp, toDateTimestamp)

  const isInvalidDateFormat = !!(
    (fromDate && isNaN(fromDateTimestamp)) || (toDate && isNaN(toDateTimestamp))
  )

  useEffect(() => {
    if (rangeSelected) {
      setFromDate(formatDate(dateRange[0], language))
      setToDate(formatDate(dateRange[1], language))
      setFromTime(dateRange[0].toLocaleTimeString(language))
      setToTime(dateRange[1].toLocaleTimeString(language))
    }
  }, [dateRange, language])

  useEffect(() => {
    if (!isInvalidDateRange && !isInvalidFrom && !isInvalidTo && !isInvalidDateFormat && fromDate && toDate) {
      setDateRange([parseDate(`${fromDate} ${fromTime}`, language), parseDate(`${toDate} ${toTime}`, language)])
    }
  }, [fromDate, toDate, fromTime, toTime])

  const handleApply = () => {
    setIsOpen(false)
    onChange(dateRange)
    if (selectedPredefinedRange === '') {
      setDateButtonLabel(dateRange)
    } else {
      setDateButtonLabel(null, selectedPredefinedRange)
      setSelectedPredefinedRange('')
    }
  }

  return (
    <Tooltip
      isOpen={isOpen}
      width={650}
      type="dialog"
      position="bottom-start"
      onOut={() => setIsOpen(false)}
      className="container-datepicker"
      content={(
        <NewDatePicker
          onCalendarChange={getCurrentRange}
          locale={language as 'es' | 'en'}
          value={dateRange}
          onApply={handleApply}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          getMaxDate={() => maxDate!}
        />)}
    >
      <div
        id="input-calendar"
        className="calendar__input-row "
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <button
          type="button"
          onClick={() => setIsOpen(true)}
          className="btn-date-filter"
        >
          <Icon name="Calendar" />
          <Typography
            variant="H200"
            color="white"
          >
            {!dateText ? dateRangeLabel : t(dateText)}
          </Typography>
        </button>

      </div>
    </Tooltip>
  )
}
