import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Icon } from '@jarvis-catalyst/ui-components'
import { Avatar, IconButton, Menu, Grid } from '@jarvis-catalyst/jarvis-ui-kit'
import LogoFalabella from '../components/FalabellaIcon'
import './Header.css'
import useAuthenticationContextWrapper from '../../shell/contexts/AuthenticationContextWrapper'
import { ROUTES } from '../../../const'
import { navigateToUrl } from '../../shell/utils/router'
import { applicationUiSidebarAction } from '../../../store/modules/application/actions'
import EnvironmentIdentifier from './EnvironmentIdentifier'

type HeaderLandingProps = {
  displayIcons?: boolean
}

const HeaderLanding = function ({ displayIcons = false }: HeaderLandingProps) {
  const { t } = useTranslation()
  const history = useHistory()

  const { logoutToLogin, userDetails }: any = useAuthenticationContextWrapper()

  const getMinName = (name: string, defaultName = 'UN') => {
    const lName: Array<string> = (name || defaultName).split(' ')
    const firstNameSecondChar = lName[0][1] || ''
    if (lName.length > 1) return `${lName[0][0]}${lName[lName.length - 1][0]}`
    if (lName.length === 1) return `${lName[0][0]}${firstNameSecondChar}`
    return defaultName
  }

  const contents = [
    { content: userDetails.name, disabled: true, key: 0 },
    { content: userDetails.email, variant: 'H100', divider: true, disabled: true, key: 1 },
    {
      content: t('TENANT_MANAGEMENT_LABEL'),
      onClick: () => {
        navigateToUrl(history, '/tenants')
      },
      key: 2,
    },
    {
      content: t('USER_AND_PERMISSIONS'),
      onClick: () => {
        navigateToUrl(history, '/users')
      },
      key: 3,
    },
    {
      content: t('APPLICATIONS'),
      onClick: () => {
        navigateToUrl(history, '/applications')
      },
      key: 4,
    },
    {
      content: t('ACTIVITY_LOG'),
      onClick: () => {
        navigateToUrl(history, '/logs')
      },
      key: 5,
    },
    {
      content: t('LOGOUT'),
      onClick: async () => {
        await logoutToLogin()
      },
      key: 6,
    },
  ]

  return (
    <Grid
      item
      className="header"
    >
      <Grid
        item
        className="header__logo"
      >
        <a
          href="/"
          id="menu__link-logo"
          className="header__menu__link_logo"
          style={{ width: 146, height: 56, display: 'flex' }}
        >
          <LogoFalabella />
        </a>
        <EnvironmentIdentifier />
      </Grid>
      {displayIcons && (
        <Grid item>
          <Button
            title="Home"
            color="link-light"
            onClick={() => {
              applicationUiSidebarAction('full', true)
              navigateToUrl(history, ROUTES.DEFAULT_URI_START_APP)
            }}
          >
            <Icon
              name="Home"
              color="white"
            />
          </Button>
        </Grid>
      )}
      <Grid
        item
        className="header__menu"
      >
        {displayIcons && (
          <Icon
            name="Bell"
            color="white"
          />
        )}
        {displayIcons && (
          <Icon
            name="Help"
            color="white"
          />
        )}
        <Menu
          AnchorComponent={IconButton}
          AnchorComponentProps={{
            children: (<Avatar size={36}>{getMinName(userDetails.name).toUpperCase()}</Avatar>),
          }}
          contents={contents as any}
          id="header-landing-menu"
        />
      </Grid>
    </Grid>
  )
}

export default HeaderLanding
