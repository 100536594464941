import React from 'react'
import { Autocomplete, Icon, Progress } from '@jarvis-catalyst/jarvis-ui-kit'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { UseFormReturn } from 'react-hook-form/dist/types/form'
import { Controller } from 'react-hook-form'
import { ApplicationPayload } from '../../../../store/modules/applications/types'
import { parseOption } from '../../../../utils/parseOption'
import CardBlock from '../../../../components/CardBlock/CardBlock'

type GroupsSelectorProps = {
  groups?: { id: string; name: string }[]
  form: UseFormReturn<ApplicationPayload>
  init: boolean
}

interface Option {
  label: string
  value: string | number
}

export const GroupsSelector = function ({ groups, form, init }: GroupsSelectorProps) {
  const { t } = useTranslation()
  const { data: groupList, error: groupListError } = useSelector((s: any) => s.groups.getAll)
  const { control } = form

  return (
    <CardBlock title={t('MANAGE_CUSTOM_APPLICATION.GROUPS_TITLE')}>
      {groupListError ? (
        <span data-testid="icon-error">
          <Icon
            name="Warning"
            size="medium"
            color="#e0350b"
          />
          {t('MANAGE_CUSTOM_APPLICATION.GROUPS_ERROR')}
        </span>
      ) : init ? (
        <Controller
          name="groups"
          control={control}
          defaultValue={groups || []}
          rules={{ required: true }}
          render={({ field: { onChange, value }, formState: { errors } }) => (
            <div className={errors?.groups ? 'application_mgmt--error' : 'application_mgmt--dropdown'}>
              <Autocomplete
                data-testid="autocomplete-groups"
                placeholder={t('MANAGE_CUSTOM_APPLICATION.GROUPS_LABEL')}
                multiple
                options={groupList.map(parseOption)}
                error={!!errors?.groups}
                onChange={(e, option) => {
                  const options = (option as Option[]).map((group: any) => ({ id: group.value, name: group.label }))
                  onChange(options)
                }}
                value={value?.map(parseOption)}
                helpertext={errors?.groups ? t('HINT_REQUIRED_FIELD') : ''}
              />
            </div>
          )}
        />
      ) : (
        <Progress
          circle
          color="secondary"
        />
      )}
    </CardBlock>
  )
}
