import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Title, ModalBox, ButtonGroup, Button, Row, Col, Textarea, Divider, Switch, Alert } from '@jarvis-catalyst/ui-components'
import { useForm, Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Group, GroupPayload } from '../../../../store/modules/application/types'
import FieldsetBlock from '../../../../components/FieldsetBlock/FieldsetBlock'
import {
  groupCreateResetAction,
  groupsGetAllAction,
  groupUpdateResetAction,
} from '../../../../store/modules/groups/actions'
import { sendDangerNotification, sendSuccessNotification } from '../../../../utils/sendNotification'

import './GroupModal.css'

interface GroupModalProps {
  heading: string;
  isModalOpen: boolean;
  setModalOpen: any;
  hasWrite?: boolean;
  handleSaveClick: (data: GroupPayload) => void;
  defaultGroup?: Group | null;
  setCurrentPage: any
}

export const useAfterCommit = (
  createGroupStore: any,
  updateGroupStore: any,
  groupsGetAllAction: any,
  handleModalClose: any,
  setCurrentPage: any,
): void => {
  const { t } = useTranslation()

  const getErrorMessage = (errorMessage: string): string => {
    if (errorMessage.includes('CONFLICT')) return t('MANAGE_CUSTOM_APPLICATION.GROUPS_DUPLICATED')

    return t('MANAGE_CUSTOM_APPLICATION.ERROR')
  }

  useEffect(() => {
    if (createGroupStore.success || updateGroupStore.success) {
      createGroupStore.success ? groupCreateResetAction() : groupUpdateResetAction()
      sendSuccessNotification(t(createGroupStore.success ? 'ACTIONS.CREATED' : 'ACTIONS.SAVED'))
      groupsGetAllAction()
      setCurrentPage(1)
      handleModalClose()
    }

    if (createGroupStore.error || updateGroupStore.error) {
      const errorMessage = getErrorMessage(createGroupStore.errorMessage || updateGroupStore.errorMessage)
      sendDangerNotification(errorMessage)
      createGroupStore.error ? groupCreateResetAction() : groupUpdateResetAction()
    }
  }, [createGroupStore, updateGroupStore])
}

export const GroupModal = function ({
  isModalOpen,
  setModalOpen,
  handleSaveClick,
  heading,
  hasWrite,
  defaultGroup,
  setCurrentPage }: GroupModalProps) {
  const { t } = useTranslation()
  const createGroupStore: any = useSelector((s: any) => s.groups.create)
  const updateGroupStore: any = useSelector((s: any) => s.groups.update)
  const { control, handleSubmit, formState, register, watch, reset } = useForm<GroupPayload>({
    mode: 'onChange',
  })
  const { errors } = formState
  const MAX_DESC = 500
  const MAX_NAME = 20

  const errorMessageParser = (error: any, value: string, max: number) => {
    if (error?.type === 'required') {
      return t('HINT_REQUIRED_FIELD')
    }
    if (error?.type === 'maxLength') return `${t('HINT_MAX_LENGTH')} (${value.length}/${max})`

    return undefined
  }

  const handleModalClose = () => {
    reset()
    setModalOpen(false)
  }

  const isLoadingOrInvalidForm = !formState.isDirty
    || !formState.isValid
    || createGroupStore.loading
    || updateGroupStore.loading

  const HeaderComponent = function () {
    return <Title>{heading}</Title>
  }
  const FooterComponent = function () {
    return <Row>
      <Col
        className="text-right"
        xs={12}
      >
        <ButtonGroup>
          <Button
            type="button"
            onClick={handleModalClose}
            color="bordered-light"
          >
            {t('CANCEL')}
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit(handleSaveClick)}
            disabled={isLoadingOrInvalidForm}
            color="bordered"
          >
            {t('CONFIRM')}
          </Button>
        </ButtonGroup>
      </Col>
    </Row>
  }

  const components = {
    Header: HeaderComponent,
    Footer: FooterComponent,
  }

  useAfterCommit(createGroupStore, updateGroupStore, groupsGetAllAction, handleModalClose, setCurrentPage)

  return (
    <ModalBox
      isOpen={isModalOpen}
      components={components}
    >
      <div className="group-modal">
        <form
          onSubmit={handleSubmit(handleSaveClick)}
          data-testid="modal-group"
        >
          <div className={errors?.name && 'error_input'}>
            <FieldsetBlock
              legend={t('MANAGE_CUSTOM_APPLICATION.GROUPS_MODAL_NAME_INPUT_TITLE')}
              error={!!errors.name}
              errorMessage={errorMessageParser(errors.name, watch('name'), MAX_NAME)}
            >
              <input
                defaultValue={defaultGroup?.name}
                {...register('name', { required: true, maxLength: MAX_NAME })}
                placeholder={t('MANAGE_CUSTOM_APPLICATION.GROUPS_MODAL_NAME_PLACEHOLDER')}
                disabled={!hasWrite}
              />
            </FieldsetBlock>
          </div>
          <br />
          <Controller
            name="description"
            control={control}
            defaultValue={defaultGroup?.description}
            rules={{ required: true, maxLength: MAX_DESC }}
            render={({ field: { onChange, value: description } }) => (
              <div className={errors?.description && 'error_input'}>
                <Textarea
                  disabled={!hasWrite}
                  onChange={onChange}
                  value={description}
                  placeholder={t('MANAGE_CUSTOM_APPLICATION.GROUPS_MODAL_DESCRIPTION_PLACEHOLDER')}
                  hintShow={Boolean(errors?.description)}
                  hintColor="danger"
                  hintText={errorMessageParser(errors?.description, description, MAX_DESC)}
                />
              </div>
            )}
          />
          {!defaultGroup
            && <>
              <Divider
                type="blank"
                size="sm"
              />
              <Controller
                name="isProtected"
                defaultValue={false}
                control={control}
                render={({ field: { onChange, value: isProtected } }) =>
                  <>
                    <span>{t('MANAGE_CUSTOM_APPLICATION.PROTECTED')}</span>
                    :
                    {isProtected ? t('MANAGE_CUSTOM_APPLICATION.WITH_ROUTE') : 'No'}
                    <div className="application_mgmt--right">
                      <Switch
                        value={isProtected}
                        onChange={onChange}
                      />
                    </div>
                  </>}
              />
              <Divider
                type="blank"
                size="sm"
              />
              <Alert color="info">{t('MANAGE_CUSTOM_APPLICATION.PROTECTED_HINT')}</Alert>
            </>}
        </form>
      </div>
    </ModalBox>
  )
}

export default GroupModal
