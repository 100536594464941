import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { permissions } from '@jarvis-catalyst/custom-app-sdk'
import { Link } from 'react-router-dom'
import {
  Grid,
  Breadcrumbs,
  Typography,
  Container,
  Box,
  Card,
  CardContent as CardBody,
  CardHeader,
  Progress,
  Button,
  Divider,
  Alert,
  Modal,
} from '@jarvis-catalyst/jarvis-ui-kit'
import CustomPermissions from './components/CustomPermissions'
import RoleDefinition from './components/RoleDefinition'
import { RoleDescription } from '../../../../store/modules/roles/types'
import { getAllCorePermissionsFilter } from '../../../../utils/filters'
import corePermissionsRemap from '../../../../utils/corePermissionsRemap'
import CorePermission from './components/CorePermission'
import ButtonToggleCardPermissions from './components/ButtonToggleCardPermissions'
import objCorePermissionsBase from '../../../../utils/objCorePermissionsBase'
import objCustomPermissionBase from '../../../../utils/objCustomPermissionBase'
import useAppendQS from '../../../../hooks/useAppendQS'
import {
  roleByIdAction,
  roleByIdResetAction,
  roleCreateAction,
  roleCreateResetAction,
  roleUpdateAction,
  roleUpdateResetAction,
} from '../../../../store/modules/roles/actions'
import { corePermissionsGetAllAction } from '../../../../store/modules/custom-permissions/actions'
import { sendDangerNotification, sendSuccessNotification } from '../../../../utils/sendNotification'
import { assignPrevPermissions, getInitialRoleData, makeOutData, makeScopes, objectIsVoid } from './utils'
import { BOOLEAN, ROUTES, VOID_STRING, ZERO } from '../../../../const'
import { userPermissionsGetSelector } from '../../../../store/modules/user-permissions/selectors'
import useDocumentTitle from '../../../../hooks/useDocumentTitle'
import CustomBox from '../../../../components/custombox/custombox'
import CustomTitleAndTooltip from '../../../../components/customTitleAndTooltip/CustomTitleAndTooltip'

export const useAfterCommit = (
  createStore: any,
  history: any,
  updateStore: any,
  processSave: any,
  setProcessSave: any,
  appendQs: any,
) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (createStore.success && !createStore.loading) {
      roleCreateResetAction()
      sendSuccessNotification(t('ACTIONS.CREATED'))
    }
    if (updateStore.success && !updateStore.loading) {
      roleUpdateResetAction()
      sendSuccessNotification(t('ACTIONS.SAVED'))
    }

    if (updateStore.error && !updateStore.loading && processSave) {
      setProcessSave(false)
      sendDangerNotification(t(`ACTIONS.${updateStore.errorMessage}`))
    }

    if (createStore.error && !createStore.loading && processSave) {
      setProcessSave(false)
      sendDangerNotification(t(`ACTIONS.${createStore.errorMessage}`))
    }

    if ((createStore.success && !createStore.loading) || (updateStore.success && !updateStore.loading)) {
      setProcessSave(false)
      history.push(appendQs(ROUTES.ROLE_INDEX))
    }
  }, [createStore, updateStore, t, history, appendQs, processSave, setProcessSave])
}

export const Manage = function ({ id, history, roleStore, createStore, updateStore, corePermissionsStore }: any) {
  const { t } = useTranslation()
  const appendQs = useAppendQS()

  const [isInitCoreRole, setIsInitCoreRole] = React.useState(false)
  const [isGetData, setIsGetData] = React.useState(false)
  const [alertModalIsOpen, setAlertModalIsOpen] = React.useState(false)
  const [processSave, setProcessSave] = React.useState(false)
  const [openCardCorePermission, setOpenCardCorePermission] = React.useState(true)

  const [role, setRole] = React.useState<RoleDescription>(() => getInitialRoleData())
  const [corePermissionBase, setCorePermissionsBase] = React.useState<any>([])
  const [customPermissions, setCustomPermissions] = React.useState<any[]>([])

  const userPermissions: any = useSelector(userPermissionsGetSelector)
  const hasWrite = (() =>
    permissions.utils.hasPermissionAndHasSomeScopes(userPermissions, 'subscription', 'rbac.roles', ['write']))()
    && (role.createdBy === 'USER' || !id)
  const handlerSave = () => {
    const coreP = objCorePermissionsBase(corePermissionBase)
    const customP = objCustomPermissionBase(customPermissions)
    const permissionsIsEmpty = objectIsVoid(coreP) && objectIsVoid(customP)

    if (permissionsIsEmpty) {
      setAlertModalIsOpen(true)
      return
    }
    setProcessSave(true)
    const out = makeOutData(role, coreP, customP)
    if (id) roleUpdateAction(id, out)
    else roleCreateAction(out)
  }

  const handlerToggleScope = (p: any) =>
    setCorePermissionsBase(corePermissionBase.map((cp: any) => (cp.key === p.key ? p : cp)))

  useEffect(() => {
    roleByIdResetAction()
  }, [])
  useEffect(() => {
    corePermissionsGetAllAction(getAllCorePermissionsFilter(1, 100))
  }, [])

  useEffect(() => {
    if (corePermissionsStore.data.length > ZERO && !isInitCoreRole) {
      setIsInitCoreRole(true)
      setCorePermissionsBase(corePermissionsRemap(corePermissionsStore.data))
    }
  }, [corePermissionsStore, isInitCoreRole])

  useEffect(() => {
    if (!isGetData && !roleStore.loading && corePermissionsStore.data.length > ZERO && id) {
      setIsGetData(BOOLEAN.TRUE)
      roleByIdAction(id)
    }
  }, [isGetData, corePermissionsStore, id, roleStore])

  useEffect(() => {
    if (roleStore.success && !role.title && id && corePermissionBase.length > ZERO) {
      const { data: d } = roleStore
      const { arrPermissionsCore, arrPermissionsCustom } = assignPrevPermissions(d, corePermissionBase)
      setCustomPermissions(arrPermissionsCustom)

      if (arrPermissionsCore.length > 0) setCorePermissionsBase(arrPermissionsCore)

      setRole({
        ...role,
        title: d.name,
        description: d.description,
        subscriptionScope: d.scopes.includes('SUBSCRIPTION'),
        tenantScope: d.scopes.includes('TENANT'),
        siteScope: d.scopes.includes('SITE'),
        sellerScope: d.scopes.includes('SELLER'),
        createdBy: d.createdBy,
      })
    }
  }, [id, roleStore, role, corePermissionBase])

  useAfterCommit(createStore, history, updateStore, processSave, setProcessSave, appendQs)

  const handlerBackNavigate = () => history.push(appendQs(ROUTES.ROLE_INDEX))
  const getIsDisabledSave = () =>
    makeScopes(role).length < 1 || role.title.trim() === VOID_STRING || role.description.trim() === VOID_STRING

  useDocumentTitle(t(`MANAGE_ROLE.${id ? (hasWrite ? 'TITLE_EDIT' : 'TITLE_VIEW') : 'TITLE_CREATE'}`))

  return (
    <div style={{ padding: 20 }}>
      <Card variant="elevation">
        <Container maxWidth="xl">
          <div style={{ marginLeft: 0, marginTop: 4 }}>
            <Breadcrumbs>
              <Link
                style={{ textDecoration: 'none', padding: 0 }}
                to={{ pathname: '/' }}
              >
                <Typography
                  color="#a7a7a7"
                  variant="h4"
                >
                  {t('HOME')}
                </Typography>
              </Link>
              <Link
                style={{ textDecoration: 'none', padding: 0 }}
                to={{ pathname: '/users/roles' }}
              >
                <Typography
                  color="#a7a7a7"
                  variant="h4"
                >
                  {t('ROLES')}
                </Typography>
              </Link>
              <Link
                style={{ textDecoration: 'none', padding: 0 }}
                to={{ pathname: '/users/roles/create' }}
              >
                <Typography
                  color="primary"
                  variant="h4"
                >
                  {t(`MANAGE_ROLE.${id ? (hasWrite ? 'TITLE_EDIT' : 'TITLE_VIEW') : 'TITLE_CREATE'}`)}
                </Typography>
              </Link>
            </Breadcrumbs>
          </div>
          <Box sx={{ mb: 4, mt: 2 }}>
            <CustomTitleAndTooltip
              title={t(`MANAGE_ROLE.${id ? (hasWrite ? 'TITLE_EDIT' : 'TITLE_VIEW') : 'TITLE_CREATE'}`)}
              tooltipTitle={t(`MANAGE_ROLE.${id ? (hasWrite ? 'TOOLTIP_EDIT' : 'TOOLTIP_VIEW') : 'TOOLTIP_CREATE'}`)}
            />
          </Box>

          <CustomBox>
            <CardBody>
              <Box sx={{ pb: 3 }}>
                <Typography variant="H500">{t('MANAGE_ROLE.DEFINITION')}</Typography>
              </Box>
              <RoleDefinition
                hasWrite={hasWrite}
                role={role}
                setRole={setRole}
              />
              {(roleStore.loading || corePermissionsStore.loading) && <Progress circle />}
            </CardBody>
          </CustomBox>
          <hr style={{ border: 'none', height: 0 }} />
          <CustomBox>
            <CardHeader
              title={
                <Grid
                  container
                  direction="row"
                  columns={12}
                >
                  <Grid
                    item
                    xs={11}
                  >
                    <Typography variant="H500">{t('MANAGE_ROLE.CORE_PERMISSIONS')}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                  >
                    <ButtonToggleCardPermissions
                      open={openCardCorePermission}
                      setOpen={setOpenCardCorePermission}
                    />
                  </Grid>
                </Grid>
              }
            />
            <CardBody>
              {openCardCorePermission && (
                <Box>
                  {corePermissionBase.length > 0
                    && corePermissionBase.map((cpb: any, i: number) => (
                      <div key={i}>
                        <Typography variant="H300">{cpb.key}</Typography>
                        <hr />

                        {cpb.data.map((cp: any, j: number) => (
                          <CorePermission
                            hasWrite={hasWrite}
                            onToggleScope={handlerToggleScope}
                            corePermission={cp}
                            groupPermission={cpb}
                            groupPermissionIndex={j}
                            key={j}
                          />
                        ))}
                        {i + 1 < corePermissionBase.length && <hr style={{ border: 'none', height: 0 }} />}
                      </div>
                    ))}
                </Box>
              )}
              {(corePermissionsStore.loading || corePermissionBase.length === 0 || roleStore.loading) && (
                <Progress circle />
              )}
            </CardBody>
          </CustomBox>
          <hr style={{ border: 'none', height: 0 }} />
          <CustomBox>
            <CardBody>
              <Box sx={{ mb: 2 }}>
                <Typography variant="H500">{t('MANAGE_ROLE.CUSTOM_PERMISSIONS')}</Typography>
              </Box>

              {corePermissionsStore.loading && <Progress circle />}
              {id ? roleStore.loading && <Progress circle /> : ''}
              <CustomPermissions
                hasWrite={hasWrite}
                data={customPermissions}
                setData={setCustomPermissions}
              />
            </CardBody>
          </CustomBox>

          <Box className="text-right my-4">
            <Button
              disabled={processSave}
              onClick={handlerBackNavigate}
            >
              {t('ACTIONS.CANCEL')}
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={getIsDisabledSave() || processSave || !hasWrite}
              onClick={handlerSave}
            >
              {t('ACTIONS.SAVE')}
            </Button>
          </Box>
          <Modal
            open={alertModalIsOpen}
            onClose={() => setAlertModalIsOpen(false)}
          >
            <CardHeader title={t('ACTIONS.WARNING')} />
            <Divider blank />
            <Alert
              color="warning"
              severity="warning"
            >
              {t('MANAGE_ROLE.WARNING')}
            </Alert>
            <Box sx={{ textAlign: 'right' }}>
              <Button onClick={() => setAlertModalIsOpen(false)}>{t('ACTIONS.CLOSE')}</Button>
            </Box>
          </Modal>
        </Container>
      </Card>
    </div>
  )
}

export default Manage
