import React from 'react'
import { Grid, Typography, Switch, TextField, Progress } from '@jarvis-catalyst/jarvis-ui-kit'
import { Controller } from 'react-hook-form'
import { UseFormReturn } from 'react-hook-form/dist/types/form'
import { useTranslation } from 'react-i18next'
import { ApplicationPayload } from '../../../../store/modules/applications/types'
import './ApplicationManagment.css'
import CardBlock from '../../../../components/CardBlock/CardBlock'

type CardGeneralProps = {
  form: UseFormReturn<ApplicationPayload>
  app?: ApplicationPayload
  init: boolean
}

const CardGeneral = function ({ form, app, init }: CardGeneralProps) {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
  } = form

  return (
    <CardBlock title={t('MANAGE_CUSTOM_APPLICATION.TITLE_GENERAL')}>
      {init ? (
        <div style={{ padding: 2 }}>
          <Grid
            container
            columnSpacing={3}
          >
            <Grid
              item
              sm={6}
            >
              <Controller
                name="name"
                defaultValue={app?.name || ''}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, ref } }) => (
                  <TextField
                    fullWidth
                    label={t('MANAGE_CUSTOM_APPLICATION.NAME')}
                    error={!!errors.name}
                    helperText={errors.name ? t('HINT_REQUIRED_FIELD') : ''}
                    value={value}
                    inputRef={ref}
                    onChange={onChange}
                    placeholder={t('MANAGE_CUSTOM_APPLICATION.NAME_PLACEHOLDER')}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              sm={6}
            >
              <Controller
                name="description.en"
                defaultValue={app?.description?.en || ''}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, ref } }) => (
                  <TextField
                    fullWidth
                    label={t('MANAGE_CUSTOM_APPLICATION.DESCRIPTION_EN')}
                    error={!!(errors?.description?.en)}
                    helperText={errors?.description?.en ? t('HINT_REQUIRED_FIELD') : ''}
                    value={value}
                    inputRef={ref}
                    onChange={onChange}
                    placeholder={t('MANAGE_CUSTOM_APPLICATION.DESCRIPTION_PLACERHOLDER_EN')}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              sm={6}
            >
              <div
                style={{
                  marginTop: 8,
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                }}
              >
                <Controller
                  name="isActive"
                  defaultValue={app ? app.isActive : true}
                  control={control}
                  render={({ field: { onChange, value: isActive } }) => (
                    <>
                      <div style={{ marginLeft: 5 }}>
                        <Typography variant="H400">
                          {`${t('MANAGE_CUSTOM_APPLICATION.STATUS')}: `}
                          {isActive
                            ? t('MANAGE_CUSTOM_APPLICATION.STATUS_ACTIVE')
                            : t('MANAGE_CUSTOM_APPLICATION.STATUS_INACTIVE')}
                        </Typography>
                      </div>

                      <div className="application_mgmt--right">
                        <Switch
                          checked={isActive}
                          value={Boolean(isActive)}
                          onChange={onChange}
                        />
                      </div>
                    </>
                  )}
                />
              </div>
            </Grid>
            <Grid
              item
              sm={6}
            >
              <div style={{ marginTop: 15 }}>
                <Controller
                  name="description.es"
                  defaultValue={app?.description?.es || ''}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ref } }) => (
                    <TextField
                      fullWidth
                      label={t('MANAGE_CUSTOM_APPLICATION.DESCRIPTION_ES')}
                      error={!!(errors?.description?.es)}
                      helperText={errors?.description?.es ? t('HINT_REQUIRED_FIELD') : ''}
                      value={value}
                      inputRef={ref}
                      onChange={onChange}
                      placeholder={t('MANAGE_CUSTOM_APPLICATION.DESCRIPTION_PLACERHOLDER_ES')}
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        <Progress
          circle
          color="secondary"
        />
      )}
    </CardBlock>
  )
}

export default CardGeneral
