import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Icon, Box } from '@jarvis-catalyst/jarvis-ui-kit'
import GroupsListingTable from './GroupsListingTable'
import GroupModal from '../groupModal/GroupModal'
import { groupsCreateAction, groupUpdateAction } from '../../../../store/modules/groups/actions'
import { Group, GroupPayload } from '../../../../store/modules/application/types'

type GroupsListingProps = {
  hasWrite: boolean
}

const GroupsListing = function ({ hasWrite }: GroupsListingProps) {
  const { t } = useTranslation()
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false)
  const [selectedGroup, setSelectedGroup] = useState<Group>()
  const [currentPage, setCurrentPage] = useState(1)

  const handleSave = (data: GroupPayload) => {
    selectedGroup ? groupUpdateAction(selectedGroup.id, data) : groupsCreateAction(data)
  }

  const renderModal = (group?: Group) => {
    setSelectedGroup(group)
    setIsAddModalOpen(true)
  }

  return (
    <>
      <Box sx={{ textAlign: 'end' }}>
        <Button
          variant="contained"
          size="large"
          disabled={!hasWrite}
          startIcon={<Icon name="Plus" />}
          onClick={() => renderModal()}
        >
          {t('MANAGE_CUSTOM_APPLICATION.CREATE_GROUP')}
        </Button>
      </Box>
      {isAddModalOpen && (
        <GroupModal
          isModalOpen={isAddModalOpen}
          heading={t(`MANAGE_CUSTOM_APPLICATION.GROUPS_MODAL_${selectedGroup ? 'EDIT' : 'NEW'}`)}
          setModalOpen={setIsAddModalOpen}
          hasWrite={hasWrite}
          handleSaveClick={handleSave}
          defaultGroup={selectedGroup}
          setCurrentPage={setCurrentPage}
        />
      )}
      <Box sx={{ marginTop: 4 }}>
        <GroupsListingTable
          hasWrite={hasWrite}
          handleEdit={renderModal}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </Box>
    </>
  )
}

export default GroupsListing
