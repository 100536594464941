import { Dispatch } from 'redux'
import { enhancementAction } from '../../index'

import { TenantList } from './types'
import { TENANTS_GET_ALL_START, TENANTS_GET_ALL_RESULT, TENANTS_GET_ALL_ERROR, getMyTenantsStore } from '.'
import { getAllTenants, getMyTenants } from '../../../services/clients/tenants'
import { HTTPResponsePagination } from '../../../services/types'
import { ParamsGetAllAction, ParamsGetAllTenantsAction } from '../../types'
import { getAllAction } from '../../utils'

export const myTenantsGetAction = (params?: ParamsGetAllAction): void =>
  getAllAction(getMyTenantsStore, getMyTenants)(params)

export const tenantsGetAllAction = (params?: ParamsGetAllTenantsAction): void =>
  enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
    const { token } = getState().application
    dispatch({ type: TENANTS_GET_ALL_START, payload: null })
    try {
      const request = {
        token,
        limit: params && params.limit ? params.limit : 10,
        filter: params && typeof params.filter === 'string' ? params.filter : '',
        page: params && params.page ? params.page : 1,
        withSites: params && params.withSites,
      }
      const data: HTTPResponsePagination<TenantList[]> = await getAllTenants(request)
      dispatch({ type: TENANTS_GET_ALL_RESULT, payload: data })
    } catch (error) {
      dispatch({ type: TENANTS_GET_ALL_ERROR, payload: error })
    }
  })
