import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Breadcrumbs,
  Card,
  CardContent,
  CardHeader,
  Icon,
  Tabs,
  Tooltip,
  Typography,
} from '@jarvis-catalyst/jarvis-ui-kit'
import { useLocation, useHistory, Link } from 'react-router-dom'
import ApplicationListing from '../applicationsListing/ApplicationsListing'
import GroupsListing from '../groupsListing/GroupsListing'
import useHasPermissionAndHasSomeScopes from '../../../../hooks/useHasPermissionAndHasSomeScopes'
import {
  applicationUiSidebarAction,
  applicationUiTenantDependAction,
} from '../../../../store/modules/application/actions'
import { ROUTES } from '../../../../const'
import useAppendQS from '../../../../hooks/useAppendQS'

const ApplicationsPage = function () {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const appendQS = useAppendQS()

  useEffect(() => {
    applicationUiSidebarAction('hidden')
    applicationUiTenantDependAction(false)
  }, [])

  const hasWrite = useHasPermissionAndHasSomeScopes({
    scope: 'subscription',
    permission: 'subscription.tenants',
    values: ['write'],
  })

  const tabs = [
    {
      key: ROUTES.APPLICATION_LIST,
      value: 0,
      title: t('APPLICATIONS'),
      content: <ApplicationListing hasWrite={hasWrite} />,
    },
    {
      key: ROUTES.GROUP_LIST,
      value: 1,
      title: t('MANAGE_CUSTOM_APPLICATION.GROUPS_TITLE'),
      content: <GroupsListing hasWrite={hasWrite} />,
    },
  ]

  const tooltipTitle = () => (
    <Typography
      variant="H200"
      color="#8B97AD"
      gutterBottom
    >
      <div style={{ display: 'flex', lineHeight: 1.5, maxWidth: 240 }}>{t('APPLICATIONS_TOOLTIP')}</div>
    </Typography>
  )

  const CustomHeader = function () {
    return (
      <section style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
        <Typography
          variant="H900"
          classes="decoration"
        >
          <div style={{ textDecoration: 'underline', marginRight: 18 }}>{t('APPLICATIONS_MANAGMENT')}</div>
        </Typography>
        <div
          className="conteiner-tooltip"
          style={{ margin: 0, display: 'flex', height: 22 }}
        >
          <Tooltip
            title={tooltipTitle()}
            placement="right-start"
          >
            <Icon
              name="Info"
              color="white"
              size="medium"
              bgcolor="#4a1eeb"
            />
          </Tooltip>
        </div>
      </section>
    )
  }

  return (
    <section style={{ padding: 20 }}>
      <Card
        variant="elevation"
      >
        <div style={{ marginLeft: 15, marginTop: 4 }}>
          <Breadcrumbs>
            <Link
              style={{ textDecoration: 'none', padding: 0 }}
              to={{ pathname: '/' }}
            >
              <Typography
                color="#a7a7a7"
                variant="h4"
              >
                {t('HOME')}
              </Typography>
            </Link>
            <Link
              style={{ textDecoration: 'none', padding: 0 }}
              to={{ pathname: '/applications' }}
            >
              <Typography
                color="primary"
                variant="h4"
              >
                {t('APPLICATIONS')}
              </Typography>
            </Link>
          </Breadcrumbs>
        </div>
        <CardHeader title={<CustomHeader />} />
        <CardContent>
          <Tabs
            sx={{ padding: 0, paddingBottom: 3 }}
            tabs={tabs}
            value={location.pathname.includes(ROUTES.GROUP_LIST) ? 1 : 0}
            onChange={(e, value) => {
              history.push(appendQS(tabs[value].key))
            }}
            sxBox={{ p: 0, paddingTop: 0, paddingBottom: 0 }}
          />
        </CardContent>
      </Card>
    </section>
  )
}

export default ApplicationsPage
