/* eslint-disable no-console */
import React from 'react'
import { Box, Divider } from '@jarvis-catalyst/jarvis-ui-kit'
import CustomPermission from './CustomPermission'
import CustomPermissionAdd from './CustomPermissionAdd'
import type { CustomPermission as CustomPermissionType } from '../../../../../store/modules/custom-permissions/types'

const CustomPermissions = function ({ hasWrite, data, setData }: any) {
  const handleCustomPermissionChange = (cp: CustomPermissionType) => {
    const newPermissions = data.map((c: CustomPermissionType) => (c.value === cp.value ? cp : c))
    setData(newPermissions)
  }

  const handleCustomPermissionRemove = (customPermission: CustomPermissionType) => {
    const newPermissions = data.filter((c: CustomPermissionType) => c.id !== customPermission.id)
    setData(newPermissions)
  }

  return (
    <>
      {hasWrite && (
        <Box>
          <CustomPermissionAdd
            customPermissions={data}
            onAdd={(customPermission: CustomPermissionType[]) => setData([...data, customPermission])}
          />
        </Box>
      )}
      {data.length > 0 && hasWrite && <Divider sx={{ border: 'none', height: 0 }} />}
      {data.map((cp: any, i: number) => (
        <div key={i}>
          <CustomPermission
            hasWrite={hasWrite}
            onRemove={handleCustomPermissionRemove}
            onChange={handleCustomPermissionChange}
            customPermission={cp}
          />
          {i + 1 < data.length && <Divider sx={{ border: 'none', height: 0 }} />}
        </div>
      ))}
    </>
  )
}

export default CustomPermissions
