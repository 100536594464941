import { useSelector } from 'react-redux'

const useAppendQS = () => {
  const { activeTenant, language } = useSelector((s: any) => s.application)
  return (s: string, onlyQS = false) => {
    let qs = `locale=${language}`
    if (activeTenant && activeTenant.id !== '') {
      const sellerKey = activeTenant.sellers[0]?.key || ''
      const sites = `${
        activeTenant?.sites ? activeTenant.sites.reduce((prev: string, curr: any) => `${prev},${curr.key}`, '') : ''
      }`.replace(',', '')
      qs += `&tenant=${activeTenant?.id}&sites=${sites}&seller=${sellerKey}`
    }
    if (onlyQS) return qs
    return `${s}?${qs}`
  }
}

export default useAppendQS
