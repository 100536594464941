import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { Column } from '@jarvis-catalyst/jarvis-ui-kit'
import { Table, Icon, Button } from '@jarvis-catalyst/jarvis-ui-kit'
import { Input } from '@jarvis-catalyst/ui-components'
import { Site } from '../../../../store/modules/sites/types'

interface Props {
  sites: any[]
  hasWrite: boolean
  wrapHandleSiteRemove: (i: number) => ({ target }: any) => void
  wrapHandleSiteKey: (i: number) => ({ target }: any) => void
  wrapHandleSiteDescription: (i: number) => ({ target }: any) => void
  wrapHandleSiteName: (i: number) => ({ target }: any) => void
}

const SiteTable: React.FC<Props> = function ({
  sites,
  hasWrite,
  wrapHandleSiteRemove,
  wrapHandleSiteKey,
  wrapHandleSiteName,
  wrapHandleSiteDescription,
}) {
  const { t } = useTranslation()
  const columns: Column[] = [
    { key: 'key', content: t('MANAGE_SITE.SITE_KEY') },
    { key: 'name', content: t('MANAGE_SITE.SITE_NAME') },
    { key: 'description', content: t('MANAGE_SITE.SITE_DESCRIPTION') },
    { key: 'action', content: '' },
  ]

  const newRows = useMemo(
    () =>
      sites.map((site: Site, i: number) => ({
        id: i.toString(),
        key: (
          <Input
            disabled={!!site.id || !hasWrite}
            placeholder={t('MANAGE_SITE.SITE_KEY_PLACEHOLDER')}
            value={site.key}
            onChange={wrapHandleSiteKey(i)}
          />
        ),
        name: (
          <Input
            disabled={!hasWrite}
            placeholder={t('MANAGE_SITE.SITE_NAME_PLACEHOLDER')}
            value={site.name}
            onChange={wrapHandleSiteName(i)}
          />
        ),
        description: (
          <Input
            disabled={!hasWrite}
            placeholder={t('MANAGE_SITE.SITE_DESCRIPTION_PLACEHOLDER')}
            value={site.description}
            onChange={wrapHandleSiteDescription(i)}
          />
        ),
        action: (
          <Button
            disabled={!hasWrite}
            size="small"
            onClick={wrapHandleSiteRemove(i)}
          >
            <Icon name="Delete" />
          </Button>
        ),
      })),
    [sites],
  )

  return (
    <Table
      columns={columns}
      rows={newRows}
    />
  )
}

export default SiteTable
