import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { rolesCreateSelector, rolesGetByIdSelector, rolesUpdateSelector } from '../../../../store/modules/roles/selectors'
import { Manage } from './Manage'

const ManageWrapper = function () {
  const { id } = useParams<any>()
  const history = useHistory()
  const roleStore = useSelector(rolesGetByIdSelector)
  const createStore = useSelector(rolesCreateSelector)
  const updateStore = useSelector(rolesUpdateSelector)
  const corePermissionsStore = useSelector((s: any) => s.customPermissions.getAllCore)
  const applicationStore = useSelector((s: any) => s.application)

  return <Manage
    history={history}
    id={id}
    roleStore={roleStore}
    createStore={createStore}
    updateStore={updateStore}
    corePermissionsStore={corePermissionsStore}
    applicationStore={applicationStore}
  />
}

export default ManageWrapper
