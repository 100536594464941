import React from 'react'
import { Col, Row, Text } from '@jarvis-catalyst/ui-components'
import { useTranslation } from 'react-i18next'

const HelpTooltip = function () {
  const { t } = useTranslation()

  return (
    <Row className="help__content__tooltip">
      <Col>
        <Text className="help__content__text">
          {t('HELP.GET_HELP')}
        </Text>
      </Col>
    </Row>
  )
}

export default HelpTooltip
