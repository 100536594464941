import { Dispatch } from 'redux'
import { getAllSitesStore, SITE_BATCH } from '.'
import { enhancementAction } from '../..'
import { getAllSites, updateSite, createSite, deleteSite } from '../../../services/clients/sites'
import { ParamsGetAllAction } from '../../types'
import { getAllAction, resetAction } from '../../utils'
import { Site } from './types'

type Data = {
  delete: string[]
  update: Site[]
  create: Site[]
}

export const sitesGetAllAction = (params?: ParamsGetAllAction): void =>
  getAllAction(getAllSitesStore, getAllSites)(params)

export const sitesGetAllResetAction = (): void => resetAction(getAllSitesStore)()

export const sitesBatchResetAction = (): void =>
  enhancementAction(async (dispatch: Dispatch) => {
    dispatch({ type: SITE_BATCH.SITES_BATCH_RESET, payload: null })
  })

export const sitesBatchAction = (data: Data): void =>
  enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
    const { token } = getState().application
    const { data: dataSites } = getState().sites.getAll
    dispatch({ type: SITE_BATCH.SITES_BATCH_START, payload: null })
    try {
      for (const siteCreate of data.create) {
        try {
          await createSite({ token, data: siteCreate })
          dispatch({
            type: SITE_BATCH.SITE_BATCH_ADD,
            payload: {
              type: 'create',
              data: siteCreate,
            },
          })
        } catch (_) {
          dispatch({
            type: SITE_BATCH.SITES_BATCH_ERROR_ADD,
            payload: { type: 'update', data: siteCreate },
          })
        }
      }

      for (const siteUpdate of data.update) {
        try {
          await updateSite({ id: siteUpdate.id || '', token, data: siteUpdate })
          dispatch({
            type: 'SITES_BATCH_ADD',
            payload: {
              type: 'update',
              data: siteUpdate,
            },
          })
        } catch (_) {
          dispatch({ type: 'SITES_BATCH_ERROR_ADD', payload: { type: 'update', data: siteUpdate } })
        }
      }

      for (const siteDeleteId of data.delete) {
        try {
          await deleteSite({ id: siteDeleteId, token })
          dispatch({
            type: 'SITES_BATCH_ADD',
            payload: {
              type: 'delete',
              data: siteDeleteId,
            },
          })
        } catch (_) {
          dispatch({
            type: 'SITES_BATCH_ERROR_ADD',
            payload: {
              type: 'delete',
              data: dataSites.find((e: any) => e.id === siteDeleteId),
            },
          })
        }
      }

      dispatch({ type: 'SITES_BATCH_END', payload: null })
    } catch (_) {
      dispatch({ type: 'SITES_BATCH_END', payload: null })
    }
  })
