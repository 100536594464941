import React, { useEffect, useState } from 'react'
import {
  Classes,
  Spinner,
} from '@jarvis-catalyst/ui-components'
import TableBody from './TableBody'
import TableHeader from './TableHeader'
import './Table.css'

export interface Header {
		cells: Cell[];
}

interface Cell {
		key: string;
		content: React.ReactNode;
		isSortable?: boolean;
		colSpan?: number;
}

export interface Row {
		key: string;
		cells: Cell[];
		isActive?: boolean;
		hasCheckbox: boolean;
		className?: string;
}

export interface RowsStore {
  list: string[];
  add: (id: string) => void;
  remove: (id: string) => void;
  clear: () => void;
}

interface Props {
		header: Header;
		rows: Row[];
		isLoading?: boolean;
		emptyMessage?: string;
		errorMessage?: string;
		testId?: string;
		disabled?: boolean;
		hasHeaderCheckbox: boolean;
    selectedRowsStore?: RowsStore;
}

const Table: React.FC<Props> = function ({
  header,
  rows,
  emptyMessage,
  errorMessage,
  isLoading = false,
  disabled = false,
  testId,
  hasHeaderCheckbox,
  selectedRowsStore,
}: Props) {
  const cssClass = new Classes('app__table')
    .if(!rows.length || !!errorMessage)
    .add('app__table--empty')

  const numberOfColumns = header.cells.length
  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const checkRowsInStore = () => {
    const rowsInMemory : string[] = []
    selectedRowsStore && selectedRowsStore.list.forEach((key) => {
      if (selectedRowsStore && selectedRowsStore.list.includes(key)) {
        rowsInMemory.push(key)
      }
    })
    setSelectedRows && setSelectedRows(rowsInMemory)
  }

  useEffect(() => {
    checkRowsInStore()
  }, [rows])

  return (
    <div data-testid={testId}>
      {isLoading && <Spinner isCompleting={!isLoading} />}
      <table className={cssClass.build()}>
        <TableHeader
          headers={header}
          setSelected={setSelectedRows}
          rows={rows}
          disabled={disabled}
          selected={selectedRows}
          hasHeaderCheckbox={hasHeaderCheckbox}
          selectedRowsStore={selectedRowsStore}
        />
        <TableBody
          rows={rows}
          errorMessage={errorMessage}
          emptyMessage={emptyMessage}
          columnLength={selectedRowsStore ? numberOfColumns + 1 : numberOfColumns}
          setSelected={setSelectedRows}
          selected={selectedRows}
          disabled={disabled}
          isLoading={isLoading}
          selectedRowsStore={selectedRowsStore}
        />
      </table>
    </div>
  )
}

export default Table
