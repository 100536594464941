import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Typography, Box, Grid } from '@jarvis-catalyst/jarvis-ui-kit'
import { AppInfoProps } from '../../dashboard.types'

const AppModalInfo = function ({ description, open = false, links, icon, title }: AppInfoProps) {
  const { t } = useTranslation()

  return (
    <Modal open={open}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={2}
        >
          <Box
            sx={{
              display: 'flex',
              padding: '10px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
              backgroundColor: 'rgba(234, 236, 254, 1)',
              marginTop: '3px',
              height: '85%',
            }}
          >
            {icon}
          </Box>
        </Grid>
        <Grid
          item
          xs={10}
        >
          <Box sx={{ marginTop: 0.5 }}>
            <Box sx={{ marginBottom: 3 }}>
              <Typography variant="H700">{t(title)}</Typography>
            </Box>
            <Typography
              variant="h4"
              align="justify"
            >
              {t(description)}
            </Typography>
          </Box>
          <ul>
            {links.map((link, index) => (
              <li key={index}>
                <a
                  style={{ padding: 0, color: 'rgba(117, 105, 244, 1)' }}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography
                    color="primary"
                    variant="h4"
                  >
                    {t(link.title)}
                  </Typography>
                </a>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default AppModalInfo
