import React from 'react'
import { Badge } from '@jarvis-catalyst/ui-components'
import { BadgeProps } from '@jarvis-catalyst/ui-components/lib/components/badge/Badge'
import config from '../../shell/config'

import './Header.css'

enum Env {
  DEV = 'DEV',
  STAGING = 'STAGING',
  PROD = 'PROD',
}

const EnvironmentIdentifier = function () {
  const env = config.REACT_APP_ENVIRONMENT?.toUpperCase()

  const dev = env?.includes(Env.DEV)
  const staging = env?.includes(Env.STAGING)
  const prod = env?.includes(Env.PROD)

  const getColor = (): BadgeProps['color'] => {
    if (dev) return 'info'
    if (staging) return 'warning'
    if (prod) return 'danger'
    return 'default'
  }
  return env ? (
    <Badge
      className="header__environment__identifier"
      id="environment__identifier"
      color={getColor()}
    >
      {env}
    </Badge>
  ) : (
    <span />
  )
}

export default EnvironmentIdentifier
