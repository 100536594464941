import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@jarvis-catalyst/jarvis-ui-kit'
import { JARVIS_DOCUMENTATION } from '../../dashboard.constants'
import { questions } from '../../utils/questions'
import QuestionAndAnswer from '../questionandanswer/QuestionAndAnswer'
import LoadMoreBox from '../loadmore/LoadMore'

const QuestionSection = function () {
  const { t } = useTranslation()
  return (
    <Box>
      <Box sx={{ marginTop: '16px' }}>
        <Typography
          variant="H900"
          color="primary"
        >
          <Box sx={{ fontWeight: 'bolder', marginTop: '4.5px' }}>{t('QUESTIONS_TITLE')}</Box>
        </Typography>
        <Box sx={{ marginTop: 1, marginBottom: 3 }}>
          <Typography
            variant="h3"
            color="text"
          >
            {t('QUESTIONS_SUBTITLE')}
            <a
              href={JARVIS_DOCUMENTATION}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: 4, textDecoration: 'none', color: 'rgba(117, 105, 244, 1)', fontWeight: 'bolder' }}
            >
              {t('FULL_DOCUMENTATION')}
            </a>
          </Typography>
        </Box>
      </Box>

      <Grid container>
        <LoadMoreBox height={460}>
          {questions.map(({ question, answer, open }) => (
            <Grid
              item
              xs={12}
            >
              <QuestionAndAnswer
                title={question}
                points={answer?.points}
                description={answer.description}
                open={open}
                footer={answer?.footer}
                links={answer?.links}
              />
            </Grid>
          ))}
        </LoadMoreBox>
      </Grid>
    </Box>
  )
}

export default QuestionSection
