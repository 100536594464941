import React, { useEffect } from 'react'
import LogsListing from '../components/listing/LogsListing'
import { applicationUiSidebarAction, applicationUiTenantDependAction } from '../../../store/modules/application/actions'

export const LogsListingByTenant = function () {
  useEffect(() => {
    applicationUiSidebarAction('full')
    applicationUiTenantDependAction(true)
  }, [])

  return (
    <div>
      <LogsListing tenant />
    </div>
  )
}
