import { IconProps } from '@jarvis-catalyst/ui-components/lib/components/icon/Icon'
import { ROUTE_PREFIX, DEFAULT_URI_START_APP } from '../../../const'
import { Permissions } from '../../../hooks/useAppPermissions'
import { Contact, Application, MenuItem, NAVIGATION } from '../../../store/modules/applications/types'

type LocaleString = {
    en: string,
    es: string
};

export type Item = {
    id?: string;
    icon?: IconProps['name'];
    isActive?: boolean;
    route?: string;
    name?: string | LocaleString;
    url?: string;
    type: 'divider' | 'item' | 'label' | 'labelMenu';
    hidden?: boolean;
    submenu?: Array<Item>;
    description?: string | LocaleString;
    initAppWithRoute?: boolean;
    navigationType?: NAVIGATION;
    metadata?: { contactInfo: Contact[] },
    groups?: any[];
    appType?: string;
    microApplication?: boolean;
}

const menu = () => {
  let m: Item[] = []
  const build = () => m

  const add = (i: Item) => {
    if (!m.find((item: Item) => i.type === 'item' && item.route === i.route)) {
      m.push(i)
      i.submenu?.map((sub) => m.push(sub))
    }
  }

  const reset = () => {
    m = []
  }

  return {
    reset,
    add,
    build,
  }
}

export const addAppsHeader = (m: any) => {
  m.add({
    id: 'customApps',
    name: 'INTEGRATED_APPS',
    type: 'label',
  })
  return m
}

export const addHome = (m: any) => {
  m.add({
    id: 'menu',
    name: 'MENU',
    type: 'labelMenu',
  })

  m.add({
    id: 'Home',
    route: DEFAULT_URI_START_APP,
    url: '',
    icon: 'Home',
    name: 'HOME',
    isActive: true,
    type: 'item',
  })
  return m
}

export const addApps = (m: any, apps: Application[], permissions: Permissions, language: 'en' | 'es') => {
  const type = 'item'

  const getRoute = (r: string) => `${ROUTE_PREFIX}${r}`

  const getActive = (isActive: any, menu: any) => isActive && permissions.customApp(menu)

  const compareName = (a: Application, b: Application) => (a.menu.name[language] > b.menu.name[language] ? 1 : -1)

  const compareIndex = (a: MenuItem, b: MenuItem) => (a.index || 0) - (b.index || 0)

  apps.sort(compareName).map((app: any) => m.add(
    {
      ...app,
      ...app.menu,
      type,
      icon: app.menu.icon || 'Circle',
      route: getRoute(app.menu.route),
      isActive: getActive(app.isActive, app.menu),
      microApplication: true,
      submenu: app.menu.submenu.sort(compareIndex).map((sub: MenuItem) => (
        {
          ...sub,
          type,
          route: getRoute(`${app.menu.route}${sub.route}`),
          isActive: getActive(app.isActive, sub),
          hidden: true,
          icon: undefined,
        }
      )),
    },
  ))
  m.add({
    type: 'divider',
  })
  return m
}

export default menu()
