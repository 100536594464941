/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Typography, Box, CardContent, Card, Icon, Tooltip, Breadcrumbs } from '@jarvis-catalyst/jarvis-ui-kit'

import TenantsListing from '../../components/tenantsListing/TenantsListing'
import TenantModal from '../../components/tenantModal/index'
import DeleteTenantModal from '../../components/deleteTenantModal/index'
import useTenantsContext from '../../contexts/TenantsContext'

import './Tenants.css'
import {
  applicationUiSidebarAction,
  applicationUiTenantDependAction,
} from '../../../../store/modules/application/actions'
import useHasPermissionAndHasSomeScopes from '../../../../hooks/useHasPermissionAndHasSomeScopes'
import useDocumentTitle from '../../../../hooks/useDocumentTitle'

const Tenants = function () {
  const { t } = useTranslation()
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [selectedTenant, setSelectedTenant] = useState({})
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const { createTenant, updateTenant, deleteTenant } = useTenantsContext()

  const hasWrite = useHasPermissionAndHasSomeScopes({
    scope: 'subscription',
    permission: 'subscription.tenants',
    values: ['write'],
  })

  React.useEffect(() => {
    applicationUiSidebarAction('hidden')
    applicationUiTenantDependAction(false)
  }, [])

  useDocumentTitle(t('MANAGE_TENANTS'))

  const tooltipTitle = () => (
    <Typography
      variant="H200"
      color="#8B97AD"
      gutterBottom
    >
      <div style={{ display: 'flex', lineHeight: 1.5 }}>{t('MANAGE_TENANTS_TOOLTIP')}</div>
    </Typography>
  )

  return (
    <section style={{ padding: 20 }}>
      <Card variant="elevation">
        <div style={{ marginLeft: 15, marginTop: 4 }}>
          <Breadcrumbs>
            <Link
              style={{ textDecoration: 'none', padding: 0 }}
              to={{ pathname: '/' }}
            >
              <Typography
                color="#a7a7a7"
                variant="h4"
              >
                {t('HOME')}
              </Typography>
            </Link>
            <Link
              style={{ textDecoration: 'none', padding: 0 }}
              to={{ pathname: '/tenants' }}
            >
              <Typography
                color="primary"
                variant="h4"
              >
                {t('TENANT')}
              </Typography>
            </Link>
          </Breadcrumbs>
        </div>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
            <Typography variant="H900">
              <div style={{ textDecoration: 'underline', marginRight: 16 }}>{t('MANAGE_TENANTS')}</div>
            </Typography>
            <Tooltip
              title={tooltipTitle()}
              placement="right-start"
            >
              <Icon
                name="Info"
                color="white"
                size="medium"
                bgcolor="#4a1eeb"
              />
            </Tooltip>
          </Box>
        </CardContent>
        <CardContent>
          {isAddModalOpen && (
            <TenantModal
              hasWrite={hasWrite}
              heading={t('CREATE_TENANT_HEADING')}
              isModalOpen={isAddModalOpen}
              handleModalClose={() => setIsAddModalOpen(false)}
              handleSaveClick={createTenant}
            />
          )}
          <TenantsListing
            hasWrite={hasWrite}
            setIsAddModalOpen={setIsAddModalOpen}
            handleDelete={(tenant) => {
              setSelectedTenant(tenant)
              setIsDeleteModalOpen(true)
            }}
            handleEdit={(tenant) => {
              setSelectedTenant(tenant)
              setIsEditModalOpen(true)
            }}
          />
          {isEditModalOpen && (
            <TenantModal
              heading={t('EDIT_TENANT_HEADING')}
              tenant={selectedTenant}
              isModalOpen={isEditModalOpen}
              handleModalClose={() => setIsEditModalOpen(false)}
              handleSaveClick={updateTenant}
              hasWrite={hasWrite}
            />
          )}
          {isDeleteModalOpen && (
            <DeleteTenantModal
              heading={t('DELETE_TENANT_HEADING')}
              tenant={selectedTenant}
              isModalOpen={isDeleteModalOpen}
              handleModalClose={() => setIsDeleteModalOpen(false)}
              handleSaveClick={deleteTenant}
            />
          )}
        </CardContent>
      </Card>
    </section>
  )
}

export default Tenants
