import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button, ButtonActionGroup, Icon, Tab, Modal } from '@jarvis-catalyst/ui-components'
import TenantsListing from './TenantsListing'
import './TenantSiteSelector.css'
import SitesListing from './SitesListing'
import { applicationSetConfigurationAction } from '../../../../store/modules/application/actions'
import { updateParams } from '../../../shell/utils/router'
import { Tenant } from '../../../../store/modules/tenants/types'
import { Site } from '../../../../store/modules/sites/types'

type TenantSelectorProps = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  tenant?: Tenant
  site?: Site
}

const TenantSiteSelector = function ({ isOpen, setOpen, tenant, site }: TenantSelectorProps) {
  const { t } = useTranslation()
  const history = useHistory()
  const [selectedTenant, setSelectedTenant] = useState(tenant)
  const [selectedSite, setSelectedSite] = useState<Site>()
  const [tabSelected, setTabSelected] = useState(0)

  useEffect(() => {
    setSelectedTenant(tenant)
  }, [tenant])

  useEffect(() => {
    site && selectedTenant?.id === tenant?.id
      ? setSelectedSite(site)
      : setSelectedSite({ id: selectedTenant?.id, key: '', name: 'Default', description: 'Default', tenant: '' })
  }, [selectedTenant, tenant, site])

  const tabs = [
    {
      key: 'tenant',
      title: t('GLOBAL_APPS.TENANT_SITE_SELECTOR.SELECT_TENANT'),
      body: (
        <TenantsListing
          selectedTenant={selectedTenant}
          setSelectedTenant={setSelectedTenant}
        />
      ),
    },
    {
      key: 'site',
      title: t('GLOBAL_APPS.TENANT_SITE_SELECTOR.SELECT_SITE'),
      body: selectedTenant && (
        <SitesListing
          selectedTenant={selectedTenant}
          selectedSite={selectedSite}
          setSelectedSite={setSelectedSite}
        />
      ),
      disabled: !selectedTenant,
    },
  ]

  const handleClose = () => {
    setSelectedTenant(tenant)
    setSelectedSite(site)
    setTabSelected(0)
    setOpen(false)
  }

  const handleChange = (page: any) => {
    if (page.key === 'site') {
      setTabSelected(1)
    } else setTabSelected(0)
  }

  const handleCancel = () => (tabSelected === 0 ? handleClose() : setTabSelected(0))

  const handleConfirm = () => {
    if (selectedTenant) {
      applicationSetConfigurationAction({
        key: 'activeTenant',
        value: {
          id: selectedTenant.id,
          name: selectedTenant.name,
          sites: [selectedSite],
        },
      })
      updateParams(history, { tenant: selectedTenant.id, sites: selectedSite?.key })
    }
  }

  const handleSave = () => {
    if (tabSelected === 0) {
      setTabSelected(1)
    } else {
      handleConfirm()
      handleClose()
    }
  }

  return (
    <Modal isOpen={isOpen}>
      <div className="tenant-site-selector">
        <Button
          color="link"
          title="Back"
          iconLeft={<Icon name="ArrowLeft" />}
          onClick={handleCancel}
        />
        <Tab
          setIndexPage={tabSelected}
          pages={tabs}
          onChange={handleChange}
        />
        <ButtonActionGroup
          textSave={t(tabSelected === 0 ? 'CONTINUE' : 'SAVE')}
          textCancel={t('CANCEL')}
          onCancel={handleCancel}
          onSave={handleSave}
          disabledSave={tabSelected === 0 ? !selectedTenant : !selectedSite}
        />
      </div>
    </Modal>
  )
}

export default TenantSiteSelector
