import React from 'react'
import { GridItem, Grid } from '@jarvis-catalyst/ui-components'

type MainProps = {
    children: React.ReactNode;
}

const Main = function ({ children }: MainProps) {
  return (
    <GridItem
      columnStart={2}
      colSpan={12}
      className="layout__main__wrapper"
    >
      <Grid
        repeatNumber={24}
        repeatTemplate="1fr"
        gap={10}
        className="layout__main"
      >
        {children}
      </Grid>
    </GridItem>
  )
}

export default Main
