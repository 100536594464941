import React, { useEffect } from 'react'
import { Grid, Col, Badge, GridItem, Icon, Row, Title, Text } from '@jarvis-catalyst/ui-components'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useAppendQS from '../../../../hooks/useAppendQS'
import './AppListing.css'
import { isUID } from '../../../../utils/stringHelpers'
import { jarvisApps, includesGroup } from './utils/listingHelpers'
import { ROUTES } from '../../../../const'
import { applicationResetCustomAppUrl, applicationUiSidebarAction } from '../../../../store/modules/application/actions'
import { TenantSiteViewer } from '../tenantSiteViewer/TenantSiteViewer'
import { ApplicationPayload } from '../../../../store/modules/applications/types'

type AppListingProps = {
    groupName: string;
    groupId?: string;
}

const DRB_GROUP = 'digital-retail'

const AppListing = function ({ groupName, groupId }: AppListingProps) {
  const { t } = useTranslation()
  const appendQS = useAppendQS()
  const history = useHistory()
  let index = 1
  const { activeTenant, language, userHasTenant } = useSelector((s: any) => s.application)
  const isTenantSelected = activeTenant && isUID(activeTenant.id)
  const { data: globalApps, success: isLoadedApps } = useSelector((s: any) => s.applications.getAll)
  const { data: tenantApps, success: isLoadedTenantApps } = useSelector((s: any) => s.applications.getAllTenant)
  const { success: isLoadedGroups } = useSelector((s: any) => s.groups.getAll)

  const getApps = (groupId: string | undefined): any[] => {
    let apps = []

    if (userHasTenant) {
      apps = tenantApps.map((tenantApp: any) => {
        const app = { ...tenantApp, isTenantApp: true }
        return app
      })
    }

    const appsListed: any[] = globalApps.concat(apps).concat(jarvisApps)
    if (groupId && isUID(groupId)) {
      return appsListed.filter((i) => Array.isArray(i.groups) && includesGroup(i.groups, groupId))
    }
    return appsListed
  }

  useEffect(() => {
    history.replace(appendQS(`${ROUTES.GLOBAL_APPS}/${groupName}`))
  }, [])

  const handlerClick = () => {
    applicationUiSidebarAction('full')
    applicationResetCustomAppUrl()
  }

  const makeLink = (children: any, route: string) =>
    <Link
      to={appendQS(route)}
      onClick={handlerClick}
    >
      {children}
    </Link>

  const translate = (text: any) => (typeof text !== 'string' ? text[language] : t(`${text}`))

  const getIconName = (app: any) => (app.menu ? app.menu.icon : app.icon)

  const getElement = (app: any) => {
    const isActiveApp = (app.isTenantApp && app.isActive && isTenantSelected) || !app.isTenantApp

    return (
      <div data-testid={app.id}>
        <Row className={`app__${isActiveApp ? 'active' : 'inactive'}`}>
          <Icon
            name={getIconName(app) ? getIconName(app) : 'Circle'}
            color={app.isActive ? '#7569F4' : '#8B97AD'}
            size={36}
          />
          <Col>
            {app.groups.length > 0 && (
              <Row className="app__badge">
                <Badge className="app__badge">{app.groups[0].name.toUpperCase()}</Badge>
              </Row>
            )}
            <Row className="name">{app.menu ? translate(app.menu.name) : app.name}</Row>
            <Row className="description">{app.description ? translate(app.description) : 'Sample description'}</Row>
            {!isActiveApp && (
              <Row className="locked__description">{t('GLOBAL_APPS.LOCKED_DESCRIPTION')}</Row>
            )}
          </Col>
          {!isActiveApp && (
            <Icon
              name="Lock"
              color="#7569F4"
              size={16}
              id="lock__icon"
            />
          )}
        </Row>
      </div>
    )
  }

  const display = (app: ApplicationPayload) => {
    const startIndex = index
    const nextIndex = index + 3
    index = nextIndex < 12 ? nextIndex : 1
    return (
      <React.Fragment key={app.id}>
        <GridItem
          columnStart={startIndex}
          colSpan={2}
          className="apps"
        >
          {app.isActive && app.route && isTenantSelected
            ? makeLink(getElement(app), app.route)
            : getElement(app)}
        </GridItem>
      </React.Fragment>
    )
  }

  return (
    <>
      {groupName === DRB_GROUP && <TenantSiteViewer />}
      <Grid
        repeatNumber={12}
        repeatTemplate="1fr"
        gap={10}
      >
        {getApps(groupId)
          .map((app) => display(app))}
      </Grid>
      {!isLoadedApps && !isLoadedTenantApps && !isLoadedGroups
                && <div
                  id="globalapps-error"
                  className="uci__error-result"
                >
                  <Icon
                    name="Warning"
                    size={48}
                    color="var(--c-danger)"
                  />
                  <Title tag="h4">{t('GLOBAL_APPS.APPS_ERROR_TITLE')}</Title>
                  <Text>
                    {t('GLOBAL_APPS.APPS_ERROR_TEXT')}
                    {' '}
                  </Text>
                </div>}
    </>
  )
}

export default AppListing
