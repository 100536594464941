import { useEffect } from 'react'
import { useHistory, Route } from 'react-router-dom'
import useAppendQS from '../../../../hooks/useAppendQS'
import ErrorPage from '../../modules/ErrorPage'
import ApplicationError from '../applicationError'

interface ComponentWithRouterErrorProps {
  errorMessageTenant: string
}

export const ComponentWithRouterError = function ({ errorMessageTenant }: ComponentWithRouterErrorProps) {
  const appendQs = useAppendQS()
  const history = useHistory()

  useEffect(() => {
    history.push(appendQs('/error'))
  }, [])

  return (
    <ErrorPage>
      <Route
        path="/error"
        component={() => <ApplicationError errorMessageTenant={errorMessageTenant} />}
      />
    </ErrorPage>
  )
}
