import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ManageUserForm from './components/ManageUserForm'
import { Account } from './components/manage-user.type'

import { tenantsGetAllAction } from '../../../../store/modules/tenants/actions'
import {
  batchCreateUsersAction,
  batchCreateUsersResetAction,
} from '../../../../store/modules/users/actions'
import { rolesGetAllActionV2 } from '../../../../store/modules/roles/actions'
import { usersBatchCreateSeletor } from '../../../../store/modules/users/selectors'
import { getAllTenantsFilter } from '../../../../utils/filters'
import { sendDangerNotification, sendSuccessNotification } from '../../../../utils/sendNotification'
import { ROUTES } from '../../../../const'
import useAppendQS from '../../../../hooks/useAppendQS'
import useHasPermissionAndHasSomeScopes from '../../../../hooks/useHasPermissionAndHasSomeScopes'
import { applicationUiSidebarAction } from '../../../../store/modules/application/actions'
import { dataIsLoad } from '../../../../store/utils'
import {
  rolesGetAllSecondSelector,
  rolesGetAllSelector, rolesGetAllSellersSelector,
  rolesGetAllSitesSelector,
} from '../../../../store/modules/roles/selectors'
import { tenantsGetAllSelector } from '../../../../store/modules/tenants/selectors'
import useAuthenticationContextWrapper from '../../../shell/contexts/AuthenticationContextWrapper'
import { sellersGetAllAction } from '../../../../store/modules/sellers/actions'

export const callSave = (account: Account) => {
  const users = account.users.map((email) => ({
    email,
    isTestingAccount: account.isTestingAccount,
    roles: account.roles.map((r) => r.value),
    tenantRoles: account.tenantRoles.map((tenant) => ({
      id: tenant.id.value,
      roles: tenant.roles.map((r) => r.value),
      siteRoles: tenant.siteRoles.id.map((site) => ({
        id: site.value,
        roles: tenant.siteRoles.roles.map((r) => r.value),
      })),
      sellerRoles: tenant.sellerRoles.id.map((seller) => ({
        id: seller.value,
        roles: tenant.sellerRoles.roles.map((r) => r.value),
      })),
    })),
  }))
  batchCreateUsersAction(users)
}

export const useAfterCommit = (userBatchCreateStore: any) => {
  const { t } = useTranslation()
  const history = useHistory()
  const appendQs = useAppendQS()

  useEffect(() => {
    if (userBatchCreateStore.success && !userBatchCreateStore.loading) {
      history.push(appendQs(ROUTES.USER_LIST))
      batchCreateUsersResetAction()
      sendSuccessNotification(`${userBatchCreateStore.data?.length} - ${t('BATCH_CREATE.CREATED')}`)
    }

    if ((userBatchCreateStore.error && !userBatchCreateStore.loading)) {
      userBatchCreateStore.errorData.map((d: any) =>
        sendDangerNotification(`${d.email} - ${t(`BATCH_CREATE.${d.errorMessage}`)}`))
    }
  }, [userBatchCreateStore])
}

const AddUser = function () {
  const { t } = useTranslation()
  const { isAdmin } = useAuthenticationContextWrapper()
  const userBatchCreateStore = useSelector(usersBatchCreateSeletor)
  const rolesStore = useSelector(rolesGetAllSelector)
  const rolesStoreByTenant = useSelector(rolesGetAllSecondSelector)
  const rolesBySiteStore = useSelector(rolesGetAllSitesSelector)
  const rolesBySellerStore = useSelector(rolesGetAllSellersSelector)
  const tenantsStore = useSelector(tenantsGetAllSelector)
  const sellerStore = useSelector((s: any) => s.sellers.getAll)

  const [init, setInit] = useState(false)

  const hasWrite = useHasPermissionAndHasSomeScopes({
    scope: 'subscription',
    permission: 'subscription.jarvisusers',
    values: ['write'],
  }) || isAdmin

  React.useEffect(() => {
    applicationUiSidebarAction('hidden')
    batchCreateUsersResetAction()
    tenantsGetAllAction(getAllTenantsFilter({ page: 1, limit: 100, status: 'active', withSites: true }))
    sellersGetAllAction({ page: 1, limit: 100 })
    rolesGetAllActionV2({ limit: 100, page: 1, scope: 'SUBSCRIPTION' })
    rolesGetAllActionV2({ limit: 100, page: 1, scope: 'TENANT' }, 'second')
    rolesGetAllActionV2({ limit: 100, page: 1, scope: 'SITE' }, 'sites')
    rolesGetAllActionV2({ limit: 100, page: 1, scope: 'SELLER' }, 'sellers')
  }, [])

  useEffect(() => {
    if (
      dataIsLoad(rolesStore)
        && dataIsLoad(rolesStoreByTenant)
        && dataIsLoad(rolesBySiteStore)
        && dataIsLoad(rolesBySellerStore)
        && dataIsLoad(tenantsStore)
        && dataIsLoad(sellerStore)
    ) {
      setInit(true)
    }
  }, [tenantsStore, rolesStoreByTenant, rolesStore, rolesBySiteStore, rolesBySellerStore, sellerStore, setInit])

  useAfterCommit(userBatchCreateStore)

  return (
    <ManageUserForm
      isLoading={userBatchCreateStore.loading}
      callSave={callSave}
      hasWrite={hasWrite}
      pageTitle={t('ADD_USERS_HEADING')}
      init={init}
    />
  )
}

export default AddUser
