import React, { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form/dist/types/form'
import { Grid, Typography, Button, Icon, TextField } from '@jarvis-catalyst/jarvis-ui-kit'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { ApplicationPayload } from '../../../../store/modules/applications/types'
import { PermissionsSelector } from './PermissionsSelector'
import { ROUTE_PREFIX } from '../../../../const'
import './ApplicationManagment.css'
import { regexRoute } from '../../../../utils/validations'
import IconSelectorModal from './CardMenuIconSelector'
import { AvailableMenuIcons, DefaultIcon, getMenuIconByName, MenuIcon } from './CardMenuIconSelectorList'

type MenuProps = {
  form: UseFormReturn<ApplicationPayload>
  defaultName?: { en: string; es: string }
  defaultRoute?: string
  defaultIcon?: string
  fieldName: string
  handleRemove?: any
  permissions?: any
  index?: number
}

const ApplicationMenu = function ({
  form,
  defaultName,
  defaultRoute,
  defaultIcon,
  permissions = [],
  fieldName,
  index,
}: MenuProps) {
  const { t } = useTranslation()
  const {
    control,
    watch,
    register,
    setValue,
    formState: { errors },
  } = form
  const route = watch('route')
  const [isIconSelectorOpen, setIsIconSelectorOpen] = useState<boolean>(false)
  const [currentSubRoute, setCurrentSubRoute] = useState(defaultRoute)
  const [selectedMenuIcon, setSelectedMenuIcon] = useState<MenuIcon['name']>(DefaultIcon.name)
  const isMenu = !fieldName.includes('menu.submenu')
  const handleCloseModal = () => {
    setIsIconSelectorOpen(false)
  }

  const getIconNameList = () => AvailableMenuIcons.map((menu) => String(menu.name))
  const setMenuIcon = (icon: any) => {
    setSelectedMenuIcon(icon)
    setValue('menu.icon', icon)
    setIsIconSelectorOpen(false)
  }

  const hasError = (errors?: any) => {
    if (index !== undefined) {
      return errors?.menu?.submenu && errors?.menu?.submenu[index]
    }
    return errors?.menu
  }

  useEffect(() => {
    setValue('menu.route', route)
    const menuIcon = getMenuIconByName(defaultIcon)

    if (isMenu) {
      if (menuIcon) {
        setMenuIcon(menuIcon.name)
      } else {
        setMenuIcon(DefaultIcon.name)
      }
    }
  }, [route, defaultIcon])

  return (
    <>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          sm={6}
        >
          <Grid
            container
            rowSpacing={2}
          >
            <Grid
              item
              sm={12}
            >
              <Controller
                name={`${fieldName}.name.en` as any}
                defaultValue={defaultName?.en || ''}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, ref } }) => (
                  <TextField
                    fullWidth
                    label={t('MANAGE_CUSTOM_APPLICATION.NAME_EN')}
                    error={!!hasError(errors)?.name?.en}
                    helperText={hasError(errors)?.name?.en ? t('HINT_REQUIRED_FIELD') : ''}
                    value={value}
                    inputRef={ref}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              sm={12}
            >
              <Controller
                name={`${fieldName}.name.es` as any}
                defaultValue={defaultName?.es || ''}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, ref } }) => (
                  <TextField
                    fullWidth
                    label={t('MANAGE_CUSTOM_APPLICATION.NAME_ES')}
                    error={!!hasError(errors)?.name?.es}
                    helperText={hasError(errors)?.name?.es ? t('HINT_REQUIRED_FIELD') : ''}
                    value={value}
                    inputRef={ref}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sm={6}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              sm={12}
            >
              <div>
                {fieldName === 'menu' && <Typography variant="h3">{t('MANAGE_CUSTOM_APPLICATION.ROUTE')}</Typography>}
                <span className="application">
                  {ROUTE_PREFIX}
                  {route}
                  {currentSubRoute}
                </span>
              </div>
            </Grid>
            <Grid
              item
              sm={12}
            >
              {fieldName !== 'menu' && (
                <div style={{ marginTop: 35, display: 'flex' }}>
                  <Controller
                    name={`${fieldName}.route` as any}
                    defaultValue={defaultRoute || ''}
                    control={control}
                    rules={{ required: true, pattern: regexRoute }}
                    render={({ field: { onChange, value, ref } }) => (
                      <TextField
                        fullWidth
                        label={t('MANAGE_CUSTOM_APPLICATION.ROUTE_SUBMENU')}
                        error={!!errors?.menu?.submenu}
                        value={value}
                        inputRef={ref}
                        onChange={(e): any => {
                          setCurrentSubRoute(e.target.value)
                          onChange(e.target.value)
                        }}
                        helperText={t(hasError(errors)?.name?.route?.type === 'pattern' ? 'HINT_INVALID_ROUTE' : errors?.menu?.submenu ? 'HINT_REQUIRED_FIELD' : '')}
                        placeholder={t('MANAGE_CUSTOM_APPLICATION.ROUTE_PLACEHOLDER')}
                        color={hasError(errors)?.name?.route?.type === 'pattern' ? 'error' : 'primary'}
                      />
                    )}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isMenu && (
        <div className="application_mgmt--icon-selector">
          <div style={{ marginTop: 15, marginBottom: 10 }}>
            <Typography variant="H300">{t('MANAGE_CUSTOM_APPLICATION.MENU_ICON_TITLE')}</Typography>
          </div>
          <div style={{ display: 'flex', justifyItems: 'center', alignItems: 'center' }}>
            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
              <Icon
                name={selectedMenuIcon as any}
                size="medium"
                color="var(--c-primary)"
              />
            </div>
            <Button
              variant="outlined"
              id="btn-change-icon"
              size="medium"
              onClick={() => setIsIconSelectorOpen(true)}
            >
              {t('MANAGE_CUSTOM_APPLICATION.MENU_ICON_CHANGE')}
            </Button>
          </div>
          {isIconSelectorOpen && (
            <IconSelectorModal
              setSelectedIcon={setMenuIcon}
              onClose={handleCloseModal}
              iconList={getIconNameList()}
            />
          )}
        </div>
      )}
      <input
        hidden
        defaultValue={selectedMenuIcon}
        {...register(`${fieldName}.icon` as any)}
      />
      <PermissionsSelector
        permissions={permissions}
        control={control}
        name={`${fieldName}.permissions`}
      />
    </>
  )
}
export default ApplicationMenu
