import { Card, CardBody, Container, Col, Row, Divider } from '@jarvis-catalyst/ui-components'
import React, { Suspense } from 'react'
import styles from './Error.module.css'

const Cod000UCrashInitLogin = React.lazy(() => import('../../components/cod-000-crash-init-login-provider/Cod000CrashInitLogin'))
const Cod001UserHasNoTenant = React.lazy(() => import('../../components/cod-001-user-has-no-tenant/Cod001UserHasNoTenant'))
const CrashDuringInit = React.lazy(() => import('../../components/cod-002-to-005-crash-during-init/CrashDuringInit'))
const Cod006UserHasNoAccess = React.lazy(() => import('../../components/cod-006-user-has-no-access/Cod006UserHasNoAccess'))
const Cod010TokenExpired = React.lazy(() => import('../../components/cod-010-crash-during-init-token-expired/Cod010TokenExpired'))
interface ErrorProps {
    errors: { code: string, message: string }[];
}

const Error = function ({ errors }: ErrorProps) {
  const isError = (code: string) => errors.find((e: { code: string }) => e.code === code)

  const isMainError = (code: string) => errors.find((e: { code: string }) => e.code.includes(code))

  return (
    <Container className={`mt-4 ${styles.error}`}>
      <Divider
        size="lg"
        type="blank"
      />
      <Divider
        size="lg"
        type="blank"
      />
      <Divider
        size="lg"
        type="blank"
      />
      <Row>
        <Col
          offsetMd={2}
          md={8}
        >
          <Card paddingSize="lg">
            <CardBody>
              <Suspense fallback={<div>Loading...</div>}>
                {isError('COD_000_CRASH_INIT_LOGIN_PROVIDER') && (<Cod000UCrashInitLogin errors={errors} />)}
                {isError('COD_001_USER_HAS_NO_TENANT') && (<Cod001UserHasNoTenant errors={errors} />)}
                {isMainError('CRASH_DURING_INIT') && (<CrashDuringInit errors={errors} />)}
                {isError('COD_006_USER_HAS_NO_ACCESS_TO_SUBSCRIPTION') && (<Cod006UserHasNoAccess errors={errors} />)}
                {isError('COD_010_ACCESS_TOKEN_EXPIRED') && (<Cod010TokenExpired errors={errors} />)}
              </Suspense>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Error
