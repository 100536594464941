import React, { useState } from 'react'
import { Button, Icon, Tooltip } from '@jarvis-catalyst/ui-components'
import './Help.css'
import { useSelector } from 'react-redux'
import HelpContact from './HelpContact'
import getCoreApplication from '../../../utils/getCoreApplication'
import HelpTooltip from './HelpTooltip'

const Help = function () {
  const { claims, language } = useSelector((s: any) => s.application)
  const [isOpen, setOpen] = useState(false)

  const application = getCoreApplication()

  return (
    <div className="help">
      <div className="tooltip__help">
        <HelpTooltip />
      </div>
      {application?.metadata?.contactInfo?.length && (
        <Tooltip
          type="dialog"
          width={220}
          position="top"
          content={<HelpContact
            name={claims.name}
            language={language}
            setOpen={setOpen}
            application={application}
          />}
          className="help__tooltip"
          isOpen={isOpen}
        >
          <Button
            className="help__button"
            onClick={() => setOpen(!isOpen)}
            iconLeft={<Icon
              name="Help"
              color="#FFFFFF"
              size={32}
            />}
          />
        </Tooltip>
      )}
    </div>
  )
}

export default Help
