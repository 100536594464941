export const PERMISSIONS_API_BASE_URL: string = (() => `${process.env.REACT_APP_PERMISSIONS_API_BASE_URL || ''}`)()
export const TENTANTS_API_BASE_URL: string = (() => `${process.env.REACT_APP_CATALYST_API_BASE_URL || ''}`)()
export const USER_API_BASE_URL: string = (() => `${process.env.REACT_APP_CATALYST_API_BASE_URL || ''}`)()
export const ROLES_API_BASE_URL: string = (() => `${process.env.REACT_APP_CATALYST_API_BASE_URL || ''}`)()
export const LOGS_API_BASE_URL: string = (() => `${process.env.REACT_APP_CATALYST_API_BASE_URL || ''}`)()
export const USER_PERMISSIONS_API_BASE_URL: string = (() => `${process.env.REACT_APP_CATALYST_API_BASE_URL || ''}`)()
export const REACT_APP_CUSTOM_APPLICATIONS: string = (() => `${process.env.REACT_APP_CATALYST_API_BASE_URL || ''}`)()
export const REACT_APP_TENANT_API_BASE_URL: string = (() => `${process.env.REACT_APP_TENANT_API_BASE_URL || ''}`)()
export const REACT_APP_CUSTOM_APPLICATIONS_API_BASE_URL: string = (() => `${process.env.REACT_APP_CUSTOM_APPLICATIONS_API_BASE_URL || ''}`)()
export const REACT_APP_PERMISSIONS_API_BASE_URL: string = (() => `${process.env.REACT_APP_PERMISSIONS_API_BASE_URL || ''}`)()
export const REACT_APP_SITES_API_BASE_URL: string = (() => `${process.env.REACT_APP_CATALYST_API_BASE_URL || ''}`)()
export const GLOBAL_APPS_API_BASE_URL: string = (() => `${process.env.REACT_APP_CATALYST_API_BASE_URL || ''}`)()

export const restClient = () => fetch

export enum FetchType {
    JSON = 'json',
    PLAIN = 'plain',
    CSV = 'csv'
}

export const wrapperHeaderWithToken = (token: string, extraHeaders: any = {}, type = FetchType.JSON) => {
  if (type === FetchType.PLAIN) {
    return new Headers({
      authorization: `Bearer ${token}`,
      ...extraHeaders,
    })
  }
  if (type === FetchType.CSV) {
    return new Headers({
      authorization: `Bearer ${token}`,
      'Content-Type': 'text/csv',
      ...extraHeaders,
    })
  }
  return new Headers({
    authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    ...extraHeaders,
  })
}

export const dataToBody = (data: any) => JSON.stringify(data)
