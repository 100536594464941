import { Button, Card, Col, Icon, Row } from '@jarvis-catalyst/ui-components'
import React from 'react'
import './TenantSiteCard.css'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

type TenantSiteCardProps = {
  children?: React.ReactNode
  tenant?: { id: string, name: string }
  sites?: string
  onClick?: () => void
}

const TenantSiteCard = function ({ children, tenant, sites, onClick }: TenantSiteCardProps) {
  const { t } = useTranslation()
  const { userHasTenant } = useSelector((s: any) => s.application)

  return (
    <Card className="tenant-site-card">
      {children || (
        <Row>
          <Col>
            <p>
              <Icon
                name="ProjectManagement"
                color="var(--h-4-card)"
                size={24}
              />
              <strong>{t('GLOBAL_APPS.TENANT_SITE_SELECTOR.TENANT')}</strong>
            </p>
            <p className="selected">{!userHasTenant ? t('NO_TENANTS_ASSIGNED') : tenant?.name || t('GLOBAL_APPS.TENANT_SITE_SELECTOR.TENANT_EMPTY')}</p>
          </Col>
          {sites !== undefined && <Col xs={5}>
            <p>
              <Icon
                name="ShoppingCart"
                color="var(--h-4-card)"
                size={24}
              />
              <strong>{t('GLOBAL_APPS.TENANT_SITE_SELECTOR.SITE')}</strong>
            </p>
            <p className="selected">{sites || t('GLOBAL_APPS.TENANT_SITE_SELECTOR.SITE_EMPTY')}</p>
          </Col>}
          {onClick && <Col
            xs={2}
            className="p-0 open__button"
          >
            <div className="vertically-centered">
              <Button
                type="button"
                color="primary"
                iconLeft={<Icon
                  name="ChevronRight"
                  size={32}
                />}
                onClick={onClick}
                disabled={!userHasTenant}
              />
            </div>
          </Col>}
        </Row>
      )}
    </Card>
  )
}

export { TenantSiteCard }
