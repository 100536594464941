import { combineReducers } from 'redux'
import { createGetAllReducer } from '../../utils'

export const getAllSellersStore = createGetAllReducer('SELLERS')

const rootReducer = combineReducers({
  getAll: getAllSellersStore.reducer,
})

export default rootReducer
