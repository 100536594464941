import React, { useState } from 'react'
import { flattenDeep } from 'lodash'
import { Box, Grid, Icon, Pagination } from '@jarvis-catalyst/jarvis-ui-kit'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { LogListItem } from '../../../../store/modules/logs/types'
import { formatDistanceFromNow } from '../../../../utils/date'
import './LogListing.css'
import Table, { RowsStore } from '../../../../components/table/Table'
import TableExport from '../../../../components/table/TableExport'
import LogDetail from '../detail/LogDetail'

import { logsExportAction, logsExportResetAction } from '../../../../store/modules/logs/actions'
import { sendDangerNotification } from '../../../../utils/sendNotification'
import config from '../../../shell/config'

type LogsTableProps = {
  currentPage: number
  setCurrentPage: any
  filters: any
  tenant?: string
  selectedRowsStore: RowsStore
}

const LogsTable = function ({ currentPage, setCurrentPage, filters, tenant, selectedRowsStore }: LogsTableProps) {
  const { t } = useTranslation()
  const { language } = useSelector((s: any) => s.application)
  const { data, pagination, error, loading, success } = useSelector((s: any) => s.logs.getAll)
  const exportSelector = useSelector((s: any) => s.logs.export)
  const [logDetail, setLogDetail] = useState<string | null | undefined>()
  const MAX_ROWS_TO_EXPORT = config.REACT_APP_LOG_EXPORT_LIMIT

  const options = (e: LogListItem) => (
    <div className="text-right">
      <button
        type="button"
        title="ViewDetail"
        onClick={() => (logDetail === e.id ? setLogDetail(null) : setLogDetail(e.id))}
      >
        {logDetail === e.id ? <Icon name="ChevronUp" /> : <Icon name="ChevronDown" />}
      </button>
    </div>
  )

  const headers = {
    cells: [
      { key: 'id', content: t('IDENTITY') },
      { key: 'createdAt', content: t('DATE') },
      { key: 'action', content: t('ACTION') },
      { key: 'module', content: t('APPLICATION') },
      { key: 'service', content: t('SERVICE') },
    ],
  }
  const rows = error
    ? []
    : data.map((e: LogListItem) => {
      const row = [
        {
          key: e.id,
          className: logDetail === e.id ? 'log__selected' : '',
          hasCheckbox: true,
          cells: [
            { key: 'id', content: e.identity.email },
            { key: 'createdAt', content: formatDistanceFromNow(e.createdAt, language), colSpan: 1 },
            { key: 'action', content: t(`HTTP_VERBS.${e.request.method}`) },
            { key: 'module', content: e.source.domain ? e.source.domain : 'N/A' },
            { key: 'service', content: e.source.service ? e.source.service : 'N/A' },
            { key: '', content: options(e) },
          ],
        },
      ]
      logDetail === e.id
          && row.push({
            key: 'detail',
            hasCheckbox: false,
            cells: [{ key: e.id, content: <LogDetail logId={e.id} />, colSpan: 6 }],
            className: 'log__detail',
          })
      return row
    })

  const exportLogsAction = () => {
    const filter: any = {}
    filters.forEach((x: { key: string; value: any }) => (filter[x.key] = x.value))

    if (selectedRowsStore && selectedRowsStore.list.length > 0) {
      filter.ids = selectedRowsStore.list
    }
    const selectedRows = selectedRowsStore.list.length > 0 ? selectedRowsStore.list.length : pagination.total

    if (selectedRows > MAX_ROWS_TO_EXPORT) {
      sendDangerNotification(t('EXPORT_LIMIT_MESSAGE', { max: MAX_ROWS_TO_EXPORT }))
    } else {
      logsExportAction(tenant, filter)
    }
  }

  return (
    <>
      <Grid
        item
        lg={12}
      >
        <TableExport
          rowsToExport={selectedRowsStore}
          asyncGetData={exportLogsAction}
          exportResetAction={logsExportResetAction}
          exportSelector={exportSelector}
          totalRows={pagination?.total}
        />
      </Grid>
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          lg={12}
        >
          <Table
            header={headers}
            rows={flattenDeep(rows)}
            isLoading={loading}
            errorMessage={error?.message}
            emptyMessage={t('NO_RESULTS_FOUND')}
            hasHeaderCheckbox
            testId="logs-table"
            selectedRowsStore={selectedRowsStore}
          />
        </Grid>
        <Grid
          container
          direction="row-reverse"
          spacing={1}
        >
          {success && data.length > 0 && (
            <div data-testid="logs-pagination">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <Pagination
                  color="primary"
                  currentpage={currentPage}
                  totalpages={pagination?.totalPages}
                  onChange={(event, page) => setCurrentPage(page)}
                />
              </Box>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default LogsTable
