import { Dispatch } from 'redux'
import { enhancementAction } from '../..'
import { APPLICATION_CORE_START, APPLICATION_ACTION_SET_CONFIGURATION } from '.'
import {
  ConfTenant,
  ConfToken,
  ConfLanguage,
  ConfClaims,
  ConfAvailableApplications,
  ConfErrors,
  ConfGroup,
} from './types'
import memorizer from '../../../utils/momorizer'

export const applicationStartAction = (): void =>
  enhancementAction((dispatch: Dispatch): void => {
    dispatch({
      type: APPLICATION_CORE_START,
      payload: true,
    })
  })

export const applicationSetConfigurationAction = (
  conf: ConfToken | ConfTenant | ConfLanguage | ConfClaims | ConfAvailableApplications | ConfErrors | ConfGroup,
): void =>
  enhancementAction((dispatch: Dispatch): void => {
    dispatch({
      type: APPLICATION_ACTION_SET_CONFIGURATION,
      payload: conf,
    })
  })

export const applicationUiSidebarAction = (value: 'full' | 'compact' | 'hidden', forceUpdate = false): void =>
  enhancementAction((dispatch: Dispatch, getState: () => any): void => {
    const { claims } = getState().application
    const m = memorizer(claims.nickname)
    const k = 'uiSidebarStatus'
    const prev = m.get(k)

    let v = value
    if (prev === 'compact' && value === 'full') v = prev
    if (forceUpdate) v = value
    m.set(k, v)

    dispatch({
      type: APPLICATION_ACTION_SET_CONFIGURATION,
      payload: {
        key: 'uiSidebar',
        value: v,
      },
    })
  })

export const applicationUiModalOverlay = (value: 'show' | 'hidden'): void =>
  enhancementAction((dispatch: Dispatch): void => {
    dispatch({
      type: APPLICATION_ACTION_SET_CONFIGURATION,
      payload: {
        key: 'uiModalOverlay',
        value,
      },
    })
  })

export const applicationResetCustomAppUrl = (datetime: number = new Date().getTime()): void =>
  enhancementAction((dispatch: Dispatch): void => {
    dispatch({
      type: APPLICATION_ACTION_SET_CONFIGURATION,
      payload: {
        key: 'resetCustomAppUrl',
        value: datetime,
      },
    })
  })

export const applicationUiTenantDependAction = (value: boolean): void =>
  enhancementAction((dispatch: Dispatch): void => {
    dispatch({
      type: APPLICATION_ACTION_SET_CONFIGURATION,
      payload: {
        key: 'uiTenantDepend',
        value,
      },
    })
  })

export const applicationUserHasTenantAction = (value: boolean): void =>
  enhancementAction((dispatch: Dispatch): void => {
    dispatch({
      type: APPLICATION_ACTION_SET_CONFIGURATION,
      payload: {
        key: 'userHasTenant',
        value,
      },
    })
  })
