import { Dispatch } from 'redux'
import { enhancementAction } from '../..'
import {
  getAllApplicationsStore,
  getAllTenantApplicationsStore as getAllIntegratedApplicationsStore,
  createApplicationStore,
  deleteApplicationStore,
  updateApplicationStore,
  getByIdAplicationsStore,
  getAllAppsByGroupIdStore,
  APPLICATIONS_BATCH_CREATE_RESET,
  APPLICATIONS_BATCH_CREATE_APPEND_ERROR,
  APPLICATIONS_BATCH_CREATE_APPEND_RESULT,
  APPLICATIONS_BATCH_CREATE_END,
  APPLICATIONS_BATCH_CREATE_START,
} from '.'
import { APPLICATION_ACTION_SET_CONFIGURATION } from '../application/index'
import {
  createApplication,
  deleteApplication,
  getAllApplications,
  getApplicationById,
  updateApplication,
} from '../../../services/clients/applications'
import { makeRequestFilterPagination } from '../../../services/services.utils'
import { ParamsGetAllAction } from '../../types'
import { HTTPRequestFilterPagination, HTTPResponsePagination } from '../../../services/types'
import dictionary from '../../../modules/error/dictionary'
import { deleteAction, getAction, resetAction } from '../../utils'
import { Application, ApplicationPayload, ParamsGetAllApps } from './types'
import { Tenant } from '../application/types'

const getAllApplicationsAction = (actionStore: any) =>
  (params?: ParamsGetAllAction, groupId?: string, globalApps?: boolean): void =>
    enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
      const { token } = getState().application

      dispatch({ type: actionStore.actions.START, payload: null })
      try {
        const request: HTTPRequestFilterPagination = makeRequestFilterPagination(token, params)
        request.tenant = params && params.tenant
        const data: HTTPResponsePagination<Application[]> = await getAllApplications({
          ...request,
          groupId,
          globalApps,
        })
        dispatch({
          type: actionStore.actions.RESULT,
          payload: data,
        })
      } catch (error) {
        dispatch({
          type: APPLICATION_ACTION_SET_CONFIGURATION,
          payload: {
            key: 'errors',
            value: [dictionary.COD_005_CRASH_DURING_INIT_APPLICATION_SERVICE],
          },
        })
      }
    })

export const applicationsGetAllAction = (params: ParamsGetAllApps): void =>
  getAllApplicationsAction(params.groupId ? getAllAppsByGroupIdStore : getAllApplicationsStore)(
    params,
    params.groupId,
    params.globalApps,
  )

export const tenantApplicationsGetAllAction = (
  tenant: string,
  params?: ParamsGetAllAction,
  groupId?: string,
): void =>
  getAllApplicationsAction(getAllIntegratedApplicationsStore)({ ...params, tenant }, groupId)

export const applicationCreateAction = (data: ApplicationPayload, selectedTenant?: string): void =>
  enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
    const { token } = getState().application

    dispatch({ type: createApplicationStore.actions.START, payload: null })
    try {
      const request: any = { token, data, tenant: selectedTenant }
      const result: any = await createApplication(request)
      if (result.errors && result.errors.length > 0) {
        dispatch({
          type: createApplicationStore.actions.ERROR,
          payload: result.errors[0].title,
        })
      } else {
        dispatch({
          type: createApplicationStore.actions.RESULT,
          payload: result,
        })
      }
    } catch (error) {
      dispatch({
        type: createApplicationStore.actions.ERROR,
        payload: error,
      })
    }
  })

export const applicationUpdateAction = (data: ApplicationPayload, selectedTenant: string, appId: string): void =>
  enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
    const { token } = getState().application

    dispatch({ type: updateApplicationStore.actions.START, payload: null })
    try {
      const request: any = { token, data, tenant: selectedTenant, appId }
      const result: any = await updateApplication(request)
      if (result.errors && result.errors.length > 0) {
        dispatch({
          type: updateApplicationStore.actions.ERROR,
          payload: result.errors[0].title,
        })
      } else {
        dispatch({
          type: updateApplicationStore.actions.RESULT,
          payload: result,
        })
      }
    } catch (error) {
      dispatch({
        type: updateApplicationStore.actions.ERROR,
        payload: error,
      })
    }
  })

export const applicationDeleteAction = (id: string): void => deleteAction(deleteApplicationStore, deleteApplication)(id)
export const applicationCreateResetAction = (): void => resetAction(createApplicationStore)()
export const applicationUpdateResetAction = (): void => resetAction(updateApplicationStore)()
export const applicationDeleteResetAction = (): void => resetAction(deleteApplicationStore)()
export const applicationGetByIdAction = (id: string): void => getAction(getByIdAplicationsStore, getApplicationById)(id)
export const applicationGetByIdResetAction = (): void => resetAction(getByIdAplicationsStore)()

export const batchApplicationsCreateResetAction = (): void =>
  enhancementAction((dispatch: Dispatch) => {
    dispatch({ type: APPLICATIONS_BATCH_CREATE_RESET, payload: null })
  })

export const batchApplicationsCreateAction = (data: ApplicationPayload, tenants: any[]): void =>
  enhancementAction((dispatch: Dispatch, getState: () => any) => {
    const { token } = getState().application
    dispatch({ type: APPLICATIONS_BATCH_CREATE_START, payload: null })

    if (tenants.length === 0) dispatch({ type: APPLICATIONS_BATCH_CREATE_END, payload: null })

    tenants.forEach(async (tenant: Tenant, i: number) => {
      try {
        const request: any = { token, data, tenant: tenant.id }
        const result: any = await createApplication(request)
        if (result.errors && result.errors.length > 0) {
          dispatch({
            type: APPLICATIONS_BATCH_CREATE_APPEND_ERROR,
            payload: result.errors[0].title,
          })
        } else {
          dispatch({
            type: APPLICATIONS_BATCH_CREATE_APPEND_RESULT,
            payload: result,
          })
        }
      } catch (error) {
        dispatch({ type: APPLICATIONS_BATCH_CREATE_APPEND_ERROR, payload: error })
      }

      if (tenants.length === i + 1) dispatch({ type: APPLICATIONS_BATCH_CREATE_END, payload: null })
    })
  })
