import { useSelector } from 'react-redux'
import { permissions } from '@jarvis-catalyst/custom-app-sdk'
import { MenuItem } from '../store/modules/applications/types'

export interface Permissions {
    customApp: (item: MenuItem) => boolean
}

function useHasPermissions(scope: 'tenant' | 'subscription'): Permissions {
  const { data, error } = useSelector((s: any) => s.instrospect.getUserPermissions)

  const hasUserPermission = (permission: string) => (error || !data || !data[scope]
    ? false
    : permissions.utils.hasPermission(data, scope, permission))

  const hasCustomPermissions = (item: MenuItem) => {
    if (!item.permissions?.length) return true
    return item.permissions.some((permission) => hasUserPermission(permission))
  }

  return {
    customApp: (item) => hasCustomPermissions(item),
  }
}

export const useAppPermissions = () => useHasPermissions('tenant')
