import React, { useEffect, useState } from 'react'
import { Header, RowsStore, Row } from './Table'

interface Props {
  headers: Header;
  setSelected?: (ids: string[]) => void;
  rows: Row[];
  disabled: boolean;
  hasHeaderCheckbox: boolean;
  selected?: string[];
  selectedRowsStore?: RowsStore;
}

const TableHeader: React.FC<Props> = function ({
  headers,
  rows,
  setSelected,
  disabled,
  hasHeaderCheckbox,
  selected,
  selectedRowsStore,
}: Props) {
  const [isChecked, setChecked] = useState(false)

  const rowKeys = rows?.map((x) => x.key).join()

  useEffect(() => {
    setChecked(false)
    setSelected && setSelected([])
  }, [setSelected, rowKeys])

  useEffect(() => {
    setChecked(true)
    rows.forEach(({ key, hasCheckbox }) => {
      hasCheckbox && selected && !selected.includes(key) && setChecked(false)
    })
    if (rows && rows.length === 0) {
      setChecked(false)
    }
  }, [selected, rows])

  const handleClickAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked)
    const selectedRows = rows.filter((x) => x.hasCheckbox).map((x) => x.key)

    if (e.target.checked) {
      setSelected && setSelected(selectedRows)
      selectedRows.forEach((key) => {
        selectedRowsStore && selectedRowsStore.add(key)
      })
    } else {
      setSelected && setSelected([])
      selectedRows.forEach((key) => {
        selectedRowsStore && selectedRowsStore.remove(key)
      })
    }
  }

  return (
    <thead>
      <tr>
        {setSelected && hasHeaderCheckbox && (
          <th>
            <input
              type="checkbox"
              checked={isChecked}
              disabled={disabled}
              onChange={(e) => { handleClickAll(e) }}
            />
          </th>
        )}
        {headers.cells.map((header) => (
          <th key={header.key}>
            {header.content}
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default TableHeader
