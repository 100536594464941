import React, { useEffect, useState } from 'react'
import isEmpty from 'lodash.isempty'
import { useTranslation } from 'react-i18next'
import {
  Spinner,
} from '@jarvis-catalyst/ui-components'
import { CardContent, Grid, Modal, Typography, TextField, Divider, Button, ButtonGroup } from '@jarvis-catalyst/jarvis-ui-kit'

type Tenant = {
  id: string
  name: string
  description: string
}

interface TenantModalProps {
  heading: string
  isModalOpen: boolean
  handleModalClose: any
  handleSaveClick: any
  tenant: Tenant
  hasWrite: boolean
}

const TenantModal = function ({
  handleModalClose,
  handleSaveClick,
  heading,
  isModalOpen,
  tenant,
  hasWrite,
}: TenantModalProps) {
  const { t } = useTranslation()

  const [name, setName] = useState(tenant.name || '')
  const [description, setDescription] = useState(tenant.description || '')
  const [isSaved, setIsSaved] = useState(true)

  const [isSaveDisabled, setIsSaveDisabled] = useState(true)
  const [error, setError] = useState<any>({ message: '' })

  useEffect(() => setIsSaveDisabled(isEmpty(name) || isEmpty(description)), [name, description])

  const handleSave = async () => {
    setIsSaved(false)
    try {
      await handleSaveClick({ ...tenant, name, description })
      setIsSaved(true)
      handleModalClose()
    } catch (err) {
      setIsSaved(true)
      setError(err)
    }
  }

  return (
    <Modal
      open={isModalOpen}
    >
      <Typography variant="H500">{heading}</Typography>
      <Divider />
      <CardContent>
        <Grid lg>
          <Spinner isCompleting={isSaved} />
          <TextField
            fullWidth
            disabled={!hasWrite}
            placeholder={t('TENANT_NAME_LABEL')}
            label={t('TENANT_NAME_LABEL')}
            value={name}
            onChange={(event: any) => {
              setError({ message: '' })
              setName(event.target.value)
            }}
          />
          <Typography variant="H100">{t('TENANT_NAME_NOTE')}</Typography>
          <Divider blank />
          <TextField
            fullWidth
            multiline
            minRows={5}
            disabled={!hasWrite}
            placeholder={t('DESCRIPTION')}
            value={description}
            onChange={(event: any) => {
              setDescription(event.target.value)
            }}
          />
        </Grid>
      </CardContent>
      <CardContent>
        <Grid>
          {error.message && (
            <Typography
              color="rgb(151, 36, 36)"
              variant="H600"
            >
              {`${t(error.property.toUpperCase())} `}
              {t(`ERRORS.${error.message}`)}
            </Typography>
          )}
        </Grid>
        <Grid
          container
          spacing={2}
          direction="row-reverse"
        >
          <ButtonGroup>
            <Button
              variant="text"
              onClick={handleModalClose}
            >
              {t('CANCEL')}
            </Button>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={isSaveDisabled}
            >
              {t('SAVE')}
            </Button>
          </ButtonGroup>
        </Grid>
      </CardContent>
    </Modal>
  )
}

TenantModal.defaultProps = {
// eslint-disable-next-line
  tenant: {},
}

export default TenantModal
