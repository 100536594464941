import { Dispatch } from 'react'
import { getAllGroupsStore, deleteGroupStore, createGroupStore, updateGroupStore } from '.'
import { ParamsGetAllAction } from '../../types'
import { getAllGroups, deleteGroup, createGroup, updateGroup } from '../../../services/clients/groups'
import { enhancementAction } from '../..'
import dictionary from '../../../modules/error/dictionary'
import { makeRequestFilterPagination } from '../../../services/services.utils'
import { HTTPRequestFilterPagination, HTTPResponsePagination } from '../../../services/types'
import { APPLICATION_ACTION_SET_CONFIGURATION } from '../application'
import { deleteAction, resetAction, updateAction } from '../../utils'
import { GroupPayload } from '../application/types'

export const groupsGetAllAction = (params?: ParamsGetAllAction): void =>
  enhancementAction(async (dispatch: Dispatch<any>, getState: () => any) => {
    const { token } = getState().application

    dispatch({ type: getAllGroupsStore.actions.START, payload: null })
    try {
      const request: HTTPRequestFilterPagination = makeRequestFilterPagination(token, params)
      const data: HTTPResponsePagination<any[]> = await getAllGroups(request)
      dispatch({
        type: getAllGroupsStore.actions.RESULT,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: APPLICATION_ACTION_SET_CONFIGURATION,
        payload: {
          key: 'errors',
          value: [dictionary.COD_009_CRASH_DURING_INIT_GROUPS_APP_SERVICE],
        },
      })
    }
  })

export const groupsCreateAction = (data: GroupPayload): void =>
  enhancementAction(async (dispatch: Dispatch<any>, getState: () => any) => {
    const { token } = getState().application

    dispatch({ type: createGroupStore.actions.START, payload: null })
    try {
      const request: any = { token, data }
      const result: any = await createGroup(request)

      if (result.errors && result.errors.length > 0) {
        dispatch({
          type: createGroupStore.actions.ERROR,
          payload: result.errors[0].title,
        })
      } else {
        dispatch({
          type: createGroupStore.actions.RESULT,
          payload: result,
        })
      }
    } catch (error) {
      dispatch({
        type: createGroupStore.actions.ERROR,
        payload: error,
      })
    }
  })

export const groupDeleteAction = (id: string): void => deleteAction(deleteGroupStore, deleteGroup)(id)
export const groupUpdateAction = (id: string, data: GroupPayload): void =>
  updateAction(updateGroupStore, updateGroup)(id, data)
export const groupDeleteResetAction = (): void => resetAction(deleteGroupStore)()
export const groupCreateResetAction = (): void => resetAction(createGroupStore)()
export const groupUpdateResetAction = (): void => resetAction(updateGroupStore)()
