/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { Link } from 'react-router-dom'
import {
  applicationResetCustomAppUrl,
} from '../../../store/modules/application/actions'

const LinkWrapper = function (props: any) {
  const handlerClick = () => {
    applicationResetCustomAppUrl()
  }
  return <Link
    {...props}
    onClick={handlerClick}
  >
    {props.children}
  </Link>
}

export default LinkWrapper
