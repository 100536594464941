const downloadFile = (blob: Blob, filename = 'logs') => {
  const a = document.createElement('a')
  const url = window.URL.createObjectURL(blob)
  const timestamp = new Date().toISOString()
    .replace(/([^T-]+)T([^\.]+).*/g, '$1 $2')
    .replace(/-|:/g, '')
    .replace(' ', '-')
  a.href = url
  a.download = `${filename}-${timestamp}.csv`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  window.URL.revokeObjectURL(url)
}

export default downloadFile
