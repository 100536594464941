import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Grid,
  CardContent,
  IconButton,
  ButtonGroup,
  TextField,
  Button,
  Icon,
  Menu,
  Table,
  Pagination,
  Typography,
} from '@jarvis-catalyst/jarvis-ui-kit'
import { useDebounce } from '@jarvis-catalyst/ui-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { rolesGetAllActionV2, roleDeleteAction, roleDeleteResetAction } from '../../../../store/modules/roles/actions'
import { rolesGetAllSelector, rolesDeleteSelector } from '../../../../store/modules/roles/selectors'
import useAppendQS from '../../../../hooks/useAppendQS'
import { ROUTES } from '../../../../const'
import useHasPermissionAndHasSomeScopes from '../../../../hooks/useHasPermissionAndHasSomeScopes'
import { sendDangerNotification, sendSuccessNotification } from '../../../../utils/sendNotification'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAfterCommit = (rolesDeleteStore, setProcessDelete) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (rolesDeleteStore.success && !rolesDeleteStore.loading) {
      roleDeleteResetAction()
      setProcessDelete()
      sendSuccessNotification(t('ACTIONS.DELETED'))
    }

    if (rolesDeleteStore.error && !rolesDeleteStore.loading) {
      sendDangerNotification(t(`ACTIONS.${rolesDeleteStore.errorMessage}`))
    }
  }, [rolesDeleteStore, setProcessDelete])
}

const RolesListing = function () {
  const appendQS = useAppendQS()

  const { t } = useTranslation()
  const rolesStore = useSelector(rolesGetAllSelector)
  const rolesDeleteStore = useSelector(rolesDeleteSelector)
  const [searchInput, setSearchInput] = useState('')
  const debouncedSearchTerm = useDebounce(searchInput, 500)
  const [processDelete, setProcessDelete] = useState(new Date().getTime())
  useAfterCommit(rolesDeleteStore, setProcessDelete)

  const history = useHistory()

  const hasWrite = useHasPermissionAndHasSomeScopes({
    scope: 'subscription',
    permission: 'rbac.roles',
    values: ['write'],
  })

  const handlerDelete = (id) => roleDeleteAction(id)

  useEffect(() => {
    rolesGetAllActionV2({
      name: debouncedSearchTerm,
      description: debouncedSearchTerm,
    })
  }, [debouncedSearchTerm, processDelete])

  const handlerChangePage = (_, page) => {
    rolesGetAllActionV2({
      name: debouncedSearchTerm,
      description: debouncedSearchTerm,
      page,
    })
  }

  const handlerChangeInputSearch = ({ target }) => setSearchInput(target.value)

  const rows = rolesStore.data
    ? rolesStore.data.map((role) => ({
      ...role,
      key: role.id,
      actions: hasWrite ? (
        <ButtonGroup type="table">
          <IconButton
            name={role.createdBy === 'USER' ? 'Edit' : 'View'}
            label={`button-${role.createdBy === 'USER' ? 'edit' : 'view'}`}
            onClick={() => history.push(appendQS(`${ROUTES.ROLE_VIEW}/${role.id}`))}
            disabled={rolesDeleteStore.loading}
          />
          {role.createdBy === 'USER' ? (
            <Menu
              AnchorComponent={IconButton}
              AnchorComponentProps={{ label: 'Delete', name: 'Delete', disabled: rolesDeleteStore.loading }}
              contents={[
                { content: t('DELETE_WARNING'), icon: 'Warning', disabled: true, key: 0 },
                {
                  content: (
                    <Grid>
                      <Button variant="text">{t('CANCEL')}</Button>
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => handlerDelete(role.id)}
                      >
                        {t('CONFIRM')}
                      </Button>
                    </Grid>
                  ),
                  key: 1,
                },
              ]}
            />
          ) : (
            <IconButton
              name="Delete"
              label="button-delete"
              disabled
            />
          )}
        </ButtonGroup>
      ) : (
        <ButtonGroup type="table">
          <IconButton
            name="View"
            label="button-view"
            onClick={() => history.push(appendQS(`${ROUTES.ROLE_VIEW}/${role.id}`))}
            disabled={role.createdBy !== 'USER'}
          />
        </ButtonGroup>
      ),
    }))
    : []

  const columns = [
    { content: <Typography variant="H500">{t('NAME')}</Typography>, key: 'name' },
    { content: <Typography variant="H500">{t('DESCRIPTION')}</Typography>, key: 'description' },
    { content: '', key: 'actions', align: 'right' },
  ]

  return (
    <div style={{ padding: 0 }}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={8}
          md={4}
          lg={5}
          xl={5}
        >
          <TextField
            autoFocus
            fullWidth
            icon="Search"
            placeholder={t('SEARCH_ROLES_PLACEHOLDER')}
            value={searchInput}
            onChange={handlerChangeInputSearch}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={8}
          lg={7}
          xl={7}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            {hasWrite && process.env.REACT_APP_RBAC_VERSION !== 'v2' && (
              <Button
                variant="contained"
                size="large"
                startIcon={<Icon name="Plus" />}
                disabled={!hasWrite}
                onClick={() => history.push(appendQS(ROUTES.ROLE_CREATE))}
              >
                {t('ADD_ROLE')}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
      <CardContent sx={{ padding: 0, paddingTop: 3.5 }}>
        <Table
          columns={columns}
          loading={rolesStore.loading}
          rows={rows}
          emptyMessage={rolesStore.errorMessage ? rolesStore.errorMessage : t('EMPTY_VIEW_MESSAGE_ROLE')}
        />
      </CardContent>
      <CardContent sx={{ padding: 0, paddingTop: 3 }}>
        {rolesStore.data && rolesStore.data.length > 0 && (
          <Grid
            container
            direction="row-reverse"
          >
            <Grid item>
              <Pagination
                color="primary"
                currentpage={rolesStore.pagination.page}
                totalpages={rolesStore.pagination.totalPages}
                onChange={handlerChangePage}
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </div>
  )
}

export default RolesListing
