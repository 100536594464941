import { useSelector } from 'react-redux'

const useLanguageAndTenantId = () => {
  const { activeTenant, language } = useSelector((s: any) => s.application)
  return {
    language,
    tenantId: activeTenant.id,
  }
}

export default useLanguageAndTenantId
