import React from 'react'
import { Badge, Tooltip, SimpleList } from '@jarvis-catalyst/ui-components'

const buildBagdesWithTooltip = (bagdes: string[], limit?: number, className?: string) : JSX.Element | JSX.Element[] => {
  if (!bagdes) {
    return <span />
  }
  const maxElements = limit || Math.min(bagdes.length, 3)
  const badgesElements = bagdes.slice(0, maxElements)
  const tooltipElements = bagdes.slice(maxElements, bagdes.length)

  const list = badgesElements.map((e, idx) => <Badge key={idx}>{e}</Badge>)
  const tooltip = <Tooltip
    className="tooltip-bagdes"
    content={<SimpleList items={tooltipElements} />}
  >
    <Badge key="aditional">
      {' +'}
      {tooltipElements.length}
    </Badge>
  </Tooltip>

  return className ? <span className={className}>
    {list}
    {' '}
    {tooltipElements.length > 0 ? tooltip : '' }
  </span> : list
}
export default buildBagdesWithTooltip
