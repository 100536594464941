import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './TenantSiteSelector.css'
import { useTranslation } from 'react-i18next'
import { Grid, GridItem, Icon, Text } from '@jarvis-catalyst/ui-components'
import { sitesGetAllAction } from '../../../../store/modules/sites/actions'
import { TenantSiteCard } from '../tenantSiteCard/TenantSiteCard'
import ErrorComponent from '../error/ErrorComponent'
import { Site } from '../../../../store/modules/sites/types'
import TenantSiteTable from '../tenantSiteListing/TenantSiteTable'

type SitesListingProps = {
    selectedTenant: { id: string, name: string }
    selectedSite?: Site
    setSelectedSite: (d: any) => void
}

const SitesListing = function ({ selectedTenant, selectedSite, setSelectedSite }: SitesListingProps) {
  const { t } = useTranslation()
  const { data, loading, error, errorMessage } = useSelector((s: any) => s.sites.getAll)
  const [sites, setSites] = useState<Site[]>([])

  useEffect(() => {
    selectedTenant && sitesGetAllAction({ tenant: selectedTenant.id })
  }, [selectedTenant])

  useEffect(() => {
    const sitesData = data
    if (!sitesData.find((site: Site) => site.id === selectedTenant.id)) {
      sitesData.push({ id: selectedTenant.id, key: '', name: 'Default' })
    }
    setSites(sitesData)
  }, [data])

  return (
    <Grid
      repeatTemplate="minmax(20px, 1fr)"
      repeatNumber={12}
      className="tenant-site-selector grid"
    >
      <GridItem
        columnStart={1}
        colSpan={6}
      >
        <TenantSiteCard tenant={selectedTenant} />
      </GridItem>

      <GridItem
        columnStart={1}
        colSpan={10}
        className="tenant-site-selector grid__item"
      >
        <Icon
          name="ShoppingCart"
          color="#8B97AD"
        />
        <Text>
          {t('GLOBAL_APPS.TENANT_SITE_SELECTOR.SELECT_SITE')}
          {' '}
          for
          <strong>
            (
            {selectedTenant.name}
            )
          </strong>
        </Text>
      </GridItem>

      <GridItem
        columnStart={1}
        colSpan={12}
        className="tenant-site-selector grid_content"
      >
        {error ? (
          <ErrorComponent
            icon={<Icon
              name="Jarvis"
              size={48}
              color="#D41A47"
            />}
            title={errorMessage}
            text={t('GLOBAL_APPS.TENANT_SITE_SELECTOR.ERROR_MESSAGE')}
          />
        ) : (
          <TenantSiteTable
            selected={selectedSite?.id}
            data={sites}
            loading={loading}
            setSelected={setSelectedSite}
          />
        )}
      </GridItem>
    </Grid>
  )
}

export default SitesListing
