import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography, Link, IconButton } from '@jarvis-catalyst/jarvis-ui-kit'

export const RightColumn: React.FC = function () {
  const { t } = useTranslation()
  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={2}
      direction="column"
    >
      <Grid
        item
        xs={5}
      >
        <Box
          sx={{
            marginTop: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            marginLeft: 2,
          }}
        >
          <Typography variant="H600">{t('HOMEPAGE.ON_THIS_PAGE')}</Typography>
          <Typography variant="H200">{t('HOMEPAGE.YOUR_ACC_ROLES')}</Typography>
          <Typography variant="H200">{t('HOMEPAGE.LINKED_DOCS')}</Typography>
          <Link variant="H300">FAQ</Link>
        </Box>
      </Grid>
      <Grid
        item
        xs={5}
      >
        {/* TODO: remove boolean condition when Google Analytics is available*/}
        {false && (
          <Box sx={{ marginTop: '16px', display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="H500">{t('HOMEPAGE.IS_HELPFUL')}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 6 }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                <IconButton
                  color="primary"
                  label="Edit"
                  name="Check"
                />
                <Box sx={{ mt: 0.5 }}>
                  <Typography variant="H100">{t('YES')}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                <IconButton
                  color="primary"
                  label="Edit"
                  name="Close"
                />
                <Box sx={{ mt: 0.5 }}>
                  <Typography variant="H100">{t('NO')}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Grid>
    </Grid>
  )
}
