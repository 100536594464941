import { CustomUpdateRequest, HTTPRequestCreate, HTTPRequestFilterPagination } from '../types'
import { REACT_APP_SITES_API_BASE_URL } from '../configuration'
import { createService, deleteByIdService, getAllService, updateService } from '../services.utils'
import { Site } from '../../store/modules/sites/types'

export const getAllSites = async (req: HTTPRequestFilterPagination): Promise<any> =>
  getAllService(req, (_: any, params: any) => `${REACT_APP_SITES_API_BASE_URL}/sites/v1?${params}`)

export const updateSite = async (req: CustomUpdateRequest): Promise<any> =>
  updateService<any>(req, (req: any) => `${REACT_APP_SITES_API_BASE_URL}/sites/v1/${req.id}`)

export const createSite = (req: HTTPRequestCreate<Site>): Promise<any> =>
  createService<Site>(req, () => `${REACT_APP_SITES_API_BASE_URL}/sites/v1`)

export const deleteSite = async (req: any): Promise<any> =>
  deleteByIdService(req, (req: any) => `${REACT_APP_SITES_API_BASE_URL}/sites/v1/${req.id}`)
