import React from 'react'
import { Autocomplete, Icon, Progress, Alert } from '@jarvis-catalyst/jarvis-ui-kit'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CardBlock from '../../../../components/CardBlock/CardBlock'

type TenantsSelectorProps = {
  tenants: string[]
  setTenants: (tenants: string[]) => void
  disabled: boolean
  init: boolean
}

export const TenantsSelector = function ({ tenants, setTenants, disabled, init }: TenantsSelectorProps) {
  const { t } = useTranslation()
  const { data, error } = useSelector((s: any) => s.tenants.getAll)

  const parseTenant = (tenants: string[]) => tenants.map((tenant: any) => ({ value: tenant.id, label: tenant.name }))

  const handleChange = (tenants: any): void => {
    const parsedTenants = tenants.map((tenant: any) => ({ id: tenant.value, name: tenant.label }))
    setTenants(parsedTenants as any)
  }

  return (
    <CardBlock title={t('MANAGE_CUSTOM_APPLICATION.TENANTS_TITLE')}>
      {error ? (
        <span data-testid="icon-error">
          <Icon
            name="Warning"
            size="medium"
            color="#e0350b"
          />
          {t('MANAGE_CUSTOM_APPLICATION.TENANTS_ERROR')}
        </span>
      ) : init ? (
        <div className="application_mgmt--dropdown">
          <Autocomplete
            disabled={disabled}
            defaultValue={parseTenant(tenants)}
            multiple
            options={data.map((tenant: any) => ({ value: tenant.id, label: tenant.name }))}
            placeholder={t('MANAGE_CUSTOM_APPLICATION.TENANTS_LABEL')}
            onChange={(e, option) => {
              if (!disabled) {
                handleChange(option)
              }
            }}
            value={parseTenant(tenants)}
          />
          <Alert color="info">{t('MANAGE_CUSTOM_APPLICATION.TENANTS_INFO')}</Alert>
        </div>
      ) : (
        <Progress
          circle
          color="secondary"
        />
      )}
    </CardBlock>
  )
}
