import React from 'react'
import { Grid, GridItem, Text } from '@jarvis-catalyst/ui-components'
import './ErrorComponent.css'

type ErrorComponentProps = {
    icon: React.ReactNode
    title: string
    text: React.ReactNode
}

const ErrorComponent = function ({ icon, text, title }: ErrorComponentProps) {
  return (
    <Grid
      repeatTemplate="minmax(20px, 1fr)"
      repeatNumber={12}
      className="error"
    >
      <GridItem
        columnStart={5}
        colSpan={4}
      >
        {icon}
      </GridItem>
      <GridItem
        columnStart={2}
        colSpan={10}
      >
        <Text><strong>{title}</strong></Text>
      </GridItem>
      <GridItem
        columnStart={2}
        colSpan={10}
      >
        <Text>{text}</Text>
      </GridItem>
    </Grid>
  )
}

export default ErrorComponent
