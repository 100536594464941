import React from 'react'
import { Checkbox, Box, Typography as Text, Grid, Button, Icon } from '@jarvis-catalyst/jarvis-ui-kit'

type Scope = { name: string; selected: boolean }

type CustomPermission = {
  value: string
  description: string
  label: string
  id: string
  scopes: Scope[]
}

type CustomPermissionProps = {
  customPermission: CustomPermission
  onChange: (CustomPermission: any) => void
  onRemove: (CustomPermission: any) => void
  hasWrite: boolean
}

const CustomPermission = function ({ hasWrite, customPermission, onChange, onRemove }: CustomPermissionProps) {
  const toggleScope = (scope: Scope) => {
    onChange({
      ...customPermission,
      scopes: customPermission.scopes.map((s: Scope) => (s.name === scope.name ? { ...s, selected: !s.selected } : s)),
    })
  }

  const handlerRemove = () => {
    onRemove(customPermission)
  }

  return (
    <Box className="uci__hover pl-2">
      <Grid
        container
        direction="row"
      >
        <Grid
          item
          xs={9}
          sm={9}
        >
          <Text variant="H200">
            <strong>{customPermission.label}</strong>
            {' '}
            {customPermission.description && `(${customPermission.description})`}
          </Text>
        </Grid>
        <Grid
          item
          xs={3}
          sm={3}
        >
          <Box className="text-right">
            <Button
              disabled={!hasWrite}
              size="small"
              onClick={handlerRemove}
            >
              <Icon
                name="CloseSmall"
                color="var(--c-danger)"
              />
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Box className="uci__badge__container">
        {customPermission.scopes.map((s: any, i: number) => (
          <span key={i}>
            <Checkbox
              disabled={!hasWrite}
              size="medium"
              value={s.selected}
              checked={s.selected}
              onChange={() => toggleScope(s)}
              label={s.name}
            />
          </span>
        ))}
      </Box>
    </Box>
  )
}

export default CustomPermission
