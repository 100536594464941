import permissionsUtils from './permissions/utils'
import contextReducer from './store/contextReducer'
import { setApplicationContextAction } from './store/actions'
import ChannelRoot from './channel'
import { sendNotification, setModalOverlay, setSidebar } from './channel'
import { getBaseUrl, getEnv } from './utils'

export { default as Channel } from './channel'
export { sendNotification, setModalOverlay, setSidebar } from './channel'

export { setApplicationContextAction } from './store/actions'
export { default as reducer } from './store/contextReducer'

export { getBaseUrl, getEnv } from './utils'

export const DEV = 'dev'

export type LocaleChange = null | {
	locale: 'es' | 'en'
}

export type AuthTokenChange = null | {
	token: string
}

export type TenantChange = null | {
	id: string
}

export type UserDetailsChange = null | {
	__raw: string
	nickname: string
	name: string
	picture: string
	updated_at: string
	email: string
	email_verified: boolean
	iss: string
	sub: string
	aud: string
	iat: number
	exp: number
	nonce: string
}

export type UserPermissionsChanged = null | {
	subscription: {
		[key: string]: any
	}
	tenant: {
		[key: string]: any
	}
	seller: {
		[key: string]: any
	}
	site: {
		[key: string]: any
	}
}

export type ContextApplication = {
	localeChange: LocaleChange
	authTokenChange: AuthTokenChange
	tenantChange: TenantChange
	userDetailsChange: UserDetailsChange
	userPermissionsChanged: any
	[key: string]: any
}

export type SendNotification = {
	level: 'success' | 'warning' | 'danger' | 'info'
	timeout: number
	message: {
		title: string
		text: string
	}
}

// Utils
export const utils = {
	getBaseUrl,
	getEnv,
}

// Channel
export const eventTypesList = [
	'userDetailsChange',
	'tenantChange',
	'localeChange',
	'authTokenChange',
	'userPermissionsChanged',
]

export const eventTypes = {
	APPLICATION_LOADED: 'applicationLoaded',
	TENANT_CHANGE: 'tenantChange',
	LOCALE_CHANGE: 'localeChange',
	AUTH_TOKEN_CHANGE: 'authTokenChange',
	USER_PERMISSIONS_CHANGED: 'userPermissionsChanged',
}

export const commandTypes = {
	PUSH_NAVIGATION_HISTORY: 'pushNavigationHistory',
	DISPLAY_NOTIFICATION: 'displayNotification',
	DISPLAY_SIDEBAR: 'displaySidebar',
	DISPLAY_MODAL_OVERLAY: 'displayModalOverlay',
}

export const channel = {
	Channel: ChannelRoot,
	eventTypes,
	commandTypes,
	store: {
		reducer: contextReducer,
		actions: {
			setApplicationContextAction,
		},
	},
	ui: {
		sendNotification,
		setModalOverlay,
		setSidebar,
	},
}

// Permissions

export type Scope = 'subscription' | 'tenant' | 'site' | 'seller'

export interface UserPermission {
	[propName: string]: string[]
}
export interface UserPermissions {
	subscription: UserPermission
	tenant: UserPermission
	seller: UserPermission
	site: UserPermission
}

export const permissions = {
	utils: permissionsUtils,
}
