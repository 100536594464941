import { Dispatch } from 'redux'
import { enhancementAction } from '../../index'
import {
  USER_PERMISSIONS_GET_START,
  USER_PERMISSIONS_GET_RESULT,
  USER_PERMISSIONS_GET_ERROR,
  getRolesByAccountIdStore,
} from '.'
import { getUserPermissions, getRolesByAccountId } from '../../../services/clients/introspect'
import { getAction, resetAction } from '../../utils'

export const userPermissionsGetAction = (): void =>
  enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
    const { token, activeTenant, claims } = getState().application
    const req = {
      token,
      userId: claims.accountId,
      tenant: activeTenant?.id,
      ...(activeTenant?.sites?.length > 0 && { site: activeTenant.sites[0].id }),
      ...(activeTenant?.sellers?.length > 0 && { seller: activeTenant.sellers[0].id }),
    }

    dispatch({ type: USER_PERMISSIONS_GET_START, payload: null })
    try {
      const data = await getUserPermissions(req)
      dispatch({ type: USER_PERMISSIONS_GET_RESULT, payload: data })
    } catch (error) {
      dispatch({ type: USER_PERMISSIONS_GET_ERROR, payload: error })
    }
  })

export const rolesByAccountIdResetAction = (): void => resetAction(getRolesByAccountIdStore)()
export const rolesByAccountIdAction = (id: string): void => getAction(getRolesByAccountIdStore, getRolesByAccountId)(id)
