export const URL_ACTIVITY = 'https://confluence.falabella.tech/display/PLSRE/Audit+Log'
export const URL_ACTIVITY_GUIDE_1 = 'https://confluence.falabella.tech/display/PLSRE/How+to+check+and+find+audit+logs'
export const URL_ACTIVITY_GUIDE_2 = 'https://confluence.falabella.tech/display/PLSRE/How+to+integrate+with+Jarvis+Audit+Log'
export const URL_MERCHANT_CENTER = 'https://confluence.falabella.tech/display/Catalyst/Merchant+Center+Jarvis+Module'
export const URL_SEARCH = 'https://confluence.falabella.tech/display/Catalyst/Search+Publishing+Rules'
export const URL_SEARCH_DOCUMENTATION = 'https://confluence.falabella.tech/display/BPSP/Search'
export const URL_PROMOTIONS = 'https://confluence.falabella.tech/display/Catalyst/Promotion+Definition+Guide'
export const URL_CUSTOMERS = 'https://confluence.falabella.tech/display/POP/1-+Core+Users'
export const URL_CONTENT = 'https://confluence.falabella.tech/display/BPSP/Content'
export const URL_CONTENT_API = 'https://confluence.falabella.tech/display/BPSP/Content+%7C+Services+Swagger+List'
export const JARVIS_DOCUMENTATION = 'https://confluence.falabella.tech/pages/viewpage.action?pageId=53383542'
export const LEAVE_FEEDBACK = 'https://forms.office.com/Pages/ResponsePage.aspx?id=a4ioxEDxi0esRySVVcMK_dpwelMww3FEo-qJToY9QPlURDlNUjFUSU5OODdVSUlVRDhTUkk2NFg0MCQlQCN0PWcu'
export const CHAT_LINK = 'https://falabella.slack.com/archives/CM19DDD0A'
