import React, { useState } from 'react'
import {
  Progress,
  CardHeader,
  CardContent,
  Switch,
  Typography,
  Box,
  Grid,
  ChipsInput,
} from '@jarvis-catalyst/jarvis-ui-kit'
import { Hint } from '@jarvis-catalyst/ui-components'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import config from '../../../../shell/config'
import { isValidEmail } from '../../../../../utils/validations'
import CustomBox from '../../../../../components/custombox/custombox'
import { CardDetailProps } from './manage-user.type'

const CardDetail = function ({ user, init, form }: CardDetailProps) {
  const { t } = useTranslation()
  const [isValidNewEmail, setIsValidNewEmail] = useState(true)
  const { REACT_APP_ALLOW_TESTING_ACCOUNT } = config
  const [onChangeInput, setOnChangeInput] = useState(false)

  const handlerValidEmail = (_:any, value:any) => {
    if (_.type === 'keydown') return
    const isValid = isValidEmail(value)
    isValid ? setIsValidNewEmail(true) : setIsValidNewEmail(false)
  }

  return (
    <CustomBox>
      <CardHeader title={t('MANAGE_USER.ACCOUNT_DETAIL')} />
      <CardContent>
        {init ? (
          <Grid
            container
            columnSpacing={3}
          >
            <Grid
              item
              lg={6}
            >
              <Box
                sx={{ display: 'flex', height: '100%' }}
                className="container-usermail-add"
              >
                {user?.data ? (
                  <Typography variant="H400">{user.data.email}</Typography>
                ) : (
                  <Controller
                    control={form.control}
                    name="users"
                    rules={{ required: true }}
                    render={({ field: { onChange, value }, formState: { errors } }) => (
                      <div style={{ width: '100%' }}>
                        <ChipsInput
                          placeholder={t('ADD_USER_INPUT_PLACEHOLDER')}
                          value={value}
                          onChange={(_, value) =>
                            onChange(value)}
                          onInputChange={(_, value) => {
                            setOnChangeInput(true)

                            handlerValidEmail(_, value)
                          }}
                          helpertext={onChangeInput ? t('PRESS_ENTER_EMAIL') : ''}

                        />
                        {errors.users && <Hint color="danger">{t('MANAGE_USER.REQUIRED_EMAIL')}</Hint>}
                        {!isValidNewEmail && <Hint color="danger">{t('HINT_INVALID_EMAIL')}</Hint>}
                      </div>
                    )}
                  />
                )}
              </Box>
            </Grid>
            <Grid
              item
              lg={6}
            >
              {REACT_APP_ALLOW_TESTING_ACCOUNT && (
                <Box sx={{ display: 'flex', height: '100%', alignItems: 'end' }}>
                  <div style={{ marginBottom: 5 }}>
                    {/* eslint-disable-next-line */}
                    <Typography variant="H400">{t('MANAGE_USER.TESTING_ACCOUNT')}:</Typography>
                    <Controller
                      control={form.control}
                      defaultValue={user?.data ? user.data.isTestingAccount : false}
                      name="isTestingAccount"
                      render={({ field: { value, onChange } }) => (
                        <Switch
                          checked={value}
                          onChange={onChange}
                          disabled={!!user?.data}
                        />
                      )}
                    />
                  </div>
                </Box>
              )}
            </Grid>
          </Grid>
        ) : (
          <Progress circle />
        )}
      </CardContent>
    </CustomBox>
  )
}

export default CardDetail
