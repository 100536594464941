import { useEffect, useRef, useState } from 'react'
import { Box, IconButton } from '@jarvis-catalyst/jarvis-ui-kit'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { createPortal } from 'react-dom'
import TenantWithRole from './TenantWithRole'
import {
  rolesGetAllSecondSelector,
  rolesGetAllSellersSelector,
  rolesGetAllSitesSelector,
} from '../../../../../store/modules/roles/selectors'
import { parseOption } from '../../../../../utils/parseOption'
import { Option, TenantsWithRoleProps } from './manage-user.type'

const TenantsWithRole = function ({
  tenants,
  fields,
  tenantRoles,
  hasWrite,
  form,
  onRemove,
  onTenantSelect,
  onChangeTenant,
}: TenantsWithRoleProps) {
  const { t } = useTranslation()
  const rolesStoreByTenant = useSelector(rolesGetAllSecondSelector)
  const rolesStoreBySite = useSelector(rolesGetAllSitesSelector)
  const rolesStoreBySeller = useSelector(rolesGetAllSellersSelector)
  const containerRef = useRef(null)
  const [isContainerDom, setIsContainerDom] = useState(false)

  useEffect(() => {
    if (containerRef.current) setIsContainerDom(true)
  }, [])
  return (
    <div
      id="container_tenants_roles"
      ref={containerRef}
    >
      {fields.map(
        (tenant, index) =>
          isContainerDom && createPortal(
            <div
              className="add_user_tenant_roles p-2"
              key={tenant.id}
            >
              <Box sx={{ display: 'flex' }}>
                <TenantWithRole
                  onTenantSelect={onTenantSelect}
                  id={tenant.id}
                  roles={tenant.roles as unknown as Option[]}
                  existingTenants={tenantRoles}
                  tenants={tenants}
                  data={rolesStoreByTenant.error ? [] : rolesStoreByTenant.data.map(parseOption)}
                  hasWrite={hasWrite}
                  index={index}
                  control={form.control}
                  rolesError={rolesStoreByTenant.error ? t(rolesStoreByTenant.errorMessage) : undefined}
                  onChangeTenant={onChangeTenant}
                />
                <IconButton
                  name="CloseSmall"
                  label="Close"
                  color="error"
                  onClick={() => onRemove(tenant.idTenant, index)}
                  disabled={!hasWrite}
                />
              </Box>
              {tenantRoles[index]?.sites?.length > 0 && (
                <Box sx={{ display: 'flex', marginLeft: 6, marginTop: 1 }}>
                  <TenantWithRole
                    id={tenantRoles[index].siteRoles.id}
                    roles={tenantRoles[index].siteRoles.roles}
                    tenants={tenantRoles[index].sites}
                    data={rolesStoreBySite.error ? [] : rolesStoreBySite.data.map(parseOption)}
                    hasWrite={hasWrite}
                    type="SITES"
                    index={index}
                    control={form.control}
                    rolesError={rolesStoreBySite.error ? t(rolesStoreBySite.errorMessage) : undefined}
                  />
                </Box>
              )}
              {tenantRoles[index]?.sellers?.length > 0 && (
                <Box sx={{ display: 'flex', marginLeft: 6, marginTop: 1 }}>
                  <TenantWithRole
                    id={tenantRoles[index].sellerRoles.id}
                    roles={tenantRoles[index].sellerRoles.roles}
                    tenants={tenantRoles[index].sellers}
                    data={rolesStoreBySeller.error ? [] : rolesStoreBySeller.data.map(parseOption)}
                    hasWrite={hasWrite}
                    type="SELLER"
                    index={index}
                    control={form.control}
                    rolesError={rolesStoreBySeller.error ? t(rolesStoreBySeller.errorMessage) : undefined}
                  />
                </Box>
              )}
            </div>,
            document.getElementById('container_tenants_roles') || document.body,
          ),
      )}
    </div>
  )
}

export default TenantsWithRole
