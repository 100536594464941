import React, { useEffect } from 'react'
import './App.css'
import { Router } from 'react-router-dom'
import { applicationStartAction, applicationSetConfigurationAction } from './store/modules/application/actions'
import Shell from './modules/shell'
import { getParams } from './modules/shell/utils/router'
import history from './modules/shell/history'
import { LANG } from './const'

const App = function () {
  const { locale } = getParams(history)
  useEffect(() => {
    applicationStartAction()
    const conf: any = { key: 'language', value: locale }
    if (locale === LANG.ES || locale === LANG.EN) applicationSetConfigurationAction(conf)
  }, [locale])

  return (
    <div className="App">
      <Router history={history}>
        <Shell />
      </Router>
    </div>
  )
}

export default App
