import React, { useEffect, useState } from 'react'
import {
  Grid,
  TextField,
  Alert, Box,
  Card,
  Breadcrumbs,
  Typography,
  CardContent,
  Select,
} from '@jarvis-catalyst/jarvis-ui-kit'
import { uniq } from 'lodash'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { logsGetAllAction } from '../../../../store/modules/logs/actions'
import { format } from '../../../../utils/date'
import { domainsGetAction, servicesGetAction } from '../../../../store/modules/domains-services/actions'
import { domainsGetSelector, servicesGetSelector } from '../../../../store/modules/domains-services/selectors'
import FilterTag from '../../../../components/filterTag/FilterTag'
import { isValidIdentity } from '../../../../utils/validations'
import { DatePicker } from '../../../../components/datePicker/DatePicker'
import './LogListing.css'
import LogsTable from './LogsTable'
import { RowsStore } from '../../../../components/table/Table'
import { getAllTenantsFilter } from '../../../../utils/filters'
import { tenantsGetAllAction } from '../../../../store/modules/tenants/actions'
import { sellersGetAllAction } from '../../../../store/modules/sellers/actions'
import CustomTitleAndTooltip from '../../../../components/customTitleAndTooltip/CustomTitleAndTooltip'

interface DropdownOption {
  label: string;
  value: string;
}

interface DropdownData {
  label: string;
  options: Array<DropdownOption>
}

interface Filter {
  label: string;
  value: string;
  type: string;
  searchPlaceholder?: string;
  data?: DropdownData
  disabled?: boolean;
  displayOptionAsBadgeValue?: boolean;
}

interface BadgeData {
  key: string;
  label: string;
  value: string;
  valueKey?: string;
}

type LogsListingProps = {
  tenant: boolean
}

enum FILTER {
  IDENTITY = 'identity',
  DATE = 'date',
  ACTION = 'action',
  MODULE = 'module',
  SERVICE = 'service',
  STATE_OLDNEW = 'state',
}

const MIN_STATE_SEARCH_LENGTH = 4
const MAX_STATE_SEARCH_LENGTH = 1024

const DEFAULT_FILTER_DAYS = 7

const LogsListing = function ({ tenant }: LogsListingProps) {
  const { t } = useTranslation()
  const { language, activeTenant } = useSelector((s: any) => s.application)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [didInit, setDidInit] = useState<boolean>(false)

  const selectedRowsStore: RowsStore = {
    list: selectedRows,
    add: (item: string) => setSelectedRows((actual) => uniq([...actual, item])),
    remove: (item: string) => setSelectedRows((actual) => actual.filter((i) => item !== i)),
    clear: () => setSelectedRows([]),
  }

  const getAllLogs = (page = 1) => {
    const filter: any = {}
    selectedFilters.forEach((x) => filter[x.key] = x.value)
    logsGetAllAction(tenant && activeTenant?.id, { page, ...filter })
  }

  useEffect(() => {
    getAllLogs(currentPage)
  }, [currentPage])

  const FILTER_TYPE_EMPTY = {
    label: t('SELECT_FILTER'),
    value: '',
    type: 'empty',
    disabled: true,
    searchPlaceholder: 'SEARCH_EMPTY_PLACEHOLDER',
  }

  const defaultEndDate = new Date()
  const pastDate = new Date()
  const defaultStartDate = new Date(pastDate.setDate(defaultEndDate.getDate() - DEFAULT_FILTER_DAYS))
  const defaultDateFilter = {
    key: FILTER.DATE,
    label: '',
    value: `${format(defaultStartDate, language)} - ${format(defaultEndDate, language)}`,
    valueKey: '',
  }

  const [sourceTypeOptions, setSourceTypeOptions] = useState<Filter[]>([])
  const [filterTypeOptions, setFilterTypeOptions] = useState<Filter[]>([])
  const [selectedFilterType, setSelectedFilterType] = useState<Filter>(FILTER_TYPE_EMPTY)
  const [identitySearchText, setIdentitySearchText] = useState('')
  const [selectedFilters, setSelectedFilters] = useState<BadgeData[]>([defaultDateFilter])
  const [selectedDomain, setSelectedDomain] = useState<string>()
  const [freeTextSearch, setFreeTextSearch] = useState('')
  const invalidSearchLength = (freeTextSearch.length && freeTextSearch.length < MIN_STATE_SEARCH_LENGTH)
    || freeTextSearch.length > MAX_STATE_SEARCH_LENGTH
  const [secondSelectorOptionSelected, setSecondSelectorOptionSelected] = useState('')
  const invalidSearchLengthMessage = freeTextSearch.length < MIN_STATE_SEARCH_LENGTH ? 'HINT_MIN_LENGTH' : 'HINT_MAX_LENGTH'

  const { data: domainsData, success: isDomainsLoaded } = useSelector(domainsGetSelector)
  const { data: servicesData, success: isServicesLoaded } = useSelector(servicesGetSelector)

  useEffect(() => {
    domainsGetAction(tenant)
    if (!tenant) {
      tenantsGetAllAction(getAllTenantsFilter({ page: 1, limit: 100, status: 'active', withSites: true }))
      sellersGetAllAction({ page: 1, limit: 100 })
    }
  }, [tenant])

  useEffect(() => {
    selectedRowsStore.clear()
  }, [selectedFilters])

  useEffect(() => {
    if (selectedDomain) {
      servicesGetAction(selectedDomain, tenant)
    }
  }, [selectedDomain])

  useEffect(() => {
    if (isDomainsLoaded) {
      const domainList = domainsData.Data.map((domain) => ({ label: domain, value: domain }))
      domainList.push({ label: 'N/A', value: 'N/A' })

      const serviceList = isServicesLoaded
        ? servicesData.Data.map((service) => ({ label: service, value: service })) : []
      serviceList?.push({ label: 'N/A', value: 'N/A' })

      const actions = ['POST', 'PUT', 'PATCH', 'DELETE'].map((value) => ({ label: t(`HTTP_VERBS.${value}`), value }))

      const OPTIONS = [
        {
          label: t('IDENTITY'),
          value: FILTER.IDENTITY,
          type: 'email',
          searchPlaceholder: 'SEARCH_IDENTITY',
        },
        {
          label: t('ACTION'),
          value: FILTER.ACTION,
          type: 'dropdown',
          data: {
            label: t('SEARCH_ACTION'),
            options: actions,
          },
          displayOptionAsBadgeValue: true,
        },
        {
          label: t('APPLICATION'),
          value: FILTER.MODULE,
          type: 'dropdown',
          data: {
            label: t('SEARCH_APPLICATION'),
            options: domainList,
          },
        },
        {
          label: t('SERVICE'),
          value: FILTER.SERVICE,
          type: 'dropdown',
          disabled: !selectedDomain,
          data: {
            label: `${t('SEARCH_SERVICE')}`,
            options: serviceList,
          },
        },
        {
          label: t('STATE_OLDNEW'),
          value: FILTER.STATE_OLDNEW,
          type: 'text',
          searchPlaceholder: `${t('SEARCH_STATE_OLDNEW')}`,
        },
      ]

      setSourceTypeOptions(OPTIONS)

      if (!filterTypeOptions.length) {
        setFilterTypeOptions(OPTIONS)
      } else {
        const keys = filterTypeOptions.map((option) => option.value)
        setFilterTypeOptions(OPTIONS.filter((option) => keys.includes(option.value)))
      }

      setSelectedFilterType(OPTIONS.find((option) =>
        option.value === selectedFilterType.value) || FILTER_TYPE_EMPTY)
    }
  }, [isDomainsLoaded, isServicesLoaded, selectedDomain, language])

  const handleSelectFilterType = (value: string) => {
    setIdentitySearchText('')
    setFreeTextSearch('')
    setSelectedFilterType(filterTypeOptions.filter((x) => x.value === value)[0])
  }

  const handlerChangeIdentitySearchText = (e: any) => {
    setIdentitySearchText(e.target.value)
  }

  const addSelectedFilter = (value: string) => {
    const newSelectedFilters = [...selectedFilters]

    newSelectedFilters.push({
      key: selectedFilterType.value,
      label: selectedFilterType.label,
      value,
      valueKey: selectedFilterType.displayOptionAsBadgeValue
        ? selectedFilterType.data?.options.filter((x: any) => x.value === value)[0].label : '',
    })

    setSelectedFilters(newSelectedFilters)

    setFilterTypeOptions(filterTypeOptions.filter((x) => x.value !== selectedFilterType.value))
    setSelectedFilterType(FILTER_TYPE_EMPTY)
    setSecondSelectorOptionSelected('')
  }

  const addDateFilter = (value: string) => {
    const newSelectedFilters = selectedFilters.filter((selectedFilter) => selectedFilter.key !== FILTER.DATE)

    newSelectedFilters.push({
      key: FILTER.DATE,
      label: '',
      value,
      valueKey: '',
    })

    setSelectedFilters(newSelectedFilters)
  }

  const handleSelectFilter = (value: string, filterType: string) => {
    if (filterType === FILTER.MODULE) {
      setSelectedDomain(value)
    }
    addSelectedFilter(value)
  }

  const handleRemoveFilter = (key: string) => {
    const filtersToBeRemoved = [key]
    if (key === FILTER.MODULE) {
      setSelectedDomain(undefined)
      filtersToBeRemoved.push(FILTER.SERVICE)
    }
    setSelectedFilters(selectedFilters.filter((x) => !filtersToBeRemoved.includes(x.key)))

    const keys = filterTypeOptions.map((option) => option.value)
    keys.push(...filtersToBeRemoved)

    setFilterTypeOptions(sourceTypeOptions.filter((option) => keys.includes(option.value)))
  }

  const handleIdentitySearchTextSubmit = (event: any) => {
    event.preventDefault()

    if (isValidIdentity(identitySearchText) && identitySearchText !== '') {
      addSelectedFilter(identitySearchText)
    }
  }

  const handleFreeTextSearchSubmit = (event: any) => {
    event.preventDefault()
    if (freeTextSearch !== '' && !invalidSearchLength) {
      addSelectedFilter(freeTextSearch)
    }
  }

  const handleSearchInput = (e: any) => {
    if (e.target.value.length <= (MAX_STATE_SEARCH_LENGTH + 1)) {
      setFreeTextSearch(e.target.value)
    }
  }

  useEffect(() => {
    if (!didInit) {
      setDidInit(true)
    } else {
      currentPage === 1 ? getAllLogs() : setCurrentPage(1)
    }
  }, [activeTenant, selectedFilters])

  const today = () => new Date()
  const lastYear = () => new Date(new Date().setFullYear(new Date().getFullYear() - 1))

  return (
    <div style={{ padding: 20 }}>
      <Card variant="elevation">
        <div style={{ marginLeft: 15, marginTop: 4 }}>
          <Breadcrumbs>
            <Link
              style={{ textDecoration: 'none', padding: 0 }}
              to={{ pathname: '/' }}
            >
              <Typography
                color="#a7a7a7"
                variant="h4"
              >
                {t('HOME')}
              </Typography>
            </Link>
            <Link
              style={{ textDecoration: 'none', padding: 0 }}
              to={{ pathname: '/logs' }}
            >
              <Typography
                color="primary"
                variant="h4"
              >
                {t('ACTIVITY')}
              </Typography>
            </Link>
          </Breadcrumbs>
        </div>
        <CustomTitleAndTooltip
          title={t('ACTIVITY_LOG')}
          tooltipTitle="ACTIVITY_LOG_POPUP"
        />
        <CardContent>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              lg={3}
              md={3}
              sm={3}
            >
              <DatePicker
                value={[defaultStartDate, defaultEndDate]}
                onChange={(e: string[]) => addDateFilter(e.map((d) => format(d, language)).join(' - '))}
                language={language}
                getMaxDate={today}
                getMinDate={lastYear}
              />
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={3}
            >
              <Select
                label={FILTER_TYPE_EMPTY.label}
                labelId="logs-filter-select"
                onChange={(e: any) => {
                  handleSelectFilterType(e.target.value)
                }}
                options={filterTypeOptions}
                variant="outlined"
                value={selectedFilterType.value || ''}
              />
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={3}
            >
              {selectedFilterType?.type === 'empty'
                && <TextField
                  id={`logs-search-${selectedFilterType.type}`}
                  autoFocus
                  fullWidth
                  icon="Search"
                  value=""
                  onChange={() => null}
                  placeholder={t(selectedFilterType.searchPlaceholder || '')}
                  disabled={selectedFilterType.disabled}
                />}
              {selectedFilterType?.type === 'email'
                && <form
                  onSubmit={handleIdentitySearchTextSubmit}
                  className={identitySearchText !== '' && !isValidIdentity(identitySearchText) ? 'hint-visible' : ''}
                >
                  <TextField
                    id={`logs-search-${selectedFilterType.type}`}
                    autoFocus
                    icon="Search"
                    value={identitySearchText}
                    onChange={handlerChangeIdentitySearchText}
                    placeholder={t(selectedFilterType.searchPlaceholder || '')}
                  />
                </form>}
              {selectedFilterType?.type === 'dropdown'
                && <div>
                  <Select
                    id={`logs-search-${selectedFilterType.type}`}
                    label={t(selectedFilterType.data?.label || '')}
                    onChange={(e: any) => {
                      setSecondSelectorOptionSelected(e.target.value)
                      handleSelectFilter(e.target.value, selectedFilterType.value)
                    }}
                    options={selectedFilterType.data?.options || []}
                    variant="outlined"
                    disabled={selectedFilterType.disabled}
                    value={secondSelectorOptionSelected}
                  />
                  {(!selectedDomain && selectedFilterType.value === FILTER.SERVICE) && (
                    <Alert color="info">{t('HINT_SELECT_APPLICATION')}</Alert>
                  )}
                </div>}
              {selectedFilterType?.type === 'text'
                && <form
                  onSubmit={handleFreeTextSearchSubmit}
                  className={invalidSearchLength ? 'hint-visible' : ''}
                >
                  <TextField
                    fullWidth
                    id={`logs-search-${selectedFilterType.type}`}
                    autoFocus
                    icon="Search"
                    value={freeTextSearch}
                    onChange={handleSearchInput}
                    placeholder={t(selectedFilterType.searchPlaceholder || '')}
                    helperText={invalidSearchLength ? t(invalidSearchLengthMessage) : ''}
                  />
                </form>}
              {identitySearchText && !isValidIdentity(identitySearchText)
                && <Box>
                  <Alert severity="warning">{t('HINT_INVALID_IDENTITY')}</Alert>
                </Box>}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              lg={12}
            >
              <div className="badges__container">
                {selectedFilters.map((selectedFilter) =>
                  selectedFilter.key !== FILTER.DATE && (
                    <FilterTag
                      key={selectedFilter.key}
                      testId={`logs-${selectedFilter.key}`}
                      filter={selectedFilter.label}
                      searchText={selectedFilter.valueKey ? selectedFilter.valueKey : selectedFilter.value}
                      handleClose={() => handleRemoveFilter(selectedFilter.key)}
                    />
                  ))}
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              lg={12}
            >
              <LogsTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                filters={selectedFilters}
                tenant={tenant && activeTenant?.id}
                selectedRowsStore={selectedRowsStore}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

export default LogsListing
