import React from 'react'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued'
import { LogState, LogSource } from '../../../../store/modules/logs/types'
import './LogDetail.css'
import { parseRbacLogs } from '../../utils/parseRbacLogs'
import { TenantList } from '../../../../store/modules/tenants/types'

const DOMAIN_JARVIS = 'jarvis'
const SERVICE_RBAC = 'rbac'

type Seller = {
  id: string,
  name:string,
}

type LogDiffProps = {
  state: LogState,
  source: LogSource,
  tenants: TenantList[]
  sellers: Seller[]
}

const LogDiff = function ({ state, source, tenants, sellers }: LogDiffProps) {
  const stringify = (text: string) => {
    try {
      let json = JSON.parse(text)
      if (typeof json === 'string') {
        json = JSON.parse(json)
      }

      if (source.domain === DOMAIN_JARVIS && source.service === SERVICE_RBAC && json.roles) {
        const tenantData = tenants.map((t) => ({ id: t.id, name: t.name }))
        const sites = tenants.flatMap((t) => t.sites)
        json = parseRbacLogs(json, tenantData, sites, sellers)
      }

      return JSON.stringify(json, undefined, 2).trim()
    } catch (_) {
      return text.trim()
    }
  }

  const newStyles = {
    variables: {
      light: {
        diffViewerBackground: 'rgba(233, 233, 233, 0.2)',
        addedBackground: 'rgba(230, 255, 210, 0.5)',
        removedBackground: 'rgba(212, 26, 71, 0.15)',
        wordAddedBackground: '#B4E396',
        wordRemovedBackground: '#E20342',
        diffViewerColor: '#42526E',
        addedColor: '#42526E',
        removedColor: '#42526E',
      },
    },
    contentText: {
      lineHeight: '20px !important',
    },
    emptyLine: {
      background: 'rgba(233, 233, 233, 0.2)',
    },
    wordDiff: {
      borderRadius: '4px',
      padding: '2px 6px',

    },
    wordRemoved: {
      color: '#FFFFFF',
    },
  }

  return (
    <ReactDiffViewer
      oldValue={stringify(state.old)}
      newValue={stringify(state.new)}
      hideLineNumbers
      compareMethod={DiffMethod.LINES}
      styles={newStyles}
    />
  )
}

export default LogDiff
