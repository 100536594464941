import { StoreAllData, StoreData } from '../../types'
import { createSelector } from '../../utils'

export const rolesGetAllSelector = createSelector < StoreAllData < any >>('roles.getAll')
export const rolesGetAllSecondSelector = createSelector < StoreAllData < any >>('roles.getAllSecond')
export const rolesGetAllSitesSelector = createSelector < StoreAllData < any >>('roles.getAllSites')
export const rolesGetAllSellersSelector = createSelector < StoreAllData < any >>('roles.getAllSellers')
export const rolesGetByIdSelector = createSelector < StoreData < any >>('roles.getById')
export const rolesCreateSelector = createSelector < StoreData < any >>('roles.create')
export const rolesUpdateSelector = createSelector < StoreData < any >>('roles.update')
export const rolesDeleteSelector = createSelector < StoreData < any >>('roles.delete')
